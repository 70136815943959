import { produce } from 'immer';

const initialState = {
    list: null,

    search_plans: null,

    widget_info: null,

    widget_edit: null,
    request_status_form: false,

    request_status: false,
    create_status: false,

    widgets_of_group: [],
};

const widgets = (state = initialState, action) => {
    switch (action.type) {

        case 'REQUEST_STATUS_FORM_WIDGET':
            return produce(state, draftState => {
                draftState.request_status_form = action.status;
            });

        case 'UPDATE_WIDGET_FLATS_TOUR_EDIT':
            return produce(state, draftState => {
                draftState.widget_edit = {
                    ...draftState?.widget_edit,
                    flats: action?.data || []
                };
            });

        case 'UPDATE_WIDGET_REALESTATE_EDIT':
            return produce(state, draftState => {
                draftState.widget_edit = {
                    ...draftState?.widget_edit,
                    realestate: action?.data || []
                };
            });

        case 'REMOVE_WIDGET':
            return produce(state, draftState => {
                draftState.list = {
                    ...state.list,
                    data: draftState?.list?.data?.filter(item => String(item?._id) != String(action?.id))
                };
            });

        case 'UPDATE_WIDGET_EDIT':
            return produce(state, draftState => {
                draftState.widget_edit = action.data || null;
            });

        case 'DELETE_WIDGET_Of_GROUP':
            return produce(state, draftState => {
                draftState.widgets_of_group = draftState?.widgets_of_group.filter(
                    (item) => item?._id != action?.id
                );
            });

        case 'UPDATE_WIDGETS_OF_GROUP':
            return produce(state, draftState => {
                draftState.widgets_of_group = action.data;
            });

        case 'UPDATE_WIDGETS':
            return produce(state, draftState => {
                draftState.list = action.data;
            });

        case 'UPDATE_WIDGET_FOR_EDIT':
            return produce(state, draftState => {
                draftState.widget_edit = action.data || null;
            });

        case 'SEARCH_PLANS':
            return produce(state, draftState => {
                draftState.search_plans = action.data;
            });

        case 'UPDATE_WIDGET_INFO':
            return produce(state, draftState => {
                draftState.widget_info = action.data;
            });

        case 'REQUEST_STATUS_WIDGET':
            return produce(state, draftState => {
                draftState.request_status = action.status;
            });

        case 'CREATE_STATUS_WIDGET':
            return produce(state, draftState => {
                draftState.create_status = action.status;
            });

        default:
            return state;
    }
};

export default widgets;
