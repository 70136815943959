import React, { useState } from 'react';
import { Input, Tooltip, Collapse, Button } from 'antd';
import ScenesAndProducts from './ScenesAndProducts';
import styles from '../../styles/vt.module.scss';
import { LoadingOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const CollapseForm = ({
    loading = false,
    virtual_tour,
    refMain,
    updateFields,
    tour_index,
    set_yaw,
    set_pitch,
    vt_id
}) => {
    const [title, set_title] = useState(virtual_tour?.title || '');
    const [secondary_title, set_secondary_title] = useState(
        virtual_tour?.secondary_title || ''
    );

    return (
        <div className={styles.blockSettings}>
            <Collapse accordion>
                <Panel header="Настройки наименования" key="1">
                    <Tooltip
                        placement="topLeft"
                        trigger={['focus']}
                        title="Наименование виртуального тура"
                    >
                        <Input
                            value={title}
                            size="large"
                            placeholder="Введите наименование виртуального тура"
                            onChange={(val) => set_title(val.target.value)}
                        />
                    </Tooltip>
                    <Tooltip
                        placement="topLeft"
                        trigger={['focus']}
                        title="Вторичное наименование виртуального тура"
                    >
                        <Input
                            value={secondary_title}
                            size="large"
                            placeholder="Введите вторичное наименование виртуального тура"
                            onChange={(val) =>
                                set_secondary_title(val.target.value)
                            }
                        />
                    </Tooltip>
                    {loading ? (
                        <Button type="primary">
                            <LoadingOutlined /> Обновляем, подождите...
                        </Button>
                    ) : (
                        <Button
                            type="primary"
                            onClick={() => {
                                updateFields({
                                    variables: {
                                        fields: {
                                            type: 'change_titles',
                                            title,
                                            secondary_title,
                                            vt_id: vt_id,
                                        },
                                    },
                                });
                            }}
                        >
                            Обновить
                        </Button>
                    )}
                </Panel>
                <Panel header="Сцены и товары" key="2">
                    <ScenesAndProducts
                        scenes={virtual_tour?.photos}
                        tour_index={tour_index}
                        styles={styles}
                        refMain={refMain}
                        updateFields={updateFields}
                        vt_id={vt_id}
                        set_yaw={set_yaw}
                        set_pitch={set_pitch}
                    />
                </Panel>
            </Collapse>
        </div>
    );
}

export default CollapseForm;
