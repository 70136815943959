import { Button, Popconfirm, Tag } from 'antd';
import styles from '../../../styles/intergration.module.scss';
import { useDispatch } from 'react-redux';
import { api } from '@/Services';

const ResetConnection = () => {
    const dispatch = useDispatch();

    const resetConnect = () => {
        dispatch(api.account.resetConnectionBitrix24());
    };

    return (
        <div className={styles.resetBlock}>
            <Tag color="red">
                После сброса настроек подключения, рекомендуется удалить
                приложение из портала битрикс24
            </Tag>
            <Popconfirm
                title="Настройки подключения будут сброшены?"
                onConfirm={resetConnect}
                okText="Сбросить настройки"
                cancelText="Нет"
            >
                <Button type="primary" danger>
                    сбросить настройки
                </Button>
            </Popconfirm>
        </div>
    );
};

export default ResetConnection;
