import React, { memo } from 'react';
import styles from '../../../../styles/form-payment.module.scss';
import { Tag, Tooltip, Popconfirm, Form, Divider, Collapse } from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import FormFloors from './forms/floors';

const Floors = ({
    form, 
    apartments_condition, 
}) => {

    Form.useWatch('conditions', { form, preserve: true });
    const getFloors = form.getFieldsValue() ? form.getFieldsValue()?.conditions[apartments_condition?.condition_key]?.apartments_conditions[apartments_condition?.apartment_condition_key]?.floors : [];

    const opFloor = (itemData, type) => {

        let updateFloors = getFloors || [];

        if(type === 'delete'){
            updateFloors = getFloors.filter((item)=>Number(item?.floor) != Number(itemData));
        }else{
            updateFloors = getFloors && getFloors.length ? [...getFloors, itemData] : [itemData];
        }

        let { conditions } = form.getFieldsValue();

        Object.assign(conditions[apartments_condition?.condition_key].apartments_conditions[apartments_condition?.apartment_condition_key], { floors: updateFloors });

        return form.setFieldsValue({
            conditions
        });

    }

    return (
        <div>
            <div>
                <h4>Добавленные этажи</h4>
                {
                    getFloors && getFloors.length ?
                    <div className={styles.itemData}>
                        {
                            getFloors.map((item, index)=>(
                                <div key={`${item?._id}_${index}`} className={styles.item}>
                                    <Tag color="lime">Этаж { item?.floor }, ЖК {item?.block?.complex_id?.title}, Блок {item?.block?.title}</Tag>
                                    <Tooltip title="Удалить" placement='bottom'>
                                        <Popconfirm
                                            title="Блок будет удален из условия"
                                            onConfirm={()=>opFloor(item?.floor, 'delete')}
                                            okText="Удалить"
                                            cancelText="Нет"
                                        >
                                            <CloseOutlined />
                                        </Popconfirm>
                                    </Tooltip>
                                </div>
                            ))
                        }
                    </div>
                    :
                        <Tag color="red">Этажей не найдено</Tag>
                }
            </div>

            <Divider/>

            <Collapse
                bordered={false}
                expandIcon={({ isActive }) => <PlusOutlined rotate={isActive ? 135 : 0} />}
                items={[{
                    key: '1',
                    label: 'Добавить этажи',
                    children: <FormFloors
                        opFloor={opFloor}
                        styles={styles}
                    />,
                    style: {
                        background: '#f1f1f1',
                        borderRadius: 7
                    }
                }]}
            />

        </div>
    )

}

export default memo(Floors);