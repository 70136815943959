import { Tooltip, Button, Space, Tag, Popconfirm } from 'antd';
import { moment } from '@/Services';
import config from '@/Config';
import { 
    CheckOutlined, 
    StopOutlined, 
    EyeOutlined, 
    DeleteOutlined, 
    EditOutlined,
    LoadingOutlined
} from '@ant-design/icons';
import ContractPaymentTimer from '../comps/listContracts/timer';

export const contract_template_columns = (formEdit, deleteContractTemplate, form_request) => [
    {
        title: 'Наименование шаблона',
        dataIndex: 'title',
        key: 'title'
    },
    {
        title: 'Дата создания',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (_, item)=>{
            return <>{ moment(item?.createdAt).format('DD/MM/YYYY в HH:mm') }</>
        },
        sorter: (a, b) => {
            const dateA = moment(a.createdAt);
            const dateB = moment(b.createdAt);
            if (dateA < dateB) {
              return -1;
            }
            if (dateA > dateB) {
              return 1;
            }
            return 0;
        },
    },
    {
        title: 'Подключенные жк',
        dataIndex: 'realestate',
        key: 'realestate',
        render: (_, item)=>{
            return (
                <>
                    {
                        item?.realestate && item?.realestate.length ?
                        item?.realestate.map((item)=>(
                            <Tooltip key={item?._id}>
                                <Tag>{item?.title}</Tag>
                            </Tooltip>
                        ))
                        :
                            <h5>Информации нет</h5>
                    }
                </>
            )
        },
    },
    {
        title: 'Управление',
        key: 'action',
        render: (_, item) => (
            <Space size="middle">
                <Tooltip title="Редактирование">
                    <Button 
                        icon={<EditOutlined />}
                        size="small" 
                        type="dashed" 
                        onClick={()=>formEdit(item)}
                    />
                </Tooltip>
                <Tooltip title="Удалить шаблон" placement='bottom'>
                    <Popconfirm
                        title="Шаблон договора будет удален"
                        onConfirm={()=>deleteContractTemplate(item?._id)}
                        okText="Удалить"
                        cancelText="Нет"
                    >
                        <Button 
                            icon={form_request ? <LoadingOutlined /> : <DeleteOutlined />}
                            danger
                            size="small" 
                            type="dashed"
                        />
                    </Popconfirm>
                </Tooltip>
            </Space>
        ),
        width: 200
    },
];

export const contract_columns = (set_pdf_contract, type, contractSuccessfulPayment) => [
    {
        title: 'Номер договора',
        dataIndex: 'contract_number',
        key: 'contract_number'
    },
    {
        title: 'Клиент',
        dataIndex: 'title',
        key: 'title',
        render: (_, item)=>{
            return (
                <p>
                    { item?.fields?.second_name + ' ' } 
                    { item?.fields?.first_name ? item?.fields?.first_name.substr(0, 1) + '. ' : '' }
                    { item?.fields?.middle_name ? item?.fields?.middle_name.substr(0, 1) + '.' : '' }
                </p>
            )
        },
    },
    {
        title: 'Квартира',
        key: 'title',
        render: (_, item)=>{
            return (
                <p style={{margin: 0}}>{`ЖК ${item?.apartment?.apartment_complex_id?.title}, блок ${item?.apartment?.block_id?.title}, квартира номер ${item?.apartment?.title}`}</p>
            )
        },
    },
    {
        title: 'Дата создания',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (_, item)=>{
            return <>{ moment(item?.createdAt).format('DD/MM/YYYY в HH:mm:ss') }</>
        },
        sorter: (a, b) => {
            const dateA = moment(a.createdAt);
            const dateB = moment(b.createdAt);
            if (dateA < dateB) {
              return -1;
            }
            if (dateA > dateB) {
              return 1;
            }
            return 0;
        },
    },
    {
        title: 'Время на оплату',
        dataIndex: 'lock_up_to',
        key: 'lock_up_to',
        render: (_, item)=> <ContractPaymentTimer lock_up_to={item?.lock_up_to} />,
        sorter: (a, b) => {
            const dateA = moment(a.lock_up_to);
            const dateB = moment(b.lock_up_to);
            if (dateA < dateB) {
              return -1;
            }
            if (dateA > dateB) {
              return 1;
            }
            return 0;
        },
    },
    {
        title: 'Статус',
        dataIndex: 'success',
        key: 'success',
        render: (_, item)=>{
            return (
                <Tooltip title={item?.status ? 'Подтверждено успешной оплатой' : 'Договор недействителен, оплата не произведена'}>
                    {
                        item?.status ?
                            <CheckOutlined style={{color:'green'}} />
                        :
                            <StopOutlined style={{color:'red'}}/>
                    }
                </Tooltip>
            )
        },
    },
    {
        title: 'Управление',
        key: 'action',
        render: (_, item) => (
            <Space size="small">
                <Tooltip title="Посмотреть договор">
                    <Button size="small" type="dashed" onClick={()=>{
                        set_pdf_contract(`${config.public}${item?.pdf_contract?.url}`);
                    }}><EyeOutlined /></Button>
                </Tooltip>
                {
                    type === 'pending_payment' ? (
                        <Popconfirm
                            title="Подтверждаете оплату?"
                            onConfirm={()=>contractSuccessfulPayment(item?._id)}
                            okText="Да"
                            cancelText="Нет"
                        >
                            <Tooltip title="Подтвердить оплату" placement='bottom'>
                                <Button size="small" type="dashed"><CheckOutlined /></Button>
                            </Tooltip>
                        </Popconfirm>
                    ) : type === 'contract_archive' ? (
                        <Popconfirm
                            title="Подтверждаете оплату?"
                            onConfirm={()=>contractSuccessfulPayment(item?._id)}
                            okText="Да"
                            cancelText="Нет"
                        >
                            <Tooltip title="Подтвердить оплату" placement='bottom'>
                                <Button size="small" type="dashed"><CheckOutlined /></Button>
                            </Tooltip>
                        </Popconfirm>
                    ) : <></>
                }
            </Space>
        ),
        width: 200
    },
];