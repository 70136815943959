import { useEffect, useState, useRef } from 'react';
import { Form, Upload, Button, Modal, Space, Typography, Divider, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { InboxOutlined } from '@ant-design/icons';
import { Image } from '@/Components';
import * as RZSpace from 'react-zoomable-ui';
import styles from '../../../../styles/custom-chess.module.scss';

const { Dragger } = Upload;
const { Text } = Typography;

const ParkingSettings = ({
    api
}) => {

    const { id } = useParams();

    const spaceRef = useRef(null);

    const parking = useSelector((state: any) => state?.chess?.realestate_parking_info || null);
    const parking_plan_info = useSelector((state: any) => state?.chess?.parking_information);

    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const [preview_image, set_preview_image] = useState(null);
    const [press_coords, set_press_coords] = useState(null);
    const [parkink_plan_url, set_parkink_plan_url] = useState(null);

    useEffect(()=>{
        console.log(667, press_coords)
    }, [press_coords]);

    useEffect(()=>{
        form.setFieldsValue(parking);
    }, [parking]);

    const onFinish = (values) => {

        set_parkink_plan_url(null);

        let fields = {
            parking_plan: values?.parking_plan?.file?.response?.file?.info?.id || null,
            parking_floor: Number(values?.parking_floor) || 0
        }

        return dispatch(api.realestate.updateChessSettings({
            fields,
            realestate_id: id
        }));

    }

    const customRequest = async ({ file, onSuccess, data }) => {

        const getFile: any = await dispatch(api.files.uploadFile(file, data?.uploadType));

        let response = {};

        response = {
            success: true,
            file: {
                name: file.name,
                size: file.size,
                type: file.type,
                info: getFile
            },
        };

        onSuccess(response);
    };

    const handlePreview = async (file) => {
        set_preview_image(file?.response?.file?.info?.full_url || file?.url);
    };

    const removeFile = (data) => {
        dispatch(api.files.removeFile(data?.response?.file?.info?.id || data?.uid));
        return true;
    }

    return (
        <div>
            <Form
                form={form}
                onFinish={onFinish}
                autoComplete="off"
                layout="vertical"
                disabled={false}
            >

                <Form.Item
                    label=""
                    name="parking_plan"
                    rules={[
                        {
                            required: true,
                            message: 'Заполните поле',
                        },
                    ]}
                >
                    <Dragger
                        maxCount={1}
                        accept="image/png, image/jpeg, image/svg+xml"
                        name='file'
                        headers={{
                            authorization: localStorage.getItem('SESSION_TOKEN'),
                        }}
                        data={{
                            uploadType: '360',
                            form_key: '360'
                        }}
                        customRequest={({ file, onSuccess, data }) => customRequest({ file, onSuccess, data })}
                        listType="picture-card"
                        onPreview={handlePreview}
                        onRemove={removeFile}
                    > 
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>    
                        <Space direction="vertical">          
                            <Text>Выберите или перетащите изображение</Text>
                            <Text type="secondary">Доступны изображения формата png, jpeg</Text>
                        </Space>   
                    </Dragger>
                </Form.Item>

                <Form.Item
                    label=""
                    name="parking_floor"
                    rules={[
                        {
                            required: true,
                            message: 'Заполните поле',
                        },
                    ]}
                >
                    <Input placeholder="Введите номер этажа паркинга"/>
                </Form.Item>

                <Form.Item style={{marginTop:5}}>
                    <Button type="primary" htmlType="submit">
                        Сохранить информацию
                    </Button>
                </Form.Item>

            </Form>

            {
                parking?.parking_plans ? 
                    <>
                        <Divider/>

                        <div className={styles.plan_buttons}>
                            {
                                parking?.parking_plans.map((item)=>(
                                    <Button onClick={()=>set_parkink_plan_url(item?.plan?.url)}>Этаж {item?.floor}</Button>
                                ))
                            }
                        </div>

                        {
                            parkink_plan_url && (
                                <div style={{
                                    width: '100%',
                                    height: 400,
                                    backgroundColor: 'rgba(0,0,0,0.04)',
                                    position: 'relative'
                                }}>
                                    <RZSpace.Space
                                        ref={spaceRef}
                                        onCreate={vp => {
                                            vp.camera.recenter(700, 0);
                                        }}
                                        onDecideHowToHandlePress={(element, coords) => {
                                            set_press_coords(coords);
                                            return null;
                                        }}
                                    >
                                        <Image src={`${parkink_plan_url}`}/>
                                    </RZSpace.Space>
                                </div>
                            )
                        }
                    </>
                : <></>
            }

            <Modal 
                open={preview_image ? true : false} 
                title="Просмотр изображения" 
                footer={null} 
                onCancel={()=>set_preview_image(null)}
                centered
                width="40%"
                destroyOnClose
            >
                <Image
                    src={preview_image}
                />
            </Modal>

        </div>
    )
}

export default ParkingSettings;