import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { api } from '@/Services';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Form, Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import FormItems from './helpers/items';
import { Loader } from '@/Components';

const { Title } = Typography;

const RealestateTemplateForm = () => {
    const [form] = Form.useForm();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const realestate_id = searchParams.get('realestate_id');
    const template_id = searchParams.get('template_id');

    const { form_meta_template_data, form_template_request } = useSelector(
        (state: any) => state?.realestate
    );

    useEffect(() => {
        dispatch(
            api.realestate.formMetaTemplate({
                realestate_id,
                template_id,
            })
        );
    }, []);

    useEffect(() => {
        form.resetFields();
        form.setFieldsValue(form_meta_template_data?.template);
    }, [form_meta_template_data?.template]);

    const onFinish = (values) => {
        const plan_photo =
            values?.plan_photo && values?.plan_photo.length
                ? values?.plan_photo[0]?.uid
                : null;
        const square_metres = Number(
            String(values?.square_metres).replace(/,/g, '.')
        );

        let fields = {
            ...values,
            plan_photo: plan_photo,
            preview_photo:
                values?.preview_photo && values?.preview_photo.length
                    ? values?.preview_photo[0]?.uid
                    : plan_photo
                    ? plan_photo
                    : null,
            realestate_id,
            template_id,
            square_metres,
        };

        dispatch(api.realestate.formTemplate(fields, navigate));
    };

    return (
        <div>
            {form_template_request && !form_meta_template_data ? (
                <Loader />
            ) : (
                <>
                    <Title level={5}>
                        Форма шаблона ЖК{' '}
                        {form_meta_template_data?.realestate?.title}
                    </Title>
                    <Form
                        form={form}
                        onFinish={onFinish}
                        autoComplete="off"
                        size="large"
                        layout="vertical"
                        disabled={form_template_request}
                    >
                        <FormItems />

                        <Form.Item style={{ marginTop: 5 }}>
                            <Button type="primary" htmlType="submit">
                                {form_template_request ? (
                                    <LoadingOutlined />
                                ) : form_meta_template_data?.status_form_edit ? (
                                    'Редактировать информацию'
                                ) : (
                                    'Добавить шаблон'
                                )}
                            </Button>
                        </Form.Item>
                    </Form>
                </>
            )}
        </div>
    );
};

export default RealestateTemplateForm;
