import React from 'react';
import { Input, Result, Tag } from 'antd';
import { useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';

const { Search } = Input;

const Formapartments = ({
    dispatch,
    api,
    realestate_id,
    realestate,
    opApartment,
    styles
}) => {

    const { search_data, request_search_data } = useSelector((state: any) => state?.payments);

    const search = (value) => {
        return dispatch(api.payments.search(realestate_id, value, 'apartments', realestate));
    }

    return (
        <>
            <div className={styles.blockSearch} style={{padding: 10}}>
                <Search size="large" loading={request_search_data} onSearch={search} placeholder="Поиск по номеру квартиры"/>

                <div className={styles.searchResultBlock} style={{marginTop: 10}}>
                    {
                    request_search_data ?
                        <LoadingOutlined />
                    : search_data && search_data.length ?
                        <div className={styles.searchResultContent}>
                            {
                                search_data.map((item)=>(
                                    <Tag 
                                        key={item?._id} 
                                        onClick={()=>opApartment(item, 'add')} 
                                        className={styles.item}>
                                        Квартира №{ item?.title }, ЖК {item?.apartment_complex_id?.title || 'Не определено'}, Блок {item?.block_id?.title || 'Не определено'}
                                    </Tag>
                                ))
                            }
                        </div>
                    :
                    <Result
                        title="Квартир не найдено"
                        extra={null}
                    />
                }
                </div>

            </div>
        </>
    )

}

export default Formapartments;