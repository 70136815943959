import React, { useMemo, useEffect } from "react";

import styles from "../styles/index.module.scss";

const LoginSuccess = () => {
	useEffect(() => {
		setTimeout(() => {
			return (window.location.href = "/");
		}, 1200);
	});

	return useMemo(
		() => (
			<div className={styles.formLogin}>
				<h1>Вы успешно авторизовались!</h1>
			</div>
		),
		[]
	);
};

export default LoginSuccess;
