import React, { FC, useContext } from 'react';

import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

import * as gqls from '@/Services/apollo/gqls';

import { message } from 'antd';

import { BookingContext } from '@/Services/context/booking';

import closeIcon from '@/Assets/close-notification.svg';
import styles from './styles/bookingNotification.module.scss';

import { bookingStatus } from '@/enums/bookingStatus';
import { role } from '@/enums/roles';

import { useSelector, useDispatch } from 'react-redux';

let hiddenNotificationsID: string[] = [];

export const BookingNotification: FC<{
    bookingType: string;
    bookingNotificationRole?: string;
    flatID: string;
    flatNumber: number;
    disableCloseBtn?: boolean;
    flat?: any;
    openButton?: boolean;
}> = ({
    bookingType,
    bookingNotificationRole,
    flatID,
    flatNumber,
    disableCloseBtn = false,
    flat,
    openButton = false,
}) => {
    const dispatch = useDispatch();

    const stateAccount: any = useSelector((state: any) => state.account);
    const accountRole = stateAccount?.user?.role?.type || null;

    const { booking, setBooking } = useContext(BookingContext);

    const paramsRoute = useParams();

    const isManager = accountRole === role.manager;
    const isRealtor = accountRole === role.realtor;
    const isBuilder = accountRole === role.builder;

    const isBookingCanceled: boolean = bookingType === bookingStatus.canceled;
    const isBookingSuccess: boolean = bookingType === bookingStatus.success;
    const isBookingWaiting: boolean = bookingType === bookingStatus.waiting;

    const [requestBooking] = useMutation(gqls.builder.requestBooking, {
        refetchQueries: () => [
            {
                query: isBuilder
                    ? gqls.builder.getChessOfRealEstate
                    : gqls.builder.getChessOfRealEstateByRealtor,
                variables: {
                    id: paramsRoute.id,
                    fields: {
                        id: paramsRoute.id,
                    },
                },
            },
        ],

        onCompleted: (data) => {
            hiddenNotificationsID = [...hiddenNotificationsID, flatID];
            dispatch({
                type: 'UPDATE_FLAT',
                flat: data?.requestBooking || null,
            });
        },
        onError: () => message.error('Упс... Что-то пошло не так'),
    });

    const [hideNotification] = useMutation(
        gqls.builder.BudgetNotificationHide,
        {
            onCompleted: () => {
                hiddenNotificationsID = [...hiddenNotificationsID, flatID];
                setBooking({
                    bookingInfo: booking.bookingInfo.filter(
                        ({ _id }: { _id: string }) =>
                            !hiddenNotificationsID.includes(_id)
                    ),
                    managers: null,
                    flatSidebar: null,
                    accountRole: null,
                });
            },
            onError: () => message.error(`Упс... Что-то пошло не так`),
        }
    );

    if (isBuilder) {
        return bookingType ? (
            <article className={styles.notificationBlock}>
                {!disableCloseBtn && (
                    <button
                        type="button"
                        onClick={() => {
                            hideNotification({
                                variables: { budgetNotificationHideId: flatID },
                            });
                        }}
                        className={styles.removeNotification}
                    >
                        <img src={closeIcon} alt="убрать уведомление" />
                    </button>
                )}

                <p className={styles.requestText}>
                    Запрос на бронь квартиры <strong>№{flatNumber}</strong>
                </p>
                {isBookingWaiting ? (
                    <div className={styles.notificationBlockRow}>
                        <button
                            type="button"
                            onClick={() => {
                                message.success('Бронь одобрена');
                                requestBooking({
                                    variables: {
                                        fields: {
                                            id: flatID,
                                            booking: bookingStatus.success,
                                            booking_notification: 'REALTOR',
                                        },
                                    },
                                });
                            }}
                        >
                            Да
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                message.warning('Бронь отклонена');
                                requestBooking({
                                    variables: {
                                        fields: {
                                            id: flatID,
                                            booking: bookingStatus.canceled,
                                            booking_notification: 'REALTOR',
                                        },
                                    },
                                });
                            }}
                        >
                            Нет
                        </button>
                    </div>
                ) : isBookingCanceled &&
                  bookingNotificationRole === role.builder ? (
                    <span className={styles.canceled}>Отменено</span>
                ) : (
                    isBookingSuccess && (
                        <>
                            <span className={styles.success}>
                                Ожидание подписи договора
                            </span>
                            <button
                                className={styles.cancelRequest}
                                onClick={() => {
                                    message.warning('Бронь отменена');
                                    requestBooking({
                                        variables: {
                                            fields: {
                                                id: flatID,
                                                booking: bookingStatus.none,
                                                booking_notification: 'REALTOR',
                                            },
                                        },
                                    });
                                }}
                            >
                                Отменить бронь
                            </button>
                        </>
                    )
                )}
            </article>
        ) : null;
    } else if (isRealtor || isManager) {
        return (
            <article className={styles.notificationBlock}>
                {!disableCloseBtn && (
                    <button
                        type="button"
                        onClick={() =>
                            hideNotification({
                                variables: { budgetNotificationHideId: flatID },
                            })
                        }
                        className={styles.removeNotification}
                    >
                        <img src={closeIcon} alt="убрать уведомление" />
                    </button>
                )}
                {isBookingWaiting &&
                String(flat?.booking_comunication?.realtor?._id) ===
                    String(stateAccount?.user?._id) ? (
                    <div className={styles.bookingBlock}>
                        <p className={styles.requestText}>
                            Запрос на бронь квартиры{' '}
                            <strong>№{flatNumber}</strong>
                        </p>
                        <span className={styles.waiting}>Ожидаем ответа</span>
                        <button
                            className={styles.cancelRequest}
                            onClick={() => {
                                message.warning('Бронь отменена');
                                requestBooking({
                                    variables: {
                                        fields: {
                                            id: flatID,
                                            booking: bookingStatus.canceled,
                                            booking_notification: 'BUILDER',
                                        },
                                    },
                                });
                            }}
                        >
                            Отменить бронь
                        </button>
                    </div>
                ) : isBookingCanceled ? (
                    <span className={styles.canceled}>Отказано</span>
                ) : isBookingSuccess ? (
                    <>
                        <span className={styles.success}>
                            Одобрено на бронь
                        </span>
                        <button
                            className={styles.cancelRequest}
                            onClick={() => {
                                message.warning('Бронь отменена');
                                requestBooking({
                                    variables: {
                                        fields: {
                                            id: flatID,
                                            booking: bookingStatus.canceled,
                                            booking_notification: 'BUILDER',
                                        },
                                    },
                                });
                            }}
                        >
                            Отменить бронь
                        </button>
                    </>
                ) : flat?.booking_comunication &&
                  flat?.booking_comunication?.realtor ? (
                    <div className={styles.realtorContacts}>
                        <h5>Квартира забронированна другим риэлтором</h5>
                        <a
                            href={`tel:+7${flat?.booking_comunication?.realtor?.phone}`}
                        >{`+7${flat?.booking_comunication?.realtor?.phone}`}</a>
                    </div>
                ) : flat?.booking_comunication &&
                  !flat?.booking_comunication?.realtor ? (
                    <button
                        className={styles.cancelRequest}
                        onClick={() => {
                            message.warning('Бронь отменена');
                            requestBooking({
                                variables: {
                                    fields: {
                                        id: flatID,
                                        booking: bookingStatus.canceled,
                                        booking_notification: 'BUILDER',
                                    },
                                },
                            });
                        }}
                    >
                        Отменить бронь
                    </button>
                ) : (
                    <></>
                )}

                {openButton && (
                    <button
                        className={styles.openFlatButton}
                        onClick={() => {
                            dispatch({
                                type: 'UPDATE_FLAT',
                                flat,
                            });
                        }}
                    >
                        Открыть
                    </button>
                )}
            </article>
        );
    } else {
        return null;
    }
};
