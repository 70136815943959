import axios from '../axios';
import { message } from 'antd';
import widgets from '../Widgets';

const Contracts = {
    successfulPayment: (contract_id, key_tab) => async dispatch => {

        try {

            await axios.post(`/v1/admin/widgets/contracts/triggers/successful-payment`, {
                contract_id
            });

            let { data } = await axios.get(`/v1/admin/widgets/contracts`, {
                params: {
                    type: key_tab
                }
            });

            return dispatch({
                type: 'DATA_CONTRACTS',
                data,
            });

        }catch(err){
            console.log(333, err)
            message.warning(
                'Ошибка. Проверьте Ваши права или попробуйте позже'
            ); 
        }

    },
    uploadImage: async (file, type) => {

        try {

            let formData = new FormData();
            formData.append('uploadType', type);
            formData.append('file', file);

            let { data } = await axios({
                url: '/v1/admin/files',
                method: 'post',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            const response = {
               "result": [
                {
                  "url": data?.full_url,
                  "name": file.name,
                  "size": file.size,
                  "width": 300
                },
            ]}

            return response;

        }catch(err){
            console.log(333, err)
            message.warning(
                'Ошибка. Проверьте Ваши права или попробуйте позже'
            ); 
        }

    },
    deleteContractTemplate: (contract_template_id) => async dispatch => {

        dispatch({
            type: 'REQUEST_FORM_CONTRACTS',
            status: true,
        });

        try {
            await axios.delete(`/v1/admin/widgets/contracts/template`, {
                params: {
                    contract_template_id
                }
            });

            dispatch(Contracts.getContractTemplates());

            dispatch({
                type: 'REQUEST_FORM_CONTRACTS',
                status: false,
            });
        } catch (err) {
            dispatch({
                type: 'REQUEST_FORM_CONTRACTS',
                status: false,
            });

            message.warning(
                'Ошибка. Проверьте Ваши права или попробуйте позже'
            );
        }

    },
    getContractTemplates: () => async dispatch => {

        dispatch({
            type: 'REQUEST_CONTRACTS',
            status: true,
        });

        try {
            let { data } = await axios.get(`/v1/admin/widgets/contracts/templates`);

            dispatch({
                type: 'CONTRACT_TEMPLATES_DATA',
                data,
            });

            dispatch({
                type: 'REQUEST_CONTRACTS',
                status: false,
            });
        } catch (err) {
            dispatch({
                type: 'REQUEST_CONTRACTS',
                status: false,
            });

            message.warning(
                'Ошибка. Проверьте Ваши права или попробуйте позже'
            );
        }

    },
    form_cu: (fields, contract_template_id, close_form_action) => async dispatch => {

        dispatch({
            type: 'REQUEST_FORM_CONTRACTS',
            status: true,
        });

        try {
            await axios.post(`/v1/admin/widgets/contracts/template/cu`, {
                ...fields,
                contract_template_id
            });

            dispatch(Contracts.getContractTemplates());

            close_form_action();

            dispatch({
                type: 'REQUEST_FORM_CONTRACTS',
                status: false,
            });

        } catch (err) {
            dispatch({
                type: 'REQUEST_FORM_CONTRACTS',
                status: false,
            });

            message.warning(
                'Ошибка. Проверьте Ваши права или попробуйте позже'
            );
        }

    },
    get: (type) => async (dispatch) => {
        dispatch({
            type: 'REQUEST_CONTRACTS',
            status: true,
        });

        try {
            let { data } = await axios.get(`/v1/admin/widgets/contracts`, {
                params: {
                    type
                }
            });

            dispatch({
                type: 'DATA_CONTRACTS',
                data,
            });

            dispatch({
                type: 'REQUEST_CONTRACTS',
                status: false,
            });
        } catch (err) {
            dispatch({
                type: 'REQUEST_CONTRACTS',
                status: false,
            });

            message.warning(
                'Ошибка. Проверьте Ваши права или попробуйте позже'
            );
        }
    },
    getMeta: (type) => async dispatch => {

        try {
            let { data } = await axios.get(`/v1/admin/widgets/contracts/form-meta`, {
                params: {
                    type
                }
            });

            if(type != 'whatsapp-business'){
                dispatch(widgets.get(null, null, 1, 100));
            }

            dispatch({
                type: 'DATA_CONTRACTS_FORM_META',
                data_type: type,
                data,
            });

            return true;

        } catch (err) {
            return false;
        }

    }
};

export default Contracts;
