import axios from '../axios';
import { message, notification } from 'antd';

const realestate = {
    getBuilder: (builder_id) => async (dispatch) => {
        dispatch({
            type: 'BUILDER_REQUEST_STATUS',
            status: true,
        });

        try {
            const { data } = await axios.get(
                `/v1/admin/builders/get-information/${builder_id}`
            );

            dispatch({
                type: 'BUILDER_REQUEST_DATA',
                data,
            });

            dispatch({
                type: 'BUILDER_REQUEST_STATUS',
                status: false,
            });
        } catch (err) {
            dispatch({
                type: 'BUILDER_REQUEST_STATUS',
                status: false,
            });
        }
    },
    deleteParkingPlace:
        ({ id, realestate_id, close }) =>
        async (dispatch) => {
            try {
                dispatch({
                    type: 'REALESTATE_PARKING_STATUS_REQUEST',
                    status: true,
                });

                const { data } = await axios.delete(
                    `/v1/admin/apartments/parking/${id}`
                );
                const getParking = await axios.get(
                    `/v1/admin/apartments/parking/${realestate_id}`
                );

                dispatch({
                    type: 'REALESTATE_PARKING_DATA',
                    data: getParking.data,
                });

                dispatch({
                    type: 'REALESTATE_PARKING_STATUS_REQUEST',
                    status: false,
                });

                close();

                return notification.info({
                    message: 'Сообщение',
                    description: data?.message,
                });
            } catch (err) {
                dispatch({
                    type: 'REALESTATE_PARKING_STATUS_REQUEST',
                    status: false,
                });

                return message.warning(err?.response?.data?.message);
            }
        },
    parkingForm: (fields, reset, close) => async (dispatch) => {
        try {
            dispatch({
                type: 'REALESTATE_PARKING_STATUS_REQUEST',
                status: true,
            });

            const { data } = await axios.post(
                `/v1/admin/apartments/parking/form`,
                fields
            );
            const getParking = await axios.get(
                `/v1/admin/apartments/parking/${fields?.realestate_id}`
            );

            dispatch({
                type: 'REALESTATE_PARKING_DATA',
                data: getParking.data,
            });

            dispatch({
                type: 'REALESTATE_PARKING_STATUS_REQUEST',
                status: false,
            });

            reset();
            close();

            return notification.info({
                message: 'Сообщение',
                description: data?.message,
            });
        } catch (err) {
            dispatch({
                type: 'REALESTATE_PARKING_STATUS_REQUEST',
                status: false,
            });

            return message.warning(err?.response?.data?.message);
        }
    },
    getParking: (realestate_id) => async (dispatch) => {
        try {
            dispatch({
                type: 'REALESTATE_PARKING_STATUS_REQUEST',
                status: true,
            });

            const { data } = await axios.get(
                `/v1/admin/apartments/parking/${realestate_id}`
            );

            dispatch({
                type: 'REALESTATE_PARKING_DATA',
                data,
            });

            dispatch({
                type: 'REALESTATE_PARKING_STATUS_REQUEST',
                status: false,
            });

            return;
        } catch (err) {
            dispatch({
                type: 'REALESTATE_PARKING_STATUS_REQUEST',
                status: false,
            });

            return message.warning(err?.response?.data?.message);
        }
    },
    updateChessSettings: (fields) => async (dispatch) => {
        try {
            const { data } = await axios.post(
                `/v1/admin/apartments/chess/settings`,
                fields
            );

            const getParking = await axios.get(
                `/v1/admin/apartments/parking/${fields?.realestate_id}`
            );

            dispatch({
                type: 'REALESTATE_PARKING_DATA',
                data: getParking?.data,
            });

            dispatch({
                type: 'REALESTATE_MODAL_SETTINGS_CHESS_PARKING',
                data: data?.data,
                fields: {
                    ...fields.fields,
                },
            });

            return notification.info({
                message: 'Сообщение',
                description: data?.message,
            });
        } catch (err) {
            return message.warning(err?.response?.data?.message);
        }
    },
    ApartmentSheetsForm: (fields) => async (dispatch) => {
        dispatch({
            type: 'APARTMENT_SHEETS_FORM_REQUEST',
            status: true,
        });

        try {
            const { data } = await axios.post(
                `/v2/admin/apartment-sheets/form`,
                fields
            );

            dispatch({
                type: 'APARTMENT_SHEETS_FORM_REQUEST',
                status: false,
            });

            return notification.info({
                message: 'Сообщение',
                description: data?.message,
            });
        } catch (err) {
            dispatch({
                type: 'APARTMENT_SHEETS_FORM_REQUEST',
                status: false,
            });

            return message.warning(err?.response?.data?.message);
        }
    },
    ApartmentSheetsFormInformation: (realestate_id) => async (dispatch) => {
        dispatch({
            type: 'APARTMENT_SHEETS_FORM_REQUEST',
            status: true,
        });

        try {
            const { data } = await axios.post(
                `/v2/admin/apartment-sheets/form-information`,
                {
                    realestate_id,
                }
            );

            dispatch({
                type: 'APARTMENT_SHEETS_FORM_DATA',
                data,
            });

            return dispatch({
                type: 'APARTMENT_SHEETS_FORM_REQUEST',
                status: false,
            });
        } catch (err) {
            dispatch({
                type: 'APARTMENT_SHEETS_FORM_REQUEST',
                status: false,
            });

            return message.warning(err?.response?.data?.message);
        }
    },
    formTemplate: (fields, navigate) => async (dispatch) => {
        try {
            dispatch({
                type: 'REQ_APARTMENT_TEMPLATE_FORM',
                status: true,
            });

            const { data } = await axios.post(
                `/v2/admin/realestate/templates`,
                fields
            );

            notification.info({
                message: 'Сообщение',
                description: data?.message,
            });

            dispatch({
                type: 'REQ_APARTMENT_TEMPLATE_FORM',
                status: false,
            });

            if (!fields?.template_id) {
                return navigate(
                    `/realestate/templates/${fields?.realestate_id}`
                );
            }
        } catch (err) {
            dispatch({
                type: 'REQ_APARTMENT_TEMPLATE_FORM',
                status: false,
            });

            return message.warning('Упс, ошибка. Проверьте Ваши права');
        }
    },
    formMetaTemplate: (params) => async (dispatch) => {
        try {
            dispatch({
                type: 'REQ_APARTMENT_TEMPLATE_FORM',
                status: true,
            });

            const { data } = await axios.get(
                `/v2/admin/realestate/templates/form-meta-information`,
                {
                    params: params,
                }
            );

            dispatch({
                type: 'META_APARTMENT_TEMPLATE_FORM',
                data,
            });

            return dispatch({
                type: 'REQ_APARTMENT_TEMPLATE_FORM',
                status: false,
            });
        } catch (err) {
            dispatch({
                type: 'REQ_APARTMENT_TEMPLATE_FORM',
                status: false,
            });

            return message.warning('Упс, ошибка. Проверьте Ваши права');
        }
    },
    deleteTemplate: (template_id, realestate_id) => async (dispatch) => {
        try {
            dispatch({
                type: 'REQ_REMOVE_APARTMENT_TEMPLATE',
                status: true,
            });

            const { data } = await axios.delete(
                `/v2/admin/realestate/templates`,
                {
                    params: {
                        realestate_id,
                        template_id,
                    },
                }
            );

            dispatch({
                type: 'REALESTATE_DELETE_ITEM_TEMPLATE',
                template_id,
            });

            notification.info({
                message: 'Сообщение',
                description: data?.message,
            });

            return dispatch({
                type: 'REQ_REMOVE_APARTMENT_TEMPLATE',
                status: false,
            });
        } catch (err) {
            dispatch({
                type: 'REQ_REMOVE_APARTMENT_TEMPLATE',
                status: false,
            });

            return message.warning('Упс, ошибка. Проверьте Ваши права');
        }
    },
    realestateTemplates: (realestate_id) => async (dispatch) => {
        try {
            dispatch({
                type: 'REQUEST_REALESTATE_TEMPLATES',
                status: true,
            });

            const { data } = await axios.get(
                `/v2/admin/realestate/templates/${realestate_id}`
            );

            dispatch({
                type: 'DATA_REALESTATE_TEMPLATES',
                data,
            });

            return dispatch({
                type: 'REQUEST_REALESTATE_TEMPLATES',
                status: false,
            });
        } catch (err) {
            dispatch({
                type: 'REQUEST_REALESTATE_TEMPLATES',
                status: false,
            });

            return message.warning('Упс, ошибка. Проверьте Ваши права');
        }
    },
    delete: (realestate_id) => async () => {
        try {
            const { data } = await axios.delete(`/v2/admin/realestate/delete`, {
                params: {
                    realestate_id,
                },
            });

            return notification.info({
                message: 'Сообщение',
                description: data?.message,
            });
        } catch (err) {
            message.warning('Упс, ошибка. Проверьте Ваши права');
        }
    },
    edit: (fields) => async (dispatch) => {
        dispatch({
            type: 'FORM_REALESTATE_REQUEST',
            status: true,
        });

        try {
            const { data } = await axios.post(
                `/v2/admin/realestate/edit`,
                fields
            );

            dispatch({
                type: 'FORM_REALESTATE_REQUEST',
                status: false,
            });

            return notification.info({
                message: 'Сообщение',
                description: data?.message,
            });
        } catch (err) {
            dispatch({
                type: 'FORM_REALESTATE_REQUEST',
                status: false,
            });

            message.warning('Упс, ошибка. Проверьте Ваши права');
        }
    },
    create: (fields, navigate, client) => async (dispatch) => {
        dispatch({
            type: 'FORM_REALESTATE_REQUEST',
            status: true,
        });

        try {
            let { data } = await axios.post(
                `/v2/admin/realestate/create`,
                fields
            );

            dispatch({
                type: 'FORM_META_INFORATION_DATA',
                data,
            });

            dispatch({
                type: 'FORM_REALESTATE_REQUEST',
                status: false,
            });

            client.reFetchObservableQueries();

            return navigate(`/realestate/form?realestate_id=${data?._id}`);
        } catch (err) {
            dispatch({
                type: 'FORM_REALESTATE_REQUEST',
                status: false,
            });

            message.warning('Упс, ошибка. Проверьте Ваши права');
        }
    },
    formMetaInformation: (realestate_id) => async (dispatch) => {
        dispatch({
            type: 'FORM_REALESTATE_REQUEST',
            status: true,
        });

        try {
            let { data } = await axios.get(
                `/v2/admin/realestate/form-meta-information`,
                {
                    params: {
                        realestate_id,
                    },
                }
            );

            dispatch({
                type: 'FORM_META_INFORATION_DATA',
                data,
            });

            dispatch({
                type: 'FORM_REALESTATE_REQUEST',
                status: false,
            });
        } catch (err) {
            dispatch({
                type: 'FORM_REALESTATE_REQUEST',
                status: false,
            });

            message.warning('Упс, ошибка. Проверьте Ваши права');
        }
    },
    searchFlats: (fields) => async (dispatch) => {
        dispatch({
            type: 'REQUEST_STATUS_SEARCH_FLATS',
            status: true,
        });

        try {
            let { data } = await axios.get(
                `/v1/admin/apartments/flats/filter`,
                {
                    params: fields,
                }
            );

            dispatch({
                type: 'SEARCH_FLATS_DATA',
                data,
            });

            dispatch({
                type: 'REQUEST_STATUS_SEARCH_FLATS',
                status: false,
            });
        } catch (err) {
            dispatch({
                type: 'REQUEST_STATUS_SEARCH_FLATS',
                status: false,
            });

            message.warning('Ошибка, попробуйте позже');
        }
    },
};

export default realestate;
