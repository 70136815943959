import { Button, Tag, Typography } from 'antd';
import styles from '../../../styles/templatePattern.module.scss';
import { useDispatch } from 'react-redux';

const { Title } = Typography;

const ItemGroup = ({ group, index }) => {
    const dispatch = useDispatch();

    const openForm = () => {
        dispatch({
            type: 'TEMPLATE_PATTERN_FIELD_MODAL_FORM',
            status: true,
            groupIndex: index,
        });
    };

    return (
        <div className={styles.groupWrapper}>
            <div className={styles.header}>
                <Title level={5} style={{ margin: 0 }}>
                    {group?.groupName}
                </Title>
            </div>
            <div className={styles.content}>
                {group?.fields && group?.fields.length ? (
                    group?.fields.map((field) => (
                        <div key={field?.id}>
                            <Title level={5}>{field?.name}</Title>
                        </div>
                    ))
                ) : (
                    <Tag color="red">Поля не добавлены</Tag>
                )}
            </div>
            <div className={styles.buttonGroup}>
                <Button className={styles.button} onClick={openForm}>
                    добавить поле
                </Button>
            </div>
        </div>
    );
};

export default ItemGroup;
