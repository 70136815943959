import React, { useState, useEffect, useMemo } from 'react';
import { Popover, Button, Input, Popconfirm, Segmented, Tooltip } from 'antd';
import styles from '../../styles/custom-chess.module.scss';
import { useDispatch } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';

const FlatItem = ({
    item,
    addFlats,
    addPenthouse,
    addPantry,
    selectBox,
    api,
    updateFlatPlanModal
}) => {
    if (item?._id) {
        item.flat = item;
    }

    const { id } = useParams();

    const dispatch = useDispatch();
    const [select_box, set_select_box] = useState(false);
    const [visible, setVisible] = useState(false);
    const [status, set_status] = useState(
        item?.flat && item?.flat?.status === 'BOOKING' ? 'Бронь'
        : item?.flat && (item?.flat.sold || item?.flat?.status === 'SOLD') ? 'Продано'
        : item?.flat && (!item?.flat.sold || item?.flat?.status === 'FREE') ? 'Свободно'
        : ''
    );
    const [inputValues, setInputValues] = useState({
        title: item?.flat?.title || '',
        count_rooms:
            item?.flat?.count_rooms || item?.flat?.template?.count_rooms || '',
        square_metres:
            item?.flat?.square_metres ||
            item?.flat?.template?.square_metres ||
            '',
        square_metre_price:
            item?.flat?.square_metre_price ||
            item?.flat?.template?.square_metre_price ||
            '',
        total_price:
            item?.flat?.total_price || item?.flat?.template?.total_price || '',
        floor: item?.flat?.floor || '',
        comment: item?.flat?.comment || '',
    });

    const [saveTimeout, setSaveTimeout] = useState(null);

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        let updateState = {
            ...inputValues,
            [name]: value,
        };

        setInputValues((prevValues) => ({
            ...prevValues,
            total_price: Number(Math.round((Number(updateState?.square_metres) || 0) * (Number(updateState?.square_metre_price) || 0))),
            [name]: value,
        }));
        if (saveTimeout) {
            clearTimeout(saveTimeout);
        }
        setSaveTimeout(setTimeout(() => saveData(updateState), 1000));
    };

    useEffect(() => {
        setSaveTimeout({
            title: item?.flat?.title || '',
            count_rooms: item?.flat?.template?.count_rooms || '',
            square_metres: item?.flat?.template?.square_metres || '',
            square_metre_price: item?.flat?.template?.square_metre_price || '',
            total_price: item?.flat?.template?.total_price || 0,
            floor: item?.flat?.floor || '',
            comment: item?.flat?.comment || '',
        });
    }, []);

    const saveData = (data) => {
        dispatch(
            api.chess.updateFlat(
                {
                    ...data,
                    total_price: Number(Math.round((Number(data?.square_metres) || 0) * (Number(data?.square_metre_price) || 0))),
                    flat_id: item?.flat?._id,
                    sold: (status === 'Продано') ? true : false,
                    status:
                        status === 'Бронь'
                            ? 'BOOKING'
                            : status === 'Свободно'
                            ? 'FREE'
                            : status === 'Продано'
                            ? 'SOLD'
                            : '',
                    realestate_id: id,
                },
                item?.flat?.apartment_complex_id
            )
        );
    };

    const changeFlatSold = (status) => {

        dispatch(
            api.chess.updateFlat({
                ...inputValues,
                flat_id: item?.flat?._id,
                realestate_id: id,
                sold: (status === 'Бронь' || status === 'Продано') ? true : false,
                status:
                    status === 'Бронь'
                        ? 'BOOKING'
                        : status === 'Свободно'
                        ? 'FREE'
                        : status === 'Продано'
                        ? 'SOLD'
                        : '',
            })
        );

        set_status(status);
    };

    const deleteFlat = () => {
        set_select_box(false);
        dispatch(
            api.chess.deleteFlat({
                ...item?.flat,
                flat_id: item?.flat?._id,
                realestate_id: id,
            })
        );
    };

    const content = () => {
        return (
            <div className={styles.contextMenuBlock}>
                <Button 
                    className={styles.button} 
                    onClick={addFlats} 
                    type="primary"
                >
                    Добавить квартиру(ы)
                </Button>
                <Button
                    className={styles.button}
                    onClick={addPenthouse}
                    type="primary"
                >
                    Добавить пентхаус
                </Button>
                <Button 
                    className={styles.button} 
                    onClick={addPantry} 
                    type="primary"
                >
                    Добавить кладовую
                </Button>
            </div>
        );
    }

    const colorOverlay = 
        select_box ? '#1677ff' 
        : status === 'Бронь' ? 'rgb(248, 239, 41)'
        : status === 'Продано' ? 'rgb(221,233,255)'
        : status === 'Свободно' ? '#5BCD3E'
        : '';

    return useMemo(()=>(
        <Popover
            trigger={['contextMenu']}
            content={content}
            title="Выберите функцию добавления"
        >
            <div
                className={styles.box}
                style={{
                    background: colorOverlay,
                    border: `1px solid ${colorOverlay}`,
                    gridArea: item?.flat && `flat_${item?.flat?._id}`,
                }}
                onClick={() => {
                    if (!item?.flat) {
                        set_select_box(!select_box);
                        selectBox(item)
                    }
                }}
            >
                {item?.flat && (
                    <div className={styles.flatForm}>
                        <div 
                            className={styles.buttonForm} 
                            onClick={() => {
                                setVisible(true);
                                dispatch({
                                    type: 'UPDATE_FLAT',
                                    flat: item?.flat,
                                });
                            }}
                        >
                            {
                                item?.flat?.pantry ?
                                    <Tooltip title="Кладовая">
                                        <img src="https://files.logovo.kz/uploads/storeroom.png"/>
                                    </Tooltip>
                                :
                                    <Tooltip title="Квартира">
                                        <img src="https://files.logovo.kz/uploads/flat-room.png"/>
                                    </Tooltip>
                            }
                            <h1>{inputValues?.title || '-'}</h1>
                        </div>

                        {visible && (
                            <div
                                className={styles.blockFormFlat}
                                style={{
                                    background: colorOverlay,
                                }}
                            >
                                <div className={styles.gorizontInputs}>
                                    <Popover
                                        content="Номер квартиры"
                                        trigger={['hover']}
                                        placement="left"
                                    >
                                        <Input
                                            placeholder="Номер квартиры"
                                            name="title"
                                            value={inputValues?.title}
                                            onChange={handleInputChange}
                                        />
                                    </Popover>
                                    <Popover
                                        content="Количество комнат"
                                        trigger={['hover']}
                                        placement="right"
                                    >
                                        <Input
                                            placeholder="Количество комнат"
                                            name="count_rooms"
                                            value={inputValues?.count_rooms}
                                            onChange={handleInputChange}
                                        />
                                    </Popover>
                                </div>
                                <div className={styles.gorizontInputs}>
                                    <Popover
                                        content="Площадь квартиры"
                                        trigger={['hover']}
                                        placement="left"
                                    >
                                        <Input
                                            placeholder="Площадь квартиры"
                                            name="square_metres"
                                            value={inputValues?.square_metres}
                                            onChange={handleInputChange}
                                        />
                                    </Popover>
                                    <Popover
                                        content="Цена за m2"
                                        trigger={['hover']}
                                        placement="right"
                                    >
                                        <Input
                                            placeholder="Цена за m2"
                                            name="square_metre_price"
                                            value={
                                                inputValues?.square_metre_price
                                            }
                                            onChange={handleInputChange}
                                        />
                                    </Popover>
                                </div>
                                <Popover
                                    content="Стоимость квартиры"
                                    trigger={['hover']}
                                    placement="left"
                                >
                                    <Input
                                        placeholder="Стоимость квартиры"
                                        name="total_price"
                                        value={inputValues?.total_price}
                                        onChange={handleInputChange}
                                    />
                                </Popover>
                                {inputValues?.floor === '' && (
                                    <Popover
                                        content="Этаж"
                                        trigger={['hover']}
                                        placement="left"
                                    >
                                        <Input
                                            placeholder="Этаж"
                                            name="floor"
                                            value={inputValues?.floor}
                                            onChange={handleInputChange}
                                        />
                                    </Popover>
                                )}

                                <Popover
                                    content="Комментарий"
                                    trigger={['hover']}
                                    placement="left"
                                >
                                    <Input
                                        placeholder="Комментарий"
                                        name="comment"
                                        value={inputValues?.comment}
                                        onChange={handleInputChange}
                                    />
                                </Popover>

                                <Segmented
                                    type="primary"
                                    block={true}
                                    size="large"
                                    options={['Продано', 'Свободно', 'Бронь']}
                                    value={status || 'Свободно'}
                                    onChange={(key) => changeFlatSold(key)}
                                />

                                <Popover
                                    content="Настройка планировка"
                                    trigger={['hover']}
                                    placement="left"
                                >
                                    <Button
                                        type="primary"
                                        onClick={() =>
                                            updateFlatPlanModal({
                                                flat_id: item?.flat?._id,
                                                status: true,
                                                form_flat_preview:
                                                    item?.flat?.template
                                                        ?.preview_photo,
                                                form_flat_plan:
                                                    item?.flat?.template
                                                        ?.plan_photo,
                                                flat_floor: item?.floor,
                                                flat_number: item?.flat?.title,
                                                realestate_id:
                                                    item?.flat
                                                        ?.apartment_complex_id,
                                                block_id: item?.flat?.block_id,
                                                coords: item?.flat?.area_points,
                                                polygon:
                                                    item?.flat
                                                        ?.area_points_for_css,
                                            })
                                        }
                                    >
                                        Планировка
                                    </Button>
                                </Popover>
                                <Popconfirm
                                    title="Вы действительно хотите удалить квартиру?"
                                    onConfirm={deleteFlat}
                                    okText="Удалить"
                                    cancelText="Нет"
                                >
                                    <Button danger type="primary">
                                        Удалить квартиру
                                    </Button>
                                </Popconfirm>
                                <Popover
                                    content="закрыть форму"
                                    trigger={['hover']}
                                    placement="top"
                                >
                                    <Button
                                        danger
                                        onClick={() => {
                                            setVisible(false);
                                            dispatch({
                                                type: 'UPDATE_FLAT',
                                                flat: null,
                                            });
                                        }}
                                        style={{
                                            position: 'absolute',
                                            top: '-30px',
                                            right: '-30px',
                                            zIndex: 10,
                                        }}
                                        shape="circle"
                                        icon={<CloseOutlined />}
                                    />
                                </Popover>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </Popover>
    ), [select_box, item, visible, inputValues, status]);
};

export default FlatItem;
