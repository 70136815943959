import React from 'react';
import styles from '../styles/leads.module.scss';
import { Menu, Dropdown, Popconfirm, Popover } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { separateNumber } from '@/Services';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ItemCompany = (props: any) => {
    const { item, deleteCompany } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const menu = (
        <Menu
            items={[
                {
                    key: '1',
                    label: (
                        <span
                            onClick={() => {
                                return navigate(`/leads/company/${item?._id}`);
                            }}
                        >
                            Открыть
                        </span>
                    ),
                },
                {
                    key: '2',
                    label: (
                        <span
                            onClick={() => {
                                dispatch({
                                    type: 'MODAL_EDIT_FORM_COMPANY',
                                    item,
                                });
                                dispatch({
                                    type: 'MODAL_FORM_COMPANY',
                                    status: true,
                                });
                            }}
                        >
                            Редактировать
                        </span>
                    ),
                },
                {
                    key: '3',
                    label: (
                        <Popconfirm
                            title="Компания будет удалена?"
                            onConfirm={() => deleteCompany()}
                            okText="Удалить"
                            cancelText="Нет"
                        >
                            <span>удалить</span>
                        </Popconfirm>
                    ),
                },
            ]}
        />
    );

    return (
        <div className={styles.item}>
            <div className={styles.section}>
                <Popover content="Наименование компании" title="">
                    <p>{item?.title}</p>
                </Popover>
            </div>
            <div className={styles.line}></div>
            <div className={styles.section}>
                <p>
                    {item?.collected_leads || 0} из {item?.quantity_leads} лидов
                </p>
            </div>
            <div className={styles.line}></div>
            <div className={styles.section}>
                <Popover content="Бюджет компании" title="">
                    <p>{separateNumber(item?.budget || 0)}₸</p>
                </Popover>
            </div>
            <div className={styles.line}></div>
            <div className={styles.section}>
                <Popover content="Тип компании" title="">
                    <p>{item?.gift ? 'Подарочная' : 'Стандартная'}</p>
                </Popover>
            </div>
            <div className={styles.line}></div>
            <div className={styles.section}>
                <Popover content="Жилой комплекс" title="">
                    <p>
                        {item?.realestate?.title
                            ? item?.realestate?.title
                            : 'Не опредено'}
                    </p>
                </Popover>
            </div>
            <div className={styles.line}></div>
            <div className={styles.section} style={{ width: 55 }}>
                <Dropdown trigger={['click']} overlay={menu}>
                    <MoreOutlined />
                </Dropdown>
            </div>
        </div>
    );
};

export default ItemCompany;
