import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { useDispatch } from 'react-redux';

import * as gqls from '@/Services/apollo/gqls';
import { session } from '@/Services';

import styles from '../styles/index.module.scss';

import logoWhite from '../../../Assets/logo-white.svg';
import mailIcon from '../../../Assets/mail.svg';
import lockIcon from '../../../Assets/lock.svg';
import { message } from 'antd';

const Login = () => {
    const dispatch = useDispatch();

    let navigate = useNavigate();

    const [email, set_email] = useState('');
    const [password, set_password] = useState('');

    const [authentication] = useMutation(gqls.users.authentication, {
        onError: (error) => {
            message.warning('Доступ запрещен');
        },
        onCompleted: (data) => {

            if(data?.authentication){

                session.set(data?.authentication?.token);
    
                dispatch({
                    type: 'UPDATE_ACCOUNT',
                    user: {
                        ...data?.authentication,
                        token: null
                    },
                });

                return navigate('success');

            }

            return;
        }
    });

    const loginUser = () => {
        authentication({
            variables: {
                profile: {
                    email,
                    password,
                    type: 'CRM',
                },
            },
        });
    };

    return useMemo(
        () => (
            <div className={styles.formLogin}>
                <img
                    className={styles.logo}
                    src={logoWhite}
                    alt="логотип logovo.kz"
                />
                <h1 className={styles.title}>Добро пожаловать!</h1>
                <h2 className={styles.subtitle}>Авторизация</h2>
                <form
                    className={styles.form}
                    onSubmit={(e) => {
                        e.preventDefault();
                        loginUser();
                    }}
                >
                    <label className={styles.label}>
                        <img
                            width={18}
                            height={18}
                            src={mailIcon}
                            alt="почта"
                        />
                        <input
                            required
                            value={email}
                            onChange={(val) => set_email(val.target.value)}
                            type="text"
                            placeholder="Email"
                        />
                    </label>
                    <label className={styles.label}>
                        <img
                            width={18}
                            height={18}
                            src={lockIcon}
                            alt="пароль"
                        />
                        <input
                            required
                            value={password}
                            onChange={(val) => set_password(val.target.value)}
                            type="password"
                            placeholder="Пароль"
                        />
                    </label>

                    <input type="submit" value="Войти" />
                </form>
            </div>
        ),
        [email, password]
    );
};

export default Login;
