const typeFormPayment = (type) => {
    switch(type){
        case 'purchase' :
            return 'Покупка';
        case 'mortgage' :
            return 'Ипотека';
        case 'installment' :
            return 'Рассрочка';
        default :
            return 'Неопределенно';
    }
}

export default typeFormPayment;