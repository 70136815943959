import React, { useEffect } from 'react';
import { Modal, TreeSelect, Result } from 'antd';
import { api } from '@/Services';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';

const AddProduct = ({
    isVisible,
    close,
    styles,
    config,
    vt_id,
    refMain,
    set_yaw,
    set_pitch,
    active_scene_id,
    updateFields,
}) => {
    const dispatch = useDispatch();
    const getLibrary = useSelector((state: any) => state.library);

    useEffect(() => {
        dispatch(api.library.treeCategories());
    }, []);

    const getProductsByCatID = (cat_id) => {
        dispatch(api.library.getProductsByID(cat_id));
    };

    const addProduct = (product_id) => {
        const getYaw = refMain.current.getViewer().getYaw();
        const getPitch = refMain.current.getViewer().getPitch();

        set_yaw(getYaw);
        set_pitch(getPitch);

        let sceneFields = {
            position: {
                pitch: getPitch,
                yaw: getYaw,
            },
            product: product_id,
            type: 'NEW_POINT',
        };

        updateFields({
            variables: {
                fields: {
                    vt_id,
                    active_scene_id,
                    type: 'add_product',
                    data_scene_object: sceneFields,
                },
            },
        });

        close();
    };

    return (
        <Modal
            title="Выберите товар"
            open={isVisible}
            footer={null}
            onCancel={close}
            width="65%"
        >
            <TreeSelect
                virtual={true}
                size="large"
                treeDataSimpleMode
                style={{
                    width: '100%',
                }}
                dropdownStyle={{
                    maxHeight: 400,
                    overflow: 'auto',
                }}
                placeholder="Выберите категорию"
                onChange={getProductsByCatID}
                treeData={getLibrary?.tree_categories || []}
            />

            {getLibrary?.request ? (
                <LoadingOutlined />
            ) : getLibrary?.products && getLibrary?.products.length ? (
                <div className={styles.products}>
                    {getLibrary?.products.map((item) => (
                        <div
                            className={styles.product}
                            key={item?._id}
                            onClick={() => addProduct(item?._id)}
                        >
                            <div
                                className={styles.productPhoto}
                                style={{
                                    background: `url(${config.public}${item?.photo?.url}) center / contain no-repeat`,
                                }}
                            ></div>
                            <h4>{item?.title}</h4>
                        </div>
                    ))}
                </div>
            ) : (
                <Result title="Товаров не найдено" extra={null} />
            )}
        </Modal>
    );
};

export default AddProduct;
