import React, { useState } from 'react';
import { Modal, Tabs } from 'antd';
import FlatForm from './flat-form';
import SelectFlat from './select-flat';

const FlatModalForm = ({ api, realestate_id, type, open, close }) => {
    const [area_points, set_area_points] = useState([]);
    const [area_points_for_css, set_area_points_for_css] = useState('');

    const [modal_width, set_modal_width] = useState(65);

    const items = [
        {
            key: 'form',
            label: `Данные о помещении`,
            children: (
                <FlatForm
                    area_points={area_points}
                    area_points_for_css={area_points_for_css}
                    realestate_id={realestate_id}
                    api={api}
                />
            ),
        },
        {
            key: 'select_flat',
            label: `Выделить помещение на этаже`,
            children: (
                <SelectFlat
                    saveCoords={(area_points, area_points_for_css) => {
                        set_area_points(area_points);
                        set_area_points_for_css(area_points_for_css);
                    }}
                    api={api}
                />
            ),
        },
    ];

    return (
        <Modal
            title={
                type === 'create'
                    ? 'Форма добавления'
                    : 'Форма редактирования'
            }
            open={open}
            onCancel={() => {
                close();
                set_modal_width(55);
            }}
            footer={null}
            width={`${modal_width}%`}
            destroyOnClose={true}
            centered={true}
        >
            <Tabs
                onChange={(key) => {
                    if (key === 'select_flat') {
                        set_modal_width(95);
                    } else {
                        set_modal_width(65);
                    }
                }}
                defaultActiveKey="form"
                items={items}
            />
        </Modal>
    );
};

export default FlatModalForm;
