import React from 'react';
import { Popover } from 'antd';

const RenderFloor = ({ styles, floor, block_id, dispatch, api }) => {
    return (
        <div className={styles.wrapperFloor}>
            <Popover content={`Обновить цены этажа ${floor}`}>
                <div
                    className={styles.priceIcon}
                    onClick={() => {
                        dispatch(
                            api.chess.modalFormPrices({
                                block_id: block_id,
                                floor: floor,
                                status: true,
                            })
                        );
                    }}
                >
                    <img src="https://api.logovo.kz/icons/tenge_symbol.svg" />
                </div>
            </Popover>
            <div className={styles.line} />
            <h5>{floor}</h5>
            <p>Этаж</p>
        </div>
    );
};

export default RenderFloor;
