import React, { useEffect } from 'react';
import styles from '../styles/leads.module.scss';
import { useLazyQuery, useMutation } from '@apollo/client';
import * as gqls from '@/Services/apollo/gqls';
import { LoadingOutlined, MoreOutlined } from '@ant-design/icons';
import { Result, Button, Popconfirm, Menu, Dropdown } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import FormStatuses from '../helpers/form_statuses';

const Statuses = () => {
    const dispatch = useDispatch();
    const formStatus = useSelector(
        (state: any) => state?.companies?.modal_form_statuses
    );

    const [createStatus] = useMutation(gqls.builder.createCrmStatus, {
        fetchPolicy: 'network-only',
        onCompleted: () => {
            loadCrmStatuses();
            dispatch({
                type: 'MODAL_EDIT_FORM_STATUS',
                data: null,
            });
            dispatch({
                type: 'MODAL_FORM_STATUSES',
                status: false,
            });
        },
    });

    const [editStatus] = useMutation(gqls.builder.editCrmStatus, {
        fetchPolicy: 'network-only',
        onCompleted: () => {
            loadCrmStatuses();
            dispatch({
                type: 'MODAL_EDIT_FORM_STATUS',
                data: null,
            });
            dispatch({
                type: 'MODAL_FORM_STATUSES',
                status: false,
            });
        },
    });

    const [deleteStatus] = useMutation(gqls.builder.deleteCrmStatus, {
        fetchPolicy: 'network-only',
        onCompleted: () => {
            loadCrmStatuses();
        },
    });

    const [loadCrmStatuses, { data, loading }] = useLazyQuery(
        gqls.builder.getCrmStatuses,
        {
            fetchPolicy: 'network-only',
        }
    );

    useEffect(() => {
        loadCrmStatuses();
    }, []);

    const menu = (item: any) => {
        return (
            <Menu
                items={[
                    {
                        key: '1',
                        label: (
                            <span
                                onClick={() => {
                                    dispatch({
                                        type: 'MODAL_EDIT_FORM_STATUS',
                                        data: item,
                                    });
                                    dispatch({
                                        type: 'MODAL_FORM_STATUSES',
                                        status: true,
                                    });
                                }}
                            >
                                Редактировать
                            </span>
                        ),
                    },
                    {
                        key: '2',
                        label: (
                            <Popconfirm
                                title="Статус будет удален"
                                onConfirm={() =>
                                    deleteStatus({
                                        variables: {
                                            id: item?._id,
                                        },
                                    })
                                }
                                okText="Удалить"
                                cancelText="Нет"
                            >
                                <span>удалить</span>
                            </Popconfirm>
                        ),
                    },
                ]}
            />
        );
    };

    return (
        <section className={styles.wrapper}>
            <FormStatuses
                createStatus={createStatus}
                editStatus={editStatus}
                isModalOpen={formStatus}
                close={() => {
                    dispatch({
                        type: 'MODAL_EDIT_FORM_STATUS',
                        data: null,
                    });
                    dispatch({
                        type: 'MODAL_FORM_STATUSES',
                        status: false,
                    });
                }}
            />

            <div className={styles.header}>
                <h2 className={styles.settingsTitle}>Управление статусами</h2>
                <Button
                    type="primary"
                    key="console"
                    onClick={() =>
                        dispatch({
                            type: 'MODAL_FORM_STATUSES',
                            status: true,
                        })
                    }
                >
                    Добавить статус
                </Button>
            </div>

            <div>
                {loading ? (
                    <div>
                        <LoadingOutlined />
                    </div>
                ) : data?.crmStatuses && data?.crmStatuses.length ? (
                    <div className={styles.items}>
                        {data?.crmStatuses.map((item: any) => {
                            return (
                                <div
                                    key={item?._id}
                                    className={styles.itemStatus}
                                >
                                    <div className={styles.itemStatusLeft}>
                                        <div
                                            className={
                                                styles.itemStatusLeftColor
                                            }
                                            style={{
                                                background:
                                                    item?.color || '#333',
                                            }}
                                        ></div>
                                        <p>{item?.title}</p>
                                    </div>
                                    <div className={styles.itemStatusRight}>
                                        <Dropdown
                                            trigger={['click']}
                                            overlay={() => menu(item)}
                                        >
                                            <MoreOutlined />
                                        </Dropdown>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <Result
                        status="404"
                        title="Статусов не найдено"
                        subTitle={'Информации не найдено'}
                        extra={
                            <Button
                                type="primary"
                                key="console"
                                onClick={() =>
                                    dispatch({
                                        type: 'MODAL_FORM_STATUSES',
                                        status: true,
                                    })
                                }
                            >
                                Добавить статус
                            </Button>
                        }
                    />
                )}
            </div>
        </section>
    );
};

export default Statuses;
