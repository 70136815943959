import React, { useMemo, useEffect } from 'react';
import styles from './styles/index.module.scss';
import { session } from '@/Services';
import { useDispatch } from 'react-redux';

const LoginSuccess = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({
            type: 'UPDATE_ACCOUNT',
            user: null,
        });
        session.remove();
        setTimeout(() => {
            return (window.location.href = '/');
        }, 1200);
    });

    return useMemo(
        () => (
            <div className={styles.contentModal}>
                <div className={styles.formLogin}>
                    <h1>Сессия успешно завершена!</h1>
                </div>
            </div>
        ),
        []
    );
};

export default LoginSuccess;
