import styles from '../../styles/intergration.module.scss';
import { Typography, Tag, Button, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { api } from '@/Services';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CrmStatuses from './helpers/statuses';
import { Collapse } from 'antd';
import ResetConnection from './helpers/resetConnection';
import { useEffect } from 'react';

const IntegrationB24 = () => {
    const account = useSelector((state: any) => state.account);
    const dispatch = useDispatch();

    const generateApiKey = () => {
        if (!account?.user.builder?.integration_api_key) {
            dispatch(api.account.generateIntegrationKeyApi('bitrix24'));
        }
    };

    useEffect(() => {
        dispatch(api.account.getBuilderCrmStatuses());
    }, []);

    return (
        <section className={styles.wrapper}>
            <h2 className={styles.title}>Интеграция с bitrix24</h2>

            <div className={styles.content}>
                <div className={styles.section}>
                    <div className={styles.header}>
                        <Typography.Text className={styles.textHeader}>
                            Параметры
                        </Typography.Text>
                    </div>
                    <div className={styles.info}>
                        <Typography.Text>Логин</Typography.Text>
                        <Tag
                            bordered={false}
                            color="warning"
                            style={{ maxWidth: '50%', userSelect: 'none' }}
                        >
                            <CopyToClipboard
                                onCopy={() =>
                                    message.success('Email скопирован')
                                }
                                text={account?.user?.email || ''}
                                options={{ format: 'text/plain' }}
                            >
                                <p>{account?.user?.email || '-'}</p>
                            </CopyToClipboard>
                        </Tag>
                    </div>
                    <div className={styles.info}>
                        <Typography.Text style={{ marginBottom: 5 }}>
                            Ключ API
                            {!account?.user.builder?.integrations?.api_key && (
                                <Button
                                    onClick={generateApiKey}
                                    size="small"
                                    style={{ marginLeft: 5 }}
                                >
                                    Сгенерировать ключ
                                </Button>
                            )}
                        </Typography.Text>
                        <Tag
                            bordered={false}
                            color="warning"
                            style={{
                                maxWidth: '50%',
                                overflow: 'hidden',
                                userSelect: 'none',
                            }}
                        >
                            <CopyToClipboard
                                onCopy={() =>
                                    message.success('Ключ скопирован')
                                }
                                text={
                                    account?.user.builder?.integrations
                                        ?.api_key || ''
                                }
                                options={{ format: 'text/plain' }}
                            >
                                <p>
                                    {account?.user.builder?.integrations
                                        ?.api_key || 'Не найден'}
                                </p>
                            </CopyToClipboard>
                        </Tag>
                    </div>

                    <div className={styles.info}>
                        <p className={styles.text}>
                            Скопируйте и вставьте Логин и Ключ API в поля
                            настройки виджета LOGOVO.KZ в панели Bitrix24. При
                            включении виджета данные будут синхронизированы
                            автоматически.
                        </p>
                    </div>

                    <div
                        className={styles.info}
                        style={{ flexDirection: 'row' }}
                    >
                        <Typography.Text style={{ marginRight: 5 }}>
                            Статус
                        </Typography.Text>
                        <Tag
                            bordered={false}
                            color={
                                account?.crm_integration?.builder?.integrations
                                    ?.connection_status
                                    ? 'lime'
                                    : 'error'
                            }
                            style={{ width: 'fit-content' }}
                        >
                            {account?.crm_integration?.builder?.integrations
                                ?.connection_status
                                ? 'Подключено'
                                : 'Не подключено'}
                        </Tag>
                    </div>
                </div>

                {account?.crm_integration?.statuses &&
                account?.crm_integration?.statuses.length ? (
                    <div>
                        <CrmStatuses />
                    </div>
                ) : (
                    <></>
                )}

                {account?.user.builder?.integrations?.connection_status && (
                    <div style={{ marginTop: 10 }}>
                        <Collapse
                            size="small"
                            items={[
                                {
                                    key: 'resetConnection',
                                    label: 'Сброс настроек подключения',
                                    children: <ResetConnection />,
                                },
                            ]}
                        />
                    </div>
                )}
            </div>
        </section>
    );
};

export default IntegrationB24;
