import React from 'react';
import { Modal, Form, Input, Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';

const ModalFormPrices = ({
    open,
    close,
    request_status,
    styles,
    fields,
    api,
    dispatch,
}) => {
    const { block_id, floor } = fields;

    const { id } = useParams();

    const eventForm = (fields) => {
        dispatch(
            api.chess.updatePrices({
                ...fields,
                block_id,
                floor,
                realestate_id: id,
                type: floor && Number(floor) > 0 ? 'floor' : 'block',
            })
        );
    };

    return (
        <Modal
            title={`Форма обновления цен на квартиры`}
            open={open}
            onCancel={close}
            footer={null}
            width={`60%`}
            destroyOnClose={true}
            centered={true}
        >
            <Form
                name="basic"
                initialValues={{
                    amount: '',
                }}
                onFinish={eventForm}
                className={styles.form}
            >
                <Form.Item
                    label="Стоимость м²"
                    labelCol={{
                        span: 4,
                        offset: 0,
                    }}
                    labelAlign="left"
                    name="amount"
                    rules={[
                        {
                            required: true,
                            message: 'Заполните стоимость м²',
                        },
                    ]}
                    className={styles.formInput}
                >
                    <Input size="large" placeholder="стоимость м²" />
                </Form.Item>

                <Form.Item>
                    <Button size="medium" type="primary" htmlType="submit">
                        {request_status ? <LoadingOutlined /> : 'Обновить цены'}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ModalFormPrices;
