const separateNumber = (number: number) => {
    return Math.round(number)
        .toString()
        .replace(/^([0-9]{2,3})?([0-9]{3})([0-9]{3}$)/, '$1 $2 $3');
};

export const generateUniqueKey = () => {
    const timestamp = new Date().getTime();
    const randomValue = Math.floor(Math.random() * 100000);
    const uniqueKey = `${timestamp}-${randomValue}`;
    return uniqueKey;
}

export default separateNumber;
