import React from 'react';
import FormItemTitles from './titles';
import FormItemTemplate from './template';

const ApartmentheetFormItems = ({ editorRef }) => {
    return (
        <>
            <FormItemTitles/>
            <FormItemTemplate editorRef={editorRef}/>
        </>
    )
}

export default ApartmentheetFormItems;