import { produce } from 'immer';

const initialState = {
    list: [],

    search_data: [],
    search_data_blocks: [],
    request_search_data: [],

    conditions_apartments: [],

    request: false,
    save_request: false
};

const payments = (state = initialState, action) => {
    switch (action.type) {
        case 'CONDITION_APARTMENTS' :
            return produce(state, draftState => {
                draftState.conditions_apartments = action.data || [];
            });

        case 'DATA_SEARCH_CLEAR' :
            return produce(state, draftState => {
                draftState.search_data = [];
            });

        case 'DATA_SEARCH' :
            return produce(state, draftState => {

                if(action?.type_request === 'blocks' && action?.seach_text === 'ALL'){
                    draftState.search_data_blocks = action?.data || [];
                    draftState.search_data = [];
                }

                if(action?.type_request === 'apartments'){
                    draftState.search_data = action?.data || [];
                }
            });

        case 'REQUEST_DATA_SEARCH' :
            return produce(state, draftState => {
                draftState.request_search_data = action.status || false;
            });

        case 'PAYMENT_SAVE_REQUEST' :
            return produce(state, draftState => {
                draftState.save_request = action.status || false;
            });

        case 'PAYMENT_REQUEST' :
            return produce(state, draftState => {
                draftState.request = action.status || false;
            });

        case 'PAYMENT_LIST_DATA' :
            return produce(state, draftState => {
                draftState.list = action.data || [];
            });

        default:
            return state;
    }
};

export default payments;
