import { combineReducers } from 'redux';

//reducers
import realestate from './realestate';
import account from './account';
import flats from './flats';
import companies from './companies';
import chess from './chess';
import chessData from './chessData';
import widgets from './widgets';
import payments from './payments';
import contracts from './contracts';
import library from './library';
import files from './files';

export default combineReducers({
    realestate,
    account,
    flats,
    companies,
    chess,
    chessData,
    widgets,
    payments,
    contracts,
    library,
    files
});
