import React, { useEffect, useRef, useState } from 'react';
import { Pannellum } from 'pannellum-react';
import styles from '../styles/vt.module.scss';
import { useQuery, useMutation } from '@apollo/client';
import { virtual_tours } from '@/Services/apollo/gqls';
import { useLocation } from 'react-router-dom';
import { Result, Button, message } from 'antd';
import { Loader } from '@/Components';
import { UploadOutlined, LoadingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '@/Services';
import config from '@/Config';
import Scene from './helpers/scene';
import LayoutVT from './helpers/layout-vt';
import RenderPoint from './helpers/point';
import CollapseForm from './helpers/Collapse-form';
import FormButtons from './helpers/buttons-form';

const EditVirtualTour = () => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const template_id = searchParams.get('template_id');

    const VT = useSelector((state: any) => state?.realestate?.form_meta_template_data?.template?.virtual_tour);
    const template = useSelector((state: any) => state?.realestate?.form_meta_template_data?.template);

    const refMain: any = useRef(null);
    const dispatch = useDispatch();
    const stateFile = useSelector((state: any) => state.files);
    const [hfov] = useState(120);
    const [yaw, set_yaw] = useState(0);
    const [pitch, set_pitch] = useState(0);
    const [virtual_tour, set_virtual_tour] = useState(null);
    const [tour_index, set_tour_index] = useState(0);
    const [load_image, set_load_image] = useState(true);

    const [virtual_tour_id, set_virtual_tour_id] = useState(null);

    let { data, loading, refetch } = useQuery(virtual_tours.getVirtualTourByID, {
        fetchPolicy: 'network-only',
        variables: {
            id: VT?._id,
            type: 'NAME_ROOM',
        },
        onError: (err) => {
            return console.log(err);
        },
    });

    let [createVirtualTour, createVirtualTourData] = useMutation(
        virtual_tours.createVirtualTours,
        {
            onCompleted: (data) => {
                message.success('Виртуальный тур успешно создан');
                set_virtual_tour_id(data?.createVirtualTour?._id);
                refetch({
                    id: data?.createVirtualTour?._id,
                    type: 'NAME_ROOM',
                });
            }
        }
    );

    let [updateScene] = useMutation(virtual_tours.updateScene, {
        onError: (err) => {
            return console.log(err);
        },
        onCompleted: (data) => {
            set_virtual_tour({
                ...virtual_tour,
                photos: data?.vt_updateScene || [],
            });
            set_tour_index(data?.vt_updateScene.length - 1);
        },
    });

    let [updateFields, updateFieldsData] = useMutation(
        virtual_tours.updateFields,
        {
            onError: (err) => {
                return console.log(err);
            },
            onCompleted: (data) => {
                set_virtual_tour(data?.vt_updateFields || null);
            },
        }
    );

    useEffect(() => {
        if (!loading && data?.getVirtualTourByID) {
            set_virtual_tour(data?.getVirtualTourByID);
        }
    }, [loading, data]);

    const uploadPhoto = (file, type = '360') => {
        return dispatch(api.files.uploadVTImage(file.target.files[0], type));
    };

    useEffect(() => {
        if (virtual_tour && stateFile?.photo_360) {
            updateScene({
                variables: {
                    fields: {
                        photo_id: stateFile?.photo_360.id,
                        vt_id: virtual_tour_id || VT?._id,
                        type: 'push',
                    },
                },
            });
        }
    }, [stateFile?.photo_360]);

    const eventPreviewPhoto = (index) => {
        if (index != tour_index) {
            set_load_image(true);
        }

        set_tour_index(index);
    };

    const createVTEvent = () => {

        if(!template?.plan_photo && !template?.plan_photo.length){
            return message.warning('Планировка шаблона не найдена.');
        }
        if (
            !createVirtualTourData?.loading
        ) {

            createVirtualTour({
                variables: {
                    fields: {
                        title: template?.title,
                        secondary_title: template?.title,
                        template_id: template_id,
                        type: 'apartment_template',
                        layout: (template?.plan_photo && template?.plan_photo.length) ? template?.plan_photo[0]?.uid : null,
                    }
                },
            });

        }

    }

    return (
        <>
            {loading ? (
                <Loader />
            ) : virtual_tour ? (
                <div className={styles.form}>
                    <div className={styles.header}>
                        <h1>
                            Настройка виртуального тура - {virtual_tour?.title}{' '}
                            ({virtual_tour?.secondary_title})
                        </h1>
                    </div>

                    <CollapseForm
                        updateFields={updateFields}
                        refMain={refMain}
                        virtual_tour={virtual_tour}
                        vt_id={virtual_tour_id || VT?._id}
                        tour_index={tour_index}
                        set_yaw={set_yaw}
                        set_pitch={set_pitch}
                    />

                    <div className={styles.Pannellum}>

                        <FormButtons
                            updateFields={updateFields}
                            virtualTour={virtual_tour}
                            template_id={virtual_tour?.template_id?._id}
                            styles={styles}
                            uploadPhoto={uploadPhoto}
                            loading={stateFile?.request_upload_status}
                            scenes={virtual_tour?.photos}
                            tour_index={tour_index}
                            refMain={refMain}
                            vt_id={virtual_tour_id || VT?._id}
                            updateFieldsData={updateFieldsData}
                            set_yaw={set_yaw}
                            set_pitch={set_pitch}
                        />

                        <section>
                            {!!virtual_tour?.layout && (
                                <LayoutVT
                                    yaw_def={virtual_tour?.yaw || 0}
                                    updateFields={updateFields}
                                    room_types={data?.getVTRoomTypes || null}
                                    styles={styles}
                                    virtual_tour={virtual_tour}
                                    scene_id={
                                        virtual_tour?.photos[tour_index]?._id ||
                                        ''
                                    }
                                    scenes={virtual_tour?.photos}
                                    vt_id={virtual_tour_id || VT?._id}
                                    eventPreviewPhoto={eventPreviewPhoto}
                                    tour_index={tour_index}
                                    refMain={refMain}
                                />
                            )}

                            {!!load_image && (
                                <div className={styles.loader}>
                                    <img src={`${config.public}uploads/locationMarkBlue.svg`} />
                                </div>
                            )}
                            {virtual_tour?.photos &&
                            virtual_tour?.photos.length ? (
                                <div className={styles.scenes}>
                                    {virtual_tour?.photos.map(
                                        (scene, index) => (
                                            <Scene
                                                key={scene?._id + Math.random()}
                                                scene={scene}
                                                index={index}
                                                styles={styles}
                                                tour_index={tour_index}
                                                config={config}
                                                eventPreviewPhoto={
                                                    eventPreviewPhoto
                                                }
                                                updateScene={updateScene}
                                                vt_id={virtual_tour_id || VT?._id}
                                            />
                                        )
                                    )}
                                </div>
                            ) : (
                                <></>
                            )}

                            {virtual_tour?.photos &&
                            virtual_tour?.photos.length ? (
                                <Pannellum
                                    width="100%"
                                    height="100%"
                                    autoRotate={1.4}
                                    image={`${config.public}${virtual_tour?.photos[tour_index]?.url}`}
                                    //@ts-ignore
                                    ref={refMain}
                                    yaw={yaw || virtual_tour?.yaw || 0}
                                    pitch={pitch}
                                    hfov={hfov}
                                    autoLoad
                                    onLoad={() => {
                                        set_load_image(false);
                                        console.log('panorama loaded');
                                    }}
                                    showControls={false}
                                    hotspotDebug={true}
                                >
                                    {virtual_tour?.photos[
                                        tour_index
                                    ]?.vr_data.map((item) =>
                                        RenderPoint(
                                            item,
                                            set_tour_index,
                                            virtual_tour?.photos
                                        )
                                    )}
                                </Pannellum>
                            ) : (
                                <></>
                            )}

                            {!virtual_tour?.photos ||
                            virtual_tour?.photos.length === 0 ? (
                                <div className={styles.noData}>
                                    <Result
                                        title="Изображений не найдено"
                                        subTitle="Загрузите изображение формата 360"
                                        extra={
                                            <Button className={styles.button}>
                                                {stateFile?.request_upload_status ? (
                                                    <>
                                                        <LoadingOutlined />{' '}
                                                        Подождите...
                                                    </>
                                                ) : (
                                                    <>
                                                        <UploadOutlined />{' '}
                                                        Загрузить
                                                        <input
                                                            onChange={(value) =>
                                                                uploadPhoto(
                                                                    value
                                                                )
                                                            }
                                                            type="file"
                                                        />
                                                    </>
                                                )}
                                            </Button>
                                        }
                                    />
                                </div>
                            ) : (
                                <></>
                            )}
                        </section>
                    </div>
                </div>
            ) : (
                <Result
                    status="404"
                    title="Виртуальный тур не создан"
                    subTitle="информации не найдено"
                    extra={
                        <Button onClick={createVTEvent}>
                            Создать тур для редактирования информации
                        </Button>
                    }
                />
            )}
        </>
    );
};

export default EditVirtualTour;
