import React from 'react';
import { Result, Button, Popconfirm, Popover } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import config from '@/Config';

const Points = ({
    points,
    styles,
    refMain,
    scene_id,
    updateFields,
    vt_id,
    set_yaw,
    set_pitch,
    scenes,
}) => {
    const deletePoint = (index, item) => {
        updateFields({
            variables: {
                fields: {
                    type: 'delete_point',
                    scene_id,
                    point_index: index,
                    vt_id,
                },
            },
        });

        set_yaw(item?.position?.yaw);
        set_pitch(item?.position?.pitch);
    };

    const updatePositionOnScene = (item) => {
        refMain.current.getViewer().setPitch(Number(item?.position?.pitch));
        refMain.current.getViewer().setYaw(Number(item?.position?.yaw));
    };

    const renderPointProduct = (item) => {
        if (item?.type === 'NEW_POINT') {
            return (
                <div
                    className={styles.pointBlockProduct}
                    style={{
                        background: `url(${config.public}${item?.product?.photo?.url}) center / contain no-repeat`,
                    }}
                />
            );
        }
    };

    return (
        <>
            {points && points.length ? (
                <div className={styles.pointsBlock}>
                    {points.map((item, index) => (
                        <Popover
                            key={Math.random() * Math.random()}
                            placement="bottom"
                            title={
                                item?.type === 'NEW_POINT'
                                    ? 'Товар'
                                    : 'Переход на сцену'
                            }
                            content={() => renderPointProduct(item)}
                        >
                            <div
                                className={styles.point}
                                //onMouseOver={() => updatePositionOnScene(item)}
                                onClick={() => updatePositionOnScene(item)}
                            >
                                {item?.type === 'NEW_SCENE' ? (
                                    <h5>
                                        Сцена:{' '}
                                        {scenes[item?.indexScene]?.title_room
                                            ?.title || 'Не определено'}
                                    </h5>
                                ) : (
                                    <h5>{item?.product?.title}</h5>
                                )}

                                <Popconfirm
                                    title={
                                        item?.type === 'NEW_SCENE'
                                            ? 'Переход на другую сцену будет удален'
                                            : 'Описание товара будет удалено'
                                    }
                                    onConfirm={() => deletePoint(index, item)}
                                    okText="Удалить"
                                    cancelText="Нет"
                                >
                                    <Button size="small">
                                        <DeleteOutlined />
                                    </Button>
                                </Popconfirm>
                            </div>
                        </Popover>
                    ))}
                </div>
            ) : (
                <Result title="Точек не найдено" extra={null} />
            )}
        </>
    );
};

export default Points;
