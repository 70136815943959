import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import styles from './styles/sidebar.module.scss';
import * as Types from './interfaces';

import Icon from '@mdi/react';
import {
    mdiDomain,
    mdiHammerWrench,
    mdiLogout,
} from '@mdi/js';
import Icons from '@/Assets';
import { StarTwoTone, UnorderedListOutlined, FileTextOutlined, ApiOutlined } from '@ant-design/icons';

const Sidebar = ({ account }: Types.AccountType) => {
    let { pathname } = useLocation();

    return (
        <div className={styles.sidebar}>
            <Link className={pathname === '/' ? styles.activeLink : ''} to="/">
                <Icon path={mdiDomain} size={1} />
                <span className={styles.title}>Жилые комплексы</span>
            </Link>

            {account.role.type === 'REALTOR' ? (
                <>
                    <Link
                        className={pathname === '/leads' ? styles.activeLink : ''}
                        to="leads"
                    >
                        <img src={Icons.Leads} />
                        <span className={styles.title}>Лиды</span>
                    </Link>
                    <Link
                        className={
                            pathname === '/subscription' ? styles.activeLink : ''
                        }
                        to="subscription"
                    >
                        <StarTwoTone style={{ fontSize: 20 }} />
                        <span className={styles.title}>Подписка</span>
                    </Link>
                    <Link
                        className={pathname === '/finance' ? styles.activeLink : ''}
                        to="finance"
                    >
                        <img src={Icons.Tenge} className={styles.tenge} />
                        <span className={styles.title}>Заказы и оплата</span>
                    </Link>
                </>
            ) : (
                <></>
            )}

            {account.role.type === 'BUILDER' ? (
                <>
                    <Link
                        className={
                            pathname === '/widgets' ? styles.activeLink : ''
                        }
                        to="widgets"
                    >
                        <UnorderedListOutlined style={{ fontSize: 20 }} />
                        <span className={styles.title}>Виджеты</span>
                    </Link>
                    <Link
                        className={
                            pathname === '/contracts' ? styles.activeLink : ''
                        }
                        to="contracts"
                    >
                        <FileTextOutlined style={{ fontSize: 20 }}/>
                        <span className={styles.title}>Договора</span>
                    </Link>
                    <Link
                        className={
                            pathname === '/integration' ? styles.activeLink : ''
                        }
                        to="integration"
                    >
                        <ApiOutlined style={{ fontSize: 20 }}/>
                        <span className={styles.title}>Интеграция</span>
                    </Link>
                </>
            ) : (
                <></>
            )}

            <Link
                className={
                    pathname === '/settings' ||
                    pathname === '/settings/reset-password'
                        ? styles.activeLink
                        : ''
                }
                to="/settings"
            >
                <Icon path={mdiHammerWrench} size={1} />
                <span className={styles.title}>Настройки</span>
            </Link>
            <Link to="/logout">
                <Icon path={mdiLogout} size={1} />
                <span className={styles.title}>Выход</span>
            </Link>
        </div>
    );
};

export default Sidebar;
