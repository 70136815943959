import React, { useState } from 'react';
import styles from './styles/pricingPlans.module.scss';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Segmented, Button, Modal, Result } from 'antd';

const PricingPlans = ({ status, close }: any) => {
    const [modal_view, set_modal_view] = useState<boolean>(false);
    const [payment_status, set_payment_status] = useState<string | number>(
        'Ежегодная'
    );

    const selectPlan = () => {
        set_modal_view(!modal_view);
    };

    return (
        <div className={status ? styles.plans : styles.disabled}>
            <Modal
                title="Уведомление"
                open={modal_view}
                onOk={() => selectPlan()}
                footer={null}
                zIndex={9999999}
                onCancel={() => selectPlan()}
                centered
            >
                <Result
                    status="success"
                    title="Тестовая подписка активна"
                    subTitle="Подписка действует в течении 2 месяцев"
                    extra={null}
                />
            </Modal>
            <div className={styles.content}>
                <div className={styles.header}>
                    <h5>Тарифные планы</h5>
                    <CloseCircleOutlined onClick={() => close()} />
                </div>
                <div className={styles.paymentStatus}>
                    <Segmented
                        size="large"
                        value={payment_status}
                        onChange={(value: string | number) =>
                            set_payment_status(value)
                        }
                        options={['Ежемесячно', 'Ежегодная']}
                    />
                </div>
                <div className={styles.listPlans}>
                    <div className={styles.plan}>
                        <div>
                            <h5>Начальный (активно)</h5>
                            <div className={styles.planContent}>
                                <span>
                                    Доступ к ЖК <b>0.5%</b> от продажи
                                </span>
                                <span>2 бесплатных объявлений </span>
                            </div>
                        </div>
                        <div className={styles.paymentButton}>
                            <h3>Бесплатно</h3>
                        </div>
                    </div>
                    <div className={styles.plan}>
                        <div>
                            <h5>Стандарт</h5>
                            <div className={styles.planContent}>
                                <span>
                                    Доступ к ЖК <b>1%</b> от продажи
                                </span>
                                <span>5 лидов в месяц от Logovo</span>
                                <span>5 бесплатных объявлений</span>
                            </div>
                        </div>

                        <div className={styles.paymentButton}>
                            <h3>
                                {payment_status === 'Ежегодная'
                                    ? '200 000 ₸'
                                    : '20 000 ₸'}
                            </h3>
                            <Button onClick={() => selectPlan()} type="primary">
                                Выбрать
                            </Button>
                        </div>
                    </div>
                    <div className={styles.plan}>
                        <div>
                            <h5>Профи</h5>
                            <div className={styles.planContent}>
                                <span>
                                    Доступ к ЖК <b>1.5%</b> от продажи
                                </span>
                                <span>1 бонусная карта (100к)</span>
                                <span>5 лидов в месяц от Logovo</span>
                                <span>5 бесплатных объявлений</span>
                                <span>Продажа через бутик Logovo </span>
                            </div>
                        </div>

                        <div className={styles.paymentButton}>
                            <h3>
                                {payment_status === 'Ежегодная'
                                    ? '600 000 ₸'
                                    : '60 000 ₸'}
                            </h3>
                            <Button onClick={() => selectPlan()} type="primary">
                                Выбрать
                            </Button>
                        </div>
                    </div>
                    <div className={styles.plan}>
                        <div>
                            <h5>Компания</h5>
                            <div className={styles.planContent}>
                                <span>
                                    Доступ к ЖК <b>1.5%</b> от продажи
                                </span>
                                <span>1 бонусная карта (200к)</span>
                                <span>5 лидов в месяц от Logovo</span>
                                <span>5 Менеджеров</span>
                                <span>10 бесплатных объявлений</span>
                                <span>Продажа через бутик Logovo </span>
                            </div>
                        </div>
                        <div className={styles.paymentButton}>
                            <h3>
                                {payment_status === 'Ежегодная'
                                    ? '1 000 000 ₸'
                                    : '120 000 ₸'}
                            </h3>
                            <Button onClick={() => selectPlan()} type="primary">
                                Выбрать
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PricingPlans;
