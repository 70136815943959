import React from 'react';
import { Select } from 'antd';
import { useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';

const FormBlocks = ({
    styles,
    opBlock
}) => {

    const { search_data_blocks, request_search_data } = useSelector((state: any) => state?.payments);

    return (
        <>
            <div className={styles.blockSearch} style={{padding: 10}}>

                <div className={styles.searchResultBlock}>

                    {
                        request_search_data ?
                            <LoadingOutlined />
                        : search_data_blocks && search_data_blocks.length ?
                            <Select
                                size='large'
                                options={search_data_blocks.map((item)=>({
                                    value: item?._id,
                                    label: `ЖК ${item?.complex_id?.title}, блок ${item?.title}`
                                }))}
                                loading={request_search_data}
                                onChange={(item)=>{
                                    
                                    const getBlock = search_data_blocks.filter((block)=>String(item) === String(block?._id));
                                    if(getBlock.length){
                                        opBlock(getBlock[0], 'add')
                                    }

                                }}
                                style={{width:'100%'}}
                                placeholder="Выберите блок"
                            />
                        :
                            <></>
                    }

                </div>

            </div>
        </>
    )

}

export default FormBlocks