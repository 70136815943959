import { produce } from 'immer';

const initialState = {
    flat: null,
};

const flats = (state = initialState, action: any) => {
    switch (action.type) {
        case 'UPDATE_FLAT':
            return produce(state, draftState => {
                draftState.flat = action?.flat || null;
            });

        default:
            return state;
    }
};

export default flats;
