import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import styles from '../../../styles/intergration.module.scss';
import ItemStatus from './itemStatus';
import { Button } from 'antd';
import { api } from '@/Services';

const CrmStatuses = () => {
    const dispatch = useAppDispatch();
    const { crm_integration } = useAppSelector((state) => state.account);

    const updateStatuses = () => {
        dispatch(
            api.account.updateButrixStatuses(
                crm_integration?.builder?.integrations?.api_key || ''
            )
        );
    };

    return (
        <div>
            {crm_integration?.statuses && crm_integration?.statuses.length ? (
                <div className={styles.statusSection}>
                    <Button
                        onClick={updateStatuses}
                        type="primary"
                        className={styles.buttonUpdatStatuses}
                    >
                        Обновить статусы
                    </Button>
                    <div className={styles.wrapperStatus}>
                        <div className={styles.content}>
                            {crm_integration?.statuses.map((item) => (
                                <div key={`item_crm_Status_${Math.random()}`}>
                                    <h2>{item?.name}</h2>
                                    <div>
                                        {item?.statuses &&
                                        item?.statuses.length ? (
                                            item?.statuses.map((status) => (
                                                <ItemStatus
                                                    key={`item_Status_${Math.random()}`}
                                                    item={status}
                                                />
                                            ))
                                        ) : (
                                            <h5>Статусы не найдены</h5>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <h4>Нет информации по канбан статусам</h4>
                </div>
            )}
        </div>
    );
};

export default CrmStatuses;
