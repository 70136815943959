import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../styles/index.module.scss';
import { Tabs } from 'antd';
import { useParams } from 'react-router-dom';
import FormEdit from './Helpers/form-edit';
import WidgetEmail from './Helpers/email';
import { api } from '../../../../Services';
import Loader from '../../../Loader';
import WidgetConnection from './Helpers/connection';
import WebhookTabs from './Helpers/webhook'

const WidgetEdit = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const widgetState = useSelector((state: any) => state.widgets);

    useEffect(()=>{
        dispatch(api.widgets.getById(id));
    }, []);

    const items = [
        {
            key: '1',
            label: `Настройки`,
            children: (
                <FormEdit
                    id={id}
                    dispatch={dispatch}
                    widgetState={widgetState}
                    widget={widgetState?.widget_edit || null}
                    styles={styles}
                    api={api}
                />
            ),
        },
        {
            key: '3',
            label: `Заявки`,
            children: (
                <WebhookTabs
                    styles={styles}
                    id={id}
                    dispatch={dispatch}
                    widget={widgetState?.widget_edit || null}
                />
            ),
        },
        {
            key: '4',
            label: `Контакты`,
            children: (
                <WidgetEmail
                    styles={styles}
                    id={id}
                    dispatch={dispatch}
                    widget={widgetState?.widget_edit || null}
                />
            ),
        },       
        {
            key: '6',
            label: `Подключение`,
            children: <WidgetConnection styles={styles} widget_id={id}/>,
        },
    ];

    return (
        <div className={`cities-page-block`}>
            <h1>Редактирование виджета</h1>

            {
                widgetState?.request_status ? 
                    <Loader width="100%" height="100%"/>
                : widgetState?.widget_edit ?   
                    <Tabs defaultActiveKey="1" items={items} />
                : 
                    <h5>Виджет не найден. обратитесь к администратору сервиса</h5>
            }
            
        </div>
    );
};

export default WidgetEdit;
