import React, { useEffect, useState } from 'react';
import { Form, Select } from 'antd';
import { useSelector } from 'react-redux';
import { Collapse } from 'antd';
import DynamicKeys from '../dynamicKeys';

const FormItemTemplate = ({ editorRef }) => {

    const [items, set_items] = useState([]);

	const {
		apartment_sheets_form_data,
        apartment_sheets_form_data_keys
	} = useSelector((state: any) => state?.realestate);

    const form = Form.useFormInstance();
	const content_html = Form.useWatch('content_html', form);
    const template = Form.useWatch('template', form);

    useEffect(()=>{
      
        if(apartment_sheets_form_data_keys && apartment_sheets_form_data_keys.length){
            set_items([...apartment_sheets_form_data_keys,
            {
                key: 'dynamic_keys',
                label: 'Динамические ключи',
                children: <DynamicKeys/>
            }])
        }

    }, [apartment_sheets_form_data_keys]);

	useEffect(()=>{

		const getTemplateHtml = apartment_sheets_form_data?.templates.filter((item)=>item?.value === template) || [];
		const templateHTML = getTemplateHtml.length && getTemplateHtml[0]?.html ? getTemplateHtml[0]?.html : '';

		form.setFieldsValue({
			content_html: templateHTML
		});

	}, [template]);

    useEffect(()=>{
      
        document.addEventListener('keydown', handleEnterKey);
        return () => {
            document.removeEventListener('keydown', handleEnterKey);
        };

    }, []);

    const handleEnterKey = event => {
        if (event.key === 'Enter') {
          document.execCommand('insertLineBreak');
          event.preventDefault();
        }
    };

    return (
        <>
            <Form.Item
                name="template"
                label="Выберите шаблон"
            >
                <Select
					placeholder="Выберите шаблон"
					options={apartment_sheets_form_data?.templates || []}
				/>
            </Form.Item>
            <Form.Item
                name="content_html"
                label="Заполните шаблон"
				rules={[
                    {
                        required: true,
                        message: 'Поле обязательно для заполнения',
                    },
                ]}
            >
                {
                    !!content_html && <Collapse size='small' accordion items={items} />
                }
                <div 
                    contentEditable={true}
                    ref={editorRef}
                    style={{
                        marginTop:10,
                        display: 'inline-block',
                        width: '100%'
                    }}
                    dangerouslySetInnerHTML={{
                        __html: content_html || '<div><h3>Выберите шаблон</h3></div>'
                    }}
                /> 
            </Form.Item>
        </>
    )
}

export default FormItemTemplate;