import { createContext } from 'react';

import { BookingContextProps } from './interfaces/builders';

export const BookingContext = createContext<BookingContextProps>({
    booking: {
        bookingInfo: [],
        managers: null,
        flatSidebar: null,
    },
    setBooking: () => {},
});
