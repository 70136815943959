import React from 'react';
import FormItemInformation from './information';
import FormItemPlan from './plan';
import FormItemPlanPrivew from './planPreview';
import styles from '../../../../../styles/home.module.scss';
import ItemTour from './tour/';
import { useSelector } from 'react-redux';

const FormItems = () => {

    const {
        form_meta_template_data
    } = useSelector((state: any) => state?.realestate);

    return (
        <>
            <FormItemInformation/>
            <div className={styles.flexRowWrapper}>
                <FormItemPlan/>
                <FormItemPlanPrivew/>
            </div>
            {
                form_meta_template_data?.status_form_edit && (
                    <ItemTour/>
                )
            }
            
        </>
    )
}

export default FormItems;