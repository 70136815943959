import React, { useState } from 'react';
import styles from './styles/image.module.scss';
import { LoadingOutlined } from '@ant-design/icons';

const Image = ({src, ref = null, height = 'auto', type = ''}) => {

    const [loading, set_loading] = useState(true);
    const [load_error, set_load_error] = useState(false);

    return (
        <div 
            className={styles.imageWrapper} 
            ref={ref}
            style={{
                maxHeight: height || '250px',
                minHeight: height || '250px',
                padding: type === 'REALESTATE' ? '' : height != 'auto' ? '0 15px' : '',
                margin: type === 'REALESTATE' ? '' : height != 'auto' ? '10px 0px' : '',
            }}
        >

            <div className={`${styles.loadingOverlay} ${loading ? styles.loadingOverlayActive : ''}`}>
                <LoadingOutlined />
            </div>

            <img
                alt='image'
                style={{
                    height: 'auto',
                    maxHeight: height,
                    maxWidth: '100%',
                    width: (type === 'REALESTATE' || type === 'PLAN') ? '100%' : 'auto',
                    objectFit: load_error ? 'scale-down' : 'cover',
                    opacity: load_error ? 0.1 : 1,
                }}
                src={load_error ? 'https://api.logovo.kz/files/2023/09/13/651660572f6a46e454f33e5a.webp' : src}
                onLoad={()=>set_loading(false)}
                onError={() => set_load_error(true)}

            />
        </div>
    )

}

export default Image;