import { produce } from 'immer';

const initialState = {
    user: null,
    mortgage: [],
    notes: [],

    request_mortgage: false,
    request: false,

    update_settings_request: false,

    status_request_generate_api_key: false,

    crm_integration: null,
};

const account = (state = initialState, action: any) => {
    switch (action.type) {
        case 'UPDATE_BUILDER_STATUSES':
            return produce(state, (draftState) => {
                draftState.crm_integration.statuses = action?.data || [];
            });

        case 'UPDATE_CRM_INTEGRATION_INFORMATION':
            return produce(state, (draftState) => {
                draftState.crm_integration = action?.data || null;
            });

        case 'RESET_BUILDER_CONNECTION_BX24':
            return produce(state, (draftState) => {
                draftState.user.builder.integrations = null;
                draftState.crm_integration = null;
            });

        case 'UPDATE_BUILDER_API_KEY':
            return produce(state, (draftState) => {
                draftState.user.builder.integrations = {
                    ...draftState.user.builder.integrations,
                    api_key: action?.data || '',
                };
            });

        case 'STATUS_REQUEST_GENERATE_API_KEY':
            return produce(state, (draftState) => {
                draftState.status_request_generate_api_key =
                    action?.status || false;
            });

        case 'ACCOUNT_NOTES_DATA':
            return produce(state, (draftState) => {
                draftState.notes = action?.data || [];
            });

        case 'ACCOUNT_NOTES_REQUEST':
            return produce(state, (draftState) => {
                draftState.request = action?.status || false;
            });

        case 'UPDATE_SETTINGS_REQUEST':
            return produce(state, (draftState) => {
                draftState.update_settings_request = action?.status || false;
            });

        case 'ACCOUNT_BUILDER_MORTGAGE_REQUEST':
            return produce(state, (draftState) => {
                draftState.request_mortgage = action?.status || false;
            });

        case 'ACCOUNT_BUILDER_MORTGAGE':
            return produce(state, (draftState) => {
                draftState.mortgage = action?.data || [];
            });

        case 'UPDATE_ACCOUNT':
            return produce(state, (draftState) => {
                draftState.user = action?.user || null;
            });

        case 'ACCOUNT_MORTGAGE_REQUEST':
            return produce(state, (draftState) => {
                draftState.request = action?.status || false;
            });

        default:
            return state;
    }
};

export default account;
