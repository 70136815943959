import React from 'react';

import { useLocation } from 'react-router-dom';
import styles from '../../styles/layout.module.scss';
import RoomInformation from './roomInformation';
import { useSelector } from 'react-redux';
import { Divider } from 'antd';

const SalesReport = () => {

    const stateSalesReport = useSelector((state: any) => state?.chess?.salesReport || null);
    const { pathname } = useLocation();
    return (<></>)
    return (
        <>
            {
                (stateSalesReport && pathname.includes('real-estate')) && (
                    <div className={styles.salesReportWrapper}>
                        <Divider plain style={{margin: '7px 0'}} >Отчет о продажах</Divider>

                        <fieldset>
                            <legend>1-комнатные квартиры</legend>
                            <RoomInformation room_key="one" />
                        </fieldset>

                        <fieldset>
                            <legend>2-комнатные квартиры</legend>
                            <RoomInformation room_key="two" />
                        </fieldset>

                        <fieldset>
                            <legend>3-комнатные квартиры</legend>
                            <RoomInformation room_key="three" />
                        </fieldset>

                        <fieldset>
                            <legend>4+ комнатные квартиры</legend>
                            <RoomInformation room_key="four" />
                        </fieldset>

                        <fieldset>
                            <legend>Общее</legend>
                            <RoomInformation room_key="all" />
                        </fieldset>

                        {
                            /*
                                <Divider plain style={{margin: '7px 0'}} >Паркинг</Divider>

                                {
                                    stateSalesReport?.parking ?
                                        <>
                                            <fieldset>
                                                <legend>Общая информация</legend>
                                                <div className={styles.section}>
                                                    <p>Всего</p>
                                                    <div className={styles.line}></div>
                                                    <h3>{stateSalesReport?.parking?.total_parking}</h3>
                                                </div>
                                                <div className={styles.section}>
                                                    <p>Продано</p>
                                                    <div className={styles.line}></div>
                                                    <h3>{stateSalesReport?.parking?.sold_parking_spaces} из {stateSalesReport?.parking?.total_parking}</h3>
                                                </div>
                                            </fieldset>
                                        </>
                                    :
                                    <h5>Информации нет</h5>
                                }
                            */
                        }

                    </div>
                )
            }
        </>
    )

}

export default SalesReport;