import React, { FC } from 'react';

import { FlatItem } from './Helpers/Flat';
import styles from './styles/chess.module.scss';

const ChessGrid: FC<{
    managers?: any[];
    account: string | null;
    block: any;
    realEstateId: string;
}> = ({ managers = [], account = null, block, realEstateId }) => (
    <div className={styles.columnGrid}>
        <h2 className={styles.blockTitle}>Блок - {block.title}</h2>
        <div className={styles.columnGridChess}>
            {block.floors &&
                block.floors.map((floor: any, floorIndex: number) => (
                    <div className={styles.columnGridFloor} key={floorIndex}>
                        <span>{floor.floor}</span>
                        {floor.flats && floor.flats.length ? (
                            floor.flats.map((flat: any, index: number) => (
                                <FlatItem
                                    managers={managers}
                                    account={account}
                                    realEstateId={realEstateId}
                                    key={flat._id}
                                    flat={flat}
                                />
                            ))
                        ) : (
                            <div className={styles.floorClear}>
                                <h6>На этаже нет квартир</h6>
                            </div>
                        )}
                    </div>
                ))}
        </div>
    </div>
);
export default ChessGrid;
