import React, { useMemo } from "react";

import { Outlet } from "react-router-dom";

import styles from "../styles/index.module.scss";

const AuthLayout = () => {
	return useMemo(
		() => (
			<div className={styles.layout}>
				<Outlet />
			</div>
		),
		[]
	);
};

export default AuthLayout;
