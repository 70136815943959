import { ApolloProvider } from '@apollo/react-hooks';
import { BrowserRouter } from 'react-router-dom';
import Initial from '@/Containers/Initial';
import { apollo } from '@/Services';
import { ConfigProvider } from 'antd';
import ru_RU from 'antd/lib/locale/ru_RU';
import { Provider } from 'react-redux';
import createStore from './Services/store';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { createRoot } from 'react-dom/client';
import SubscriptionProvider from './Containers/Initial/subscriptions';
import { persistStore } from 'redux-persist';

import '@/Services/default/styles/index.css';

const store = createStore();
const persistor = persistStore(store);

const rootElement = document.getElementById('root');

if (rootElement) {
    const root = createRoot(rootElement);

    root.render(
        <ConfigProvider locale={ru_RU}>
            <ApolloProvider client={apollo()}>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <SubscriptionProvider>
                            <BrowserRouter>
                                <Initial />
                            </BrowserRouter>
                        </SubscriptionProvider>
                    </PersistGate>
                </Provider>
            </ApolloProvider>
        </ConfigProvider>
    );
}
