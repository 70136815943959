import lang from 'suneditor/src/lang';

export const editorOptions = {
    buttonList: [
        ['font', 'fontSize', 'formatBlock'],
        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
        ['removeFormat'],
        ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'list', 'table'],
        ['link', 'image'],
        ['fullScreen', 'showBlocks', 'codeView'],
        ['preview', 'print'],
        ['undo', 'redo'],
    ],
    height: '700px',
    lang: lang?.ru
}