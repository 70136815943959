import React from 'react';
import styles from '../../../../../../styles/home.module.scss';
import { Collapse, theme } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import ItemTourEditor from './editor';

const ItemTour = () => {

    const { token } = theme.useToken();

    const getItems = () => [
        {
          key: 'tour',
          label: 'виртуальный тур',
          children: <ItemTourEditor/>,
          style: {
            marginBottom: 24,
            background: token.colorFillAlter,
            borderRadius: token.borderRadiusLG,
            border: 'none',
          },
        }
    ];

    return (
        <Collapse
            bordered={false}
            defaultActiveKey={['1']}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            style={{
                background: token.colorBgContainer,
            }}
            items={getItems()}
        />
    )
}

export default ItemTour;