import { Button, Checkbox, Form, Input, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

const FieldModalForm = ({ updatefields }) => {
    const dispatch = useDispatch();
    const {
        template_pattern_field_modal_form,
        template_pattern_field_modal_form_index,
    } = useSelector((state: any) => state?.realestate);
    const addField = (values) => {
        updatefields((data) => {
            data[template_pattern_field_modal_form_index].fields.push({
                ...values,
                name: `field_group_${template_pattern_field_modal_form_index}_${Date.now()}${Math.random()}`.replace(
                    /\./g,
                    ''
                ),
                id: `${Date.now()}_${Math.random()}`,
            });
            return [...data];
        });
    };

    const close = () => {
        dispatch({ type: 'TEMPLATE_PATTERN_FIELD_MODAL_FORM' });
    };

    return (
        <Modal
            title="Добавить новое поле"
            open={template_pattern_field_modal_form}
            footer={null}
            onCancel={close}
            centered
            destroyOnClose
        >
            <Form
                name="basic"
                onFinish={addField}
                autoComplete="off"
                layout="vertical"
            >
                <Form.Item
                    label="Наименование поля"
                    name="title"
                    rules={[
                        {
                            required: true,
                            message: 'Поле обязательно',
                        },
                    ]}
                >
                    <Input placeholder="Наименование поля" />
                </Form.Item>
                <Form.Item label="" name="required" valuePropName="checked">
                    <Checkbox>Обязательное поле</Checkbox>
                </Form.Item>
                <Button type="primary" htmlType="submit">
                    добавить
                </Button>
            </Form>
        </Modal>
    );
};

export default FieldModalForm;
