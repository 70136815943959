import React from 'react';
import { Collapse } from 'antd';
import { useSelector } from 'react-redux';

const FormKeys = () => {

    const { form_keys } = useSelector((state: any) => state?.contracts);

    return ( 
        <>
            <Collapse accordion items={form_keys} />
        </>
    )
}

export default FormKeys;