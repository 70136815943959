import React from 'react';
import styles from './styles/index.module.scss';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {

	let navigate = useNavigate();

	return (
		<div className={styles.pageNoFound}>
			<h4>404</h4>
			<h1>Страница не найдена</h1>
			<span onClick={()=>navigate('/')}>Вернуться на главную</span>
		</div>
	)
}

export default PageNotFound;