import { Typography } from 'antd';

const { Title } = Typography;

const NoData = ({ message = 'Информация не найдена' }) => {
    return (
        <div>
            <Title level={4}>{message}</Title>
        </div>
    );
};

export default NoData;
