import React from 'react';
import styles from '../styles/leads.module.scss';
import { Result } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import ItemCompany from '../helpers/item_company';

const Companies = ({ data, loading, no_data_text, deleteCompany }: any) => {
    return (
        <div>
            {loading ? (
                <div className={styles.infoblock}>
                    <LoadingOutlined />
                </div>
            ) : data && data.length ? (
                <div className={styles.list}>
                    {data.map((item: any) => (
                        <ItemCompany
                            deleteCompany={() =>
                                deleteCompany({
                                    variables: {
                                        id: item?._id,
                                    },
                                })
                            }
                            key={item?._id}
                            item={item}
                        />
                    ))}
                </div>
            ) : (
                <Result
                    status="404"
                    title="Компаний не найдено"
                    subTitle={no_data_text}
                    extra={null}
                />
            )}
        </div>
    );
};

export default Companies;
