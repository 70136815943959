import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import styles from './styles/loader.module.scss';

const Loader = () => {
    return (
        <div className={styles.loaderWrapper}>
            <LoadingOutlined />
        </div>
    )
}

export default Loader;