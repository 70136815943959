import React, { useState } from 'react';
import { Button, Tooltip, Popover } from 'antd';
import {
    PictureOutlined,
    ExclamationCircleOutlined,
    LinkOutlined,
    LoadingOutlined,
    CloseOutlined,
} from '@ant-design/icons';
import { useNavigate } from "react-router-dom";

import config from '@/Config';
import AddProduct from './add-product-form';

const FormButtons = ({
    styles,
    virtualTour,
    template_id,
    uploadPhoto,
    loading,
    scenes,
    tour_index,
    refMain,
    updateFields,
    vt_id,
    updateFieldsData,
    set_yaw,
    set_pitch,
}) => {
    const [modal_scenes, set_modal_scenes] = useState(false);
    const [add_product_modal, set_add_product_modal] = useState(false);

    const navigate = useNavigate();

    const editTemplateflat = () => {
        navigate(`/apartments/flats/${template_id}/edit`);
    }

    const renderScenes = () => {
        return (
            <div className={styles.buttonScenes}>
                {scenes.map((scene, index) => {
                    if (tour_index !== index) {
                        return (
                            <Tooltip
                                placement="bottom"
                                key={scene?._id}
                                title={
                                    scene?.title_room?.title ||
                                    'Не определено'
                                }
                            >
                                <div
                                    onClick={() => addScene(index)}
                                    className={styles.buttonScene}
                                    style={{
                                        background: `url(${config.public}${scene?.url}) center / cover no-repeat`,
                                    }}
                                />
                            </Tooltip>
                        );
                    }
                })}
            </div>
        );
    };

    const addScene = (select_index_scene) => {
        const getYaw = refMain.current.getViewer().getYaw();
        const getPitch = refMain.current.getViewer().getPitch();

        set_yaw(getYaw);
        set_pitch(getPitch);

        let sceneFields = {
            position: {
                pitch: getPitch,
                yaw: getYaw,
            },
            type: 'NEW_SCENE',
            indexScene: select_index_scene,
        };

        updateFields({
            variables: {
                fields: {
                    vt_id,
                    active_scene_id: scenes[tour_index]?._id,
                    type: 'add_scene',
                    data_scene_object: sceneFields,
                },
            },
        });

        set_modal_scenes(false);
    };

    return (
        <div className={styles.buttons}>
            <div className={styles.left}></div>
            <div className={styles.right}>
                <Tooltip title="Загрузить сцену формата 360">
                    <Button
                        type="primary"
                        size="large"
                        className={styles.button}
                    >
                        {loading ? (
                            <>
                                <LoadingOutlined />
                            </>
                        ) : (
                            <>
                                <input
                                    type="file"
                                    onChange={(value) => uploadPhoto(value)}
                                />
                                <PictureOutlined />
                            </>
                        )}
                    </Button>
                </Tooltip>
                {virtualTour?.photos && virtualTour?.photos.length ? (
                    <Tooltip title="Добавить описание товара">
                        <AddProduct
                            styles={styles}
                            close={() => set_add_product_modal(false)}
                            isVisible={add_product_modal}
                            config={config}
                            vt_id={vt_id}
                            refMain={refMain}
                            set_pitch={set_pitch}
                            set_yaw={set_yaw}
                            active_scene_id={scenes[tour_index]?._id}
                            updateFields={updateFields}
                        />

                        {updateFieldsData?.loading ? (
                            <Button
                                type="primary"
                                size="large"
                                className={styles.button}
                            >
                                <LoadingOutlined />
                            </Button>
                        ) : (
                            <Button
                                type="primary"
                                size="large"
                                className={styles.button}
                                onClick={() =>
                                    set_add_product_modal(
                                        !add_product_modal
                                    )
                                }
                            >
                                <ExclamationCircleOutlined />
                            </Button>
                        )}
                    </Tooltip>
                ) : (
                    <></>
                )}

                {virtualTour?.photos && virtualTour?.photos.length > 1 ? (
                    <Tooltip
                        title={
                            modal_scenes
                                ? 'Закрыть окно'
                                : 'Добавить переход на другую сцену'
                        }
                    >
                        <Popover
                            placement="left"
                            title="Выберите сцену"
                            content={renderScenes}
                            open={modal_scenes}
                        >
                            {updateFieldsData?.loading ? (
                                <Button
                                    type="primary"
                                    size="large"
                                    className={styles.button}
                                >
                                    <LoadingOutlined />
                                </Button>
                            ) : (
                                <Button
                                    type="primary"
                                    size="large"
                                    className={styles.button}
                                    onClick={() =>
                                        set_modal_scenes(!modal_scenes)
                                    }
                                >
                                    {modal_scenes ? (
                                        <CloseOutlined />
                                    ) : (
                                        <LinkOutlined />
                                    )}
                                </Button>
                            )}
                        </Popover>
                    </Tooltip>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
}

export default FormButtons;
