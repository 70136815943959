import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { api } from '@/Services';
import styles from './styles/catalog.module.scss';
import { Button } from 'antd';
import FilterCatalog from './helpers/filter';
import ContentFlats from './helpers/flats';

const RealestateCatalog = () => {

    const { id } = useParams();
    const dispatch = useDispatch();
    const realestateTitle = useSelector((state: any) => state?.realestate?.form_meta_information_data?.realestate?.title);

    useEffect(()=>{
        dispatch({
            type: 'SEARCH_FLATS_DATA',
            data: []
        });
        dispatch(api.realestate.formMetaInformation(id));
    }, [])

    return (
        <div className={styles.chessWrapper}>
            <div className={styles.header}>
                <h1>Каталог жк {realestateTitle}</h1>
                <div className={styles.navigation}>
                    <Link to={`/real-estate/${id}`}>
                        <Button>
                            Шахматка
                        </Button>
                    </Link>
                    <Button disabled>
                        Каталог
                    </Button>
                </div>
            </div>
            <div className={styles.content}>
                <FilterCatalog api={api} realestate_id={id}/>
                <ContentFlats/>
            </div>
        </div>
    );
};

export default RealestateCatalog;
