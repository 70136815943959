import React from 'react';
import styles from '../../../../styles/form-payment.module.scss';
import { Tag, Tooltip, Popconfirm, Form, Divider, Collapse } from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import FormBlocks from './forms/blocks';

const Blocks = ({
    form, 
    apartments_condition
}) => {

    Form.useWatch('conditions', { form, preserve: true });
    const getBlocks = form.getFieldsValue() ? form.getFieldsValue()?.conditions[apartments_condition?.condition_key]?.apartments_conditions[apartments_condition?.apartment_condition_key]?.blocks : [];

    const opBlock = (itemData, type) => {

        let updateBlocks = getBlocks || [];

        if(type === 'delete'){
            updateBlocks = getBlocks.filter((item)=>String(item?._id) != String(itemData));
        }else{
            updateBlocks = getBlocks && getBlocks.length ? [...getBlocks, itemData] : [itemData];
        }

        let { conditions } = form.getFieldsValue();

        Object.assign(conditions[apartments_condition?.condition_key].apartments_conditions[apartments_condition?.apartment_condition_key], { blocks: updateBlocks });

        return form.setFieldsValue({
            conditions
        });

    }

    return (
        <div>
            <div>
                <h4>Добавленные блоки</h4>
                {
                    getBlocks && getBlocks.length ?
                    <div className={styles.itemData}>
                        {
                            getBlocks.map((item, index)=>(
                                <div key={`${item?._id}_${index}`} className={styles.item}>
                                    <Tag color="lime">Блок №{ item?.title }, ЖК {item?.apartment_complex_id?.title || item?.complex_id?.title || 'Не определено'}</Tag>
                                    <Tooltip title="Удалить" placement='bottom'>
                                        <Popconfirm
                                            title="Блок будет удален из условия"
                                            onConfirm={()=>opBlock(item?._id, 'delete')}
                                            okText="Удалить"
                                            cancelText="Нет"
                                        >
                                            <CloseOutlined />
                                        </Popconfirm>
                                    </Tooltip>
                                </div>
                            ))
                        }
                    </div>
                    :
                        <Tag color="red">Блоков не найдено</Tag>
                }
            </div>

            <Divider/>

            <Collapse
                bordered={false}
                expandIcon={({ isActive }) => <PlusOutlined rotate={isActive ? 135 : 0} />}
                items={[{
                    key: '1',
                    label: 'Добавить блоки',
                    children: <FormBlocks
                        opBlock={opBlock}
                        styles={styles}
                    />,
                    style: {
                        background: '#f1f1f1',
                        borderRadius: 7
                    }
                }]}
            />

        </div>
    )

}

export default Blocks;