import React from 'react';
import { Select, Input } from 'antd';
import styles from '../../styles/index.module.scss'

const { Search } = Input;

const Filter = ({set_type, set_txt, searchEvent, set_page, loading }) => {


    return (
        <div className={styles.filter}>
            <p>Поиск</p>
            <div className={styles.form}>
                <Select
                    size="large"
                    showSearch
                    placeholder="Выберите тип виджета"
                    optionFilterProp="children"
                    onChange={(val)=>{
                        set_type(val);
                        set_page(1);
                    }}
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    className={styles.select}
                    options={[
                    {
                        value: 'DEFAULT',
                        label: 'Стандратный',
                    },
                    {
                        value: '3DTOURS',
                        label: '360',
                    }
                    ]}
                />
                <Search onSearch={searchEvent} loading={loading} onChange={(val)=>{
                    set_txt(val.target.value);
                    set_page(1);
                }} size="large" placeholder='Введите название виджета' />
            </div>
        </div>
    )
}

export default Filter;