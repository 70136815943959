import react, { useState, useEffect } from 'react';
import { Modal, Select, Input, Statistic, message } from 'antd';
import { useQuery } from '@apollo/client';
import * as gqls from '@/Services/apollo/gqls';
import styles from '../styles/leads.module.scss';
import { separateNumber } from '@/Services';
import { useSelector, useDispatch } from 'react-redux';

const { Option } = Select;

const CreateCompany = ({
    isModalOpen,
    close,
    createCompanyData,
    createCompany,
    editCompanyData,
    editCompany,
}: {
    isModalOpen: boolean;
    close: () => void;
    createCompanyData?: any;
    createCompany?: any;
    editCompanyData?: any;
    editCompany?: any;
}) => {
    const itemCompany = useSelector(
        (state: any) => state?.companies?.edit_company
    );
    const dispatch = useDispatch();

    const [select_realestate, set_select_realestate] = useState(null);
    const [title, set_title] = useState('');
    const [quantity_leads, set_quantity_leads] = useState('');
    const [price_lead, set_price_lead] = useState(0);

    const { data, loading } = useQuery(gqls.builder.getDataForCreateCompany);

    const selectRealestate = (value: any) => {
        set_select_realestate(value);

        let getSelectedCompany = data?.getRealestateForCompany.filter(
            (item: any) => String(item?._id) === String(value)
        );

        set_price_lead(
            getSelectedCompany && getSelectedCompany.length
                ? getSelectedCompany[0].crm_price_lead
                : 0
        );
    };

    useEffect(() => {
        if (isModalOpen) {
            set_title('');
            set_quantity_leads('');
            set_select_realestate(null);
        }
    }, [isModalOpen]);

    useEffect(() => {
        if (itemCompany) {
            let getSelectedCompany = data?.getRealestateForCompany.filter(
                (item: any) =>
                    String(item?._id) === String(itemCompany?.realestate?._id)
            );

            set_price_lead(
                getSelectedCompany && getSelectedCompany.length
                    ? getSelectedCompany[0].crm_price_lead
                    : 0
            );

            set_title(itemCompany?.title);
            set_quantity_leads(itemCompany?.quantity_leads);
            set_select_realestate(itemCompany?.realestate?._id);
        } else {
            set_title('');
            set_quantity_leads('');
            set_select_realestate(null);
            set_price_lead(0);
        }
    }, [itemCompany]);

    return (
        <>
            <Modal
                width="65%"
                title={
                    itemCompany
                        ? 'Редактирование компании'
                        : 'Создание компании'
                }
                onCancel={() => {
                    close();
                    dispatch({
                        type: 'MODAL_EDIT_FORM_COMPANY',
                        item: null,
                    });
                }}
                open={isModalOpen}
                onOk={() => {
                    if (
                        !createCompanyData?.loading &&
                        !editCompanyData?.loading
                    ) {
                        if (itemCompany) {
                            if (title) {
                                return editCompany({
                                    variables: {
                                        fields: {
                                            realestate: select_realestate,
                                            quantity_leads:
                                                Number(quantity_leads),
                                            title,
                                            _id: itemCompany?._id,
                                        },
                                    },
                                });
                            }
                        } else {
                            if (select_realestate && title && quantity_leads) {
                                return createCompany({
                                    variables: {
                                        fields: {
                                            realestate: select_realestate,
                                            quantity_leads:
                                                Number(quantity_leads),
                                            title,
                                        },
                                    },
                                });
                            }
                        }

                        return message.warning('Заполните поля');
                    }
                }}
                okText={itemCompany ? 'Редактировать' : 'Создать'}
            >
                <Input
                    size="large"
                    value={title}
                    placeholder="Наименование компании"
                    onChange={(value: any) => set_title(value.target.value)}
                    style={{ marginBottom: 10 }}
                />
                <Select
                    size="large"
                    allowClear
                    loading={loading}
                    showSearch
                    style={{
                        width: '100%',
                        marginBottom: 10,
                    }}
                    value={select_realestate}
                    disabled={itemCompany ? true : false}
                    placeholder="Выберите жк из списка"
                    onChange={selectRealestate}
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                        option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA: any, optionB: any) =>
                        optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                    }
                >
                    {data?.getRealestateForCompany &&
                        data?.getRealestateForCompany.length &&
                        data?.getRealestateForCompany.map((item: any) => {
                            return (
                                <Option key={item?._id} value={item?._id}>
                                    {item?.title}
                                </Option>
                            );
                        })}
                </Select>
                <Input
                    size="large"
                    value={quantity_leads}
                    placeholder="Количество лидов"
                    onChange={(value: any) =>
                        set_quantity_leads(value.target.value)
                    }
                    disabled={itemCompany ? true : false}
                    style={{ marginBottom: 10 }}
                />

                <div className={styles.blockPriceLeads}>
                    <Statistic
                        title={`Стоимость компании (стоимость 1 лида ${separateNumber(
                            Number(price_lead || 0)
                        )}₸)`}
                        value={`${separateNumber(
                            Number(price_lead || 0) *
                                Number(quantity_leads || 0)
                        )}₸`}
                    />
                </div>
            </Modal>
        </>
    );
};

export default CreateCompany;
