import React, { useEffect } from 'react';
import { Form, Input, Button, Tooltip } from 'antd';
import { api } from '@/Services';

const WebhookDeals = ({
    bitrix_deal,
    styles,
    dispatch,
    id
}) => {

    const [form] = Form.useForm();

    useEffect(()=>{

        form.setFieldsValue({
            contact_method: bitrix_deal?.contact_method || '',
            deal_method: bitrix_deal?.deal_method || '',
            deal_contact_method: bitrix_deal?.deal_contact_method || '',
        });

    }, [bitrix_deal])

    const onFinish = (values) => {

        dispatch(api.widgets.saveWebhook(id, {
            type_form: 'webhook_bitrix_contract',
            ...values
        }));

    };

    return (
        <Form
            form={form}
            name="basic"
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
        >
            <Tooltip 
                title="webhook: crm.contact.add"
                placement='topLeft'
            >
                <Form.Item
                    label=""
                    name="contact_method"
                    rules={[
                        {
                            required: true,
                            message: 'Заполните обязательное поле',
                        },
                    ]}
                    className={styles.inputItem}
                >
                    <Input.Password size="large" placeholder='Добавьте webhook: crm.contact.add' />
                </Form.Item>
            </Tooltip>

            <Tooltip 
                title="webhook: crm.deal.add"
                placement='topLeft'
            >
                <Form.Item
                    label=""
                    name="deal_method"
                    rules={[
                        {
                            required: true,
                            message: 'Заполните обязательное поле',
                        },
                    ]}
                    className={styles.inputItem}
                >
                    <Input.Password size="large" placeholder='Добавьте webhook: crm.deal.add' />
                </Form.Item>
            </Tooltip>

            <Tooltip 
                title="webhook: crm.deal.contact.add"
                placement='topLeft'
            >
                <Form.Item
                    label=""
                    name="deal_contact_method"
                    rules={[
                        {
                            required: true,
                            message: 'Заполните обязательное поле',
                        },
                    ]}
                    className={styles.inputItem}
                >
                    <Input.Password size="large" placeholder='Добавьте webhook: crm.deal.contact.add'/>
                </Form.Item>
            </Tooltip>

            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Сохранить
                </Button>
            </Form.Item>
        </Form>
    )
}

export default WebhookDeals;