import React, { useEffect, useState } from 'react';
import styles from '../../styles/settings.module.scss';
import { Button, Result, Table, Tag, Space, Popconfirm } from 'antd';
import { api } from '@/Services';
import { useDispatch, useSelector } from 'react-redux';
import NoteForm from './form';
import { LoadingOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import moment from 'moment';

const Notes = () => {

    const [status_form, set_status_form] = useState(false);
    const [note, set_note] = useState(null);

    const dispatch = useDispatch();
    const { notes, request } = useSelector((state: any) => state?.account)

    useEffect(()=>{
        dispatch(api.account.getNotes());
    }, []);

    const columns = [
        {
            title: 'Заметка',
            dataIndex: 'note',
            key: 'note'
        },
        {
            title: 'Дата создания',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (_, item)=>{
                return <Tag>{ moment(item?.createdAt).format('DD MMMM YYYY') }</Tag>
            },
            sorter: (a, b) => a.createdAt - b.createdAt
        },
        {
            title: 'Управление',
            key: 'action',
            render: (_, item) => (
                <Space size="middle">
                    <Button size="small" type="dashed" onClick={()=>{
                        set_note(item);
                        set_status_form(true);
                    }}><EditOutlined /></Button>
                    <Popconfirm
                        title="Запись будет удалена"
                        onConfirm={()=>deleteNote(item?._id)}
                        okText="удалить"
                        cancelText="Нет"
                    >
                        <Button size="small" danger type="dashed"><DeleteOutlined /></Button>
                    </Popconfirm>
                    
                </Space>
            ),
            width: 200
        },
    ];

    const deleteNote = (note_id) => {
        dispatch(api.account.deleteNote(note_id));
    }

    return (
        <div className={styles.wrapperNotes}>

            <NoteForm
                api={api}
                dispatch={dispatch}
                status={status_form}
                close={()=>{
                    set_status_form(false);
                    set_note(null);
                }}
                note={note}
            />

            <div className={styles.header}>
                <Button type='primary' onClick={()=>set_status_form(true)}>Добавить</Button>
            </div>

            <div className={styles.content}>
                
                {
                    request ?
                        <LoadingOutlined />
                    : notes && notes.length ?
                        <Table 
                            pagination={false} 
                            loading={request} 
                            columns={columns} 
                            dataSource={notes} 
                        />
                    :
                        <Result
                            status="404"
                            title="Заметок не найдено"
                            subTitle="добавьте несколько заметок"
                            extra={<Button type='primary' onClick={()=>set_status_form(true)}>Добавить</Button>}
                        />
                }

            </div>

        </div>
    )
}

export default Notes;