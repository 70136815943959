import React, { useState, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import * as gqls from '@/Services/apollo/gqls';
import styles from './styles/createManager.module.scss';
import InputMask from 'antd-mask-input';
import _ from 'underscore';
import checkFields from '../helpers/fields';
import { Button, Input, notification } from 'antd';

const CreateManager: FC = () => {
    let navigate = useNavigate();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');

    const [addManager, addManagerData] = useMutation(gqls.builder.addManager, {
        refetchQueries: () => [
            {
                query: gqls.builder.getManagersOfRealtor,
            },
        ],
        onCompleted: () => {
            return navigate('/managers');
        },
        onError: (err: any) => {
            return notification.open({
                message: 'Ошибка',
                description:
                    err.message ||
                    'Ошибка, попробуйте чуть позже или обратитесь к администратору',
            });
        },
    });

    const addManagerEvent = async () => {
        let checkError = await checkFields({
            email,
            firstname: firstName,
            lastname: lastName,
            password,
            phone,
            repeat_password: repeatPassword,
        });

        if (!checkError.status) {
            return alert(checkError.msg);
        }

        return addManager({
            variables: {
                fields: {
                    email,
                    firstname: firstName,
                    lastname: lastName,
                    password,
                    phone,
                },
            },
        });
    };

    return (
        <section className={styles.managerWrapper}>
            <h1 className={styles.headManagerPage}>Добавить менеджера</h1>

            <div className={`${styles.formManager} ${styles.formRealtor}`}>
                <div className={styles.formManagerPWD}>
                    <Input
                        size="large"
                        type="text"
                        value={firstName}
                        onChange={(val) => setFirstName(val.target.value)}
                        placeholder="Имя"
                    />
                    <Input
                        size="large"
                        type="text"
                        value={lastName}
                        onChange={(val) => setLastName(val.target.value)}
                        placeholder="Фамилия"
                    />
                </div>
                <div className={styles.formManagerPWD}>
                    <InputMask
                        size="large"
                        mask="+7 (000) 000 0000"
                        type="text"
                        placeholder="Телефон"
                        value={phone}
                        onChange={(val) => setPhone(val.target.value)}
                    />
                    <Input
                        size="large"
                        type="text"
                        value={email}
                        onChange={(val) => setEmail(val.target.value)}
                        placeholder="Email"
                    />
                </div>

                <div className={styles.formManagerPWD}>
                    <Input
                        size="large"
                        type="password"
                        value={password}
                        onChange={(val) => setPassword(val.target.value)}
                        placeholder="Пароль"
                    />
                    <Input
                        size="large"
                        type="password"
                        value={repeatPassword}
                        onChange={(val) => setRepeatPassword(val.target.value)}
                        placeholder="Повторите пароль"
                    />
                </div>

                {addManagerData.error && (
                    <section className={styles.error}>
                        <p>{addManagerData?.error?.message}</p>
                    </section>
                )}

                <Button onClick={addManagerEvent} type="primary">
                    Добавить
                </Button>
            </div>
        </section>
    );
};

export default CreateManager;
