import { applyMiddleware } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import ReduxThunk from 'redux-thunk';
import pReducers from './persist';

const createStore = () => {

    const middleware = [];
    const enhancers = [];

    if(process.env.NODE_ENV !== 'production'){
        middleware.push(logger);
    }

    middleware.push(ReduxThunk);
    enhancers.push(applyMiddleware(...middleware));

    const store = configureStore({
        devTools: process.env.NODE_ENV !== 'production',
        reducer: pReducers,
        enhancers,
        middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
    });

    return store;
};

export type RootState = ReturnType<typeof pReducers>;
export type AppStore = ReturnType<typeof createStore>;
export type AppDispatch = AppStore['dispatch'];

export default createStore;
