import React, { useEffect } from 'react';
import { useTimer } from 'react-timer-hook';
import styles from '../../styles/contracts.module.scss';

const ContractPaymentTimer = ({ lock_up_to }) => {

    const {
        seconds,
        minutes,
        hours,
        restart
    } = useTimer({ 
        expiryTimestamp: new Date(lock_up_to),
        autoStart: true
    });

    useEffect(()=>{
        restart(new Date(lock_up_to));
    }, [lock_up_to])

    return (<div className={styles.timerBlock}>
        {
            minutes === 0 && seconds === 0 && hours === 0 ?
                <h5>Время вышло</h5>
            :
                <>
                    <span>{ hours < 10 ? 0 : '' }{hours}</span>
                    <p>:</p>
                    <span>{ minutes < 10 ? 0 : '' }{minutes}</span>
                    <p>:</p>
                    <span>{ seconds < 10 ? 0 : '' }{seconds}</span>
                </>
        }
    </div>)

}

export default ContractPaymentTimer;