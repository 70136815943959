import { Link } from 'react-router-dom';
import styles from './styles/intergration.module.scss';

const Integration = () => {
    return (
        <section className={styles.wrapper}>
            <h2 className={styles.title}>Интеграция с CRM системами</h2>

            <div className={styles.contentGrid}>
                <Link to="b24" className={styles.link}>
                    <div className={`${styles.logo} ${styles.logob24}`}></div>
                </Link>
            </div>

        </section>
    )
}

export default Integration;