import { Switch, Input, Form, Button, Select } from "antd";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { api } from '@/Services';

interface LogovoFormP {
    styles: any
    id: string
    dispatch: string
}

interface FormType {
    status_redirect: boolean
    url: string
    realestate: string
}

const LogovoForm:FC<LogovoFormP> = ({styles, id}) => {

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const widgetInfo = useSelector((state: any) => state.widgets?.widget_edit);
    const status_redirect = Form.useWatch('status_redirect', form);
    const realestate = Form.useWatch('realestate', form);

    const onFinish = (values) => {
        dispatch(api.widgets.editFormLogovoApp({
            ...values,
            id
        }));

    }

    useEffect(()=>{

        const getFormLogovoOfRealestate =  (widgetInfo?.form_logovo && widgetInfo.form_logovo.length) ? widgetInfo?.form_logovo.filter((item)=>String(item.realestate) === String(realestate)) : null;

        form.setFieldsValue({
            url: getFormLogovoOfRealestate && getFormLogovoOfRealestate.length ? getFormLogovoOfRealestate[0]?.url : '',
            status_redirect: getFormLogovoOfRealestate && getFormLogovoOfRealestate.length ? getFormLogovoOfRealestate[0]?.status_redirect : false,
        });

    }, [realestate]);

    return (
        <div className={styles.pageWrapper}>

            <Form
                form={form}
                name="basic"
                onFinish={onFinish}
                autoComplete="off"
                layout="vertical"
                size="large"
            >

                <Form.Item<FormType>
                    label="Выберите жк из списка"
                    name="realestate"
                >
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Выберите жк"
                        optionFilterProp="children"
                        filterOption={(input: any, option: any) => (option?.label ?? '').includes(input)}
                        filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={(widgetInfo.realestate && widgetInfo.realestate.length) ? widgetInfo.realestate.map((item)=>({
                            value: item._id,
                            label: item.title
                        })) : []}
                    />
                </Form.Item>

                {
                    realestate && (
                        <>
                            <Form.Item<FormType>
                                label="Перенаправлять клиента после успешной заявки на другую страницу"
                                name="status_redirect"
                                valuePropName="checked"
                            >
                                <Switch/>
                            </Form.Item>

                            {
                                status_redirect && (
                                    <Form.Item<FormType>
                                        label="Укажите ссылку"
                                        name="url"
                                        rules={[{
                                            required: true 
                                        }, { 
                                            type: 'url', 
                                            warningOnly: true 
                                        }, { 
                                            type: 'string', min: 6 
                                        }]}
                                    >
                                        <Input placeholder="Введите ссылку"/>
                                    </Form.Item>
                                )
                            }
                        </>
                    )
                }

                <Form.Item style={{marginTop:5}}>
                    <Button type="primary" htmlType="submit">
                        Обновить информацию
                    </Button>
                </Form.Item>

            </Form>
        </div>
    )
}

export default LogovoForm;