import React from 'react';
import Points from './points';

const ScenesAndProducts = ({
    scenes,
    tour_index,
    styles,
    refMain,
    updateFields,
    vt_id,
    set_yaw,
    set_pitch,
}) => {
    return (
        <Points
            updateFields={updateFields}
            vt_id={vt_id}
            refMain={refMain}
            styles={styles}
            points={scenes[tour_index]?.vr_data}
            scene_id={scenes[tour_index]?._id}
            set_yaw={set_yaw}
            set_pitch={set_pitch}
            scenes={scenes}
        />
    );
};

export default ScenesAndProducts;
