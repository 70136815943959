import React, { memo, useState } from 'react';
import { Button, Input, Popover, Form, Tag, message } from 'antd';
import { api } from '../../../../../Services';
import MaskedInput from 'antd-mask-input';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';

const WidgetEmail = ({ styles, id, dispatch, widget }) => {

    const [form_value] = useState({
        email: widget?.email || '',
        phones: widget?.phones || []
    });

    const onFinish = (values) => {
        if(values?.phones.length === 0){
            return message.warning('Пожалуйста, добавьте телефон');
        }
        dispatch(api.widgets.saveContacts(id, values));
    }

    const onFinishFailed = (errors) => {

        if(errors?.values?.phones.length === 0){
            return message.warning('Пожалуйста, добавьте телефон');
        }

    };

    return (
        <div className={styles.formWebhook}>


            <Form
                name="basic"
                initialValues={form_value}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                size="large"
            >

                <Popover
                    title=""
                    content="Введите email (на данный email будут приходить заявки)"
                    trigger={['hover', 'focus']}
                    placement="topLeft"
                >
                    <Form.Item
                        name="email"
                        className={styles.inputWithoutMargin}
                    >
                        <Input
                            placeholder="email"
                            className={styles.formItem}
                        />
                    </Form.Item>
                    
                </Popover>

                <Form.List name="phones">
                    {
                        (fields, { add, remove }) => (
                            <div className={styles.blockPhones}>
                                {
                                    fields && fields.length === 0 ?
                                        <div className={styles.messages}>
                                            {
                                                widget?.phone ? 
                                                <Tag color="lime" style={{marginBottom: 10}}>Сейчас используется: {widget?.phone}</Tag>
                                                : <></>
                                            }
                                            
                                            <Tag color="red" style={{marginBottom: 10}}>Нет добавленных телефонов. Добавьте хотя бы 1 номер телефона</Tag>
                                        </div>
                                    :
                                        <></>
                                }
                                
                                {
                                    fields.map((field)=>(
                                        <div key={field?.key +'_'+Math.random()} className={styles.phoneItem}>

                                            <Popover 
                                                title="" 
                                                content="Введите телефон"
                                                trigger={['hover', 'focus']}
                                                placement="topLeft"
                                            >
                                                <Form.Item
                                                    name={[field.name, 'phone']}
                                                    className={styles.item}
                                                    rules={[
                                                        {
                                                        required: true,
                                                        message: 'Пожалуйста, заполните поле',
                                                        },
                                                    ]}
                                                >
                                                    <MaskedInput
                                                        size='large'
                                                        mask="+7 (000) 000 00 00"
                                                        placeholder="Телефон"
                                                        className={styles.formItem}
                                                    />
                                                </Form.Item>
                                            </Popover>

                                            <Button onClick={() => remove(field.name)} type="link" danger size="small">
                                                <MinusCircleOutlined className={styles.deleteConditionIcon} />
                                            </Button>

                                        </div> 
                                    ))
                                }

                                <Form.Item style={{margin: 0}}>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        Добавить телефон
                                    </Button>
                                </Form.Item>

                            </div>
                        )
                    }
                </Form.List>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                    Сохранить
                    </Button>
                </Form.Item>


            </Form>
        </div>
    );
};

export default memo(WidgetEmail);
