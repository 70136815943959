import React, { useMemo } from 'react';

import styles from './styles/index.module.scss';

import { MutatingDots } from 'react-loader-spinner';

import * as types from './interfaces';

const LoaderComp = (props: types.PropType) => {
    let configStyle: types.PropType = {
        width: props.width ? props.width : '100vw',
        height: props.height ? props.height : '100vw',
    };

    if (props.background) {
        configStyle = {
            ...configStyle,
            background: 'transparent',
        };
    }

    return useMemo(
        () => (
            <div className={styles.loader} style={configStyle}>
                <div className={styles.formLogin}>
                    <MutatingDots
                        height="100"
                        width="100"
                        color="#4079c6"
                        secondaryColor="#4079c6"
                        radius="12.5"
                        ariaLabel="mutating-dots-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                </div>
            </div>
        ),
        []
    );
};

export default LoaderComp;
