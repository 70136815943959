import React from 'react';
import { Form, Select, Typography, Input } from 'antd';
import styles from '../../../../../styles/home.module.scss';
import { useSelector } from 'react-redux';

const { Text } = Typography;

const FormItemMoreInformation = () => {

    const {
        form_meta_information_data
    } = useSelector((state: any) => state?.realestate);

    return (
        <div className={styles.sectionForm}>

            <Text type="secondary">Дополнительная информация</Text>

            <Form.Item
                name="interior"
                label="Внутренняя отделка"
            >
                <Select
                    placeholder="Внутренняя отделка"
                    options={form_meta_information_data?.interior || []}
                />
            </Form.Item>

            <Form.Item
                name="parking"
                label="Паркинг"
            >
                <Select
                    placeholder="Паркинг"
                    options={form_meta_information_data?.parking || []}
                />
            </Form.Item>

            <Form.Item
                name="building_construction"
                label="Конструкция здания"
            >
                <Input
                    placeholder="Конструкция здания"
                />
            </Form.Item>

            <Form.Item
                name="building_facade"
                label="Фасад здания"
            >
                <Input
                    placeholder="Фасад здания"
                />
            </Form.Item>

            <Form.Item
                name="number_of_apartments"
                label="Количество квартир на площадке"
            >
                <Input
                    placeholder="Количество квартир на площадке"
                />
            </Form.Item>

            <Form.Item
                name="ceiling_height"
                label="Высота потолков"
                rules={[
                    {
                        required: true,
                        message: 'Поле обязательно для заполнения'
                    },
                ]}
            >
                <Input
                    placeholder="Высота потолков"
                />
            </Form.Item>

            <Form.Item
                name="window_height"
                label="Высота окон"
                rules={[
                    {
                        required: true,
                        message: 'Поле обязательно для заполнения'
                    },
                ]}
            >
                <Input
                    placeholder="Высота окон"
                />
            </Form.Item>

        </div>
    )
}

export default FormItemMoreInformation;