import { produce } from 'immer';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Tag, message, Button, Collapse } from 'antd';

const initialState = {
    chess: [],

    realestate_id: '',

    //notifications
    notifications: [],
    visible_notification: false,

    form_realestate_request: false,
    form_meta_information_data: null,

    set_data_chess: null,

    apartment_templates: null,
    req_apartment_templates: null,
    req_delete_apartment_template: false,

    form_template_request: false,
    form_meta_template_data: null,

    apartment_sheets_form_request: false,
    apartment_sheets_form_data: null,
    apartment_sheets_form_data_keys: null,

    flats_data_search: [],
    request_status_flats_data_search: false,

    builder_information: null,
    builder_request_status: false,

    template_pattern_field_modal_form: false,
    template_pattern_field_modal_form_index: 0,
};

const realestate = (state = initialState, action: any) => {
    switch (action.type) {
        case 'TEMPLATE_PATTERN_FIELD_MODAL_FORM':
            return produce(state, (draftState) => {
                draftState.template_pattern_field_modal_form_index =
                    Number(action?.groupIndex) || 0;
                draftState.template_pattern_field_modal_form =
                    action?.status || false;
            });

        case 'BUILDER_REQUEST_DATA':
            return produce(state, (draftState) => {
                draftState.builder_information = action?.data || null;
            });

        case 'BUILDER_REQUEST_STATUS':
            return produce(state, (draftState) => {
                draftState.builder_request_status = action?.status || false;
            });

        case 'SEARCH_FLATS_DATA':
            return produce(state, (draftState) => {
                draftState.flats_data_search = action?.data || [];
            });

        case 'REQUEST_STATUS_SEARCH_FLATS':
            return produce(state, (draftState) => {
                draftState.request_status_flats_data_search =
                    action?.status || false;
            });

        case 'APARTMENT_SHEETS_FORM_DATA':
            const items = [
                {
                    key: 'apartment_keys',
                    label: action.data?.keys?.keys?.message,
                    children: (
                        <div>
                            <Tag color="gold">
                                Кликните по ключу, чтобы скопировать код и
                                добавьте его в текст, где нужно отобразить
                                информацию
                            </Tag>
                            <div
                                style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    marginTop: 10,
                                }}
                            >
                                {action.data?.keys?.keys?.keys.map((item) => (
                                    <div
                                        key={item?.key}
                                        style={{ marginBottom: 5 }}
                                    >
                                        <CopyToClipboard
                                            onCopy={() =>
                                                message.success(
                                                    'Код скопирован'
                                                )
                                            }
                                            text={`<%${
                                                item?.key ===
                                                    'realestate_block' ||
                                                item?.key ===
                                                    'floor_apartment' ||
                                                item?.key === 'apartment_layout'
                                                    ? '-'
                                                    : '='
                                            } ${item?.key} %>`}
                                            options={{ format: 'text/plain' }}
                                        >
                                            <div
                                                style={{
                                                    padding: '5px 10px',
                                                    border: '1px solid rgba(0,0,0,0.14)',
                                                    borderRadius: 7,
                                                    margin: '0 5px 5px 0',
                                                }}
                                            >
                                                <p style={{ fontSize: 9 }}>
                                                    {item?.description ||
                                                        'Описания нет'}
                                                </p>
                                                <Button>{item?.key}</Button>
                                            </div>
                                        </CopyToClipboard>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ),
                },
                {
                    key: 'icons',
                    label: action.data?.keys?.icons?.message,
                    children: (
                        <div>
                            <Tag color="gold">
                                Кликните по ключу, чтобы скопировать код и
                                добавьте его в текст, где нужно отобразить
                                информацию
                            </Tag>
                            <div
                                style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    marginTop: 10,
                                }}
                            >
                                {action.data?.keys?.icons?.keys.map((item) => (
                                    <div
                                        key={item?.key}
                                        style={{ marginBottom: 5 }}
                                    >
                                        <CopyToClipboard
                                            onCopy={() =>
                                                message.success(
                                                    'Иконка скопирована'
                                                )
                                            }
                                            text={item?.key}
                                            options={{ format: 'text/plain' }}
                                        >
                                            <div
                                                style={{
                                                    padding: '5px 10px',
                                                    border: '1px solid rgba(0,0,0,0.14)',
                                                    borderRadius: 7,
                                                    margin: '0 5px 5px 0',
                                                }}
                                            >
                                                <p style={{ fontSize: 9 }}>
                                                    {item?.description ||
                                                        'Описания нет'}
                                                </p>
                                                <Button>{item?.key}</Button>
                                            </div>
                                        </CopyToClipboard>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ),
                },
            ];

            return produce(state, (draftState) => {
                draftState.apartment_sheets_form_data = action?.data || null;
                draftState.apartment_sheets_form_data_keys = items;
            });

        case 'APARTMENT_SHEETS_FORM_REQUEST':
            return produce(state, (draftState) => {
                draftState.apartment_sheets_form_request =
                    action?.status || false;
            });

        case 'META_APARTMENT_TEMPLATE_FORM':
            return produce(state, (draftState) => {
                draftState.form_meta_template_data = action?.data || null;
            });

        case 'REQ_APARTMENT_TEMPLATE_FORM':
            return produce(state, (draftState) => {
                draftState.form_template_request = action?.status || false;
            });

        case 'REALESTATE_DELETE_ITEM_TEMPLATE':
            const filterRealestateTemplates =
                state?.apartment_templates &&
                state?.apartment_templates?.data.length
                    ? state?.apartment_templates?.data.filter(
                          (item) =>
                              String(item?._id) != String(action?.template_id)
                      )
                    : null;

            return produce(state, (draftState) => {
                draftState.apartment_templates = {
                    ...draftState.apartment_templates,
                    data: filterRealestateTemplates,
                    count: draftState?.apartment_templates?.count - 1,
                };
            });

        case 'REQ_REMOVE_APARTMENT_TEMPLATE':
            return produce(state, (draftState) => {
                draftState.req_delete_apartment_template =
                    action?.status || false;
            });

        case 'DATA_REALESTATE_TEMPLATES':
            return produce(state, (draftState) => {
                draftState.apartment_templates = action?.data || null;
            });

        case 'REQUEST_REALESTATE_TEMPLATES':
            return produce(state, (draftState) => {
                draftState.req_apartment_templates = action?.status || false;
            });

        case 'FORM_REALESTATE_REQUEST':
            return produce(state, (draftState) => {
                draftState.form_realestate_request = action?.status || false;
            });

        case 'FORM_META_INFORATION_DATA':
            return produce(state, (draftState) => {
                draftState.form_meta_information_data = action?.data || null;
            });

        case 'UPDATE_REALESTATE_ID':
            return produce(state, (draftState) => {
                draftState.realestate_id = action?.id || '';
            });

        case 'VISIBLE_NOTIFICATIONS':
            return produce(state, (draftState) => {
                draftState.visible_notification = action?.status || false;
            });

        case 'REALESTATE_INFORMATION':
            return produce(state, (draftState) => {
                draftState.notifications =
                    action?.data?.getNotificationsOfRealEstate || [];
                draftState.set_data_chess = action?.set_data_chess || null;
            });

        default:
            return state;
    }
};

export default realestate;
