import React, { useEffect, useState } from 'react';
import { api } from '@/Services/';

import styles from './styles/index.module.scss';

import { useDispatch, useSelector } from 'react-redux';

import Loader from '../../Loader';
import ItemWidget from './helpers/item';
import Filter from './helpers/filter';
import { Pagination } from 'antd';

const Widgets = () => {
    const dispatch = useDispatch();
    const widgetState = useSelector((state: any) => state.widgets);

    const [type, set_type] = useState('');
    const [txt, set_txt] = useState('');
    const [page, set_page] = useState(1);
    const [limit] = useState(24);

    useEffect(() => {
        dispatch({
            type: 'UPDATE_WIDGET_FOR_EDIT',
            data: null,
        });

        dispatch(api.widgets.get(type, txt, page, limit));
    }, [page]);

    const searchEvent = () => {
        return dispatch(api.widgets.get(type, txt, page, limit));
    }

    return (
        <div className={styles.pageWrapper}>
            <Filter loading={widgetState.request_status} set_type={set_type} set_txt={set_txt} set_page={set_page} searchEvent={searchEvent}/>
            <div className={styles.listBlock}>
                {!widgetState.request_status ? (
                    widgetState?.list && widgetState?.list.data?.length ? (
                        <>
                            <div className={styles.listMainWgs}>
                                {widgetState?.list?.data.map((widget) => (
                                    <ItemWidget
                                        dispatch={dispatch}
                                        api={api}
                                        key={widget?._id}
                                        item={widget}
                                    />
                                ))}
                            </div>
                            {
                                widgetState?.list?.count > 24 && (
                                    <div className={styles.pagination}>
                                        <Pagination
                                            onChange={(val) => set_page(val)}
                                            current={page}
                                            showSizeChanger={false}
                                            pageSize={limit}
                                            defaultCurrent={1}
                                            total={widgetState?.list?.count}
                                        />
                                    </div>
                                )
                            }
                            
                        </>
                    ) : (
                        <h4>Нет доступных виджетов.</h4>
                    )
                ) : (
                    <Loader width="100%" height="100%"/>
                )}
            </div>
        </div>
    );
};

export default Widgets;
