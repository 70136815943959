import React, { useState, useEffect } from 'react';
import styles from '../../styles/custom-chess.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import ParkingForm from '../modals/parking/form/';
import { api } from '@/Services';
import { Tag, Tooltip } from 'antd';

const ParkingGrid = () => {

    const [open_form_, set_open_form] = useState(false);

    const [item, set_item] = useState(null);

    const { id } = useParams();
    const dispatch = useDispatch();
    const parking = useSelector((state: any) => state?.chess?.parking_information);
    const parkingPlans = useSelector((state: any) => state?.chess?.realestate_parking_info?.parking_plans);

    useEffect(()=>{
        dispatch(api.realestate.getParking(id));
    }, []);

    return (
        <>
            {
                parkingPlans && parkingPlans.length ? (
                    <div className={styles.wrapperParking}>

                        <ParkingForm 
                            open={open_form_}
                            close={()=>{
                                set_item(null);
                                set_open_form(false);
                            }}
                            item={item}
                            api={api}
                        />

                        <div className={styles.parking}>

                            {
                                (parking?.parking && parking?.parking.length) ? 
                                    parking?.parking.map((item)=>{
                                        const colorOverlay = 
                                        item?.status === 'BOOKING' ? 'rgb(248, 239, 41)'
                                        : item?.status === 'SOLD' ? 'rgb(221,233,255)'
                                        : item?.status === 'FREE' ? '#5BCD3E'
                                        : '';

                                        return (
                                            <div className={styles.box} style={{
                                                background: colorOverlay
                                            }} key={item?._id} onClick={()=>{
                                                dispatch({
                                                    type: 'UPDATE_FLAT',
                                                    flat: !item?.pantry ? null : item,
                                                });
                                                set_item(item);
                                                set_open_form(true);
                                            }}>
                                                {
                                                    item?.pantry ?
                                                        <Tooltip title="Кладовая">
                                                            <img src="https://files.logovo.kz/uploads/storeroom.png"/>
                                                        </Tooltip>
                                                    :
                                                        <Tooltip title="Парковочное место">
                                                            <img src="https://files.logovo.kz/uploads/car-parking.png"/>
                                                        </Tooltip>
                                                }
                                                <h4>{ item?.title }</h4>
                                            </div>
                                        )
                                    })
                                :
                                    <></>
                            }

                            <div className={styles.box} onClick={() => set_open_form(true)}>
                                <PlusOutlined className={styles.addIcon}/>
                            </div>
                        </div>
                    </div>
                ) : (
                    <Tag color='red'>Загрузите планировку паркинга в настройках</Tag>
                )
            }
        </>
    )
}

export default ParkingGrid;