import { ApolloClient, InMemoryCache, split, HttpLink } from "@apollo/client";

import { getMainDefinition } from "@apollo/client/utilities";
import config from "@/Config";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { setContext } from '@apollo/client/link/context';

const gqlURL = process.env.NODE_ENV === "development"
    ? config.graphqlURIDEV
    : config.graphqlURI;

const gqlWSURL = process.env.NODE_ENV === "development"
    ? config.graphqlWSURIDEV
    : config.graphqlWSURI;

const apolloClientSetup = () => {

    const httpLink = new HttpLink({
        uri: gqlURL,
    });

    const wsLink = new GraphQLWsLink(
        createClient({
            url: gqlWSURL
        })
    );

    const link = split(
        ({ query }) => {
            const definition = getMainDefinition(query);
            return (
                definition.kind === "OperationDefinition" &&
                definition.operation === "subscription"
            );
        },
        wsLink,
        httpLink,
    );

    const authLink = setContext((_, { headers }) => {
        return {
          headers: {
            ...headers,
            authorization: localStorage.getItem('SESSION_TOKEN') || null
          }
        }
    });

    const client = new ApolloClient({
        link: authLink.concat(link),
        cache: new InMemoryCache(),
    });

    return client;

};

export default apolloClientSetup;
