import React, { memo, useEffect } from 'react';
import { Drawer, Tabs } from 'antd';
import Blocks from './blocks';
import Apartments from './apartments';
import Floors from './floors';

const FormApartmnentAndBlocks = ({
    onClose, 
    open, 
    apartments_condition, 
    form,
    api,
    dispatch,
    realestate_id,
    realestate
}) => {

    useEffect(()=>{
        setTimeout(()=>{
            dispatch(api.payments.search(realestate_id, 'ALL', 'blocks', realestate));
        }, 250);
    }, [realestate]);

    const items = [
        {
            key: 'apartments',
            label: `Квартиры`,
            children: <Apartments 
                apartments_condition={apartments_condition} 
                form={form}
                api={api}
                dispatch={dispatch}
                realestate_id={realestate_id}
                realestate={realestate}
            />,
        },
        {
            key: 'floors',
            label: `Этажи`,
            children: <Floors 
                apartments_condition={apartments_condition} 
                form={form}
            />,
        },
        {
            key: 'blocks',
            label: `Блоки`,
            children: <Blocks 
                apartments_condition={apartments_condition} 
                form={form}
            />,
        },
    ];

    return (
        <Drawer 
            destroyOnClose={true}
            title="Дополнительные условия калькуляции" 
            placement="right" 
            onClose={()=>{
                onClose();
                dispatch({
                    type: 'DATA_SEARCH_CLEAR'
                });
            }} 
            open={open}
            width={950}
        >
            <Tabs 
                defaultActiveKey="apartments" 
                destroyInactiveTabPane={true}
                items={items}
            />
        </Drawer>
    )

}

export default memo(FormApartmnentAndBlocks);