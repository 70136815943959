import React, { useMemo, useState } from 'react';
import styles from './styles/settings.module.scss';
import moment from 'moment';
import { Tabs } from 'antd';
import ResetPassword from '../ResetPassword';
import { Button, Form, Input, message } from 'antd';
import { useMutation } from '@apollo/client';
import * as gqls from '@/Services/apollo/gqls';
import Mortgage from './helpers/mortgage';
import PaymentMethods from './helpers/paymentMethods';
import WhatsappBusiness from './helpers/whatsappBusiness';
import Notes from './helpers/notes';

const Settings = ({ data }: any) => {

    const [form_State] = useState({
        name: data?.getUser?.name || '',
        email: data?.getUser?.email || '',
        createdAt: moment(Number(data?.getUser?.createdAt)).format(
            'DD.MM.YYYY'
        ) || '',
        role: data?.getUser?.role?.type || '',
    });

    const [form] = Form.useForm();

    const [updateProfileInformation]: any = useMutation(gqls.users.updateProfileInformation, {
        onCompleted: (data) => {
            message.success('Информация успешно сохранена')
        },
        onError: (errors) => {
            return;
        },
    });

    const onFinish = (fields) => {
        updateProfileInformation({
            variables: {
                fields: {
                    name: fields?.name
                }
            },
        })
    };
    

    return useMemo(
        () => (
            <section className={styles.settingsWrapper}>
                <h2 className={styles.settingsTitle}>Настройки</h2>

                <Tabs defaultActiveKey="account">
                    <Tabs.TabPane tab="Профиль" key="account">
                        <div className={styles.blockSections}>
                            <Form
                                form={form}
                                layout="vertical"
                                onFinish={onFinish}
                                autoComplete="off"
                                initialValues={form_State}
                                className={styles.form}
                            >
                                <Form.Item
                                    name="name"
                                    label="ФИО"
                                    rules={[{ required: true }, { type: 'string', warningOnly: true }]}
                                    className={styles.input}
                                >
                                    <Input size="large" placeholder="Введите ФИО" />
                                </Form.Item>
                                <Form.Item
                                    name="role"
                                    label="Тип аккаунта"
                                    className={styles.input}
                                >
                                    <Input size="large" disabled placeholder="Тип аккаунта" />
                                </Form.Item>
                                <Form.Item
                                    name="email"
                                    label="Email"
                                    className={styles.input}
                                >
                                    <Input size="large" disabled placeholder="Email" />
                                </Form.Item>
                                <Form.Item
                                    name="createdAt"
                                    label="Дата регистрации"
                                    className={styles.input}
                                >
                                    <Input size="large" disabled placeholder="Дата регистрации"/>
                                </Form.Item>
                                <Form.Item className={styles.input} style={{
                                    marginTop: 5
                                }}>
                                    <Button type="primary" htmlType="submit">
                                        Сохранить
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Заметки" key="notes">
                        <Notes />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Ипотека" key="mortgage">
                        <Mortgage />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Способы оплаты" key="payment-methods">
                        <PaymentMethods />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Смена пароля" key="changePassword">
                        <ResetPassword />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="whatsapp business" key="whatsappBusiness">
                        <WhatsappBusiness/>
                    </Tabs.TabPane>
                </Tabs>
            </section>
        ),
        []
    );
};

export default Settings;
