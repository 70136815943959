import React from 'react';
import { Form, Select, Typography, DatePicker } from 'antd';
import styles from '../../../../../styles/home.module.scss';
import { useSelector } from 'react-redux';

const { Text } = Typography;

const FormItemStatus = () => {

    const {
        form_meta_information_data
    } = useSelector((state: any) => state?.realestate);

    return (
        <div className={styles.sectionForm}>

            <Text type="secondary">Срок сдачи, класс жилья, этап строительства</Text>

            <Form.Item
                name="delivery_time_month"
                label={`Срок сдачи дома`}
                rules={[
                    {
                        required: true,
                        message: 'Поле обязательно для заполнения',
                    },
                ]}
            >
                <DatePicker
                    placeholder='Срок сдачи дома'
                    picker="quarter"
                />
            </Form.Item>

            <Form.Item
                name="housing_class"
                label="Класс жилья"
                rules={[
                    {
                        required: true,
                        message: 'Поле обязательно для заполнения',
                    },
                ]}
            >
                <Select
                    placeholder="Класс жилья"
                    options={form_meta_information_data?.housing_classes || []}
                />
            </Form.Item>

            <Form.Item
                name="construction_phase"
                label="Этап строительства"
                rules={[
                    {
                        required: true,
                        message: 'Поле обязательно для заполнения',
                    },
                ]}
            >
                <Select
                    placeholder="Этап строительства"
                    options={form_meta_information_data?.stages_construction || []}
                />
            </Form.Item>

        </div>
    )
}

export default FormItemStatus;