import React from 'react';
import { Form } from 'antd';
import FormItemTypeRealestate from './typeRealestate';
import FormItemAddress from './address';
import FormItemStatus from './status';
import FormItemMoreInformation from './moreInformation';
import FormItemDocuments from './documents';
import FormItemImageBlocks from './imageBlocks';
import FormItemImages from './images';
import styles from '../../../../styles/home.module.scss';

const FormItems = () => {

    const form = Form.useFormInstance();
    const type_realestate = Form.useWatch('type_realestate', form);

    return (
        <>
            <FormItemTypeRealestate/>
            {
                !!(type_realestate) && (
                    <>
                        <FormItemAddress/>
                        <FormItemStatus/>
                        <FormItemMoreInformation/>
                        <div className={styles.fileWrapper}>
                            <FormItemImageBlocks/>
                            <FormItemImages/>
                            <FormItemDocuments/>
                        </div>
                    </>
                )
            }
        </>
    )
}

export default FormItems;