import { produce } from 'immer';

const initialState = {
    data: null,

    request_status: false,
    first_request_status: true,

    selected_boxes: 0,
};

const chessData = (state = initialState, action) => {
    switch (action.type) {

        case 'CHESS_FLAT_UPDATE_PLAN_POINTS': {

            return produce(state, draftState => {

                let data = draftState?.data || [];

                const blockIndex = data?.blocks.findIndex(
                    (block) => block?._id === action?.fields?.block_id?._id
                );

                let chess_grid = [...data?.blocks[blockIndex]?.chess_grid] || []
    
                const updateFlats = chess_grid.map(
                    (flat) => {
                        if (flat?.flat?._id === action?.fields?.flat_id) {
                            flat.flat.area_points_for_css =
                                action?.fields?.area_points_for_css || '';
                            flat.flat.area_points =
                                action?.fields?.area_points || [];
                        }
                        return flat;
                    }
                );

                draftState.data.blocks[blockIndex].chess_grid = updateFlats;
            });
        }

        case 'CHESS_FLAT_DELETE': {

            return produce(state, draftState => {

                let data = draftState?.data || [];

                const blockIndex = data?.blocks.findIndex(
                    (block) => block?._id === action?.fields?.block_id?._id
                );
    
                let chess_grid = [...data?.blocks[blockIndex]?.chess_grid] || []
    
                const updateFlats = chess_grid.map(
                    (flat) => {
                        if (flat?.flat?._id === action?.fields?.flat_id) {
                            flat.flat = null;
                        }
                        return flat;
                    }
                );

                draftState.data.blocks[blockIndex].chess_grid = updateFlats;
            });

        }

        case 'REQUEST_CUSTOM_CHESS_DATA':
            return produce(state, draftState => {
                draftState.request_status = action?.status;
                draftState.first_request_status = false;
            });

        case 'SELECT_BOXES_BLOCK': {

            let getBlockGrid = action.data || [];

            getBlockGrid.map((item, index) => {
                if (index === action.boxIndex && !item?.flat) {
                    item.selected = !item?.selected;
                }
                return item;
            });

            return produce(state, draftState => {
                draftState.data.blocks[action?.blockIndex].chess_grid = getBlockGrid;
            });

        }

        case 'UPDATE_CUSTOM_CHESS_DATA':
            return produce(state, draftState => {
                draftState.data = {
                    blocks: action?.data?.blocks || [],
                    title: action?.data?.a_complex || action?.data?.title || '',
                    _id: action?.data?._id || '',
                    chessboard: action?.data?.chessboard || '',
                    image_complex_with_all_blocks: action?.data?.image_complex_with_all_blocks || null,
                } || null;
            });

        default:
            return state;
    }
};

export default chessData;
