import config from '@/Config';

export default {
  	get: () => {
  		const token = localStorage.getItem(config.TOKEN);
  		return token
  	},
  	set: (token:any) => localStorage.setItem(config.TOKEN, token),
  	remove: () => localStorage.removeItem(config.TOKEN),
}
