import React, { useState, useEffect } from 'react';
import { Button, Table, Space, Tag, Popconfirm } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '@/Services';
import PaymentMethodForm from './form';
import styles from '../../styles/settings.module.scss';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

const PaymentMethods = () => {

    const dispatch = useDispatch();
    const { payment_methods_data, request_payment_methods } = useSelector((state: any) => state?.contracts);

    const [ item_edit, set_item_edit ] = useState(null);
    const [status_form, set_status_form] = useState(false);

    useEffect(()=>{
        dispatch(api.account.getPaymentMethods());
    }, []);

    const columns = [
        {
            title: 'Наименование',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'Банк',
            dataIndex: 'type_payment_method',
            key: 'type_payment_method',
            render: (_, item)=>{
                return <Tag>{ item?.type_payment_method === 'KASPI' ? 'Каспи банк' : '-' }</Tag>
            },
        },
        {
            title: 'Страница оплаты',
            dataIndex: 'integration_information',
            key: 'integration_information',
            render: (_, item)=>{
                return <Tag color='gold'>{ item?.type_payment_method === 'KASPI' ? item?.integration_information?.page_payment_url : 'Нет информации' }</Tag>
            },
        },
        {
            title: 'Управление',
            key: 'action',
            render: (_, item) => (
                <Space size="small">
                    <Button size="small" type="dashed" onClick={()=>{
                        set_item_edit(item);
                        set_status_form(true);
                    }}><EditOutlined /></Button>
                    <Popconfirm
                        title="Запись будет удалена"
                        onConfirm={()=>deletePaymentMethod(item?._id)}
                        okText="удалить"
                        cancelText="Нет"
                    >
                        <Button size="small" danger type="dashed"><DeleteOutlined /></Button>
                    </Popconfirm>
                    
                </Space>
            ),
            width: 200
        },
    ];

    const deletePaymentMethod = (id: String) => {
        dispatch(api.account.deletePaymentMethod(id));
    }

    return (

        <div>

            <PaymentMethodForm paymentMethod={item_edit} status={status_form} close={()=>{
                set_status_form(false);
                set_item_edit(null);
            }} api={api} />

            <div className={styles.listMortgage}>

                <div className={styles.listMortgageHeader}>
                    <Button type="primary" key="console" onClick={()=>set_status_form(true)}>Добавить способ оплаты</Button>
                </div>

                <Table 
                    pagination={false} 
                    loading={request_payment_methods} 
                    columns={columns} 
                    dataSource={payment_methods_data} 
                />
            </div>

        </div>
    )
}

export default PaymentMethods;