import { Button, Form, Input, Modal } from 'antd';

const ModalGroupForm = ({ open = false, close, updatefields }) => {
    const addGroup = (values) => {
        updatefields((data) => {
            return [
                ...data,
                {
                    groupName: values.groupName,
                    id: `${Date.now()}_${Math.random()}`,
                    fields: [],
                },
            ];
        });
        close();
    };

    return (
        <Modal
            title="Добавить новую группу"
            open={open}
            footer={null}
            onCancel={close}
            centered
            destroyOnClose
        >
            <Form
                name="basic"
                onFinish={addGroup}
                autoComplete="off"
                layout="vertical"
            >
                <Form.Item
                    label="Наименование группы полей"
                    name="groupName"
                    rules={[
                        {
                            required: true,
                            message: 'Поле обязательно',
                        },
                    ]}
                >
                    <Input placeholder="Наименование группы полей" />
                </Form.Item>
                <Button type="primary" htmlType="submit">
                    добавить
                </Button>
            </Form>
        </Modal>
    );
};

export default ModalGroupForm;
