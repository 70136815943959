import React, { memo, useMemo } from 'react';
import styles from '../../styles/custom-chess.module.scss';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import FlatItem from './flat';
import RenderFloor from './floor';

const BlockGrid = ({ item, blockIndex, api }) => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const selectBoxes = (floor, box, boxIndex) => {
        dispatch({
            type: 'SELECT_BOXES_BLOCK',
            floor,
            box,
            boxIndex,
            blockIndex,
            data: item?.chess_grid,
        });
    };

    const selectBox = (flat) => {
        dispatch({
            type: 'SELECT_BOX_BLOCK',
            flat,
        });
    };

    const addPenthouse = () => {
        dispatch({
            type: 'ADD_PENTHOUSE_FORM_MODAL',
            realestate_id: id,
            block_id: item?._id,
            data: item?.chess_grid,
        });
    };

    const addFlats = () => {
        dispatch({
            type: 'ADD_FLATS_FORM_MODAL',
            realestate_id: id,
            block_id: item?._id,
            data: item?.chess_grid,
        });
    };

    const updateFlatPlanModal = (fields) => {
        dispatch({
            type: 'UPDATE_FLAT_TEMPLATE_PLAN_FORM_MODAL',
            ...fields,
        });
    };

    const addPantry = () => {
        dispatch({
            type: 'ADD_PANTRY_FORM_MODAL',
            realestate_id: id,
            block_id: item?._id,
            data: item?.chess_grid,
        });
    }

    const renderMemoList = useMemo(() =>
        item?.chess_grid.map((item, index) => (
            <FlatItem
                key={`${item?.flat?._id}_${Math.random()}_${index}`}
                item={item}
                addFlats={addFlats}
                addPenthouse={addPenthouse}
                addPantry={addPantry}
                selectBoxes={selectBoxes}
                selectBox={selectBox}
                index={index}
                api={api}
                updateFlatPlanModal={(flat_id) => updateFlatPlanModal(flat_id)}
            />
        ))
    );

    const renderFloors = () => {
        let compFloors = [];

        for (let i = Number(item?.floors_count); i >= 1; i--) {
            compFloors.push(
                <RenderFloor
                    block_id={item?._id}
                    styles={styles}
                    key={i}
                    floor={i}
                    api={api}
                    dispatch={dispatch}
                />
            );
        }

        return compFloors;
    };
    return (
        <div className={styles.wrapperGrid}>
            <div className={styles.wrapperFloors}>{renderFloors()}</div>
            <div
                className={styles.grid}
                style={{
                    gridTemplateColumns: `repeat(${item?.max_num_apartments_floor}, 320px)`,
                    gridTemplateRows: `repeat(${item?.floors_count}, 290px)`,
                    gridTemplateAreas: item?.chess_matrix || '',
                }}
            >
                {item?.chess_grid && item?.chess_grid.length ? (
                    <>{renderMemoList}</>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

export default memo(BlockGrid);
