import React from 'react';
import { Form, Input } from 'antd';

const FormItemTitles = () => {

    const form = Form.useFormInstance();

    return (
        <>
            <Form.Item
                name="title"
                label="Наименование квартирного листа"
                rules={[
                    {
                        required: true,
                        message: 'Поле обязательно для заполнения',
                    },
                ]}
            >
                <Input
                    placeholder="Наименование квартирного листа"
                />
            </Form.Item>
        </>
    )
}

export default FormItemTitles;