import React, { useState } from 'react';
import styles from '../../styles/contracts.module.scss';
import { Table, Drawer } from 'antd';
import { useSelector } from 'react-redux';
import { contract_columns } from '../../common/table-columns';

const TableListContracts = ({type, contractSuccessfulPayment}) => {

    const { list, request } = useSelector((state: any) => state?.contracts);
    const [pdf_contract, set_pdf_contract] = useState(null);

    return (
        <section>

            <Table 
                bordered
                loading={request} 
                columns={contract_columns(set_pdf_contract, type, contractSuccessfulPayment)} 
                dataSource={list} 
            />

            <Drawer 
                title="Договор"
                onClose={()=>set_pdf_contract(null)} 
                open={pdf_contract}
                width={`90vw`}
                destroyOnClose={true}
            >
                <iframe
                    className={styles.frameBlockPDF}
                    src={pdf_contract}
                />
            </Drawer>

        </section>
    )
}

export default TableListContracts;