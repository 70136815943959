import React from 'react';
import styles from './styles/subscription.module.scss';
import { Result, Popover } from 'antd';
import * as HeaderTypes from '@/Components/Header/interfaces';
import { moment } from '@/Services';

const Subscription = ({ account }: HeaderTypes.AccountType) => {
    const infoSub = () => {
        let text = '';

        if (account?.subscription?.rate?.title === 'Начальный') {
            text = `Ваша подписка '${account?.subscription?.rate?.title}'. Данный пакет стандартный и неограничен по времени`;
        } else {
            text = `Ваша подписка '${
                account?.subscription?.rate?.title
            }'. Дата завершения ${moment(
                Number(account?.subscription?.expiration)
            ).format('DD MMMM YYYY года')}`;
        }

        return text;
    };

    const infoItemsPlan = () => {
        return (
            <div className={styles.listFuncs}>
                {account?.subscription?.rate?.items.map((item: any) => (
                    <h5>{item?.title}</h5>
                ))}
                <h3>Вами уже использовано:</h3>
                <p>Объявления: {account?.available_ads}</p>
                {account?.subscription?.rate?.title == 'Компания' && (
                    <h5>Менеджеры: {account?.available_managers}</h5>
                )}
            </div>
        );
    };

    /*
    <Popover
                            title="Доступные функции"
                            content={infoItemsPlan}
                        >
                            <h3>Что вам доступно?</h3>
                        </Popover>
    */

    return (
        <section className={styles.wrapper}>
            <h2 className={styles.settingsTitle}>Подписка</h2>

            <div className={styles.content}>
                <Result
                    status="success"
                    title={account?.subscription?.rate?.title || 'Начальный'}
                    subTitle={infoSub()}
                    extra={null}
                />
            </div>
        </section>
    );
};

export default Subscription;
