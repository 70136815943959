import React, { FC, useEffect, useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { BookingContext } from '@/Services/context/booking';
import { useSelector } from 'react-redux';
import { Header, Sidebar } from '@/Components';
import { SidebarFlatInfo } from '@/Components/SidebarFlatInfo';
import { Layout } from 'antd';
import styles from './styles/layout.module.scss';

import { BookingInfo } from '@/Services/context/interfaces/builders';
import { bookingStatus } from '@/enums/bookingStatus';
import { role } from '@/enums/roles';

import SalesReport from './helpers/salesReport';

export const LayoutWrapper: FC<{ data: any; loading: boolean }> = ({
    data,
    loading,
}) => {
    const stateFlats = useSelector((state: any) => state.flats);

    const [booking, setBooking] = useState<BookingInfo>({
        bookingInfo: [],
        managers: [],
        flatSidebar: null,
    });

    const flat = stateFlats?.flat || null;

    const { pathname } = useLocation();

    const isBuilder = data?.getUser?.role?.type === role.builder;

    // TODO: Add notification to meta.title with interval 1250 ms
    const [filtredCountOfNotifications, setFiltredCountOfNotifications] =
        useState<any[]>(
            booking?.bookingInfo
                ? booking?.bookingInfo.filter(
                      (notification: any) =>
                          notification.booking === bookingStatus.waiting
                  )
                : []
        );

    useEffect(() => {
        isBuilder
            ? setFiltredCountOfNotifications(
                  booking?.bookingInfo
                      ? booking?.bookingInfo.filter(
                            ({ booking }: { booking: string }) =>
                                booking === bookingStatus.waiting
                        )
                      : []
              )
            : setFiltredCountOfNotifications(
                  booking?.bookingInfo
                      ? booking?.bookingInfo.filter(
                            ({
                                booking,
                                booking_notification,
                            }: {
                                booking: string;
                                booking_notification: string;
                            }) =>
                                booking !== bookingStatus.none &&
                                booking_notification !== role.builder
                        )
                      : []
              );
    }, [isBuilder, booking]);

    return (
        <BookingContext.Provider value={{ booking, setBooking }}>
            <div className={styles.layout}>
                <Layout>
                    {!loading ? (
                        <div className={styles.content}>
                            <Header account={data?.getUser} />
                            <main className={styles.sections}>
                                <div className={styles.sectionWrapperLeft}>
                                    <div className={styles.sectionLeft}>
                                        {flat && pathname.includes('real-estate') ? (
                                            <SidebarFlatInfo />
                                        ) : (
                                            <Sidebar account={data?.getUser} />
                                        )}
                                    </div>

                                    <SalesReport/>

                                </div>
                                <div className={styles.sectionRight}>
                                    <Outlet />
                                </div>
                            </main>
                        </div>
                    ) : (
                        <div className={`${styles.content} ${styles.loading}`}>
                            <h5>Загрузка</h5>
                        </div>
                    )}
                </Layout>
            </div>
        </BookingContext.Provider>
    );
};
