import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './styles/header.module.scss';
import * as HeaderTypes from './interfaces';
import Logo from '@/Assets/logo.svg';
import { Tag, Popover } from 'antd';
import { PricingPlans } from '@/Components';
import currencyFormatter from 'currency-formatter';
import Config from '@/Config'

const Header = ({ account }: HeaderTypes.AccountType) => {
    const [modal_status_plans, set_modal_status_plans] =
        useState<boolean>(false);

    const defaultTitle = () => {
        switch (account?.role?.type) {
            case 'BUILDER':
                return (
                    <p>
                        Кабинет застройщика{' '}
                        <span>“{account?.builder?.title}”</span>
                    </p>
                );
            case 'REALTOR':
                return (
                    <p>
                        Кабинет партнёра{' '}
                        <span>“{account?.settings?.company_name}”</span>
                    </p>
                );
            case 'MANAGER':
                return (
                    <p>
                        Кабинет менеджера{' '}
                        <span>
                            “
                            {account?.settings?.realtor?.settings?.company_name}
                            ”
                        </span>
                    </p>
                );
            default:
                return <p>Ограниченный доступ</p>;
        }
    };

    return (
        <div className={styles.header}>
            <PricingPlans
                close={() => set_modal_status_plans(false)}
                status={modal_status_plans}
            />

            <div className={styles.left}>
                <Link to="/">
                    <img
                        className={styles.logo}
                        src={Logo}
                        alt="логотип logovo"
                    />
                </Link>
            </div>
            <div className={styles.right}>
                <div className={styles.infoCompany}>{defaultTitle()}</div>
                <div className={styles.profileInfo}>
                    <div className={styles.profileInfoLeft}>
                        <span>{account?.builder?.title}</span>
                        <span className={styles.profileInfoLeftEmail}>{account?.email}</span>
                    </div>
                    <div className={styles.profileLogo} style={{
                        background: `url(${Config.public}${account?.builder?.logo?.url}) center / cover no-repeat`
                    }}></div>
                </div>
                {account?.role?.type != 'BUILDER' ? (
                    <div className={styles.pricePlan}>
                        {account?.subscription?.rate?.title !== 'Компания' && (
                            <span onClick={() => set_modal_status_plans(true)}>
                                повысить план
                            </span>
                        )}

                        <Popover content="Ваш баланс" title="">
                            <Tag color="orange">
                                <b>₸ </b>
                                {currencyFormatter.format(
                                    account?.balance || 0,
                                    {
                                        code: 'EUR',
                                        symbol: '',
                                    }
                                )}
                            </Tag>
                        </Popover>

                        <Tag color="green">
                            {account?.subscription?.rate?.title || 'Тестовый'}
                        </Tag>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

export default Header;
