import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { useDispatch } from 'react-redux';
import { api } from '@/Services';
import TableListContracts from './table-list';

const Contracts = () => {

    const dispatch = useDispatch();
    const [key_tab, set_key_tab] = useState('pending_payment');

    useEffect(()=>{

        if(key_tab){
            dispatch(api.contracts.get(key_tab));
        }

    }, [key_tab]);

    const contractSuccessfulPayment = (contract_id) => {
        dispatch(api.contracts.successfulPayment(contract_id, key_tab));
    }

    const items = [
        {
            key: 'pending_payment',
            label: `Ожидают оплаты`,
            children: <TableListContracts type="pending_payment" contractSuccessfulPayment={contractSuccessfulPayment}/>,
        },
        {
            key: 'paid',
            label: `Оплаченные`,
            children: <TableListContracts type="paid" contractSuccessfulPayment={contractSuccessfulPayment}/>,
        },
        {
            key: 'contract_archive',
            label: `Архив`,
            children: <TableListContracts type="contract_archive" contractSuccessfulPayment={contractSuccessfulPayment}/>,
        }
    ];

    return (
        <section>
            <Tabs 
                defaultActiveKey="list_contracts" 
                items={items} 
                onChange={(key)=>set_key_tab(key)}
                destroyInactiveTabPane={true} 
            />
        </section>
    )
}

export default Contracts;