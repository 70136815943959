import axios from '../axios';
import { message } from 'antd';

const files = {
    removeFile: (file_id) => async () => {

        try {

            await axios.delete('/v1/admin/files', {
                params: {
                    file_id
                }
            });

            return true;

        }catch(err){
            return null;
        }

    },
    uploadFileV2: (file) => async () => {

        try {

            let formData = new FormData();
            formData.append('file', file);

            let { data } = await axios({
                url: '/v2/admin/files/upload',
                method: 'post',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            return data;

        }catch(err){
            return null;
        }

    },
    uploadFile: (file, type) => async () => {

        try {

            let formData = new FormData();

            formData.append('uploadType', type);
            formData.append('file', file);

            let { data } = await axios({
                url: '/v1/admin/files',
                method: 'post',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            return data;

        }catch(err){
            return null;
        }

    },
    uploadVTImage:
    (file, type) =>
    async (dispatch) => {

        dispatch({
            type: 'REQUEST_UPLOAD_FILE',
            status: true,
        });

        let formData = new FormData();
        formData.append('uploadType', type || '360');
        formData.append('file', file);

        try {
            let { data } = await axios({
                url: '/v1/admin/files',
                method: 'post',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            dispatch({
                type: 'REQUEST_UPLOAD_FILE',
                status: false,
            });

            dispatch({
                type: 'PHOTO_APARTMENT_360',
                photo: data,
            });
            return true;

        } catch (e) {
            dispatch({
                type: 'REQUEST_UPLOAD_FILE',
                status: false,
            });

            if (e.response.status == 400) {
                message.warning(e.response.data);
            } else {
                message.warning(
                    'Ошибка при загрузке файла. Попробуйте позже'
                );
            }
        }
    },
};

export default files;
