import React, { useRef, useEffect, useState } from 'react';
import useDoubleClick from 'use-double-click';
import styles from '../../../../../styles/custom-chess.module.scss';

const PointItem = ({
    point, 
    index,
    set_area_points,
    area_points,
    set_area_points_for_css
}) => {

    const pointRef = useRef();
    const [width, set_width] = useState(0);
    const [height, set_height] = useState(0);
    

    useEffect(()=>{
        const getWrapperParkingelement = document.getElementById('wrapper-area-parking');
        set_width(getWrapperParkingelement.clientWidth);
        set_height(getWrapperParkingelement.clientHeight);
    }, [])
  
    useDoubleClick({
        onDoubleClick: e => {

            let points = [...area_points];
            points.splice(index, 1);

            let updatePointCss = updatePercentPoints(points);

            set_area_points(prevPoints => [...points]);
            set_area_points_for_css(updatePointCss);

        }, 
        ref: pointRef,
        latency: 250
    });

    const updatePercentPoints = (points) => {

        let result = '';
        
        points.map((point)=>{
            result += `${point[0]}% ${point[1]}%, `;
        });

        return result.slice(0, -2);

    }

    return (
        <div ref={pointRef} className={styles.point} id="lg-item-point" style={{
            left: point[0] + '%',
            top: point[1] + '%',
        }}></div>
    )
}

export default PointItem;