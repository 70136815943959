import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { Result } from 'antd';
import styles from '../../styles/catalog.module.scss';
import { Image } from '@/Components';

const ContentFlats = () => {

    const dispatch = useDispatch();
    const {request_status_flats_data_search, flats_data_search} = useSelector((state: any) => state?.realestate);

    if(request_status_flats_data_search){
        return (
            <div className={styles.loading}>
                <LoadingOutlined />
            </div>
        )
    }

    if(flats_data_search && flats_data_search.length){
        return (
            <div className={styles.list}>
                {
                    flats_data_search.map((item)=>(
                        <div 
                            className={styles.item} 
                            key={item?._id}
                            onClick={()=>{
                                dispatch({
                                    type: 'UPDATE_FLAT',
                                    flat: !item?.template?._id ? null : item,
                                });
                            }}
                        >
                            <div>
                                <Image 
                                    src={`https://files.logovo.kz/${item?.template?.preview_photo?.url}`}
                                    height="150px"
                                />
                            </div>
                            <div className={styles.description} >
                                <p>Помещение №{item?.title}</p>
                                <p>Тип: {(item?.type_apartment === 'PARKING' && !item?.pantry) ? 'Паркинг' : item?.pantry ? 'Кладовая' : 'Квартира'}</p>
                            </div>
                        </div>
                    ))
                }
            </div>
        )
    }

    return (
        <Result
            status="404"
            title="Квартир не найдено"
            subTitle="Квартир не найдено. Попробуйте изменить параметры поиска"
        />
    )
}

export default ContentFlats;