import React, { useState, useEffect } from 'react';
import { Modal, Tabs } from 'antd';
import BlockForm from './block-form';
import SelectBlock from './select-block';
import BlockSyncForm from './sync';
import styles from '../../styles/custom-chess.module.scss';

const BlockModalForm = ({
    realestate_id,
    open = false,
    type = 'create',
    close,
    api,

    title,
    floors_count,
    max_num_apartments_floor,

    data_sync_entrance,
    data_sync_id,
}) => {
    const [area_points, set_area_points] = useState([]);
    const [area_points_for_css, set_area_points_for_css] = useState('');

    const [sync_entrance, set_sync_entrance] = useState(
        data_sync_entrance || ''
    );
    const [sync_id, set_sync_id] = useState(data_sync_id || '');
    const [modal_width, set_modal_width] = useState(65);

    useEffect(() => {
        set_sync_entrance(data_sync_entrance || '');
        set_sync_id(data_sync_id || '');
    }, [data_sync_entrance, data_sync_id]);

    const items = [
        {
            key: 'form',
            label: `Данные о блоке`,
            children: (
                <BlockForm
                    type={type}
                    realestate_id={realestate_id}
                    api={api}
                    title={title}
                    floors_count={floors_count}
                    max_num_apartments_floor={max_num_apartments_floor}
                    area_points={area_points}
                    area_points_for_css={area_points_for_css}
                    sync_id={sync_id}
                    sync_entrance={sync_entrance}
                />
            ),
        },
        {
            key: 'select_block',
            label: `Выделить блок`,
            children: (
                <SelectBlock
                    saveCoords={(area_points, area_points_for_css) => {
                        set_area_points(area_points);
                        set_area_points_for_css(area_points_for_css);
                    }}
                />
            ),
        },
        {
            key: 'sync_block',
            label: `Синхронизация`,
            children: (
                <BlockSyncForm
                    data_sync_entrance={sync_entrance}
                    data_sync_id={sync_id}
                    saveSync={(sync_entrance, sync_id) => {
                        set_sync_entrance(sync_entrance);
                        set_sync_id(sync_id);
                    }}
                />
            ),
        },
    ];

    return (
        <Modal
            title={
                type === 'create'
                    ? 'Форма добавления блока'
                    : 'Форма редактирования блока'
            }
            open={open}
            onCancel={() => {
                close();
                set_modal_width(55);
            }}
            footer={null}
            width={`${modal_width}%`}
            destroyOnClose={true}
            centered={true}
            wrapClassName={styles.wrapperModal}
        >
            <Tabs
                onChange={(key) => {
                    if (key === 'select_block') {
                        set_modal_width(95);
                    } else {
                        set_modal_width(65);
                    }
                }}
                defaultActiveKey="form"
                items={items}
            />
        </Modal>
    );
};

export default BlockModalForm;
