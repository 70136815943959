import React from 'react';
import { Form, Typography, Input, Checkbox } from 'antd';
import styles from '../../../../../../styles/home.module.scss';

const { Text } = Typography;

const FormItemInformation = () => {

    return (
        <div className={styles.sectionForm}>

            <Text type="secondary">Основная информация</Text>

            <Form.Item
                name="commercial"
                label=""
                valuePropName="checked"
            >
                <Checkbox>Коммерческая недвижимость</Checkbox>
            </Form.Item>

            <Form.Item
                name="pantry"
                label=""
                valuePropName="checked"
            >
                <Checkbox>Кладовка</Checkbox>
            </Form.Item>

            <Form.Item
                name="title"
                label="Наименование шаблона"
                rules={[
                    {
                        required: true,
                        message: 'Поле обязательно для заполнения',
                    },
                ]}
            >
                <Input
                    placeholder="Наименование шаблона"
                />
            </Form.Item>

            <Form.Item
                name="square_metres"
                label="Площадь квартиры (м²)"
                rules={[
                    {
                        required: true,
                        message: 'Поле обязательно для заполнения',
                    },
                ]}
            >
                <Input
                    placeholder="Площадь квартиры (м²)"
                />
            </Form.Item>

            <Form.Item
                name="count_rooms"
                label="Количество комнат"
                rules={[
                    {
                        required: true,
                        message: 'Поле обязательно для заполнения',
                    },
                ]}
            >
                <Input
                    placeholder="Количество комнат"
                />
            </Form.Item>

            <Form.Item
                name="ceiling_height"
                label="Укажите высоту потолка"
                rules={[
                    {
                        required: true,
                        message: 'Поле обязательно для заполнения',
                    },
                ]}
            >
                <Input
                    placeholder="Укажите высоту потолка"
                />
            </Form.Item>

            

        </div>
    )
}

export default FormItemInformation;