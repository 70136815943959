import React, { useState, useEffect } from 'react';
import { Select, DatePicker } from 'antd';
import styles from '../styles/leads.module.scss';

const { Option } = Select;
const { RangePicker } = DatePicker;

const FilterLeads = (props: any) => {
    const { statuses, company_id, loadLeads } = props;

    const [select_status, set_select_status] = useState([]);
    const [date_filter, set_date_filter] = useState<any>(null);

    useEffect(() => {
        loadLeads({
            variables: {
                fields: {
                    company_id,
                    status: select_status,
                    date: date_filter,
                },
            },
        });
    }, [select_status, date_filter]);

    return (
        <div className={styles.filter}>
            <div>
                <RangePicker
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(date: any) => {
                        if (date && date.length) {
                            set_date_filter({
                                start: date[0],
                                end: date[1],
                            });
                        } else {
                            set_date_filter(null);
                        }
                    }}
                />
            </div>
            <div>
                <Select
                    size="large"
                    mode="multiple"
                    allowClear
                    showSearch
                    style={{
                        width: '100%',
                    }}
                    value={select_status}
                    placeholder="Выберите статус лидов"
                    defaultValue={select_status}
                    onChange={(value: any) => set_select_status(value)}
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                        option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA: any, optionB: any) =>
                        optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                    }
                >
                    <Option value="Новый">Новый</Option>
                    {statuses &&
                        statuses.length &&
                        statuses.map((item: any) => {
                            return (
                                <Option key={item?._id} value={item?._id}>
                                    {item?.title}
                                </Option>
                            );
                        })}
                </Select>
            </div>
        </div>
    );
};

export default FilterLeads;
