import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { api } from '@/Services';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Result, Typography, Table } from 'antd';
import { Loader } from '@/Components';
import templateColumns from './helpers/funcs/tableTemplateCollums';
import { VList } from 'virtuallist-antd';
import { useNavigate } from 'react-router-dom';
import styles from '../../../styles/home.module.scss';

const { Title } = Typography;

const TemplateList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { realestate_id } = useParams();

    const {
        req_apartment_templates,
        apartment_templates,
        req_delete_apartment_template,
    } = useSelector((state: any) => state?.realestate);

    useEffect(() => {
        dispatch(api.realestate.realestateTemplates(realestate_id));
    }, []);

    const vComponents = useMemo(() => {
        return VList({
            height: 'calc(100vh - 300px)',
        });
    }, []);

    const removeTemplate = (template_id) => {
        return dispatch(
            api.realestate.deleteTemplate(template_id, realestate_id)
        );
    };

    const openTemplateForm = () => {
        return navigate(
            `/realestate/templates/form?realestate_id=${realestate_id}`
        );
    };

    const openTemplateSettings = () => {
        return navigate(`/realestate/templates/${realestate_id}/settings`);
    };

    return (
        <>
            {req_apartment_templates ? (
                <Loader />
            ) : apartment_templates?.data &&
              apartment_templates?.data.length ? (
                <>
                    <div className={styles.header}>
                        <Title level={4}>
                            {apartment_templates?.meta?.realestate?.title} (
                            {apartment_templates?.count} шаблонов)
                        </Title>
                        <div className={styles.right}>
                            <Button
                                type="primary"
                                onClick={openTemplateSettings}
                            >
                                Настройка шаблона
                            </Button>
                            <Button type="primary" onClick={openTemplateForm}>
                                Добавить шаблон
                            </Button>
                        </div>
                    </div>

                    <div>
                        <Table
                            dataSource={apartment_templates?.data || []}
                            columns={templateColumns(
                                removeTemplate,
                                realestate_id,
                                navigate,
                                req_delete_apartment_template
                            )}
                            scroll={{ y: 'calc(100vh - 300px)' }}
                            rowKey={(item) => item?._id}
                            components={vComponents}
                            pagination={false}
                            loading={req_apartment_templates}
                        />
                    </div>
                </>
            ) : (
                <Result
                    status="404"
                    title="Шаблонов не найдено"
                    subTitle={`У жк ${apartment_templates?.meta?.realestate?.title} нет шаблонов квартир.`}
                    extra={
                        <Button onClick={openTemplateForm} type="primary">
                            Добавить шаблон квартиры
                        </Button>
                    }
                />
            )}
        </>
    );
};

export default TemplateList;
