import React, { FC, useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { api } from '@/Services';
import Loader from '../Loader';
import { ChessGrid } from '@/Components';

import { useDispatch, useSelector } from 'react-redux';
import styles from './styles/chess.module.scss';
import { Drawer, Badge, Popover, Result } from 'antd';
import { UnorderedListOutlined } from '@ant-design/icons';
import Notifications from './helpers/notifications';
import { Space } from 'react-zoomable-ui';
import ChessCustom from './custom';

export const Chess: FC<{ account: any }> = ({ account }) => {
    const dispatch = useDispatch();
    const stateRalestate = useSelector((state: any) => state.realestate);
    const chessDataStore = useSelector(
        (state: any) => state.chessData
    );

    const { request_status } = chessDataStore;

    const [data, set_data] = useState(null);

    let paramsRoute = useParams();

    const memoizedList = useMemo(() => chessDataStore?.data || [], [chessDataStore?.data]);

    useEffect(() => {
        set_data(memoizedList);
    }, [memoizedList]);

    useEffect(() => {
        loadChess();
    }, [paramsRoute?.id]);

    const loadChess = async () => {
        const chessData = await dispatch(api.chess.getChess(paramsRoute?.id, null));
        set_data(chessData);
    }

    useEffect(() => {
        if (!request_status && data) {
            dispatch({
                type: 'REALESTATE_INFORMATION',
                data,
                set_data_chess: set_data
            });
        }
    }, [data, request_status]);

    const visibleNotifications = (status: Boolean) => {
        return dispatch({
            type: 'VISIBLE_NOTIFICATIONS',
            status,
        });
    };

    return (
        <div className={styles.chessWrapper}>
            <Drawer
                title="Уведомления"
                placement="right"
                onClose={() => visibleNotifications(false)}
                open={stateRalestate?.visible_notification || false}
            >
                <Notifications items={stateRalestate?.notifications || []} />
            </Drawer>

            {request_status ? (
                <Loader width="100%" height="100%" background="transparent" />
            ) : data?.chessboard === 'standart' &&
            data?.blocks &&
            data?.blocks.length ? (
                <>
                    <div className={styles.chessHeader}>
                        <div className={styles.chessHeaderLeft}>
                            <h2 className={styles.complexTitle}>
                                ЖК {data?.a_complex || data?.title}
                            </h2>
                            <div className={styles.chessHeaderLeftNotification}>
                                <Popover
                                    placement="bottom"
                                    content="Открыть уведомления"
                                    title=""
                                >
                                    <Badge
                                        count={
                                            stateRalestate?.notifications
                                                .length || 0
                                        }
                                        showZero
                                    >
                                        <UnorderedListOutlined
                                            onClick={() =>
                                                visibleNotifications(true)
                                            }
                                        />
                                    </Badge>
                                </Popover>
                            </div>
                        </div>
                        <div className={styles.chessDescribe}>
                            <span>забронировано</span>
                            <span>продано</span>
                            <span>свободно</span>
                            {account?.role?.type != 'BUILDER' ? (
                                <span>Вторичный рынок</span>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>

                    <div className={styles.chessContent}>
                        <Space>
                            <div
                                className={styles.chessContentHorizontalScroll}
                            >
                                {data?.blocks.map((block: any) => (
                                    <ChessGrid
                                        account={account}
                                        realEstateId={paramsRoute.id!}
                                        block={block}
                                        key={block._id}
                                    />
                                ))}
                            </div>
                        </Space>
                    </div>
                </>
            ) : data?.chessboard === 'custom' ? (
                <ChessCustom chessData={data} />
            ) : (
                <Result
                    status="404"
                    title="Жк не может быть сформирован"
                    subTitle="Побробуйте позже или обратитесь к администратору."
                    extra={null}
                />
            )}
        </div>
    );
};
