import React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import * as gqls from '@/Services/apollo/gqls';
import Loader from '../../Loader';
import styles from './styles/managers.module.scss';
import ItemManager from './helpers/item-manager';
import { useNavigate } from 'react-router-dom';
import { Result, Button } from 'antd';

const Managers = () => {
    let navigate = useNavigate();

    const { data, loading, error } = useQuery(
        gqls.builder.getManagersOfRealtor
    );
    const [deleteManager] = useMutation(gqls.builder.deleteManager, {
        update: (cache, { data }) => {
            let { getManagersOfRealtor } = cache.readQuery<any>({
                query: gqls.builder.getManagersOfRealtor,
            });

            if (getManagersOfRealtor && getManagersOfRealtor.length) {
                let updateCacheData = getManagersOfRealtor.filter(
                    (item: any) => item._id !== data.deleteManager
                );

                cache.writeQuery<any>({
                    query: gqls.builder.getManagersOfRealtor,
                    data: {
                        getManagersOfRealtor: updateCacheData,
                    },
                });
            }

            return;
        },
        onError: (err) => {
            console.error(err);
        },
    });

    const eventDeleteManager = (id: String) => {
        deleteManager({
            variables: {
                id,
            },
        });
    };

    return (
        <section className={styles.managerWrapper}>
            <div className={styles.headManagerPage}>
                <h1 className={styles.title}>Менеджеры</h1>
                <Button type="primary" onClick={() => navigate('create')}>
                    Добавить менеджера
                </Button>
            </div>
            {!loading ? (
                !error &&
                data.getManagersOfRealtor &&
                data.getManagersOfRealtor.length ? (
                    <div className={styles.managersGrid}>
                        {data?.getManagersOfRealtor?.map((manager: any) => (
                            <ItemManager
                                navigate={navigate}
                                deleteManager={(id: string) =>
                                    eventDeleteManager(id)
                                }
                                manager={manager}
                            />
                        ))}
                    </div>
                ) : (
                    <Result
                        status="403"
                        title="Менеджеров не найдено"
                        subTitle="Пока не добавлено ни одного менеджера"
                        extra={null}
                    />
                )
            ) : (
                <Loader width="100%" height="100%" background="transparent" />
            )}
        </section>
    );
};

export default Managers;
