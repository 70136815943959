import React from 'react';
import { Form, Select, Typography, Input } from 'antd';
import styles from '../../../../../styles/home.module.scss';
import { useSelector } from 'react-redux';

const { Text } = Typography;

const FormItemTypeRealestate = () => {

    const form = Form.useFormInstance();
    const type_realestate = Form.useWatch('type_realestate', form);

    const {
        form_meta_information_data
    } = useSelector((state: any) => state?.realestate);

    return (
        <div className={styles.sectionForm}>

            <Text type="secondary">Настройка жк и контента</Text>
        
            <Form.Item
                name="type_realestate"
                label="Выберите ЖК или очередь"
                rules={[
                    {
                        required: true,
                        message: 'Поле обязательно для заполнения',
                    },
                ]}
            >
                <Select
                    disabled
                    placeholder="Выберите ЖК или очередь"
                    options={[{
                        label: 'Жилой комплекс',
                        value: 'realestate'
                    },{
                        label: 'Очередь',
                        value: 'queue'
                    }]}
                />
            </Form.Item>

            <Form.Item
                name="title"
                label={`Наименование ${type_realestate === 'realestate' ? 'ЖК' : 'очереди'}`}
                rules={[
                    {
                        required: true,
                        message: 'Поле обязательно для заполнения',
                    },
                ]}
            >
                <Input
                    placeholder={`Наименование ${type_realestate === 'realestate' ? 'ЖК' : 'очереди'}`}
                />
            </Form.Item>

            {
                !!(type_realestate === 'queue') && (
                    <>
                        <Form.Item
                            name="object"
                            label="Выберите объект"
                            rules={[
                                {
                                    required: true,
                                    message: 'Поле обязательно для заполнения',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Выберите объект"
                                options={
                                    form_meta_information_data?.objects && form_meta_information_data?.objects.length ?
                                        form_meta_information_data?.objects.map((item)=>({
                                            value: item?._id,
                                            label: item?.title,
                                        }))
                                    : []
                                }
                            />
                        </Form.Item>
                        <Form.Item
                            name="number_queue"
                            label="Номер очереди"
                            rules={[
                                {
                                    required: true,
                                    message: 'Поле обязательно для заполнения',
                                },
                            ]}
                        >
                            <Input
                                placeholder="Номер очереди"
                            />
                        </Form.Item>
                    </>
                )
            }

        </div>
    )
}

export default FormItemTypeRealestate;