import React, { FC } from 'react';
import Icon from '@mdi/react';
import { mdiDeleteOutline, mdiAccountEditOutline } from '@mdi/js';
import styles from './styles/itemManager.module.scss';
import { Popconfirm } from 'antd';

const ItemManager: FC<{
    manager: any;
    deleteManager: (id: string) => void;
    navigate: (url: string) => void;
}> = ({ manager, deleteManager, navigate }) => (
    <div key={manager._id} className={styles.managerItem}>
        <div>
            <p>
                {manager?.firstname} {manager?.lastname}
            </p>
            <div className={styles.links}>
                <a href={`mailto:{manager?.email}`}>{manager?.email}</a>
                <a href={`tel:+7${manager?.phone}`}>+7{manager?.phone}</a>
            </div>
        </div>
        <div className={styles.buttons}>
            <button
                type="button"
                onClick={() => navigate(`/managers/edit/${manager._id}`)}
            >
                <Icon size={0.67} path={mdiAccountEditOutline} />
            </button>
            <Popconfirm
                title="Менеджер будет удален"
                onConfirm={() => deleteManager(manager._id)}
                okText="Удалить"
                cancelText="нет"
            >
                <button type="button">
                    <Icon size={0.67} path={mdiDeleteOutline} />
                </button>
            </Popconfirm>
        </div>
    </div>
);

export default ItemManager;
