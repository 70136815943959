import React, { useEffect, useState, memo } from 'react';
import { 
    Drawer, 
    Select, 
    Form, 
    Button, 
    Input, 
    Tag, 
    InputNumber, 
    Tooltip,
} from 'antd';
import styles from '../../../styles/form-payment.module.scss';
import { 
    PlusOutlined, 
    MinusCircleOutlined, 
    LoadingOutlined, 
    CalculatorOutlined, 
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import FormApartmnentAndBlocks from './drawer/formApartmnentAndBlocks';

const CreateFormPayment = ({
    modalStatus = false,
    close,
    realestate_id,
    dispatch,
    api,
    initial_values,
    request_status
}) => {
    const [form] = Form.useForm();

    const typeFormPayment = Form.useWatch('type', { form, preserve: true });
    const { mortgage, notes } = useSelector((state: any) => state?.account);
    const widget_edit = useSelector((state: any) => state?.widgets?.widget_edit);

    const [apartments_condition, set_apartments_condition] = useState(null);
    const [apartments_condition_status, set_apartments_condition_status] = useState(false);

    useEffect(()=>{

        setTimeout(()=>{
            form.setFieldsValue({
                title: initial_values?.title || '',
                type: initial_values?.type || null,
                conditions: initial_values?.conditions || [],
                realestate: initial_values?.realestate?._id || null,
            });
        }, 150)

    }, [initial_values]);

    const onFinish = (values) => {
        
        if(!request_status){

            const fields = {
                ...values,
                payment_id: initial_values?._id,
                realestate_id
            };
    
            return dispatch(api.payments.save(fields, close, form));

        }

    };

    const addItem = (conditionKey) => {

        let { conditions } = form.getFieldsValue();
        
        conditions[conditionKey].apartments_conditions = [
            ...conditions[conditionKey].apartments_conditions,
            {
                "count_rooms": 0,
                "min_square": 0,
                "max_square": 0,
                "math_symbol": "",
                "additional_number": 0,
                "type": "",
                "apartments": [],
                "blocks": [],
            }
        ]

        return form.setFieldsValue({
            conditions
        });

    }

    return (
        <>
            <Drawer 
                title={initial_values?._id ? `Редактирование формы оплаты: ${initial_values?.title}` : 'Добавление формы оплаты'}
                placement="right" 
                onClose={()=>{
                    close();
                    form.resetFields();
                }}
                open={modalStatus} 
                width="auto"
                style={{
                    minWidth: 800
                }}
            >

                <FormApartmnentAndBlocks
                    onClose={()=>{
                        set_apartments_condition_status(false);
                        set_apartments_condition(null);
                    }}
                    open={apartments_condition_status}
                    apartments_condition={apartments_condition}
                    form={form}
                    api={api}
                    dispatch={dispatch}
                    realestate_id={realestate_id}
                    realestate={initial_values?.realestate?._id}
                />

                <Form
                    form={form}
                    name="basic"
                    onFinish={onFinish}
                    autoComplete="off"
                    className={styles.formPayment}
                    size="large"
                >

                    <Form.Item
                        name="type"
                        rules={[
                            {
                            required: true,
                            message: 'Пожалуйста, заполните поле',
                            },
                        ]}
                        className={styles.item}
                    >
                        <Select
                            className={styles.selectTypeInput}
                            placeholder="Выберите тип оплаты"
                            size="large"
                            disabled={initial_values?._id}
                            options={[
                            {
                                value: 'purchase',
                                label: 'Покупка',
                            },
                            {
                                value: 'mortgage',
                                label: 'Ипотека',
                            },
                            {
                                value: 'installment',
                                label: 'Рассрочка',
                            },
                            ]}
                        />
                    </Form.Item>

                    <Tooltip title="Наименование">
                        <Form.Item
                            name="title"
                            rules={[
                                {
                                required: true,
                                message: 'Пожалуйста, заполните поле',
                                },
                            ]}
                            className={styles.item}
                        >
                            <Input className={styles.standartInput} placeholder="Наименование" />
                        </Form.Item>
                    </Tooltip>

                    <div className={styles.conditions}>
                        
                        <Form.List name="conditions">
                            {
                                (fields, { add, remove }) => (
                                    <>

                                        {
                                            fields.length ?
                                                <Tag style={{marginBottom: 10}} color="volcano">Цена рассчитывается согласно шахматке!</Tag>
                                            :
                                                <></>
                                        }
                                        {
                                            fields.map((conditionField, conditionFieldIndex)=>(
                                                <div key={conditionField?.key} className={styles.conditionItem}>

                                                    {
                                                        typeFormPayment === 'mortgage' ?

                                                            <div className={styles.condition} key={conditionField?.key + '_' + Math.random()}>

                                                                <Tooltip title="Выберите ипотеку">
                                                                    <Form.Item
                                                                        name={[conditionField.name, 'builder_mortgage']}
                                                                        rules={[
                                                                            {
                                                                            required: true,
                                                                            message: 'Пожалуйста, заполните поле',
                                                                            },
                                                                        ]}
                                                                        className={styles.item}
                                                                    >
                                                                        <Select
                                                                            className={styles.selectTypeInput}
                                                                            placeholder="Выберите ипотеку"
                                                                            size="large"
                                                                            options={mortgage.map((item)=>{
                                                                                return {
                                                                                    value: item?._id,
                                                                                    label: `${item?.title} - ${item?.down_payment}%`
                                                                                }
                                                                            }) || []}
                                                                        />
                                                                    </Form.Item>
                                                                </Tooltip>

                                                                <Button onClick={() => remove(conditionField.name)} type="link" danger size="small">
                                                                    <MinusCircleOutlined className={styles.deleteConditionIcon} />
                                                                </Button>

                                                            </div> 

                                                        :

                                                            <div className={styles.condition} key={conditionField?.key + '_' + Math.random()}>

                                                                <Tooltip title="Наименование">
                                                                    <Form.Item
                                                                        name={[conditionField.name, 'down_payment_title']}
                                                                        rules={[
                                                                            {
                                                                            required: true,
                                                                            message: 'Пожалуйста, заполните поле',
                                                                            },
                                                                        ]}
                                                                        className={styles.item}
                                                                    >
                                                                        <Input style={{
                                                                            width: '100%'
                                                                        }} placeholder='Наименование'/>
                                                                    </Form.Item>
                                                                </Tooltip>

                                                                <Tooltip title="Первоначальный взнос">
                                                                    <Form.Item
                                                                        name={[conditionField.name, 'down_payment']}
                                                                        rules={[
                                                                            {
                                                                            required: true,
                                                                            message: 'Пожалуйста, заполните поле',
                                                                            },
                                                                        ]}
                                                                        className={styles.item}
                                                                        style={{
                                                                            width: 350
                                                                        }}
                                                                    >
                                                                        <InputNumber style={{
                                                                            width: '100%'
                                                                        }} placeholder='Перв. взнос' addonAfter="%"/>
                                                                    </Form.Item>
                                                                </Tooltip>

                                                                <Button onClick={() => remove(conditionField.name)} type="link" danger size="small">
                                                                    <MinusCircleOutlined className={styles.deleteConditionIcon} />
                                                                </Button>

                                                            </div> 

                                                    }   

                                                    <Tooltip title="Заметка">
                                                        <Form.Item
                                                                name={[conditionField.name, 'note']}
                                                                className={styles.item}
                                                                style={{
                                                                    marginBottom: 10
                                                                }}
                                                            >
                                                                <Select
                                                                    style={{maxWidth: 880}}
                                                                    className={styles.selectTypeInput}
                                                                    placeholder="Выберите заметку"
                                                                    size="large"
                                                                    options={notes.map((item)=>{
                                                                        return {
                                                                            value: item?._id,
                                                                            label: item?.note
                                                                        }
                                                                    }) || []}
                                                                />
                                                        </Form.Item>
                                                    </Tooltip>                                  

                                                    <Form.List
                                                        name={[conditionField.name, 'apartments_conditions']}
                                                        initialValue={[]}
                                                    >
                                                        {
                                                            (fields, { add, remove, move }) => (
                                                                <div className={styles.conditionApartmentsWraper}>
                                                                    <h4>Операции расчета по количеству комнат. Всего добавлено: <Tag>{ fields.length }</Tag></h4>
                                                                    <div className={styles.conditionApartments}>
                                                                        {
                                                                            fields.map((field, fieldIndex) => (
                                                                                <div className={styles.conditionApartment} key={field?.key + '_' + Math.random()}>
                                                                                    <div>
                                                                                        <div className={styles.rooms}>
                                                                                            <div className={styles.main}>

                                                                                                <Tooltip title="Количество комнат">
                                                                                                    <Form.Item
                                                                                                        name={[field.name, 'count_rooms']}
                                                                                                        className={styles.item}
                                                                                                    >
                                                                                                        <Select
                                                                                                            optionFilterProp="children"
                                                                                                            placeholder="Количество комнат"
                                                                                                            title="Количество комнат"
                                                                                                            size="large"
                                                                                                            options={[
                                                                                                            {
                                                                                                                value: 0,
                                                                                                                label: 'Все',
                                                                                                            },
                                                                                                            {
                                                                                                                value: 1,
                                                                                                                label: '1',
                                                                                                            },
                                                                                                            {
                                                                                                                value: 2,
                                                                                                                label: '2',
                                                                                                            },
                                                                                                            {
                                                                                                                value: 3,
                                                                                                                label: '3',
                                                                                                            },
                                                                                                            {
                                                                                                                value: 4,
                                                                                                                label: '4',
                                                                                                            },
                                                                                                            {
                                                                                                                value: 5,
                                                                                                                label: '5',
                                                                                                            },
                                                                                                            {
                                                                                                                value: 6,
                                                                                                                label: '6',
                                                                                                            },
                                                                                                            {
                                                                                                                value: 7,
                                                                                                                label: '7',
                                                                                                            },
                                                                                                            ]}
                                                                                                            style={{width: 80}}
                                                                                                        />
                                                                                                    </Form.Item>
                                                                                                </Tooltip>
                                                                                                <Tooltip title="Минимальная площадь квартиры">
                                                                                                    <Form.Item
                                                                                                        name={[field.name, 'min_square']}
                                                                                                        className={styles.item}
                                                                                                    >
                                                                                                        <InputNumber style={{width: 150}} placeholder='от' addonAfter="м²"/>
                                                                                                    </Form.Item>
                                                                                                </Tooltip>
                                                                                                <Tooltip title="Максимальная площадь квартиры">
                                                                                                    <Form.Item
                                                                                                        name={[field.name, 'max_square']}
                                                                                                        className={styles.item}
                                                                                                    >
                                                                                                        <InputNumber style={{width: 150}} placeholder='до' addonAfter="м²"/>
                                                                                                    </Form.Item>
                                                                                                </Tooltip>
                                                                                                <Tooltip 
                                                                                                    title="Добавить дополнительно квартиры и блоки в условия калькуляции"
                                                                                                >
                                                                                                    <Button onClick={()=>{
                                                                                                        set_apartments_condition_status(true);
                                                                                                        set_apartments_condition({
                                                                                                            apartment_condition_key: fieldIndex, 
                                                                                                            condition_key: conditionFieldIndex, 
                                                                                                        });
                                                                                                    }}><CalculatorOutlined/></Button>
                                                                                                </Tooltip>
                                                                                            </div>
                                                                                        </div>
                                                                                        <Tooltip 
                                                                                            title="Заметка итерации"
                                                                                        >
                                                                                            <Form.Item
                                                                                                name={[field.name, 'note']}
                                                                                                className={styles.item}
                                                                                            >
                                                                                                <Input placeholder='Заметка' />
                                                                                            </Form.Item>
                                                                                        </Tooltip>
                                                                                    </div>
                        
                                                                                    <div className={styles.extra}>
                                                                                        <Tooltip title="Выберите арифметический знак">
                                                                                            <Form.Item
                                                                                                name={[field.name, 'math_symbol']}
                                                                                                className={styles.item}
                                                                                            >
                                                                                                <Select
                                                                                                    optionFilterProp="children"
                                                                                                    placeholder="Ариф. действие"
                                                                                                    title="Ариф. действие"
                                                                                                    size="large"
                                                                                                    options={[
                                                                                                    {
                                                                                                        value: 'subtraction',
                                                                                                        label: '-',
                                                                                                    },
                                                                                                    {
                                                                                                        value: 'addition',
                                                                                                        label: '+',
                                                                                                    }
                                                                                                    ]}
                                                                                                    style={{width: 80}}
                                                                                                />
                                                                                            </Form.Item>
                                                                                        </Tooltip>
                                                                                        <Tooltip title="Число">
                                                                                            <Form.Item
                                                                                                name={[field.name, 'additional_number']}
                                                                                                className={styles.item}
                                                                                            >
                                                                                                <InputNumber placeholder='Число'/>
                                                                                            </Form.Item>
                                                                                        </Tooltip>
                                                                                        <Tooltip title="Тип результата">
                                                                                            <Form.Item
                                                                                                name={[field.name, 'type']}
                                                                                                className={styles.item}
                                                                                            >
                                                                                                <Select
                                                                                                    optionFilterProp="children"
                                                                                                    placeholder="Выберите действие"
                                                                                                    title="Выберите действие"
                                                                                                    size="large"
                                                                                                    options={[
                                                                                                    {
                                                                                                        value: 'percentages',
                                                                                                        label: '%',
                                                                                                    },
                                                                                                    {
                                                                                                        value: 'cash',
                                                                                                        label: '₸',
                                                                                                    }
                                                                                                    ]}
                                                                                                    style={{width: 80}}
                                                                                                />
                                                                                            </Form.Item>
                                                                                        </Tooltip>
                                                                                    </div>
                        
                                                                                    <Button onClick={() => remove(field.name)} type="link" danger size="small">
                                                                                        <MinusCircleOutlined className={styles.deleteConditionIcon} />
                                                                                    </Button>
                                                                                </div>
                        
                                                                            ))
                                                                        }
                                                                        <Form.Item style={{margin: 0}}>
                                                                            <Button type="dashed" onClick={() => addItem(conditionField?.key)} block icon={<PlusOutlined />}>
                                                                                Добавить операцию
                                                                            </Button>
                                                                        </Form.Item>
                                                                    
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </Form.List>
                                                </div>
                                            ))
                                        }

                                        <Form.Item style={{margin: 0}}>
                                            <Button type="primary" onClick={() => add()} block icon={<PlusOutlined />}>
                                                Добавить условие
                                            </Button>
                                        </Form.Item>
                                    </>
                                )
                            }
                        </Form.List>
                    </div>

                    <Form.Item
                        className={styles.item}    
                    >
                        <Button type="primary" htmlType="submit">
                            {request_status ? <LoadingOutlined /> : initial_values?._id ? 'обновить информацию' : 'Добавить'}
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    )
}

export default memo(CreateFormPayment);