import React from 'react';
import styles from '../../../../styles/form-payment.module.scss';
import { Tag, Tooltip, Popconfirm, Form, Divider, Input, Collapse } from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import Formapartments from './forms/apartments';

const Apartments = ({
    form, 
    apartments_condition, 
    realestate_id,
    dispatch,
    api,
    realestate
}) => {

    Form.useWatch('conditions', { form, preserve: true });
    const getApartments = form.getFieldsValue() ? form.getFieldsValue()?.conditions[apartments_condition?.condition_key]?.apartments_conditions[apartments_condition?.apartment_condition_key]?.apartments : [];

    const opApartment = (itemData, type) => {

        let updateApartments = getApartments || [];

        if(type === 'delete'){
            updateApartments = getApartments.filter((item)=>String(item?._id) != String(itemData));
        }else{
            updateApartments = getApartments && getApartments.length ? [...getApartments, itemData] : [itemData];
        }

        let { conditions } = form.getFieldsValue();

        Object.assign(conditions[apartments_condition?.condition_key].apartments_conditions[apartments_condition?.apartment_condition_key], { apartments: updateApartments });

        form.setFieldsValue({
            conditions
        });

    }

    return (
        <div>
            <div>
                <h4>Добавленные квартиры</h4>
                {
                    getApartments && getApartments.length ?
                    <div className={styles.itemData}>
                        {
                            getApartments.map((item, index)=>(
                                <div key={`${item?._id}_${index}`} className={styles.item}>
                                    <Tag color="lime">Квартира №{ item?.title }, ЖК {item?.apartment_complex_id?.title || 'Не определено'}, Блок {item?.block_id?.title || 'Не определено'}</Tag>
                                    <Tooltip title="Удалить" placement='bottom'>
                                        <Popconfirm
                                            title="Квартира будет удалена из условия"
                                            onConfirm={()=>opApartment(item?._id, 'delete')}
                                            okText="Удалить"
                                            cancelText="Нет"
                                        >
                                            <CloseOutlined/>
                                        </Popconfirm>
                                    </Tooltip>
                                </div>
                            ))
                        }
                    </div>
                    :
                        <Tag color="red">Квартир не найдено</Tag>
                }
            </div>

            <Divider/>

            <Collapse
                bordered={false}
                expandIcon={({ isActive }) => <PlusOutlined rotate={isActive ? 135 : 0} />}
                items={[{
                    key: '1',
                    label: 'Добавить квартиры',
                    children: <Formapartments
                        dispatch={dispatch}
                        api={api}
                        realestate_id={realestate_id}
                        realestate={realestate}
                        opApartment={opApartment}
                        styles={styles}
                    />,
                    style: {
                        background: '#f1f1f1',
                        borderRadius: 7
                    }
                }]}
            />

        </div>
    )

}

export default Apartments;