import React, { memo } from 'react';
import styles from '../../styles/custom-chess.module.scss';
import BlockGrid from './grid';
import { SettingOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Popover } from 'antd';
import { useSelector } from 'react-redux';

const BlockItem = ({ item, index, api, dispatch }) => {
    const stateChess = useSelector((state) => state.chess);
    const { set_data_chess } = useSelector(state => state?.realestate);

    const triggerEditBlock = () => {
        dispatch({
            type: 'TRIGGER_EDIT_BLOCK_FORM',
            sync_entrance: item?.sync_entrance || '',
            sync_id: item?.sync_id || '',
            title: item?.title || '',
            floors_count: item?.floors_count || '',
            max_num_apartments_floor: item?.max_num_apartments_floor || '',
            area_points: item?.area_points || '',
            area_points_string: item?.area_points_string || '',
            edit_block_id: item?._id,
        });
    };

    const eventButton = (type) => {
        if (!stateChess?.request_status_create_block) {
            dispatch(
                api.chess.formBlock({
                    typeForm: type,
                    realestate_id: item?.complex_id,
                    block_id: item?._id,
                    set_data_chess
                })
            );
        }
    };

    return (
        <div className={styles.blockItem}>
            <div className={styles.blockHeader}>
                <div className={styles.top}>
                    <h1>Блок-{item?.title}</h1>
                    <div className={styles.icons}>
                        <Popover content={`Обновить цены блока ${item?.title}`}>
                            <div
                                className={styles.priceIcon}
                                onClick={() => {
                                    dispatch(
                                        api.chess.modalFormPrices({
                                            status: true,
                                            block_id: item?._id,
                                        })
                                    );
                                }}
                            >
                                <img src="https://api.logovo.kz/icons/tenge_symbol.svg" />
                            </div>
                        </Popover>
                        <Popover
                            content={`Дополнительные настройки блока ${item?.title}`}
                        >
                            <SettingOutlined
                                className={styles.toolIcon}
                                onClick={triggerEditBlock}
                            />
                        </Popover>
                    </div>
                </div>
                <div className={styles.bottom}>
                    <Popconfirm
                        title="Добавить дополнительный этаж для блока?"
                        onConfirm={() => eventButton('addFloor')}
                        okText="Да"
                        cancelText="Нет"
                    >
                        <Button
                            size="large"
                            type="primary"
                            className={styles.button}
                        >
                            Добавить этаж
                        </Button>
                    </Popconfirm>

                    <Popconfirm
                        title="Добавить дополнительную колонку для блока?"
                        onConfirm={() => eventButton('addColumn')}
                        okText="Да"
                        cancelText="Нет"
                    >
                        <Button
                            size="large"
                            type="primary"
                            className={styles.button}
                        >
                            Добавить колонку
                        </Button>
                    </Popconfirm>
                </div>
            </div>
            <div className={styles.block}>
                <BlockGrid api={api} blockIndex={index} item={item} />
            </div>
        </div>
    );
};

export default memo(BlockItem);
