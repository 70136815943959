import React, { useState, useEffect } from 'react';
import { Button, Table, Space, Tag, Popconfirm } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '@/Services';
import MortgageForm from './form';
import styles from '../../styles/settings.module.scss';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

const Mortgage = () => {

    const dispatch = useDispatch();
    const { mortgage, request } = useSelector((state: any) => state?.account);

    const [ mortgage_item_edit, set_mortgage_item_edit ] = useState(null);
    const [status_form, set_status_form] = useState(false);

    useEffect(()=>{
        dispatch(api.account.getMortgage());
    }, []);

    const columns = [
        {
            title: 'Наименование',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'Перв. взнос',
            dataIndex: 'down_payment',
            key: 'down_payment',
            render: (_, item)=>{
                return <Tag>{ item?.down_payment }%</Tag>
            },
            sorter: (a, b) => a.down_payment - b.down_payment
        },
        {
            title: 'Управление',
            key: 'action',
            render: (_, item) => (
                <Space size="middle">
                    <Button size="small" type="dashed" onClick={()=>{
                        set_mortgage_item_edit(item);
                        set_status_form(true);
                    }}><EditOutlined /></Button>
                    <Popconfirm
                        title="Запись будет удалена"
                        onConfirm={()=>deleteMortgage(item?._id)}
                        okText="удалить"
                        cancelText="Нет"
                    >
                        <Button size="small" danger type="dashed"><DeleteOutlined /></Button>
                    </Popconfirm>
                    
                </Space>
            ),
            width: 200
        },
    ];

    const deleteMortgage = (mortgage_id) => {
        dispatch(api.account.deleteMortgage(mortgage_id));
    }

    return (

        <div>

            <MortgageForm mortgage={mortgage_item_edit} status={status_form} close={()=>{
                set_status_form(false);
                set_mortgage_item_edit(null);
            }} api={api} />

            <div className={styles.listMortgage}>

                <div className={styles.listMortgageHeader}>
                    <Button type="primary" key="console" onClick={()=>set_status_form(true)}>Добавить ипотеку</Button>
                </div>

                <Table 
                    pagination={false} 
                    loading={request} 
                    columns={columns} 
                    dataSource={mortgage} 
                />
            </div>

        </div>
    )
}

export default Mortgage;