import React, { useEffect } from 'react';
import { Modal, Form, Button, Select, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '@/Services';
import { LoadingOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';

const RealestateForm = ({isModalOpen, close}) => {

    const client = useApolloClient();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const type_realestate = Form.useWatch('type_realestate', form);

    const {
        form_realestate_request,
        form_meta_information_data
    } = useSelector((state: any) => state?.realestate);

    useEffect(()=>{

        form.resetFields();
        dispatch(api.realestate.formMetaInformation(null));

    }, []);

    const onFinish = (values) => {
        return dispatch(api.realestate.create(values, navigate, client));
    }

    return (
        <Modal 
            title="Создание нового жк (очереди)" 
            open={isModalOpen} 
            onCancel={close}
            footer={null}
            width="50%"
            destroyOnClose
        >
            <Form
                form={form}
                onFinish={onFinish}
                autoComplete="off"
                size='large'
                layout="vertical"
                disabled={form_realestate_request}
            >

                <Form.Item
                    name="type_realestate"
                    label="Выберите ЖК или очередь"
                    rules={[
                        {
                            required: true,
                            message: 'Поле обязательно для заполнения',
                        },
                    ]}
                >
                    <Select
                        placeholder="Выберите ЖК или очередь"
                        options={[{
                            label: 'Жилой комплекс',
                            value: 'realestate'
                        },{
                            label: 'Очередь',
                            value: 'queue'
                        }]}
                    />
                </Form.Item>

                <Form.Item
                    name="title"
                    label={`Наименование ${type_realestate === 'realestate' ? 'ЖК' : 'очереди'}`}
                    rules={[
                        {
                            required: true,
                            message: 'Поле обязательно для заполнения',
                        },
                    ]}
                >
                    <Input
                        placeholder={`Наименование ${type_realestate === 'realestate' ? 'ЖК' : 'очереди'}`}
                    />
                </Form.Item>

                <Form.Item
                    name="city"
                    label="Выберите город"
                    rules={[
                        {
                            required: true,
                            message: 'Поле обязательно для заполнения',
                        },
                    ]}
                >
                    <Select
                        placeholder="Выберите город"
                        options={
                            form_meta_information_data?.cities && form_meta_information_data?.cities.length ?
                                form_meta_information_data?.cities.map((item)=>({
                                    value: item?._id,
                                    label: item?.title,
                                }))
                            : []
                        }
                    />
                </Form.Item>

                {
                !!(type_realestate === 'queue') && (
                    <>
                        <Form.Item
                            name="object"
                            label="Выберите объект"
                            rules={[
                                {
                                    required: true,
                                    message: 'Поле обязательно для заполнения',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Выберите объект"
                                options={
                                    form_meta_information_data?.objects && form_meta_information_data?.objects.length ?
                                        form_meta_information_data?.objects.map((item)=>({
                                            value: item?._id,
                                            label: item?.title,
                                        }))
                                    : []
                                }
                            />
                        </Form.Item>
                        <Form.Item
                            name="number_queue"
                            label="Номер очереди"
                            rules={[
                                {
                                    required: true,
                                    message: 'Поле обязательно для заполнения',
                                },
                            ]}
                        >
                            <Input
                                placeholder="Номер очереди"
                            />
                        </Form.Item>
                    </>
                )
            }

                <Form.Item style={{marginTop:5}}>
                    <Button type="primary" htmlType="submit">
                        {
                            form_realestate_request ? <LoadingOutlined /> : 'Создать' 
                        }
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default RealestateForm;