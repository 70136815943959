import React from 'react';
import { Tabs } from 'antd';
import WebhookDeals from './deals';
import WebhookApplications from './applications';
import LogovoForm from './logovoForm';

const WebhookTabs = ({
    styles, 
    id, 
    dispatch, 
    widget
}) => {

    const items = [
        {
            key: '1',
            label: `Webhook`,
            children: (
            <WebhookApplications
                styles={styles}
                id={id} 
                dispatch={dispatch}
                widget={widget}
            />
            ),
        },
        {
            key: '2',
            label: `Битрикс контракты`,
            children: <WebhookDeals 
                styles={styles}
                id={id} 
                dispatch={dispatch}
                bitrix_deal={widget?.bitrix_deal} 
            />,
        },
        {
            key: '3',
            label: `Форма logovo.kz`,
            children: <LogovoForm 
                styles={styles}
                id={id} 
                dispatch={dispatch}
            />,
        }
    ];


    return (
        <div>
            <Tabs defaultActiveKey="1" items={items} />
        </div>
    )
}

export default WebhookTabs;