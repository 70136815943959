import { produce } from 'immer';

const initialState = {
    modal_form_company: false,
    edit_company: null,

    edit_status: null,
    modal_form_statuses: false,

    lead: null,
};

const companies = (state = initialState, action: any) => {
    switch (action.type) {
        case 'UPDATE_INFO_LEAD':
            return produce(state, draftState => {
                draftState.lead = action.lead;
            });

        case 'MODAL_EDIT_FORM_STATUS':
            return produce(state, draftState => {
                draftState.edit_status = action.data;
            });

        case 'MODAL_FORM_STATUSES':
            return produce(state, draftState => {
                draftState.modal_form_statuses = action.status;
            });

        case 'MODAL_EDIT_FORM_COMPANY':
            return produce(state, draftState => {
                draftState.edit_company = action.item || null;
            });

        case 'MODAL_FORM_COMPANY':
            return produce(state, draftState => {
                draftState.modal_form_company = action.status;
            });

        default:
            return state;
    }
};

export default companies;
