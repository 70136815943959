import React, { useEffect } from 'react';
import { Form, Typography, Button } from 'antd';
import FormItems from './helpers/items';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '@/Services';
import getQuarter from './helpers/funcs/getQuarter';
import { LoadingOutlined } from '@ant-design/icons';
import { Loader } from '@/Components';
import dayjs from 'dayjs';

const { Title } = Typography;

const FormRealestate = () => {

    const dispatch = useDispatch();

    const {
        form_realestate_request,
        form_meta_information_data
    } = useSelector((state: any) => state?.realestate);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const realestate_id = searchParams.get('realestate_id');

    const [form] = Form.useForm();

    const onFinish = (values) => {

        let fields = {
            ...values,
            commercial: values?.comm_realestate_info === 'COMMERCIAL' ? true : false,
            real_estate_house: values?.comm_realestate_info === 'COMPLEX' ? true : false,
            delivery_time: getQuarter(values?.delivery_time_month),
            image_complex_with_all_blocks: values?.image_complex_with_all_blocks.map((item)=>(item?.uid)),
            documents: values?.documents.map((item)=>(item?.uid)),
            photos: values?.photos.map((item)=>{
                return item?.uid;
            }),
            photo: values?.photos[0].uid,
            coords: {
                lat: Number(values?.coords[0] || 0),
                lon: Number(values?.coords[1] || 0)
            },
            geo: {
                type: 'Point',
                coordinates: values?.coords
            },
            realestate_id: realestate_id,
        }

        return dispatch(api.realestate.edit(fields));

    }

    useEffect(()=>{

        form.resetFields();

        if(form_meta_information_data?.realestate){

            form.setFieldsValue({
                ...form_meta_information_data?.realestate,
                delivery_time_month: form_meta_information_data?.realestate?.delivery_time_month ? dayjs(form_meta_information_data?.realestate?.delivery_time_month) : null
            });

        }

    }, [form_meta_information_data?.realestate]);

    useEffect(()=>{

        dispatch(api.realestate.formMetaInformation(realestate_id));

    }, []);

    return (
        <>
            {
                
                (form_realestate_request && !form_meta_information_data) ?
                    <Loader/>
                :
                    <>
                        <Title level={4}>{form_meta_information_data?.realestate?.title || 'Жк не найден'}</Title>
                        <Form
                            form={form}
                            onFinish={onFinish}
                            autoComplete="off"
                            size='large'
                            layout="vertical"
                            disabled={form_realestate_request}
                        >

                            <FormItems/>

                            <Form.Item style={{marginTop:5}}>
                                <Button type="primary" htmlType="submit">
                                    { form_realestate_request ? <LoadingOutlined /> : 'Редактировать информацию' }
                                </Button>
                            </Form.Item>
                        </Form>
                    </>
            }
        </>
    )
}

export default FormRealestate;