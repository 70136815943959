import React, { useEffect } from 'react';
import styles from '../../styles/settings.module.scss';
import { Form, Input, Button, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '@/Services';

const WhatsappBusinessKeys = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { user } = useSelector((state: any) => state?.account);

    const onFinish = (values) => {
        dispatch(api.account.saveKeysWhatsappBusiness({
            ...values,
            type: 'update_keys'
        }));
    }

    useEffect(()=>{
        form.setFieldsValue({
            token: user?.builder?.whatsapp_business?.token || '',
            id: user?.builder?.whatsapp_business?.id || ''
        })
    }, []);

    return (
        <section className={styles.settingsWrapper}>
            <div className={styles.blockSections}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    autoComplete="off"
                    size="large"
                    className={styles.form}
                >
                    <Form.Item
                        name="token"
                        label="Токен"
                        className={styles.input}
                    >
                        <Input.Password size="large" placeholder="Токен" />
                    </Form.Item>
                    <Form.Item
                        name="id"
                        label="Phone ID"
                        className={styles.input}
                    >
                        <Input.Password size="large" placeholder="Phone ID" />
                    </Form.Item>

                    <Form.Item className={styles.input} style={{
                        marginTop: 5
                    }}>
                        <Button type="primary" htmlType="submit">
                            Сохранить
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </section>
    )
}

export default WhatsappBusinessKeys;