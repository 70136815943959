import {
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import { Space, Button, Popconfirm } from 'antd';
import { moment } from '@/Services';

const templateColumns =  (removeTemplate, realestate_id, navigate, req_delete_apartment_template) => ([
    {
        title: "№",
        key: "number",
        render(item, record, index) {
            return index + 1;
        },
        width: 75
    },
    {
        title: "наименование",
        key: "title",
        dataIndex: 'title'
    },
    {
        title: "Коммерческая недвижимость",
        key: "commercial",
        render: (item) => {
            return (item?.commercial ? 'Да' : 'Нет')
        },
    },
    {
        title: "Площадь",
        key: "square_metres",
        render(item) {
            return item?.square_metres + 'м²';
        },
    },
    {
        title: "Дата создания",
        key: "createdAt",
        render(item) {
            return moment(item?.createdAt).format('DD MMMM YYYY в HH:mm');
        },
    },
    {
        title: "Управление",
        key: "_id",
        render: (item) => {
            return (
                <Space>
                    <Button
                        size="small"
                        onClick={() => navigate(`/realestate/templates/form?realestate_id=${realestate_id}&template_id=${item?._id}`)}
                    >
                        <EditOutlined />
                    </Button>
                    <Popconfirm
                        title="Шаблон и связанные с ним квартиры будут удалены. Вы уверены?"
                        onConfirm={()=>{
                            if(!req_delete_apartment_template){
                                removeTemplate(item?._id) 
                            }
                        }}
                        okText="Удалить"
                        cancelText="Нет"
                    >
                        <Button size="small">
                            <DeleteOutlined />
                        </Button>
                    </Popconfirm>
                </Space>
            );
        },
    },
]);

export default templateColumns;