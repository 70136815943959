import { Tag, message, Collapse, Button } from 'antd';
import { produce } from 'immer';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const initialState = {
    list: [],
    form_keys: [],
    contract_templates: [],

    request: false,
    form_request: false,

    payment_methods_data: null,
    request_payment_methods: false,
    request_form_payment_methods: false,
    form_meta: null,
};

const contracts = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_NEW_APPLICATION_CONTRACT':
            return produce(state, (draftState) => {
                draftState.list = [action?.contract, ...draftState?.list] || [];
            });

        case 'PAYMENT_METHODS_DATA':
            return produce(state, (draftState) => {
                draftState.payment_methods_data = action.data || [];
            });

        case 'PAYMENT_METHODS_REQUEST':
            return produce(state, (draftState) => {
                draftState.request_payment_methods = action.status || false;
            });

        case 'FORM_PAYMENT_METHODS_REQUEST':
            return produce(state, (draftState) => {
                draftState.request_form_payment_methods =
                    action.status || false;
            });

        case 'CONTRACT_TEMPLATES_DATA':
            return produce(state, (draftState) => {
                draftState.contract_templates = action.data || [];
            });

        case 'REQUEST_FORM_CONTRACTS':
            return produce(state, (draftState) => {
                draftState.form_request = action.status || false;
            });

        case 'DATA_CONTRACTS_FORM_META':
            let items = [];

            if (action.data?.keys) {
                if (action?.data_type != 'whatsapp-business') {
                    items.push({
                        key: 'keys',
                        label: 'Ключи',
                        children: (
                            <Collapse
                                accordion
                                items={[
                                    {
                                        key: 'form_keys',
                                        label: 'Информация о клиенте',
                                        children: (
                                            <div>
                                                <Tag color="gold">
                                                    Кликните по ключу, чтобы
                                                    скопировать код и добавьте
                                                    его в текст, где нужно
                                                    отобразить информацию
                                                </Tag>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexWrap: 'wrap',
                                                        marginTop: 10,
                                                    }}
                                                >
                                                    {action.data?.keys?.form_keys?.keys.map(
                                                        (item) => (
                                                            <div
                                                                key={item?.key}
                                                                style={{
                                                                    marginBottom: 5,
                                                                }}
                                                            >
                                                                <CopyToClipboard
                                                                    onCopy={() =>
                                                                        message.success(
                                                                            'Код скопирован'
                                                                        )
                                                                    }
                                                                    text={`<%= ${item?.key} %>`}
                                                                    options={{
                                                                        format: 'text/plain',
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            padding:
                                                                                '5px 10px',
                                                                            border: '1px solid rgba(0,0,0,0.14)',
                                                                            borderRadius: 7,
                                                                            margin: '0 5px 5px 0',
                                                                        }}
                                                                    >
                                                                        <p
                                                                            style={{
                                                                                fontSize: 9,
                                                                            }}
                                                                        >
                                                                            {item?.description ||
                                                                                'Описания нет'}
                                                                        </p>
                                                                        <Button>
                                                                            {
                                                                                item?.key
                                                                            }
                                                                        </Button>
                                                                    </div>
                                                                </CopyToClipboard>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        ),
                                    },
                                    {
                                        key: 'apartment_keys',
                                        label: 'Информация о квартире',
                                        children: (
                                            <div>
                                                <Tag color="gold">
                                                    Кликните по ключу, чтобы
                                                    скопировать код и добавьте
                                                    его в текст, где нужно
                                                    отобразить информацию
                                                </Tag>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexWrap: 'wrap',
                                                        marginTop: 10,
                                                    }}
                                                >
                                                    {action.data?.keys?.apartment_keys?.keys.map(
                                                        (item) => (
                                                            <div
                                                                key={item?.key}
                                                                style={{
                                                                    marginBottom: 5,
                                                                }}
                                                            >
                                                                <CopyToClipboard
                                                                    onCopy={() =>
                                                                        message.success(
                                                                            'Код скопирован'
                                                                        )
                                                                    }
                                                                    text={
                                                                        item?.key ===
                                                                        'apartment_layout'
                                                                            ? `<%- ${item?.key} %>`
                                                                            : `<%= ${item?.key} %>`
                                                                    }
                                                                    options={{
                                                                        format: 'text/plain',
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            padding:
                                                                                '5px 10px',
                                                                            border: '1px solid rgba(0,0,0,0.14)',
                                                                            borderRadius: 7,
                                                                            margin: '0 5px 5px 0',
                                                                        }}
                                                                    >
                                                                        <p
                                                                            style={{
                                                                                fontSize: 9,
                                                                            }}
                                                                        >
                                                                            {item?.description ||
                                                                                'Описания нет'}
                                                                        </p>
                                                                        <Button>
                                                                            {
                                                                                item?.key
                                                                            }
                                                                        </Button>
                                                                    </div>
                                                                </CopyToClipboard>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        ),
                                    },
                                ]}
                            />
                        ),
                    });
                }
            }

            return produce(state, (draftState) => {
                draftState.form_keys = items;
                draftState.form_meta = action.data;
            });

        case 'REQUEST_CONTRACTS':
            return produce(state, (draftState) => {
                draftState.request = action.status || false;
            });

        case 'DATA_CONTRACTS':
            return produce(state, (draftState) => {
                draftState.list = action.data || [];
            });

        default:
            return state;
    }
};

export default contracts;
