import React from 'react';
import { Tag, Button, message } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const ListMessageKeys = ({keys}) => {

    return (
        <div>
            {
                keys && keys.length ?
                    keys.map((item)=>(
                        <Tag key={item?.key} style={{marginBottom:7}}>
                            <p>{ item?.description }</p>
                            <CopyToClipboard 
                                onCopy={()=>message.success('Код скопирован')}
                                text={`<%= ${item?.key} %>`}
                                options={{ format: 'text/plain' }}
                            >
                                <Button>{ item?.key }</Button>
                            </CopyToClipboard>
                        </Tag>
                    ))
                :
                    <h5>Ключей не найдено</h5>
            }
        </div>
    )

}

export default ListMessageKeys;