import React from 'react';
import { Form, Typography, Upload, Space } from 'antd';
import styles from '../../../../../styles/home.module.scss';
import { InboxOutlined, EyeOutlined } from '@ant-design/icons';
import { api } from '@/Services';
import { useDispatch } from 'react-redux';

const { Text } = Typography;
const { Dragger } = Upload;

const FormItemDocuments = () => {

    const dispatch = useDispatch();
    const form = Form.useFormInstance();
    const documents = Form.useWatch('documents', form) || form.getFieldValue('documents');

    const customRequest = async ({ file, onSuccess, data }) => {

        const getFile: any = await dispatch(api.files.uploadFile(file, data?.uploadType));
        let response = {};

        response = {
            success: true,
            file: {
                name: file.name,
                size: file.size,
                type: file.type,
                info: getFile
            },
        };

        onSuccess(response);
    };

    const getValueFromEvent = (info) => {

        let data = [];

        if(info?.fileList && info?.fileList.length){
            info?.fileList.forEach((item)=>{
                if(item?.status === 'done'){
                    data.push({
                        uid: item?.response?.file?.info?.id || item?.uid,
                        name: item?.response?.file?.info?.url || item?.name,
                        status: 'done',
                        url: item?.response?.file?.info?.full_url || item?.url,
                    });
                }
            })
        }

        return data;
    }

    const removeFile = (data) => {
        dispatch(api.files.removeFile(data?.response?.file?.info?.id || data?.uid));
        return true;
    }

    const handlePreview = (file) => {
        const urlFile = file?.response?.file?.info?.full_url || file?.url;
        return window.open(urlFile, '_blank');
    };

    return (
        <div className={styles.sectionForm}>

            <Text type="secondary">Документы</Text>

            <Form.Item
                name="documents"
                label=""
                getValueFromEvent={getValueFromEvent}
            >
                <Dragger
                    maxCount={5}
                    accept=".pdf"
                    name='file'
                    headers={{
                        authorization: localStorage.getItem('SESSION_TOKEN'),
                    }}
                    defaultFileList={documents}
                    data={{
                        uploadType: 'DOCUMENT',
                        form_key: 'DOCUMENT'
                    }}
                    customRequest={({ file, onSuccess, data }) => customRequest({ file, onSuccess, data })}
                    showUploadList={{
                        showDownloadIcon: true,
                        downloadIcon: <EyeOutlined />
                    }}
                    onDownload={handlePreview}
                    onRemove={removeFile}
                > 
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>    
                    <Space direction="vertical">          
                        <Text>Выберите или перетащите документ формата pdf</Text>
                        <Text type="secondary">Документ соглашения</Text>
                    </Space>   
                </Dragger>
            </Form.Item>

        </div>
    )
}

export default FormItemDocuments;