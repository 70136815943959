import React, { useEffect, useState } from 'react';
import { Form, Select, Typography, Input } from 'antd';
import styles from '../../../../../styles/home.module.scss';
import { useSelector } from 'react-redux';
import { Map, Placemark, YMaps } from 'react-yandex-maps';

const { Text } = Typography;

const FormItemAddress = () => {

    const form = Form.useFormInstance();
    const city = Form.useWatch('city', form);
    const coords = Form.useWatch('coords', form);

    const [districts, set_districts] = useState([]);

    const {
        form_meta_information_data
    } = useSelector((state: any) => state?.realestate);

    useEffect(()=>{

        const getCity = form_meta_information_data.cities.filter((item) => String(item?._id) === String(city));

        if(getCity.length && getCity[0].districts && getCity[0].districts.length){
            set_districts(getCity[0].districts);
        }

    }, [city])

    return (
        <div className={styles.sectionForm}>

            <Text type="secondary">Адрес, местоположение на карте</Text>
        
            <Form.Item
                name="address"
                label={`Адрес`}
                rules={[
                    {
                        required: true,
                        message: 'Поле обязательно для заполнения',
                    },
                ]}
            >
                <Input
                    placeholder="Адрес"
                />
            </Form.Item>

            <Form.Item
                name="city"
                label="Выберите город"
                rules={[
                    {
                        required: true,
                        message: 'Поле обязательно для заполнения',
                    },
                ]}
            >
                <Select
                    placeholder="Выберите город"
                    options={
                        form_meta_information_data?.cities && form_meta_information_data?.cities.length ?
                            form_meta_information_data?.cities.map((item)=>({
                                value: item?._id,
                                label: item?.title,
                            }))
                        : []
                    }
                />
            </Form.Item>

            {
                !!(city && districts.length) && (
                    <Form.Item
                        name="districts"
                        label="Выберите район"
                        rules={[
                            {
                                required: true,
                                message: 'Поле обязательно для заполнения',
                            },
                        ]}
                    >
                        <Select
                            mode="multiple"
                            placeholder="Выберите район"
                            options={
                                districts.map((item)=>({
                                    value: item?._id,
                                    label: item?.title,
                                }))
                            }
                        />
                    </Form.Item> 
                )
            }

            <Form.Item
                name="coords"
                label="Местоположение ЖК на карте"
                rules={[
                    {
                        required: true,
                        message: 'Поле обязательно для заполнения',
                    },
                ]}
            >
                <YMaps>
                    <Map
                        defaultState={{
                            center: [50.698, 70.191],
                            zoom: 4,
                        }}
                        style={{
                            width: '100%',
                            height: 500,
                        }}
                        onClick={(e) => {
                            form.setFieldsValue(
                                {
                                    coords: e.get(
                                        'coords'
                                    ),
                                }
                            );
                        }}
                    >
                        {coords ? (
                            <Placemark
                                geometry={coords}
                            />
                        ) : (
                            <div></div>
                        )}
                    </Map>
                </YMaps>
            </Form.Item>

        </div>
    )
}

export default FormItemAddress;