import React from 'react';
import styles from '../styles/finance.module.scss';
import { LoadingOutlined } from '@ant-design/icons';
import { message, Result } from 'antd';
import { separateNumber } from '@/Services';
import { Menu, Dropdown } from 'antd';
import { MoreOutlined } from '@ant-design/icons';

const List = ({ items, loading }: any) => {
    const menu = (status: Boolean) => {
        if (status) {
            return (
                <Menu
                    items={[
                        {
                            key: '1',
                            label: <span onClick={devFunc}>Удалить</span>,
                        },
                    ]}
                />
            );
        } else {
            return (
                <Menu
                    items={[
                        {
                            key: '1',
                            label: <span onClick={devFunc}>Оплатить</span>,
                        },
                        {
                            key: '2',
                            label: <span onClick={devFunc}>Отменить</span>,
                        },
                    ]}
                />
            );
        }
    };

    const devFunc = () => {
        return message.warning('В разработке');
    };

    return (
        <div className={styles.wrapper}>
            {loading ? (
                <div className={styles.infoBlock}>
                    <LoadingOutlined />
                </div>
            ) : items && items.length ? (
                <div className={styles.list}>
                    {items.map((item: any) => (
                        <div className={styles.item}>
                            <div className={styles.section}>
                                <p>
                                    {item?.type_payment === 'CRM_COMPANY'
                                        ? `Оплата компании ${item?.crm_company?.title}`
                                        : `Пополнение баланса`}
                                </p>
                            </div>
                            <div className={styles.line}></div>
                            <div className={styles.section}>
                                <p>{item?._id}</p>
                            </div>
                            <div className={styles.line}></div>
                            <div className={styles.section}>
                                <p>{separateNumber(item?.amount || 0)}₸</p>
                            </div>
                            <div className={styles.line}></div>
                            <div
                                className={styles.section}
                                style={{ width: 55 }}
                            >
                                <Dropdown
                                    trigger={['click']}
                                    overlay={() => menu(item?.status)}
                                >
                                    <MoreOutlined />
                                </Dropdown>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <Result
                    status="500"
                    title="Данных нет"
                    subTitle="Информации не найдено"
                    extra={null}
                />
            )}
        </div>
    );
};

export default List;
