import React from 'react';
import currencyFormatter from 'currency-formatter';

const Card = ({ styles, options }) => {

    const {
        width_border,
        status_total_price,
        color_text_r_and_s,
        status_square_metre_price,
        status_count_rooms,
        status_square_metres,
        a_background_and_color_card,
        background_card,
        color_border,
        border_radius,
        status_apartment_number
    } = options;

    let photoUrl = 'https://api.logovo.kz/570/400/files/2023/01/09/1673295479421.webp';

    let styleItem: any = {
        borderWidth: width_border,
    };

    let styleAdditionalInfoBack: any = {};
    let styleAdditionalInfoText: any = {};

    if (border_radius > 0) {
        styleItem.borderRadius = border_radius;
    }

    if (color_border) {
        styleItem.borderColor = color_border;
    }

    if (background_card) {
        styleItem.backgroundColor = background_card;
    }

    if (a_background_and_color_card) {
        styleAdditionalInfoBack.backgroundColor =
            a_background_and_color_card || '#4079c6';
        styleAdditionalInfoText.color =
            a_background_and_color_card || '#4079c6';
    }

    let info_count_rooms = status_count_rooms
        ? `4к`
        : '';
    let info_square_metres = status_square_metres
        ? `143м²`
        : '';
    let info_apartment_number = status_apartment_number
        ? `№254`
        : '';

    let info_total_price = status_total_price ? (
        <div
            className={styles.item_price}
            style={styleAdditionalInfoText}
        >
            {`от ${
                currencyFormatter.format(
                    25000000,
                    {
                        code: 'EUR',
                        symbol: '',
                    }
                ) + '₸'
            }`}
        </div>
    ) : (
        ''
    );

    let info_square_metre_price =
        status_square_metre_price ? (
            <div
                className={styles.item_price_2}
                style={styleAdditionalInfoText}
            >
                {`от ${
                    currencyFormatter.format(
                        540000,
                        {
                            code: 'EUR',
                            symbol: '',
                        }
                    ) + '₸/м²'
                }`}
            </div>
        ) : (
            ''
        );

    if (color_text_r_and_s) {
        styleAdditionalInfoBack.color = color_text_r_and_s;
    }

    return (
        <span
            className={styles.item_plan_block}
            style={styleItem}
        >
            <div className={styles.contentHead}>
                {status_count_rooms && (
                    <div
                        className={styles.item_head}
                        style={styleAdditionalInfoBack}
                    >
                        {`${info_count_rooms}`}
                    </div>
                )}

                {status_square_metres && (
                    <div
                        className={styles.item_head}
                        style={styleAdditionalInfoBack}
                    >
                        {`${info_square_metres}`}
                    </div>
                )}

                {status_apartment_number && (
                    <div
                        className={styles.item_head}
                        style={styleAdditionalInfoBack}
                    >
                        {`${info_apartment_number}`}
                    </div>
                )}


            </div>
            <div
                className={styles.imagePlan}
                style={{
                    background: `url(${photoUrl}) center / contain no-repeat`,
                }}
            ></div>
            {info_total_price}
            {info_square_metre_price}
        </span>
    )
}

export default Card;