const getQuarter = (date) => {

    date = new Date(date);
    const year = date.getFullYear();
    const month = date.getMonth();

    if (month >= 0 && month <= 2) {
        return `I квартал ${year}`;
    } else if (month >= 3 && month <= 5) {
        return `II квартал ${year}`;
    } else if (month >= 6 && month <= 8) {
        return `III квартал ${year}`;
    } else {
        return `IV квартал ${year}`;
    }

}

export default getQuarter;