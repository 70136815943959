import React, { useEffect, useState } from 'react';
import { api, moment } from '@/Services';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../../styles/form-payment.module.scss';
import { Button, Result, Tooltip, Popconfirm, Tag } from 'antd';
import { LoadingOutlined, EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import CreateFormPayment from './comps/formPayment';
import InfoFormPayments from './comps/infoFormPayments';
import typeFormPayment from './funcs/typeFormPayment';

const FormOfPayments = () => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const realestate_id = searchParams.get('realestate_id');

    const [status_modal, set_status_modal] = useState(false);
    const [item_edit, set_item_edit] = useState(null);

    const [calc_item, set_calc_item] = useState(null);

    const dispatch = useDispatch();
    const { list, request, save_request } = useSelector((state: any) => state?.payments);

    useEffect(()=>{
        dispatch(api.payments.getFormOfPayments(realestate_id));
        dispatch(api.account.getMortgage());
        dispatch(api.account.getNotes());
    }, []);

    useEffect(()=>{

        if(item_edit){
            set_status_modal(true);
        }else{
            set_status_modal(false);
        }
    }, [item_edit]);

    const closeForm = () => {
        set_item_edit(null);
        set_status_modal(false);

        set_calc_item(null);
    }

    const deleteFormOfPayment = (payment_id) => {
        return dispatch(api.payments.delete(payment_id, realestate_id));
    }
    
    return (
        <div className={styles.blockPayments}>

            <CreateFormPayment request_status={save_request} initial_values={item_edit} modalStatus={status_modal} close={closeForm} realestate_id={realestate_id} api={api} dispatch={dispatch}/>
            <InfoFormPayments styles={styles} item={calc_item} close={closeForm} />

            {
                request ?
                    <div className={styles.loading}>
                        <LoadingOutlined />
                    </div>
                : list && list.length ?
                    <div className={styles.listPayments}>
                        <div className={styles.listPaymentsHead}>
                            <h5></h5>
                            <Button onClick={()=>set_status_modal(true)} type="primary">Добавить форму оплаты</Button>
                        </div>
                        <div className={styles.listPaymentsContent}>
                            {
                                list.map((item)=>(
                                    <div className={styles.item} key={item?._id}>
                                        <div className={styles.left}>
                                            <Tooltip placement="topLeft" title={item?.title}>
                                                <h4 className={styles.text} style={{minWidth: 170}}>{ item?.title }</h4>
                                            </Tooltip>
                                            <Tag className={styles.tag} color={item?.type === 'purchase' ? "green" : item?.type === 'mortgage' ? "magenta" : item?.type === 'installment' ? 'purple' : ''}><b style={{fontWeight: 'bold'}}>{ typeFormPayment(item?.type) }</b></Tag>
                                            <Tooltip title={`ЖК, очередь: ${item?.realestate?.title || 'Не определен'}`}>
                                                <Tag className={`${styles.tag} ${styles.text}`}>{ item?.realestate?.title || 'Не определен' }</Tag>
                                            </Tooltip>
                                            <Tooltip title="Дата создания">
                                                <p className={styles.text} style={{width: 210}}>{ moment(item?.createdAt).format('DD MMMM YYYYг. hh:mm') }</p>
                                            </Tooltip>
                                            <Tooltip title={`Автор ${item?.author?.email}`}>
                                                <p className={styles.text}>{ item?.author?.email }</p>
                                            </Tooltip>
                                            <Tooltip title={item?.update_author?.email ? `Автор последнего обновления ${item?.update_author?.email}, время: ${moment(item?.updatedAt).format('DD MMMM YYYYг. hh:mm')}` : 'Изменений нет'}>
                                                <p className={styles.text}>{ item?.update_author?.email || 'Изменений нет'}</p>
                                            </Tooltip>
                                        </div>
                                        <div className={styles.right}>
                                            
                                            {
                                                item?.type != 'mortgage' ? 
                                                <Tooltip title="Просмотр условий">
                                                    <Button type="primary" size="small" onClick={()=>set_calc_item(item)}><EyeOutlined /></Button>
                                                </Tooltip>
                                                : <></>
                                            }
                                            
                                            <Button type="primary" size="small" onClick={()=>set_item_edit(item)}><EditOutlined /></Button>
                                            <Popconfirm
                                                title="Форма оплаты будет удалена"
                                                onConfirm={()=>deleteFormOfPayment(item?._id)}
                                                okText="Удалить"
                                                cancelText="нет"
                                            >
                                                <Button type="primary" danger size="small"><DeleteOutlined /></Button>
                                            </Popconfirm>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                :
                <Result
                    status="404"
                    title="Информации не найдено"
                    subTitle="К сожалению мы не нашли информации по оплате"
                    extra={<Button onClick={()=>set_status_modal(true)} type="primary">Добавить форму оплату</Button>}
                />
            }
        </div>
    )
}

export default FormOfPayments;