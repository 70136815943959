import React, { useState, useEffect } from 'react';
import styles from '../../styles/contracts.module.scss';
import { Button, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ContractTemplateForm from './helpers/form';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '@/Services';
import { contract_template_columns } from '../../common/table-columns';

const ContractTemplates = () => {
    const dispatch = useDispatch();
    const { contract_templates, request, form_request } = useSelector(
        (state: any) => state?.contracts
    );

    const [form_status, set_form_status] = useState(false);
    const [item_template_edit, set_item_template_edit] = useState(null);

    const formDrawer = () => {
        set_form_status(!form_status);
        set_item_template_edit(null);
    };

    useEffect(() => {
        dispatch(api.contracts.getContractTemplates());
        dispatch(api.contracts.getMeta());
        dispatch(api.account.getPaymentMethods());
    }, []);

    const formEdit = (item) => {
        set_item_template_edit(item);
        set_form_status(true);
    };

    const deleteContractTemplate = (contract_template_id) => {
        dispatch(api.contracts.deleteContractTemplate(contract_template_id));
    };

    return (
        <div className={styles.contractTemplateWrapper}>
            <ContractTemplateForm
                open={form_status}
                close={formDrawer}
                item={item_template_edit}
                dispatch={dispatch}
                api={api}
                request={form_request}
            />

            <div className={styles.header}>
                <div></div>
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={formDrawer}
                >
                    Добавить шаблон
                </Button>
            </div>

            <div className={styles.content}>
                <Table
                    bordered
                    loading={request}
                    columns={contract_template_columns(
                        formEdit,
                        deleteContractTemplate,
                        form_request
                    )}
                    dataSource={contract_templates}
                />
            </div>
        </div>
    );
};

export default ContractTemplates;
