
import React, { useState, useEffect } from 'react';
import { Modal, Input, Form, Tooltip, InputNumber, Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import styles from '../../styles/settings.module.scss';

const MortgageForm = ({status = false, close, mortgage, api}) => {
    const dispatch = useDispatch();
    const { request } = useSelector((state: any) => state?.account);

    const [form_value, set_form_value] = useState({
        title: '',
        down_payment: ''
    });

    useEffect(()=>{
        set_form_value({
            title: mortgage?.title || '',
            down_payment: mortgage?.down_payment || ''
        });
    }, [mortgage]);

    const onFinish = (values) => {
        if(!request){
            console.log('values => ', values)
            dispatch(api.account.updateMortgage({
                ...values,
                mortgage_id: mortgage?._id
            }, close));
        }
    }

    return (
        <Modal
            open={status}
            title={mortgage?._id ? 'Форма редактирования ипотеки' : 'Форма добавления ипотеки'}
            footer={null} 
            onCancel={close}
            destroyOnClose={true}
            width={500}
        >
            <Form
                name="basic"
                initialValues={form_value}
                onFinish={onFinish}
                autoComplete="off"
                size="large"
            >
                <Tooltip title="Наименование ипотеки">
                    <Form.Item
                        name="title"
                        rules={[
                            {
                            required: true,
                            message: 'Пожалуйста, заполните поле',
                            },
                        ]}
                        className={styles.itemForm}
                    >
                        <Input placeholder="Наименование ипотеки" />
                    </Form.Item>
                </Tooltip>
                <Tooltip title="Первоначальный взнос">
                    <Form.Item
                        name="down_payment"
                        rules={[
                            {
                            required: true,
                            message: 'Пожалуйста, заполните поле',
                            },
                        ]}
                        className={styles.itemForm}
                    >
                        <InputNumber style={{
                            width: '100%'
                        }} placeholder='Перв. взнос' addonAfter="%"/>
                    </Form.Item>
                </Tooltip>

                <Form.Item
                    className={styles.itemForm}    
                >
                    <Button size="middle" type="primary" htmlType="submit">
                        {request ? <LoadingOutlined /> : mortgage?._id ? 'обновить информацию' : 'Добавить'}
                    </Button>
                </Form.Item>

            </Form>
        </Modal>
    )
}

export default MortgageForm;