import { gql } from '@apollo/client';

const getUser = gql`
    query {
        getUser {
            _id

            subscription {
                status
                expiration
                rate {
                    title
                    price {
                        month
                        year
                    }
                    items {
                        title
                        description
                    }
                }
            }

            name
            email
            phone
            role {
                type
            }
            firstname
            lastname
            createdAt
            builder {
                _id
                title
                logo {
                    url
                }
                mortgage {
                    title
                    down_payment
                    _id
                }
                whatsapp_business {
                    id
                    token
                    template_message
                }
                integrations {
                    name
                    api_key
                    connection_status
                    statuses {
                        name_crm_status
                        name_logovo_status
                        key_crm_status
                    }
                }
            }
            balance
            available_ads
            available_managers
            settings {
                company_name
                realtor {
                    _id
                    settings {
                        company_name
                    }
                }
            }
        }
    }
`;

const authentication = gql`
    mutation ($profile: UserInput) {
        authentication(profile: $profile) {
            _id
            email
            role {
                type
            }
            phone
            name
            firstname
            lastname
            createdAt
            builder {
                _id
                title
            }
            settings {
                company_name
                realtor {
                    _id
                    settings {
                        company_name
                    }
                }
            }
            token
        }
    }
`;

const changePassword = gql`
    mutation ($fields: ChangePasswordInput) {
        changePassword(fields: $fields)
    }
`;

const updateProfileInformation = gql`
    mutation ($fields: updateProfileInformationInput) {
        updateProfileInformation(fields: $fields)
    }
`;

export default {
    authentication,
    getUser,
    changePassword,
    updateProfileInformation
};
