import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import * as gqls from '@/Services/apollo/gqls';
import { ToastContainer, Slide } from 'react-toastify';
import { useDispatch } from 'react-redux';

import { LayoutWrapper } from '../Layout';
import { Chess } from '../Chess';
import Logout from '../Logout';
import Loader from '../Loader';

import RealestateCatalog from '../Chess/catalog';

//Auth routes
import AuthLayout from '../Auth/Layout';
import Login from '../Auth/Login';
import LoginSuccess from '../Auth/Success/';

//builder routes
import BuilderRealestate from '../Builder/Realestate';
import BuilderTreaties from '../Builder/Treaties';
import BuilderSettings from '../Builder/Settings';
import ResetPassword from '../Builder/ResetPassword';
import Contracts from '../Builder/Contracts';
import FormRealestate from '../Builder/Realestate/pages/form';
import TemplateList from '../Builder/Realestate/pages/templates/list';
import RealestateTemplateForm from '../Builder/Realestate/pages/templates/form';
import RealestateFormPayments from '../Builder/Realestate/pages/form-payments';
import ApartmentSheetsForm from '../Builder/Realestate/pages/ApartmentSheets/form';
import EditTemplateSettings from '../Builder/Realestate/pages/templates/list/pages/editTemplateSettings';

//Realtor routes
import RealtorHome from '../Realtor/Home';
import RealtorTreaties from '../Realtor/Treaties';
import RealtorManagers from '../Realtor/Managers';
import RealtorCreateManager from '../Realtor/Managers/create';
import RealtorEditManager from '../Realtor/Managers/edit';

//pages
import PageNotFound from '../PageNotFound';
import Integration from '../Integration';

//Analytics
import Analytics from '../Realtor/Analytics';

//Leads
import Leads from '../Realtor/Leads';
import Company from '../Realtor/Leads/Company';
import Statuses from '../Realtor/Leads/Statuses';

//Subscription
import Subscription from '../Subscription';

//Finance
import Finance from '../Realtor/Finance';

//widgets
import Widgets from '../Builder/Widgets';
import WidgetEdit from '../Builder/Widgets/edit';

//lib css
import 'react-toastify/dist/ReactToastify.css';
import IntegrationB24 from '../Integration/pages/b24';

const Layout = LayoutWrapper;

const Initial: React.FC = () => {
    const dispatch = useDispatch();
    const { data, loading } = useQuery(gqls.users.getUser);

    useEffect(() => {
        if (!loading) {
            dispatch({
                type: 'UPDATE_ACCOUNT',
                user: data?.getUser || null,
            });

            dispatch({
                type: 'ACCOUNT_BUILDER_MORTGAGE',
                data: data?.getUser?.builder?.mortgage || null,
            });
        }
    }, [loading]);

    let InitialRoute = () => {
        let role = data?.getUser?.role?.type || '';

        switch (role) {
            case 'MANAGER':
                return (
                    <Route
                        path="/"
                        element={<Layout data={data} loading={loading} />}
                    >
                        <Route index element={<RealtorHome />} />
                        <Route
                            path="/settings"
                            element={<BuilderSettings data={data} />}
                        />
                        <Route
                            path="/settings/reset-password"
                            element={<ResetPassword />}
                        />
                        <Route path="/treaties" element={<RealtorTreaties />} />
                        <Route
                            path="/real-estate/:id"
                            element={<Chess account={data.getUser} />}
                        />
                        <Route
                            path="/real-estate/catalog/:id"
                            element={<RealestateCatalog />}
                        />
                        <Route path="/logout" element={<Logout />} />
                    </Route>
                );
            case 'REALTOR':
                return (
                    <Route
                        path="/"
                        element={<Layout data={data} loading={loading} />}
                    >
                        <Route index element={<RealtorHome />} />
                        <Route path="/treaties" element={<RealtorTreaties />} />
                        <Route path="/managers" element={<RealtorManagers />} />
                        <Route
                            path="/managers/create"
                            element={<RealtorCreateManager />}
                        />
                        <Route
                            path="/managers/edit/:id"
                            element={<RealtorEditManager />}
                        />
                        <Route
                            path="/settings"
                            element={<BuilderSettings data={data} />}
                        />
                        <Route path="/analytics" element={<Analytics />} />
                        <Route path="/leads" element={<Leads />} />
                        <Route path="/leads/statuses" element={<Statuses />} />
                        <Route
                            path="/leads/company/:company_id"
                            element={<Company />}
                        />

                        <Route
                            path="/subscription"
                            element={<Subscription account={data.getUser} />}
                        />
                        <Route path="/finance" element={<Finance />} />
                        <Route
                            path="/settings/reset-password"
                            element={<ResetPassword />}
                        />
                        <Route
                            path="/real-estate/:id"
                            element={<Chess account={data.getUser} />}
                        />
                        <Route
                            path="/real-estate/catalog/:id"
                            element={<RealestateCatalog />}
                        />
                        <Route path="/logout" element={<Logout />} />
                        <Route path="*" element={<PageNotFound />} />
                    </Route>
                );
            case 'BUILDER':
                return (
                    <Route
                        path="/"
                        element={<Layout data={data} loading={loading} />}
                    >
                        <Route index element={<BuilderRealestate />} />

                        <Route path="/integration" element={<Integration />} />
                        <Route
                            path="/integration/b24"
                            element={<IntegrationB24 />}
                        />

                        <Route path="/treaties" element={<BuilderTreaties />} />
                        <Route
                            path="/settings"
                            element={<BuilderSettings data={data} />}
                        />
                        <Route
                            path="/realestate/form"
                            element={<FormRealestate />}
                        />
                        <Route
                            path="/realestate/templates/:realestate_id"
                            element={<TemplateList />}
                        />
                        <Route
                            path="/realestate/templates/:realestate_id/settings"
                            element={<EditTemplateSettings />}
                        />
                        <Route
                            path="/realestate/templates/form"
                            element={<RealestateTemplateForm />}
                        />
                        <Route
                            path="/realestate/form-payments"
                            element={<RealestateFormPayments />}
                        />
                        <Route
                            path="/realestate/apartment-sheets/form"
                            element={<ApartmentSheetsForm />}
                        />

                        <Route path="/widgets" element={<Widgets />} />

                        <Route path="/contracts" element={<Contracts />} />
                        <Route
                            path="/widgets/edit/:id"
                            element={<WidgetEdit />}
                        />
                        <Route
                            path="/settings/reset-password"
                            element={<ResetPassword />}
                        />
                        <Route
                            path="/real-estate/:id"
                            element={<Chess account={data.getUser} />}
                        />
                        <Route
                            path="/real-estate/catalog/:id"
                            element={<RealestateCatalog />}
                        />
                        <Route path="/leads" element={null} />
                        <Route path="/logout" element={<Logout />} />
                        <Route path="*" element={<PageNotFound />} />
                    </Route>
                );
            case 'CRM_ADMIN_SERVICE':
                return (
                    <Route
                        path="/"
                        element={<Layout data={data} loading={loading} />}
                    >
                        <Route index element={<RealtorHome />} />
                        <Route path="/treaties" element={<RealtorTreaties />} />
                        <Route path="/managers" element={<RealtorManagers />} />
                        <Route
                            path="/managers/create"
                            element={<RealtorCreateManager />}
                        />
                        <Route
                            path="/managers/edit/:id"
                            element={<RealtorEditManager />}
                        />
                        <Route
                            path="/settings"
                            element={<BuilderSettings data={data} />}
                        />
                        <Route
                            path="/settings/reset-password"
                            element={<ResetPassword />}
                        />
                        <Route
                            path="/real-estate/:id"
                            element={<Chess account={data.getUser} />}
                        />
                        <Route
                            path="/real-estate/catalog/:id"
                            element={<RealestateCatalog />}
                        />
                        <Route path="/logout" element={<Logout />} />
                        <Route path="*" element={<PageNotFound />} />
                    </Route>
                );
            default:
                return (
                    <Route path="/" element={<AuthLayout />}>
                        <Route index element={<Login />} />
                        <Route path="/success" element={<LoginSuccess />} />
                        <Route path="*" element={<PageNotFound />} />
                    </Route>
                );
        }
    };

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={3750}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                transition={Slide}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                limit={5}
            />
            <Routes>
                {loading ? (
                    <Route
                        path="/"
                        element={<Loader height="100vh" width="100vw" />}
                    />
                ) : (
                    InitialRoute()
                )}
            </Routes>
        </>
    );
};

export default Initial;
