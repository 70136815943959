import React, { memo, useState } from 'react';
import { Checkbox, Button, Input, Popover } from 'antd';
import { api } from '../../../../../../../Services';

const WebhookApplications = ({ styles, id, dispatch, widget }) => {

    const [type_webhook, set_type_webhook] = useState(
        widget?.webhook?.type_webhook || ''
    );
    const [url_webhook, set_url_webhook] = useState(
        widget?.webhook?.url_webhook || ''
    );

    const [macro_domain, set_macro_domain] = useState(
        widget?.webhook?.macro_domain || ''
    );

    const [macro_app_secret, set_macro_app_secret] = useState(
        widget?.webhook?.macro_app_secret || ''
    );

    const options = [
        {
            label: 'Bitrix',
            value: 'bitrix',
        },
        {
            label: 'Profitbase',
            value: 'profitbase',
        },
        {
            label: 'Macro',
            value: 'macro',
        },
    ];

    const onChange = (checkedValues) => {
        set_type_webhook(checkedValues[0]);
    };

    const saveWebhook = () => {
        dispatch(api.widgets.saveWebhook(id, {
            type_form: 'webhook',
            type_webhook, 
            url_webhook, 
            macro_domain, 
            macro_app_secret
        }));
    };

    return (
        <div className={styles.formWebhook}>
            <Checkbox.Group
                value={type_webhook}
                options={options}
                onChange={onChange}
                className={styles.formItem}
            />
            {
                type_webhook === 'macro' ?
                    <div>
                        <Popover
                            title=""
                            content="Domain (введите домен, зарегистрированный в системе macro)"
                            trigger={['hover', 'focus']}
                            placement="topLeft"
                        >
                            <Input
                                size="large"
                                disabled={!type_webhook}
                                placeholder="Domain (введите домен, зарегистрированный в системе macro)"
                                value={macro_domain}
                                onChange={(e) => set_macro_domain(e.target.value)}
                                className={styles.formItem}
                            />
                        </Popover> 
                        <Popover
                            title=""
                            content="Защищенный ключ (App_secret)"
                            trigger={['hover', 'focus']}
                            placement="topLeft"
                        >
                            <Input.Password
                                size="large"
                                placeholder="Защищенный ключ (App_secret)"
                                value={macro_app_secret}
                                onChange={(e) => set_macro_app_secret(e.target.value)}
                                className={styles.formItem}
                            />
                        </Popover> 
                    </div>
                :
                    <Popover
                        title=""
                        content="Url (webhook)"
                        trigger={['hover', 'focus']}
                        placement="topLeft"
                    >
                        <Input
                            size="large"
                            disabled={!type_webhook}
                            placeholder="Url (webhook)"
                            value={url_webhook}
                            onChange={(e) => set_url_webhook(e.target.value)}
                            className={styles.formItem}
                        />
                    </Popover>
            }
            <Button
                type="primary"
                onClick={saveWebhook}
            >
                Сохранить
            </Button>
        </div>
    )
}

export default memo(WebhookApplications);