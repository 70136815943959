import { produce } from 'immer';
import { message } from 'antd';
import { checkPoints, checkColumnPoints } from './helpers/checkPoints';
import _ from 'underscore';

const initialState = {
    coords: [],
    polygon: '',

    //block: select points
    sync_entrance: '',
    sync_id: '',
    request_status_create_block: false,
    modal_form_block: false,
    modal_form_block_type: '',
    block_title: '',
    block_floors_count: '',
    block_max_num_apartments_floor: '',
    edit_block_id: '',

    //flat: form
    realestate_id: '',
    block_id: '',
    request_status_create_flat: false,
    modal_form_flat: false,
    modal_form_flat_type: '',
    modal_form_create_type_flat: '',
    flat_boxes: [],
    floor_info: null,
    floor_select_box: 0,
    request_floor_status: true,
    floor_plan_id: '',

    //flat plan form
    flat_id: '',
    modal_form_flat_plan: false,
    form_flat_preview: null,
    form_flat_plan: null,
    form_flat_floor: '',
    request_form_flat_plan: false,
    flat_number: '',
    modal_realestate_settings: false,

    //modal form prices
    modal_form_prices: false,
    floor: null,
    request_modal_form_prices: false,

    //files chess
    request_status_upload_file: false,

    select_boxes_of_block: [],
    selected_boxes: 0,

    realestate_templates: [],
    pantry_templates: [],
    realestate_parking_info: null,

    salesReport: null,

    parking_status_req: false,
    parking_information: null

};

const chess = (state = initialState, action) => {
    switch (action.type) {

        case 'REALESTATE_PARKING_DATA':
            return produce(state, draftState => {
                draftState.parking_information = action?.data || null;
            });

        case 'REALESTATE_PARKING_STATUS_REQUEST':
            return produce(state, draftState => {
                draftState.parking_status_req = action?.status || false;
            });

        case 'REALESTATE_MODAL_SETTINGS_CHESS_PARKING':
            return produce(state, draftState => {
                draftState.realestate_parking_info = action?.data || null;
            });

        case 'REALESTATE_MODAL_SETTINGS':
            return produce(state, draftState => {
                draftState.modal_realestate_settings = action?.status || false;
            });

        case 'REALESTATE_TEMPLATE_DATA':
            return produce(state, draftState => {
                draftState.realestate_templates = action?.data;
                draftState.pantry_templates = action?.pantry_templates;
                draftState.realestate_parking_info = action?.parking || null;
                draftState.salesReport = action?.salesReport || null;
            });

        case 'REQUEST_CHESS_MODAL_FORM_PRICES': {
            return produce(state, draftState => {
                draftState.request_modal_form_prices = action?.status || false;
            });
        }

        case 'CHESS_MODAL_FORM_PRICES': {
            return produce(state, draftState => {
                draftState.modal_form_prices = action.status || false;
                draftState.floor = action?.floor || null;
                draftState.block_id = action?.block_id || '';
            });
        }

        case 'SELECT_BOX_BLOCK': {
            return produce(state, draftState => {

                let selectBox = [...state.select_boxes_of_block];
                const checkBox = selectBox.includes(action.flat);
    
                if (checkBox) {
                    selectBox = selectBox.filter((item) => item != action.flat);
                } else {
                    selectBox.push(action.flat);
                }

                draftState.select_boxes_of_block = selectBox;
                draftState.selected_boxes = selectBox.length || 0;
            });

        }

        case 'REQUEST_FLAT_TEMPLATE_PLAN_FORM': {
            return produce(state, draftState => {
                draftState.request_form_flat_plan = action.status || false;
            });
        }

        case 'UPDATE_FLAT_TEMPLATE_PLAN_FORM_MODAL': {
            return produce(state, draftState => {
                draftState.modal_form_flat_plan = action.status || false;
                draftState.flat_id = action.flat_id || '';
                draftState.form_flat_preview = action.form_flat_preview || null;

                draftState.form_flat_plan = action.form_flat_plan || null;
                draftState.form_flat_floor = action.flat_floor || '';
                draftState.realestate_id = action.realestate_id || '';
                draftState.block_id = action.block_id || '';
                draftState.coords = action.coords || [];
                draftState.polygon = action.polygon || '';
                draftState.flat_number = action.flat_number || '';
            });
        }

        case 'UPDATE_FLOOR_PLAN_ID': {
            return produce(state, draftState => {
                draftState.floor_plan_id = action.id;
            });
        }

        case 'TRIGGER_EDIT_BLOCK_FORM': {
            return produce(state, draftState => {
                draftState.sync_entrance = action.sync_entrance;
                draftState.sync_id = action.sync_id;
                draftState.modal_form_block = true;
                draftState.modal_form_block_type = 'edit';
                draftState.block_title = action.title;
                draftState.block_floors_count = action.floors_count;
                draftState.block_max_num_apartments_floor = action.max_num_apartments_floor;
                draftState.coords = action.area_points;
                draftState.polygon = action.area_points_string;
                draftState.edit_block_id = action.edit_block_id;
            });
        }

        case 'SELECT_MIN_FLOOR_BOX_FORM': {
            return produce(state, draftState => {
                draftState.floor_select_box = action.floor;
            });
        }

        case 'UPLOAD_STATUS_FILE_OF_CHESS': {
            return produce(state, draftState => {
                draftState.request_status_upload_file = action.status;
            });
        }

        case 'UPDATE_CHESS_FORM_FLOOR_INFO': {
            return produce(state, draftState => {
                draftState.floor_info = action.data || null;
            });
        }

        case 'STATUS_REQUEST_FLOOR': {
            return produce(state, draftState => {
                draftState.request_floor_status = action.status;
            });
        }

        case 'STATUS_MODAL_FORM_FLAT': {
            return produce(state, draftState => {
                draftState.modal_form_flat = action.status;
                draftState.modal_form_flat_type = action.modal_type || '';
            });
        }

        case 'ADD_PANTRY_FORM_MODAL': {

            return produce(state, draftState => {

                let flat_boxes = [];

                let getSelectedBoxes = draftState.select_boxes_of_block;
    
                if (getSelectedBoxes.length != 1) {
                    message.error('Недопустимый выбор йчеек');
                    return draftState;
                }
    
                getSelectedBoxes.forEach((item) =>
                    flat_boxes.push([Number(item?.floor), Number(item?.box)])
                );

                draftState.modal_form_flat = true;
                draftState.modal_form_flat_type = 'create';
                draftState.realestate_id = action.realestate_id;
                draftState.block_id = action.block_id;
                draftState.flat_boxes = flat_boxes;
                draftState.modal_form_create_type_flat = 'create_pantry';
            });
        }

        case 'ADD_FLATS_FORM_MODAL': {

            return produce(state, draftState => {

                let flat_boxes = [];

                let getSelectedBoxes = draftState.select_boxes_of_block;
    
                if (getSelectedBoxes.length > 0) {
                    const cheskCoords = checkColumnPoints(getSelectedBoxes);
    
                    if (!cheskCoords) {
                        message.error('Недопустимый выбор ячеек');
    
                        return draftState;
                    }
                } else if (getSelectedBoxes.length === 0) {
                    message.error('Выберите ячейки');
                    return draftState;
                }
    
                getSelectedBoxes.forEach((item) =>
                    flat_boxes.push([Number(item?.floor), Number(item?.box)])
                );

                draftState.modal_form_flat = true;
                draftState.modal_form_flat_type = 'create';
                draftState.realestate_id = action.realestate_id;
                draftState.block_id = action.block_id;
                draftState.flat_boxes = flat_boxes;
                draftState.modal_form_create_type_flat = 'create_flats';
            });
        }

        case 'ADD_PENTHOUSE_FORM_MODAL': {

            return produce(state, draftState => {

                let flat_boxes = [];

                let getSelectedBoxes = draftState.select_boxes_of_block;
    
                if (getSelectedBoxes.length > 3) {
                    message.error('Максимально доступное количество ячеек: 3');
    
                    return draftState;
                } else if (
                    getSelectedBoxes.length === 3 ||
                    getSelectedBoxes.length === 2
                ) {
                    const cheskCoords = checkPoints(getSelectedBoxes);
    
                    if (!cheskCoords) {
                        message.error('Недопустимый выбор ячеек');
    
                        return draftState;
                    }
                } else if (getSelectedBoxes.length === 0) {
                    message.error('Выберите ячейки');
                    return draftState;
                }
    
                getSelectedBoxes.forEach((item) =>
                    flat_boxes.push([Number(item?.floor), Number(item?.box)])
                );

                draftState.modal_form_flat = true;
                draftState.modal_form_flat_type = 'create';
                draftState.realestate_id = action.realestate_id;
                draftState.block_id = action.block_id;
                draftState.flat_boxes = flat_boxes;
                draftState.modal_form_create_type_flat = 'create_penthouse';
            });
        }

        case 'CLEAR_SELECT_BLOCK_FORM': {
            return produce(state, draftState => {
                draftState.coords = [];
                draftState.polygon = '';
                draftState.select_boxes_of_block = [];
                draftState.selected_boxes = 0;
                draftState.realestate_templates = [];
            });
        }

        case 'STATUS_MODAL_FORM_BLOCK': {
            return produce(state, draftState => {
                draftState.modal_form_block = action.status;
                draftState.modal_form_block_type = action.modal_type || '';
            });
        }

        case 'STATUS_MODAL_FORM': {
            return produce(state, draftState => {
                draftState.modal_form_block = action.status;
                draftState.modal_form_block_type = action.modal_type || '';
                draftState.modal_form_flat = action.status;
                draftState.modal_form_flat_type = action.modal_type || '';
                draftState.coords = [];
                draftState.polygon = '';
                draftState.sync_entrance = '';
                draftState.sync_id = '';
                draftState.block_title = '';
                draftState.block_floors_count = '';
                draftState.block_max_num_apartments_floor = '';
                draftState.edit_block_id = '';
            });
        }

        case 'REQUEST_STATUS_CREATE': {
            return produce(state, draftState => {
                draftState.request_status_create_block = action.status;
                draftState.request_status_create_flat = action.status;
            });
        }

        case 'BLOCK_CHESS_UPDATE_SYNC_FORM': {
            return produce(state, draftState => {
                if (action.field === 'sync_id') {
                    draftState.sync_id = action.data;
                } else if (action.field === 'sync_entrance') {
                    draftState.sync_entrance = action.data;
                }
            });
        }
        case 'BLOCK_CHESS_DELETE_COORDS': {

            return produce(state, draftState => {

                let coordsArr = draftState.coords;

                let points = coordsArr.filter((_, idx) => {
                    return Number(idx) != Number(action.index);
                });
    
                let areaPointsString = '';
                points.forEach((point) => {
                    areaPointsString =
                        areaPointsString + `${point[0]}% ${point[1]}%,`;
                });
    
                areaPointsString = areaPointsString.substring(
                    0,
                    areaPointsString.length - 1
                );

                draftState.coords = points;
                draftState.polygon = areaPointsString;
            });

        }

        case 'BLOCK_CHESS_ADD_COORDS': {

            return produce(state, draftState => {

                let points = [...draftState.coords, [action.x, action.y]];

                let areaPointsString = '';
                points.map((point) => {
                    areaPointsString =
                        areaPointsString + `${point[0]}% ${point[1]}%,`;
                });
    
                areaPointsString = areaPointsString.substring(
                    0,
                    areaPointsString.length - 1
                );

                draftState.polygon = areaPointsString;
                draftState.coords = points;
            });
        }

        default:
            return state;
    }
};

export default chess;
