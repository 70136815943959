import React, { useEffect, useState, useRef } from 'react';
import { Modal, Form, Button, Tabs, Result } from 'antd';
import ParkingFormItemFelds from './items/fields/';
import styles from '../../../../styles/custom-chess.module.scss';
import { Image } from '@/Components';
import { useSelector, useDispatch } from 'react-redux';
import * as RZSpace from 'react-zoomable-ui';
import PointItem from './helpers/point';
import { useParams } from 'react-router-dom';

const ParkingForm = ({open, close, item, api}) => {

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { id } = useParams();

    const parking_floors = useSelector((state: any) => state?.chess?.parking_information?.realestate?.parking_plans);

    const spaceRef = useRef(null);
    const [area_points, set_area_points] = useState([]);
    const [area_points_for_css, set_area_points_for_css] = useState('');
    const [place_floor, set_place_floor] = useState(null);

    useEffect(()=>{
        form.resetFields();
        set_area_points([]);
        set_area_points_for_css('');

        if(item){

            form.setFieldsValue({
                title: item?.title,
                total_price: item?.total_price,
                place_type: item?.pantry ? 'pantry' : 'parking',
                template: item?.template?._id,
                square_metre_price: item?.square_metre_price || item?.template?.square_metre_price,
                floor: item?.floor,
            });

            set_place_floor(parking_floors.filter(place => String(place?.floor) === String(item?.floor))[0] || 0)
            
            set_area_points(item?.area_points);
            set_area_points_for_css(item?.area_points_for_css);
        }

    }, [open]);

    const onFinish = (values) => {

        let fields = {
            ...values,
            total_price: Number(values?.total_price),
            area_points,
            area_points_for_css,
            edit_id: item?._id,
            realestate_id: id,
            type_form: item ? 'edit' : 'new'
        }

        return dispatch(api.realestate.parkingForm(fields, form.resetFields, close));

    }

    const deletePlace = () => {
        return dispatch(api.realestate.deleteParkingPlace({
            id: item?._id,
            realestate_id: id,
            close
        }));
    }

    const updateCoords = (el, coords) => {

        const getWrapperParkingelement = document.getElementById('wrapper-area-parking');

        const percent_x = ( coords.x / getWrapperParkingelement.clientWidth ) * 100;
        const percent_y = ( coords.y / getWrapperParkingelement.clientHeight ) * 100;

        set_area_points(prevPoints => [...prevPoints, [`${percent_x}`, `${percent_y}`]]);
        set_area_points_for_css(prevCss => {
            return prevCss + (prevCss ? ', ' : '') + `${percent_x}% ${percent_y}%`;
        });

        return null;
    }

    const items = [
        {
            key: 'fields',
            label: `Информация`,
            children: (
                <Form
                    form={form}
                    onFinish={onFinish}
                    autoComplete="off"
                    size='large'
                    layout="vertical"
                >
                    <ParkingFormItemFelds
                        change_place_floor={(info)=>set_place_floor(info)}
                    />

                    <Form.Item style={{marginTop:5}}>
                        <Button type="primary" htmlType="submit">
                            {item ? 'обновить' : 'Добавить' }
                        </Button>
                    </Form.Item>
                </Form>
            ),
        },
        {
            key: 'area_plan',
            label: `Планировка`,
            destroyInactiveTabPane: true,
            disabled: place_floor ? false : true,
            children: (
                <div className={styles.planImageWrapper}>
                    <RZSpace.Space
                        ref={spaceRef}
                        onCreate={vp => {
                            vp.camera.recenter(700, 0, 2);
                        }}
                        //onDecideHowToHandlePress={updateCoords}
                        onContextMenu={updateCoords}
                    >
                        <div className={styles.wrapperImagePoints}>
                            <Image type="PLAN" src={`${place_floor?.plan?.url}`}/>
                            <div 
                                className={styles.overlay}
                                style={{
                                    clipPath: `polygon(${area_points_for_css})`
                                }}
                                id="wrapper-area-parking"
                            ></div>
                            {
                                area_points && area_points.length ?
                                    area_points.map((point, index)=>(
                                        <PointItem 
                                            key={index} 
                                            index={index} 
                                            point={point}
                                            set_area_points={set_area_points}
                                            area_points={area_points}
                                            set_area_points_for_css={set_area_points_for_css}
                                        />
                                    ))
                                :
                                    <></>
                            }
                        </div>
                    </RZSpace.Space>
                </div>
            ),
        },
        {
            key: 'deletePlace',
            label: `Удаление`,
            disabled: item ? false : true,
            children: (
                <Result
                    title="Данные о парковочном месте будут удалены"
                    extra={
                    <Button type="primary" key="console" onClick={deletePlace}>
                        Удалить
                    </Button>
                    }
                />
            ),
        },
    ];

    const zoomPlanCenter = () => {

        setTimeout(()=>{
            const element = document.getElementById('lg-item-point');
            
            if (element) {
              spaceRef.current?.viewPort?.camera.centerFitElementIntoView(
                element,
                {
                  elementExtraMarginForZoomInClientSpace: 10000,
                },
                {
                  durationMilliseconds: 1000,
                },
              );
            }
        }, 500)

    }

    return (
        <Modal 
            title="Форма паркинга" 
            open={open}
            onCancel={()=>{
                set_place_floor(null);
                close();
            }}
            footer={null}
            width={"75%"}
            destroyOnClose={true}
        >
            <Tabs
                onChange={(key)=>{
                    if(key === 'area_plan'){
                        zoomPlanCenter();
                    }
                }}
                defaultActiveKey="form"
                items={items}
            />

        </Modal>

    )
}

export default ParkingForm;                    