import react, { useState, useEffect } from 'react';
import { Modal, Input } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

const FormStatuses = ({
    isModalOpen,
    close,
    createStatus,
    editStatus,
}: {
    isModalOpen: boolean;
    close: () => void;
    createStatus: any;
    editStatus: any;
}) => {
    const itemStatus = useSelector(
        (state: any) => state?.companies?.edit_status
    );
    const dispatch = useDispatch();

    const [title, set_title] = useState('');
    const [color, set_color] = useState('');

    useEffect(() => {
        if (isModalOpen) {
            set_title('');
        }
    }, [isModalOpen]);

    useEffect(() => {
        if (itemStatus) {
            set_title(itemStatus?.title);
            set_color(itemStatus?.color);
        } else {
            set_title('');
            set_color('');
        }
    }, [itemStatus]);

    return (
        <>
            <Modal
                width="65%"
                title={
                    itemStatus ? 'Редактирование cтатуса' : 'Создание статуса'
                }
                onCancel={() => {
                    close();
                    dispatch({
                        type: 'MODAL_EDIT_FORM_COMPANY',
                        item: null,
                    });
                }}
                open={isModalOpen}
                onOk={() => {
                    if (itemStatus) {
                        editStatus({
                            variables: {
                                title,
                                color,
                                id: itemStatus?._id,
                            },
                        });
                    } else {
                        createStatus({
                            variables: {
                                title,
                                color,
                            },
                        });
                    }
                }}
                okText={itemStatus ? 'Редактировать' : 'Создать'}
            >
                <Input
                    size="large"
                    value={title}
                    placeholder="Наименование статуса"
                    onChange={(value: any) => set_title(value.target.value)}
                    style={{ marginBottom: 10 }}
                />

                <Input
                    size="large"
                    type="color"
                    value={color}
                    placeholder="Цвет статуса"
                    onChange={(value: any) => set_color(value.target.value)}
                    style={{ marginBottom: 10 }}
                />
            </Modal>
        </>
    );
};

export default FormStatuses;
