import react from 'react';
import styles from './styles/filter.module.scss';
import { Input, Segmented, Popover, Tag } from 'antd';
import { useSelector } from 'react-redux';

const Filter = ({ commission, search_text, count }: any) => {
    const userRole = useSelector(
        (state: any) => state?.account?.user?.role?.type
    );

    return (
        <div className={styles.filter}>
            <div className={styles.header}>
                <p>Поиск</p>
            </div>
            <div className={styles.inputs}>
                <Input
                    onChange={(value) => search_text(value.target.value || '')}
                    size="large"
                    placeholder="Введите наименование жилого комплекса"
                />
                {userRole != 'BUILDER' ? (
                    <Popover
                        placement="bottom"
                        title=""
                        content="Процент с продажи"
                    >
                        <Segmented
                            onChange={(value: string | number) =>
                                commission(value || '')
                            }
                            size="large"
                            options={['Все', '0.5%', '1%', '1.5%']}
                        />
                    </Popover>
                ) : (
                    <></>
                )}
            </div>
            <div className={styles.info}>
                <Tag color="lime">Найдено: {count}</Tag>
            </div>
        </div>
    );
};

export default Filter;
