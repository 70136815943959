import React, { useState, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Popover, Popconfirm, Button } from 'antd';
import { PricingPlans, Image } from '@/Components';
import styles from './styles/realEstateCard.module.scss';
import { RealEstateType } from './interfaces';
import { useDispatch, useSelector } from 'react-redux';
import Config from '@/Config';
import { separateNumber, api } from '@/Services';
import { useApolloClient } from '@apollo/client';
import { DeleteOutlined } from '@ant-design/icons';

const RealEstateCard: FC<{
    realEstate: RealEstateType;
    isBuilder?: boolean
}> = ({ realEstate, isBuilder = false }: any) => {
    const navigate = useNavigate();
    const stateUser: any = useSelector((state: any) => state.account.user);
    const dispatch = useDispatch();

    const client = useApolloClient();

    const {
        _id,
        count_booking,
        signed_by_realtor,
    } = realEstate;

    let photo: string = `${Config.public}${realEstate?.photo?.url}`;
    let logoBuilder: string = `${Config.public}${realEstate?.builder?.logo?.url}`;

    const [modalForm, setModalForm] = useState(false);

    const [status_pricing_plans, set_status_pricing_plans] =
        useState<boolean>(false);

    const openRealestate = () => {
        let subType = stateUser?.subscription?.rate?.title;

        if (subType === 'Начальный' && realEstate?.commission > 0.7) {
            return set_status_pricing_plans(true);
        } else if (subType === 'Стандарт' && realEstate?.commission > 1.2) {
            return set_status_pricing_plans(true);
        } else if (
            (subType === 'Профи' || subType === 'Компания') &&
            realEstate?.commission > 1.5
        ) {
            return set_status_pricing_plans(true);
        }

        dispatch({
            type: 'UPDATE_REALESTATE_ID',
            id: _id,
        });

        return navigate(`/real-estate/${_id}`);
    };

    const deleteRealestate = async () => {
        dispatch(api.realestate.delete(_id));
        return client.reFetchObservableQueries();
    }

    return (
        <div className={styles.cardWrapper}>

            <div className={styles.cardOverlay}>
                <div className={styles.cardOverlayMenu}>
                    <span
                        onClick={openRealestate}
                    >
                        <p>Шахматка</p>
                    </span>
                    <span
                        onClick={()=>navigate(`/realestate/apartment-sheets/form?realestate_id=${_id}`)}
                    >
                        <p>Квартирный лист</p>
                    </span>
                    <span
                        onClick={()=>navigate(`/realestate/templates/${_id}`)}
                    >
                        <p>Шаблоны квартир</p>
                    </span>
                    <span onClick={()=>navigate(`/realestate/form-payments?realestate_id=${_id}`)}>
                        <p>Формы оплаты</p>
                    </span>
                    <span
                        onClick={()=>navigate(`/realestate/form?realestate_id=${_id}`)}
                    >
                        <p>Редактировать</p>
                    </span>
                </div>
                <div className={styles.cardOverlayFooter}>
                    <Popconfirm
                        title="Жк, блоки, квартиры будут удалены!"
                        onConfirm={deleteRealestate}
                        okText="Удалить"
                        cancelText="Не удалять"
                    >
                        <Button danger size='small' type='primary'>
                            <DeleteOutlined />
                        </Button>
                    </Popconfirm>
                </div>
            </div>

            <PricingPlans
                status={status_pricing_plans}
                close={() => set_status_pricing_plans(false)}
            />

            <div
                onClick={() => setModalForm(!modalForm)}
                className={`${styles.modalOverlay} ${
                    modalForm ? styles.modalOverlayOpened : ''
                }`}
            />

            <article
                className={`${styles.realEstateItem} ${
                    modalForm ? styles.realEstateItemZIndex : ''
                }`}
            >
                <div className={styles.realEstateHeader}>
                    <div className={styles.realEstateHeaderLeft}>
                        <img src={logoBuilder} />
                    </div>
                    <div className={styles.realEstateHeaderRight}>
                        <h3>{realEstate?.title}</h3>
                    </div>
                </div>

                <div className={styles.imageItem}>
                    <Image type="REALESTATE" height="170px" src={photo} />
                    {!isBuilder ? (
                        <Popover title="" content={'Процент от продажи'}>
                            <h2 className={styles.realEstateTitle}>
                                {realEstate?.commission}%
                            </h2>
                        </Popover>
                    ) : (
                        <></>
                    )}
                </div>

                {
                    !!(realEstate?.price_information) && (
                        <div className={styles.prices}>
                        <h3>
                            от{' '}
                            {separateNumber(
                                Number(realEstate?.min_price_square_metres)
                            )}{' '}
                            ₸/м²
                        </h3>
                        <div className={styles.listRoomPrices}>
                            <div className={styles.listRoomPriceItem}>
                                <span>
                                    1-комн. -{' '}
                                    {
                                        realEstate?.price_information?.single_room
                                            ?.min?.area
                                    }
                                </span>
                                <span className={styles.spanDots}></span>
                                <span>
                                    от{' '}
                                    {separateNumber(
                                        Number(
                                            realEstate?.price_information
                                                ?.single_room?.min?.price
                                        )
                                    )}{' '}
                                    ₸/м²
                                </span>
                            </div>
                            <div className={styles.listRoomPriceItem}>
                                <span>
                                    2-комн. -{' '}
                                    {
                                        realEstate?.price_information?.two_room?.min
                                            ?.area
                                    }
                                </span>
                                <span className={styles.spanDots}></span>
                                <span>
                                    от{' '}
                                    {separateNumber(
                                        Number(
                                            realEstate?.price_information?.two_room
                                                ?.min?.price
                                        )
                                    )}{' '}
                                    ₸/м²
                                </span>
                            </div>
                            <div className={styles.listRoomPriceItem}>
                                <span>
                                    3-комн. -{' '}
                                    {
                                        realEstate?.price_information?.three_room
                                            ?.min?.area
                                    }
                                </span>
                                <span className={styles.spanDots}></span>
                                <span>
                                    от{' '}
                                    {separateNumber(
                                        Number(
                                            realEstate?.price_information
                                                ?.three_room?.min?.price
                                        )
                                    )}{' '}
                                    ₸/м²
                                </span>
                            </div>
                            <div className={styles.listRoomPriceItem}>
                                <span>
                                    4-комн. -{' '}
                                    {
                                        realEstate?.price_information?.four_room
                                            ?.min?.area
                                    }
                                </span>
                                <span className={styles.spanDots}></span>
                                <span>
                                    от{' '}
                                    {separateNumber(
                                        Number(
                                            realEstate?.price_information?.four_room
                                                ?.min?.price
                                        )
                                    )}{' '}
                                    ₸/м²
                                </span>
                            </div>
                        </div>
                    </div>
                    )
                }

                <div className={styles.realEstateItemOverlay}>
                    {isBuilder &&
                        (realEstate.signed_by_realtor ||
                            (Number.isInteger(realEstate.signed_by_realtor) &&
                                (count_booking ||
                                    Number.isInteger(count_booking)) && (
                                    <div className={styles.bookingInfo}>
                                        <span>
                                            {count_booking}{' '}
                                            {count_booking === 1
                                                ? 'бронь'
                                                : count_booking !== 0 &&
                                                  count_booking! < 4
                                                ? 'брони'
                                                : 'броней'}
                                        </span>
                                        <span>
                                            {signed_by_realtor}{' '}
                                            {signed_by_realtor === 1
                                                ? 'подпись'
                                                : signed_by_realtor !== 0 &&
                                                  signed_by_realtor! < 5
                                                ? 'подписи'
                                                : 'подписей	'}
                                        </span>
                                    </div>
                                )))}
                </div>
            </article>
        </div>
    );
};

export default RealEstateCard;
