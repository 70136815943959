import React, { useState, useEffect } from 'react';
import styles from './styles/finance.module.scss';
import { Tabs } from 'antd';
import List from './helpers/list';
import { useLazyQuery } from '@apollo/client';
import * as gqls from '@/Services/apollo/gqls';

const Finance = () => {
    const [type_tab, set_type_tab] = useState('orders');

    const [loadTransactions, { data, loading }] = useLazyQuery(
        gqls.builder.getTransactions,
        {
            fetchPolicy: 'network-only',
        }
    );

    useEffect(() => {
        loadTransactions({
            variables: {
                type: type_tab,
            },
        });
    }, [type_tab]);

    return (
        <section className={styles.settingsWrapper}>
            <h2 className={styles.settingsTitle}>Финансы</h2>

            <Tabs
                activeKey={type_tab}
                defaultActiveKey="orders"
                onTabClick={(key: any) => {
                    set_type_tab(key);
                }}
            >
                <Tabs.TabPane tab="Заказы" key="orders">
                    <List
                        loading={loading}
                        items={data?.getTransactions || []}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab="История транзакций"
                    key="history_transaction"
                >
                    <List
                        loading={loading}
                        items={data?.getTransactions || []}
                    />
                </Tabs.TabPane>
            </Tabs>
        </section>
    );
};

export default Finance;
