import React, { useState } from 'react';
import { Form, Upload, Space, Typography, Modal, message, Button } from 'antd';
import { InboxOutlined, CopyOutlined } from '@ant-design/icons';
import { Image } from '@/Components';
import { api } from '@/Services';
import { useDispatch } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const { Dragger } = Upload;
const { Text } = Typography;

const DynamicKeys = () => {
    const dispatch = useDispatch();

    const form = Form.useFormInstance();
    const fields =
        Form.useWatch('fields', form) || form.getFieldValue('fields');

    const [preview_image, set_preview_image] = useState(null);

    const handlePreview = (file) => {
        set_preview_image(file?.response?.file?.info || file);
    };

    const removeFile = (data) => {
        dispatch(
            api.files.removeFile(data?.response?.file?.info?.id || data?.uid)
        );
        return true;
    };

    const getValueFromEvent = (info) => {
        let data = [];

        if (info?.fileList && info?.fileList.length) {
            info?.fileList.forEach((item) => {
                if (item?.status === 'done') {
                    data.push({
                        uid: item?.response?.file?.name || item?.name,
                        name: item?.response?.file?.name || item?.name,
                        status: 'done',
                        url: item?.response?.file?.info?.full_url || item?.url,
                    });
                }
            });
        }

        return data;
    };

    const customRequest = async ({ file, onSuccess, data }) => {
        const getFile: any = await dispatch(
            api.files.uploadFile(file, data?.uploadType)
        );

        let response = {};

        response = {
            success: true,
            file: {
                name: `mediafile_${getFile?.id}`,
                size: file.size,
                type: file.type,
                info: getFile,
            },
        };

        onSuccess(response);
    };

    return (
        <>
            <Form.Item
                name="fields"
                label=""
                getValueFromEvent={getValueFromEvent}
            >
                <Dragger
                    accept="image/png, image/jpeg"
                    name="file"
                    headers={{
                        authorization: localStorage.getItem('SESSION_TOKEN'),
                    }}
                    customRequest={({ file, onSuccess, data }) =>
                        customRequest({ file, onSuccess, data })
                    }
                    onPreview={handlePreview}
                    onRemove={removeFile}
                    defaultFileList={fields}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <Space direction="vertical">
                        <Text>Выберите или перетащите изображение</Text>
                        <Text type="secondary">
                            Доступны изображения формата png, jpeg
                        </Text>
                    </Space>
                </Dragger>
            </Form.Item>

            <Modal
                open={preview_image ? true : false}
                title={`Просмотр изображения`}
                footer={null}
                onCancel={() => set_preview_image(null)}
                centered
                width="40%"
                destroyOnClose
            >
                <Image src={preview_image?.full_url || preview_image?.url} />
                <CopyToClipboard
                    onCopy={() => message.success('Код скопирован')}
                    text={`<%- ${
                        preview_image?.name
                            ? preview_image?.name
                            : `mediafile_${preview_image?.id}`
                    } || '' %>`}
                    options={{ format: 'text/plain' }}
                >
                    <Button
                        style={{ marginTop: 10 }}
                        size="small"
                        icon={<CopyOutlined />}
                    >
                        Скопировать код
                    </Button>
                </CopyToClipboard>
            </Modal>
        </>
    );
};

export default DynamicKeys;
