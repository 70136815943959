import React, { useEffect } from 'react';
import { Modal, Form, Tooltip, Input, Button } from 'antd';
import { useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import styles from '../../../styles/settings.module.scss';

const NoteForm = ({
    status,
    dispatch,
    api,
    note,
    close
}) => {

    const [form] = Form.useForm();
    const { request } = useSelector((state: any) => state?.account);

    useEffect(()=>{
        form.setFieldsValue({
            note: note?.note || '',
        });
    }, [note]);

    const onFinish = (values) => {
        if(!request){
            console.log('values => ', values)
            dispatch(api.account.updateNote({
                ...values,
                note_id: note?._id
            }, close));
        }
    }

    return (
        <Modal
            open={status}
            title={note?._id ? 'Форма редактирования заметки' : 'Форма добавления заметки'}
            footer={null} 
            onCancel={close}
            destroyOnClose={true}
            width="65%"
            centered
        >
            <Form
                form={form}
                name="basic"
                onFinish={onFinish}
                autoComplete="off"
                size="large"
            >
                <Tooltip title="Заметка">
                    <Form.Item
                        name="note"
                        rules={[
                            {
                                required: true,
                                message: 'Пожалуйста, заполните поле',
                            },
                        ]}
                        className={styles.itemForm}
                    >
                        <Input.TextArea placeholder="Заметка" />
                    </Form.Item>
                </Tooltip>

                <Form.Item
                    className={styles.itemForm}    
                >
                    <Button size="middle" type="primary" htmlType="submit">
                        {request ? <LoadingOutlined /> : note?._id ? 'обновить информацию' : 'Добавить'}
                    </Button>
                </Form.Item>

            </Form>
        </Modal>
    )
}

export default NoteForm;