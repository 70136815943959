import React from 'react';
import styles from '../../styles/catalog.module.scss';
import { Form, Button, Select, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';

const FilterCatalog = ({api, realestate_id}) => {

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const {request_status_flats_data_search, flats_data_search} = useSelector((state: any) => state?.realestate);

    const onFinish = (values) => {

        if(!request_status_flats_data_search){
            return dispatch(api.realestate.searchFlats({
                ...values,
                count_rooms: values?.count_rooms ? JSON.stringify(values?.count_rooms) : '',
                realestate: JSON.stringify([realestate_id]),
                limit: 150
            }));
        }

    }

    return (
        <Form
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
        >
            <div className={styles.formItems}>
                <Form.Item name="count_rooms" label="Выберите количество комнат">
                    <Select
                        placeholder='Выберите количестиво комнат'
                        mode="tags"
                        options={[{
                            value: 1,
                            label: '1-комнатные'
                        },{
                            value: 2,
                            label: '2-комнатные'
                        },{
                            value: 3,
                            label: '3-комнатные'
                        },{
                            value: 4,
                            label: '4-комнатные'
                        },{
                            value: 5,
                            label: '5-комнатные'
                        }]}
                    />
                </Form.Item>

                <Form.Item name="text_search" label="Номер квартиры">
                    <Input placeholder="Номер квартиры" />
                </Form.Item>

            </div>
            <Form.Item style={{marginTop:5}}>
                <Button type="primary" htmlType="submit">
                    {
                        request_status_flats_data_search ?
                            <LoadingOutlined />
                        : 'Искать'
                    }
                </Button>
            </Form.Item>
        </Form>
    )
}

export default FilterCatalog;