import React, { useRef, useEffect } from 'react';
import { Typography, Form, Button } from 'antd';
import styles from '../styles/apartment-sheets.module.scss';
import { useLocation } from 'react-router-dom';
import ApartmentheetFormItems from './items';
import { api } from '@/Services';
import { useDispatch, useSelector } from 'react-redux';

const { Title } = Typography;

const ApartmentSheetForm = () => {

	const {
		apartment_sheets_form_request,
		apartment_sheets_form_data
	} = useSelector((state: any) => state?.realestate);

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const editorRef = useRef(null);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const realestate_id = searchParams.get('realestate_id');

    useEffect(()=>{

        dispatch(api.realestate.ApartmentSheetsFormInformation(realestate_id));

    }, []);

    useEffect(()=>{

        if(apartment_sheets_form_data){
            form.setFieldsValue({
                title: apartment_sheets_form_data?.apartmentSheet?.title,
                content_html: apartment_sheets_form_data?.apartmentSheet?.content_html || '',
                fields: apartment_sheets_form_data?.apartmentSheet?.fields || [],
            });
        }

    }, [apartment_sheets_form_data]);

    const onFinish = (values) => {

        let fields = {
            ...values,
            realestate_id,
            apartment_sheet_id: apartment_sheets_form_data?.apartmentSheet?._id,
            content_html: editorRef?.current.innerHTML
        }

        dispatch(api.realestate.ApartmentSheetsForm(fields));

    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <Title level={4}>Форма квартирного листа</Title>
            </div>
            <div>
                <Form
                    form={form}
                    onFinish={onFinish}
                    autoComplete="off"
                    size='large'
                    layout="vertical"
                    disabled={apartment_sheets_form_request}
                >
                    <ApartmentheetFormItems editorRef={editorRef}/>

                    <Form.Item style={{marginTop:5}}>
                        <Button type="primary" htmlType="submit">
                            Редактировать информацию
                        </Button>
                    </Form.Item>

                </Form>
            </div>
        </div>
    )
}

export default ApartmentSheetForm;