import React, { useState } from 'react';
import { Select } from 'antd';
import { useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';

const FormFloors = ({
    styles,
    opFloor
}) => {

    const { search_data_blocks, request_search_data } = useSelector((state: any) => state?.payments);

    const [count_floors, set_count_floors] = useState(0);
    const [block, set_block] = useState(null);

    const generateFloors = () => {

        let options = [];

        for(let i = 1; i <= count_floors; i++){
            options.push({
                label: i,
                value: i
            })
        }

        return options;

    }

    return (
        <>
            <div className={styles.blockSearch} style={{padding: 10}}>

                <div className={styles.searchResultBlock}>

                    {
                        request_search_data ?
                            <LoadingOutlined />
                        : 
                        search_data_blocks && search_data_blocks.length ?
                            <Select
                                size='large'
                                options={search_data_blocks.map((item)=>({
                                    value: item?._id,
                                    label: `ЖК ${item?.complex_id?.title}, блок ${item?.title}`
                                }))}
                                loading={request_search_data}
                                onChange={(item)=>{
                                    
                                    const getBlock = search_data_blocks.filter((block)=>String(item) === String(block?._id));
                                    if(getBlock.length){
                                        set_block(getBlock[0]);
                                        set_count_floors(Number(getBlock[0]?.floors_count || 0));
                                    }

                                }}
                                style={{width:'100%'}}
                                placeholder="Выберите блок"
                            />
                        :
                            <></>
                    }

                </div>

                <div className={styles.searchResultBlock}>

                    {
                        count_floors && count_floors > 0 ?
                            <Select
                                size='large'
                                options={generateFloors()}
                                style={{width:'100%', marginTop: 10}}
                                placeholder="Выберите этаж"
                                onChange={(item)=>opFloor({
                                    block: block,
                                    floor: Number(item)
                                }, 'add')}
                            />
                        :
                            <></>
                    }

                </div>

            </div>
        </>
    )

}

export default FormFloors;