import React, { FC, useContext } from 'react';
import styles from '../styles/chess.module.scss';
import { BookingContext } from '@/Services/context/booking';

import { role } from '@/enums/roles';
import { bookingStatus } from '@/enums/bookingStatus';
import { useDispatch } from 'react-redux';

export const FlatItem: FC<{
    flat: any;
    managers?: any[];
    account: any | null;
    realEstateId: string;
}> = ({ flat, realEstateId, managers, account }) => {
    const dispatch = useDispatch();

    const delayAnimation = Math.round(Math.random() * 2) + 1;

    const { booking, setBooking } = useContext(BookingContext);

    const { _id, sold, status, title, secondary } = flat;

    const flatStatus = () => {
        if(status === 'BOOKING'){
            return 'BOOKING';
        } else if(sold || status === 'SOLD'){
            return 'SOLD';
        } else {
            return 'FREE';
        }
    }

    const isBuilder = account?.role?.type === role.builder;
    const isRealtor = account?.role?.type === role.realtor;
    const isManager = account?.role?.type === role.manager;

    const isBookingWaiting = flat?.booking === bookingStatus.waiting;
    const isBookingSuccess = flat?.booking === bookingStatus.success;
    const isBookingCanceled = flat?.booking === bookingStatus.canceled;

    const isSoldAndCanceledRealtorType =
        sold &&
        flat?.booking &&
        isBookingCanceled &&
        flat?.booking_notification === role.realtor;
    const isNotSoldAndSuccessRealtorType =
        !sold &&
        flat?.booking &&
        isBookingSuccess &&
        flat?.booking_notification === role.realtor;

    const isSecondary = secondary && !sold;

    return (
        <button
            type="button"
            className={`${styles.columnGridFlat} ${
                flatStatus() === 'BOOKING' ? styles.flatBooking : flatStatus() === 'SOLD' ? styles.flatSold : styles.flatNotSold
            } ${(isRealtor || isManager) && sold ? styles.bookingPopup : ''} ${
                (isRealtor || isManager) && isBookingWaiting
                    ? styles.bookingStyle
                    : ''
            } ${isBuilder && isBookingSuccess ? styles.bookingStyle : ''} ${
                isBuilder && !sold && isBookingWaiting
                    ? styles.flatAnimation
                    : ''
            } ${
                (isRealtor || isManager) && isSoldAndCanceledRealtorType
                    ? styles.flatAnimation
                    : ''
            } ${
                (isRealtor || isManager) && isNotSoldAndSuccessRealtorType
                    ? styles.flatAnimationRealtor
                    : ''
            } ${isSecondary ? styles.flatSecondary : {}}`}
            key={_id}
            style={{
                animationDuration: delayAnimation + 's',
            }}
            onClick={() => {
                dispatch({
                    type: 'UPDATE_FLAT',
                    flat,
                });
                setBooking({
                    ...booking,
                    flatSidebar: {
                        flat,
                        managers,
                        realEstateId,
                    },
                    accountRole: account.role.type || null,
                });
            }}
        >
            <h5>{title}</h5>
        </button>
    );
};
