import React, { useState } from 'react';
import { Form, Typography, Upload, Space, Modal } from 'antd';
import styles from '../../../../../../styles/home.module.scss';
import { InboxOutlined } from '@ant-design/icons';
import { api } from '@/Services';
import { useDispatch } from 'react-redux';
import { Image } from '@/Components';

const { Text } = Typography;
const { Dragger } = Upload;

const FormItemPlan = () => {

    const dispatch = useDispatch();
    const form = Form.useFormInstance();
    const plan_photo = Form.useWatch('plan_photo', form) || form.getFieldValue('plan_photo');

    const [preview_image, set_preview_image] = useState(null);

    const customRequest = async ({ file, onSuccess, data }) => {

        const getFile: any = await dispatch(api.files.uploadFile(file, data?.uploadType));

        let response = {};

        response = {
            success: true,
            file: {
                name: file.name,
                size: file.size,
                type: file.type,
                info: getFile
            },
        };

        onSuccess(response);
    };

    const getValueFromEvent = (info) => {

        let data = [];

        if(info?.fileList && info?.fileList.length){
            info?.fileList.forEach((item)=>{
                if(item?.status === 'done'){

                    data.push({
                        uid: item?.response?.file?.info?.id || item?.uid,
                        name: item?.response?.file?.info?.url || item?.uid,
                        status: 'done',
                        url: item?.response?.file?.info?.full_url || item?.url,
                    });
                }
            })
        }

        return data;
    }

    const handlePreview = (file) => {
        set_preview_image(file?.response?.file?.info?.full_url || file?.url);
    };

    const removeFile = (data) => {
        dispatch(api.files.removeFile(data?.response?.file?.info?.id || data?.uid));
        return true;
    }

    return (
        <>
            <div className={styles.sectionForm}>

                <Text type="secondary">Планировка квартиры</Text>
    
                <Form.Item
                    name="plan_photo"
                    label=""
                    rules={[
                        {
                            required: true,
                            message: 'Поле обязательно для заполнения',
                        },
                    ]}
                    getValueFromEvent={getValueFromEvent}
                >
                    <Dragger
                        maxCount={1}
                        accept="image/png, image/jpeg"
                        name='file'
                        headers={{
                            authorization: localStorage.getItem('SESSION_TOKEN'),
                        }}
                        defaultFileList={plan_photo}
                        customRequest={({ file, onSuccess, data }) => customRequest({ file, onSuccess, data })}
                        listType="picture-card"
                        onPreview={handlePreview}
                        onRemove={removeFile}
                    > 
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>    
                        <Space direction="vertical">          
                            <Text>Выберите или перетащите изображение</Text>
                            <Text type="secondary">Доступны изображения формата png, jpeg</Text>
                        </Space>   
                    </Dragger>
                </Form.Item>
    
            </div>

            <Modal 
                open={preview_image ? true : false} 
                title="Просмотр изображения" 
                footer={null} 
                onCancel={()=>set_preview_image(null)}
                centered
                width="40%"
                destroyOnClose
            >
                <Image
                    src={preview_image}
                />
            </Modal>
        </>
    )
}

export default FormItemPlan;