
import React, { useEffect } from 'react';
import { Drawer, Input, Form, Select, Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { LoadingOutlined, MinusOutlined } from '@ant-design/icons';
import styles from '../../styles/settings.module.scss';

const PaymentMethodForm = ({status = false, close, paymentMethod, api}) => {

    const [form] = Form.useForm();

    const typeFormPayment = Form.useWatch('type_payment_method', { form, preserve: true });

    const dispatch = useDispatch();
    const { request_form_payment_methods } = useSelector((state: any) => state?.contracts);

    useEffect(()=>{

        if(paymentMethod){

            let integration_information: any = {};

            if(paymentMethod?.type_payment_method === 'KASPI'){
                integration_information.page_payment_url = paymentMethod?.integration_information?.page_payment_url;
            }

            form.setFieldsValue({
                title: paymentMethod?.title,
                rates: paymentMethod?.rates,
                type_payment_method: paymentMethod?.type_payment_method,
                ...integration_information
            });

        }else{
            form.resetFields();
        }

    }, [paymentMethod]);

    const onFinish = (values) => {
        if(!request_form_payment_methods){
            console.log('values => ', values)
            dispatch(api.account.updatePaymentMethod({
                ...values,
                payment_method_id: paymentMethod?._id
            }, close, form.resetFields));
        }
    }

    return (
        <Drawer
            open={status}
            title={paymentMethod?._id ? 'Форма редактирования способа оплаты' : 'Форма добавления способа оплаты'}
            footer={null} 
            onClose={close}
            destroyOnClose={true}
            width={700}
            placement="right"
        >
            <Form
                name="basic"
                form={form}
                onFinish={onFinish}
                autoComplete="off"
                size="large"
            >

                <Form.Item
                    name="type_payment_method"
                    rules={[
                        {
                        required: true,
                        message: 'Пожалуйста, заполните поле',
                        },
                    ]}
                    className={styles.item}
                    style={{
                        marginBottom: 10
                    }}
                >
                    <Select
                        disabled={paymentMethod?._id ? true : false}
                        showSearch
                        placeholder="Выберите банк"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={[
                        {
                            value: 'KASPI',
                            label: 'Каспи банк',
                        }
                        ]}
                    />
                </Form.Item>

                <Form.Item
                    name="title"
                    rules={[
                        {
                        required: true,
                        message: 'Пожалуйста, заполните поле',
                        },
                    ]}
                    className={styles.item}
                    style={{
                        marginBottom: 10
                    }}
                >
                    <Input placeholder='Введите наименование тарифа'/>
                </Form.Item>

                {
                    typeFormPayment === 'KASPI' ?
                    <Form.Item
                        name="page_payment_url"
                        rules={[
                            {
                            required: true,
                            message: 'Пожалуйста, заполните поле',
                            },
                        ]}
                        className={styles.item}
                        style={{
                            marginBottom: 10
                        }}
                    >
                        <Input placeholder='Введите url страница оплаты'/>
                    </Form.Item>
                    :
                        <></>
                }

                <Form.List
                    name="rates"
                >
                    {
                        (fields, { add, remove }) => (
                            <>
                                {
                                    fields && fields.length ?
                                    <div className={styles.listItemsList}>
                                        <div className={styles.listItemsListWrapper}>
                                            {
                                                fields.map((item)=>(

                                                    <div key={item?.key} className={styles.item}>
                                                        <Form.Item
                                                            name={[item.name, 'amount']}
                                                            rules={[
                                                                {
                                                                required: true,
                                                                message: 'Пожалуйста, заполните поле',
                                                                },
                                                            ]}
                                                            className={styles.itemInput}
                                                        >
                                                            <Input addonAfter="Тенге" placeholder='Введите сумму оплаты'/>
                                                        </Form.Item>
                                                        <Form.Item
                                                            name={[item.name, 'days']}
                                                            rules={[
                                                                {
                                                                required: true,
                                                                message: 'Пожалуйста, заполните поле',
                                                                },
                                                            ]}
                                                            className={styles.itemInput}
                                                        >
                                                            <Input addonAfter="Дней" placeholder='Укажите количество дней'/>
                                                        </Form.Item>
                                                        <div>
                                                            <Button danger onClick={() => remove(item.name)} size="small" type="primary" key="console"><MinusOutlined /></Button>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <Button 
                                            style={{marginTop:10}} 
                                            onClick={() => add()} 
                                            size="small" 
                                            type="primary" 
                                            key="console"
                                        >
                                            Добавить тариф
                                        </Button>
                                    </div>
                                    :
                                    <div className={styles.wrapperCentered}>
                                        <Button style={{width: '100%'}} onClick={() => add()} type="primary" key="console">
                                            Добавить тариф
                                        </Button>
                                    </div>
                                }
                            </>
                        )
                    }
                </Form.List>


                <Form.Item
                    className={styles.itemForm}    
                >
                    <Button size="middle" type="primary" htmlType="submit">
                        {request_form_payment_methods ? <LoadingOutlined /> : paymentMethod?._id ? 'обновить информацию' : 'Добавить'}
                    </Button>
                </Form.Item>

            </Form>
        </Drawer>
    )
}

export default PaymentMethodForm;