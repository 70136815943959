import React, { useRef, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Result, Button } from 'antd';
import styles from '../../styles/custom-chess.module.scss';
import config from '@/Config';
import PointItem from './point-item';
import { UploadOutlined, LoadingOutlined } from '@ant-design/icons';
import { calcCoords, updatePolygon, deleteCoord } from '../funcs/calc-coords';
import { minFloor } from '../funcs/min-floor';

const SelectFlat = ({ api, saveCoords }) => {
    const dispatch = useDispatch();
    const blockRef = useRef(null);

    const {
        coords,
        polygon,
        realestate_id,
        block_id,
        floor_select_box,
        form_flat_floor,
        floor_info,
        flat_boxes,
        flat_id,
        request_status_upload_file,
        modal_form_flat_plan,
        request_form_flat_plan,
        request_floor_status,
    } = useSelector((state) => state?.chess);

    const [state_coords, set_state_coords] = useState(coords || []);
    const [state_polygon, set_state_polygon] = useState(polygon || '');

    useEffect(() => {
        if (flat_boxes) {
            let getMinFloor = minFloor(flat_boxes || []);
            if (getMinFloor > 0) {
                dispatch(
                    api.chess.getFloorInfo(getMinFloor, realestate_id, block_id)
                );
            }
        }
    }, [flat_boxes]);

    useEffect(() => {
        if (form_flat_floor && Number(form_flat_floor) > 0) {
            dispatch(
                api.chess.getFloorInfo(form_flat_floor, realestate_id?._id, block_id?._id)
            );
        }
    }, [form_flat_floor]);

    useEffect(() => {
        if (saveCoords) {
            saveCoords(state_coords, state_polygon);
        }
    }, [state_coords, state_polygon]);

    useEffect(() => {
        const mousedownPoint = (event) => {
            let { points, areaPointsString } = calcCoords(
                event.layerX,
                event.layerY,
                blockRef.current.clientHeight,
                blockRef.current.clientWidth,
                state_coords
            );

            set_state_coords(points);
            set_state_polygon(areaPointsString);
        };

        if (blockRef?.current) {
            blockRef.current.addEventListener('click', mousedownPoint);
            return () => {
                blockRef?.current?.removeEventListener('click', mousedownPoint);
            };
        }
    }, [blockRef?.current, floor_info, state_coords]);

    const uploadPlanFloor = (e) => {
        dispatch(
            api.chess.uploadFile(
                e.target.files[0],
                realestate_id,
                block_id,
                floor_select_box || form_flat_floor
            )
        );
    };

    const updateMovePoint = (points) => {
        let areaPointsString = updatePolygon(points);

        set_state_coords(points);
        set_state_polygon(areaPointsString);
    };

    const deletePoint = (index) => {
        let { points, areaPointsString } = deleteCoord(state_coords, index);

        set_state_coords(points);
        set_state_polygon(areaPointsString);
    };

    const renderPoints = useMemo(() =>
        state_coords.map((point, index) => (
            <PointItem
                key={index}
                point={point}
                index={index}
                blockRef={blockRef}
                coords={state_coords}
                updateMovePoint={updateMovePoint}
                deletePoint={deletePoint}
            />
        ))
    );

    const updatePointPlanOfFlat = () => {
        dispatch(
            api.chess.updateFlat({
                flat_id: flat_id,
                area_points_for_css: state_polygon,
                area_points: state_coords,
                type_form: 'update_points',
                block_id,
            })
        );
    };

    return (
        <>
            {request_floor_status ? (
                <div className={styles.selectBlockContent}>
                    <LoadingOutlined />
                </div>
            ) : floor_info ? (
                <>
                    <div className={styles.selectBlockContent}>
                        <div className={styles.selectBlockImage} ref={blockRef}>
                            <img
                                src={`${config.public}${floor_info?.photo?.url}`}
                            />
                        </div>

                        {state_coords && state_coords.length ? (
                            <>{renderPoints}</>
                        ) : (
                            <></>
                        )}

                        {state_polygon && (
                            <div
                                className={styles.polygon}
                                style={{
                                    clipPath: `polygon(${state_polygon})`,
                                }}
                            ></div>
                        )}
                    </div>
                    <Button className={styles.buttonUpload}>
                        {!request_status_upload_file ? (
                            <>
                                <input
                                    type="file"
                                    className={styles.uploadInput}
                                    onChange={uploadPlanFloor}
                                />
                                <UploadOutlined />
                                Загрузить планировку
                            </>
                        ) : (
                            <LoadingOutlined />
                        )}
                    </Button>{' '}
                    {modal_form_flat_plan && (
                        <Button
                            className={styles.buttonUpload}
                            type="primary"
                            onClick={updatePointPlanOfFlat}
                        >
                            {!request_form_flat_plan ? (
                                <>Обновить информацию</>
                            ) : (
                                <LoadingOutlined />
                            )}
                        </Button>
                    )}
                </>
            ) : (
                <Result
                    title="Планировка этажа не загружена"
                    extra={
                        <Button className={styles.buttonUpload}>
                            {!request_status_upload_file ? (
                                <>
                                    <input
                                        type="file"
                                        className={styles.uploadInput}
                                        onChange={uploadPlanFloor}
                                    />
                                    <UploadOutlined />
                                    Загрузить планировку
                                </>
                            ) : (
                                <LoadingOutlined />
                            )}
                        </Button>
                    }
                />
            )}
        </>
    );
};

export default SelectFlat;
