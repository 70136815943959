import React from 'react';
import { Form, Input, Select } from 'antd';
import { useSelector } from 'react-redux';

const ParkingFormItemFelds = ({change_place_floor}) => {

    const form = Form.useFormInstance();
    const place_type = Form.useWatch('place_type', form);

    const pantry_templates = useSelector((state: any) => state?.chess?.pantry_templates);
    const parking_floors = useSelector((state: any) => state?.chess?.parking_information?.realestate?.parking_plans);

    return (
        <>

            <Form.Item
                name="place_type"
                label="тип места"
                rules={[
                    {
                        required: true,
                        message: 'Поле обязательно для заполнения',
                    },
                ]}
            >
                <Select
                    placeholder="Выберите тип места"
                    optionFilterProp="children"
                    options={[
                        {
                            value: 'parking',
                            label: 'Паркинг',
                        },
                        {
                            value: 'pantry',
                            label: 'Кладовка',
                        },
                    ]}
                />
            </Form.Item>

            {
                place_type && (
                    <>
                        <Form.Item
                            name="title"
                            label={`Номер`}
                            rules={[
                                {
                                    required: true,
                                    message: 'Поле обязательно для заполнения',
                                },
                            ]}
                        >
                            <Input
                                placeholder="Номер"
                            />
                        </Form.Item>
                        {
                            place_type === 'parking' && (
                                <Form.Item
                                    name="total_price"
                                    label={`Стоимость`}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Поле обязательно для заполнения',
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Стоимость"
                                    />
                                </Form.Item>
                            )
                        }

                        {
                            place_type === 'pantry' && (
                                <>

                                    <Form.Item
                                        name="square_metre_price"
                                        label="Стоимость м2"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Поле обязательно для заполнения',
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder="Стоимость м2"
                                        />
                                    </Form.Item>                                    

                                    <Form.Item
                                        name="template"
                                        label="Выберите шаблон кладовки"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Поле обязательно для заполнения',
                                            },
                                        ]}
                                    >
                                        <Select
                                            placeholder="Выберите шаблон кладовки"
                                            optionFilterProp="children"
                                            options={pantry_templates.map((item)=>({
                                                value: item?._id,
                                                label: item?.title
                                            }))}
                                        />
                                    </Form.Item>
                                </>
                            )  
                        }

                        <Form.Item
                            name="floor"
                            label="Выберите этаж"
                            rules={[
                                {
                                    required: true,
                                    message: 'Поле обязательно для заполнения',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Выберите этаж"
                                optionFilterProp="children"
                                options={parking_floors.map((item)=>({
                                    value: item?.floor,
                                    label: item?.floor
                                }))}
                                onChange={(item)=>change_place_floor(parking_floors.filter(place => place?.floor === item)[0] || 0)}
                            />
                        </Form.Item>

                    </>
                )
            }
            
        </>
    )
}

export default ParkingFormItemFelds;