import axios from '../axios';
import { message } from 'antd';

const widgets = {
    editFormLogovoApp: (params) => async (dispatch) => {

        try {

            dispatch({
                type: 'REQUEST_STATUS_FORM_WIDGET',
                status: true,
            });

            const { data } = await axios.post('/v1/admin/widgets/edit/form-logovo', params);

            dispatch({
                type: 'REQUEST_STATUS_FORM_WIDGET',
                status: false,
            });

            return message.success(data);

        }catch(err){
            dispatch({
                type: 'REQUEST_STATUS_FORM_WIDGET',
                status: false,
            });

            message.warning(
                'Упс, ошибка. Проверьте Ваши права'
            );
        }

    },
    getById: (id) => async (dispatch) => {
        dispatch({
            type: 'REQUEST_STATUS_WIDGET',
            status: true,
        });

        try {
            let { data } = await axios.get(`/v1/admin/widgets/by-id/${id}`);

            dispatch({
                type: 'UPDATE_WIDGET_EDIT',
                data,
            });

            dispatch({
                type: 'REQUEST_STATUS_WIDGET',
                status: false,
            });
        } catch (err) {
            dispatch({
                type: 'REQUEST_STATUS_WIDGET',
                status: false,
            });

            message.warning(
                'Упс, ошибка. Проверьте Ваши права'
            );
        }
    },
    saveContacts: (id, fields) => async () => {
        try {
            await axios.post('/v1/admin/widgets/edit', {
                ...fields,
                type_form: 'contacts',
                id,
            });

            message.success('Информация успешно обновлена');
            return;
        } catch (err) {
            if (err.response.status === 422 && err.response.data?.email) {
                return message.error(err.response.data?.email?.message);
            }

            message.error('Ошибка. Попробуйте позже');
            return;
        }
    },
    updateRealestatePlans: (type_form, id, realestate_id) => async (dispatch) => {
        try {
            let { data } = await axios.post('/v1/admin/widgets/edit', {
                type_form: type_form,
                id,
                realestate_id
            });

            dispatch({
                type: 'UPDATE_WIDGET_REALESTATE_EDIT',
                data
            });

            message.success('Информация успешно сохранена');
            return;
        } catch (err) {
            if (err.response.status === 422 && err.response.data?.email) {
                return message.error(err.response.data?.email?.message);
            }

            message.error('Ошибка. Попробуйте позже');
            return;
        }
    },
    saveWebhook: (id, fields) => async () => {
        try {
            await axios.post('/v1/admin/widgets/edit', {
                ...fields,
                id,
            });

            message.success('Информация успешно обновлена');
            return;
        } catch (err) {
            if (err.response.status === 422 && err.response.data?.url_webhook) {
                return message.error(err.response.data?.url_webhook?.message);
            }

            message.error('Ошибка. Попробуйте позже');
            return;
        }
    },
    get: (type, txt, page = 1, limit = 24) => async (dispatch) => {
        dispatch({
            type: 'REQUEST_STATUS_WIDGET',
            status: true,
        });

        try {
            let { data } = await axios.get('/v1/admin/widgets/builder', {
                params: {
                    type,
                    txt,
                    limit,
                    page
                }
            });

            dispatch({
                type: 'UPDATE_WIDGETS',
                data,
            });

            dispatch({
                type: 'REQUEST_STATUS_WIDGET',
                status: false,
            });
        } catch (err) {
            dispatch({
                type: 'REQUEST_STATUS_WIDGET',
                status: false,
            });

            message.warning(
                'Упс, ошибка. Проверьте Ваши права администратора'
            );
        }
    },
    removeWidgetOfGroup: (id) => async (dispatch) => {
        try {
            await axios.post('/v1/admin/widgets/delete', {
                id,
            });

            dispatch({
                type: 'DELETE_WIDGET_Of_GROUP',
                id,
            });

            return message.success('Виджет удален');
        } catch (err) {
            dispatch({
                type: 'CREATE_STATUS_WIDGET',
                status: false,
            });

            message.warning(
                'Упс, ошибка. Проверьте Ваши права'
            );
        }
    },
    remove: (id) => async (dispatch) => {
        try {
            await axios.post('/v1/admin/widgets/delete', {
                id,
            });

            dispatch({
                type: 'REMOVE_WIDGET',
                id,
            });

            return message.success('Виджет удален');
        } catch (err) {
            dispatch({
                type: 'CREATE_STATUS_WIDGET',
                status: false,
            });

            message.warning(
                'Ошибка. Проверьте Ваши права'
            );
        }
    },
    getWidgetsOfGroup: (id) => async (dispatch) => {
        try {
            let { data } = await axios.get('/v1/admin/widgets-of-group', {
                params: {
                    id,
                },
            });

            dispatch({
                type: 'UPDATE_WIDGETS_OF_GROUP',
                data,
            });

            return;
        } catch (err) {
            return message.warning(
                'ошибка. обратитесь к системному администратору'
            );
        }
    },
    updateWidgetOptions: (id, options, type = '') => async (dispatch) => {
        try {
            let { data } = await axios.post('/v1/admin/widgets/group/options', {
                id,
                options,
                type
            });

            if(data?.flats){
                dispatch({
                    type: 'UPDATE_WIDGET_FLATS_TOUR_EDIT',
                    data: data?.flats
                });

                return message.success(data?.message);
            }

            return message.success(data);
        } catch (err) {
            return message.error(
                'ошибка. обратитесь к системному администратору'
            );
        }
    },
    createWidgetOfGroup:
        (plans, title, description, type, group_id) => async (dispatch) => {
            dispatch({
                type: 'CREATE_STATUS_WIDGET',
                status: true,
            });

            try {
                if (title && plans.length) {
                    let { data } = await axios.post(
                        '/v1/admin/widgets/group/create',
                        {
                            plans,
                            title,
                            description,
                            group_id,
                            type,
                        }
                    );

                    dispatch({
                        type: 'UPDATE_WIDGETS_OF_GROUP',
                        data,
                    });

                    dispatch({
                        type: 'CREATE_STATUS_WIDGET',
                        status: false,
                    });

                    return;
                }

                dispatch({
                    type: 'CREATE_STATUS_WIDGET',
                    status: false,
                });

                return message.error('Заполните все поля');
            } catch (err) {
                dispatch({
                    type: 'CREATE_STATUS_WIDGET',
                    status: false,
                });

                message.warning(
                    'ошибка. Проверьте Ваши права'
                );
                return;
            }
        },
    createWidget:
        (plans, title, description, colums, type, history = {}, options) =>
        async (dispatch) => {
            let newPlansArr = [];
            if (type == '3DTOURS') {
                try {
                    let { data } = await axios.post('/v1/admin/widgets/create', {
                        flats: newPlansArr,
                        title,
                        description,
                        colums,
                        type,
                        options,
                    });

                    dispatch({
                        type: 'UPDATE_WIDGET_INFO',
                        data,
                    });

                    dispatch({
                        type: 'CREATE_STATUS_WIDGET',
                        status: false,
                    });

                    return history(`/widgets/edit/group/${data?._id}`);
                } catch (err) {
                    dispatch({
                        type: 'CREATE_STATUS_WIDGET',
                        status: false,
                    });

                    message.warning(
                        'ошибка. Проверьте Ваши права'
                    );
                    return;
                }
            } else if (title && description && colums) {

                dispatch({
                    type: 'CREATE_STATUS_WIDGET',
                    status: false,
                });

                try {
                    let { data } = await axios.post('/v1/admin/widgets/create', {
                        title,
                        description,
                        colums,
                        type,
                        options,
                    });

                    dispatch({
                        type: 'UPDATE_WIDGET_INFO',
                        data,
                    });

                    dispatch({
                        type: 'CREATE_STATUS_WIDGET',
                        status: false,
                    });

                    return history(`/widgets/edit/${data?._id}`);
                } catch (err) {
                    dispatch({
                        type: 'CREATE_STATUS_WIDGET',
                        status: false,
                    });

                    message.warning(
                        'Упс, ошибка. Проверьте Ваши права'
                    );
                    return;
                }
            }

            message.warning('Заполните все поля');
        },
    editWidget:
        (title, description, id, colums, options) => async (dispatch) => {

            dispatch({
                type: 'CREATE_STATUS_WIDGET',
                status: false,
            });

            try {
                await axios.post('/v1/admin/widgets/edit', {
                    title,
                    description,
                    id,
                    colums,
                    options,
                });

                dispatch({
                    type: 'CREATE_STATUS_WIDGET',
                    status: false,
                });

                return message.success('Настройки сохранены');

            } catch (err) {
                dispatch({
                    type: 'CREATE_STATUS_WIDGET',
                    status: false,
                });

                return message.warning('Сервис недоступен, попробуйте позже');
                
            }
    },
};

export default widgets;
