import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import * as gqls from '@/Services/apollo/gqls';
import Loader from '../../../Loader';
import styles from './styles/editManager.module.scss';
import InputMask from 'antd-mask-input';
import config from '../../../../Config';
import SelectRealEstate from '../helpers/real-estate';
import _ from 'underscore';
import checkFields from '../helpers/fields';
import { Button, Input } from 'antd';

const EditManager = () => {
    let navigate = useNavigate();
    let paramsRoute = useParams();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [realEstate, setRealEstateState] = useState([]);
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');

    const [statusModal, setStatusModal] = useState(false);

    const { data, loading } = useQuery(
        gqls.builder.getRealEstateByRealtorAndManager,
        {
            variables: {
                id: paramsRoute.id,
            },
        }
    );

    const [editManager, addManagerData] = useMutation(
        gqls.builder.editManager,
        {
            refetchQueries: () => [
                {
                    query: gqls.builder.getManagersOfRealtor,
                },
            ],
            onCompleted: () => {
                return navigate('/managers');
            },
            onError: (err: any) => {
                return;
            },
        }
    );

    useEffect(() => {
        if (!loading) {
            setFirstName(data.getManager.firstname);
            setLastName(data.getManager.lastname);
            setEmail(data.getManager.email);
            setPhone(`+7${data.getManager.phone}`);
            setRealEstateState(data.getManager.settings.real_estate);
            setAddress(data.getManager.address);
        }
    }, [loading]);

    const editManagerEvent = async () => {
        let real_estate_ids =
            realEstate && realEstate.length
                ? realEstate.map((item: any) => {
                      return item._id;
                  })
                : null;

        let checkError = await checkFields(
            {
                email,
                firstname: firstName,
                lastname: lastName,
                password,
                phone,
                real_estate: real_estate_ids,
                address,
                repeat_password: repeatPassword,
            },
            false
        );

        if (!checkError.status) {
            return alert(checkError.msg);
        }

        return editManager({
            variables: {
                fields: {
                    email,
                    firstname: firstName,
                    lastname: lastName,
                    password,
                    phone,
                    real_estate: real_estate_ids,
                    address,
                    id: paramsRoute.id,
                },
            },
        });
    };

    const setRealEstate = (data: any) => {
        let uniqData: any = _.uniq(data, (item) => {
            return item._id;
        });

        setRealEstateState(uniqData);
        return;
    };

    const deleteRealEstate = (id: string) => {
        setRealEstateState(
            realEstate.length > 1
                ? realEstate.filter((item: any) => item._id !== id)
                : []
        );
        return;
    };

    return (
        <section className={styles.managerWrapper}>
            <h1 className={styles.headManagerPage}>Редактировать менеджера</h1>

            {!loading ? (
                <div className={`${styles.formManager} ${styles.formRealtor}`}>
                    <SelectRealEstate
                        config={config}
                        selectRealEstate={(val: any) =>
                            setRealEstate([...realEstate, val])
                        }
                        data={data?.getRealEstateByRealtor}
                        close_modal={() => setStatusModal(false)}
                        status_modal={statusModal}
                    />
                    <div className={styles.formManagerPWD}>
                        <Input
                            size="large"
                            type="text"
                            value={firstName}
                            onChange={(val) => setFirstName(val.target.value)}
                            placeholder="Имя"
                        />
                        <Input
                            size="large"
                            type="text"
                            value={lastName}
                            onChange={(val) => setLastName(val.target.value)}
                            placeholder="Фамилия"
                        />
                    </div>
                    <div className={styles.formManagerPWD}>
                        <InputMask
                            size="large"
                            mask="+7 999 999 99 99"
                            type="text"
                            placeholder="Телефон"
                            value={phone}
                            onChange={(val) => setPhone(val.target.value)}
                        />
                        <Input
                            size="large"
                            type="text"
                            value={email}
                            onChange={(val) => setEmail(val.target.value)}
                            placeholder="Email"
                        />
                    </div>

                    <div className={styles.formManagerPWD}>
                        <Input
                            size="large"
                            type="password"
                            value={password}
                            onChange={(val) => setPassword(val.target.value)}
                            placeholder="Пароль"
                        />
                        <Input
                            size="large"
                            type="password"
                            value={repeatPassword}
                            onChange={(val) =>
                                setRepeatPassword(val.target.value)
                            }
                            placeholder="Повторите пароль"
                        />
                    </div>

                    {addManagerData.error && (
                        <section className={styles.error}>
                            <p>{addManagerData?.error?.message}</p>
                        </section>
                    )}

                    <Button onClick={editManagerEvent} type="primary">
                        Редактировать
                    </Button>
                </div>
            ) : (
                <Loader width="100%" height="100%" background="transparent" />
            )}
        </section>
    );
};

export default EditManager;
