const checkFields = async (fields: any, checkPWD = true) => {
    let { email, firstname, lastname, password, phone, repeat_password } =
        fields;

    if (email && firstname && lastname && phone) {
        if (checkPWD || password || repeat_password) {
            if (password && repeat_password) {
                if (password != repeat_password) {
                    return {
                        status: false,
                        msg: 'Пароли не совпадают',
                    };
                }

                return {
                    status: true,
                };
            }

            return {
                status: false,
                msg: 'Проверьте поля с паролем',
            };
        }

        return {
            status: true,
        };
    }

    return {
        status: false,
        msg: 'Заполните все поля',
    };
};

export default checkFields;
