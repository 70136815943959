import React, { useMemo, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { api } from '@/Services';
import styles from './styles/custom-chess.module.scss';
import { Button, Result, Tour, Popover } from 'antd';
import BlockModalForm from './helpers/block-form';
import BlockItem from './helpers/block/item';
import {
    LoadingOutlined,
    RightOutlined,
    LeftOutlined,
    SettingOutlined
} from '@ant-design/icons';
import FlatModalForm from './helpers/flat-form';
import { Space } from 'react-zoomable-ui';
import FlatPlanForm from './helpers/flat-plan-form';
import ModalFormPrices from './helpers/form-prices';
import ModalSettings from './helpers/modals/settings';
import ParkingGrid from './helpers/parking';

const ChessCustom = ({chessData}) => {
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);

    let stateChess = useSelector((state) => state.chess);
    let stateChessData = useSelector((state) => state.chessData);
    let userRole = useSelector((state) => state.account?.user?.role?.type);

    const [start_tour, set_start_tour] = useState(false);

    const { id } = useParams();
    const dispatch = useDispatch();

    const modalBlockForm = (status, type) => {
        dispatch({
            type: 'STATUS_MODAL_FORM_BLOCK',
            modal_type: type,
            status,
        });
    };

    const closeFormMain = () => {
        dispatch({
            type: 'STATUS_MODAL_FORM',
            modal_type: '',
            status: false,
        });
    };

    const renderMemoList = useMemo(() =>
        chessData?.blocks.map((item, index) => (
            <BlockItem
                index={index}
                item={item}
                key={item?._id}
                api={api}
                dispatch={dispatch}
                chessData={chessData}
            />
        )), [chessData?.blocks]);

    const tourSteps = [
        {
            title: 'Форма создания нового блока жк',
            description: 'На основе внесенных данных формируется шахматка',
            target: () => ref1.current,
            nextButtonProps: {
                children: <RightOutlined />,
            },
            prevButtonProps: {
                children: <LeftOutlined />,
            },
            arrow: true,
        },
        {
            title: 'Статус квартиры',
            description: 'Отображение статуса квартиры',
            target: () => ref2.current,
            nextButtonProps: {
                children: <RightOutlined />,
            },
            prevButtonProps: {
                children: <LeftOutlined />,
            },
        },
        {
            title: 'Основная область для работы с шахматкой',
            description:
                'Добавление и редактирование квартир, пентхаусов, работа с этажами, редактирование и удаление блоков, управление ценами.',
            target: () => ref3.current,
            nextButtonProps: {
                children: 'Закрыть',
            },
            prevButtonProps: {
                children: <LeftOutlined />,
            },
        },
    ];

    return (
        <div className={styles.chessWrapper}>
            {chessData?.chessboard === 'custom' ? (
                <>
                    <Tour
                        open={start_tour}
                        onClose={() => set_start_tour(!start_tour)}
                        steps={tourSteps}
                    />

                    <ModalFormPrices
                        api={api}
                        open={stateChess?.modal_form_prices}
                        dispatch={dispatch}
                        close={() => {
                            dispatch({
                                type: 'CHESS_MODAL_FORM_PRICES',
                            });
                        }}
                        styles={styles}
                        fields={{
                            block_id: stateChess?.block_id,
                            floor: stateChess?.floor,
                        }}
                    />

                    <FlatPlanForm
                        api={api}
                        open={stateChess?.modal_form_flat_plan}
                        close={() => {
                            dispatch({
                                type: 'UPDATE_FLAT_TEMPLATE_PLAN_FORM_MODAL',
                            });
                        }}
                    />

                    <ModalSettings
                        api={api}
                        open={stateChess?.modal_realestate_settings}
                        close={() => {
                            dispatch({
                                type: 'REALESTATE_MODAL_SETTINGS',
                            });
                        }}
                    />

                    <FlatModalForm
                        api={api}
                        realestate_id={id}
                        open={stateChess?.modal_form_flat}
                        close={closeFormMain}
                        type={stateChess?.modal_form_flat_type}
                    />
                    
                    <BlockModalForm
                        open={stateChess?.modal_form_block}
                        close={closeFormMain}
                        type={stateChess?.modal_form_block_type}
                        api={api}
                        realestate_id={id}
                        title={stateChess?.block_title}
                        floors_count={stateChess?.block_floors_count}
                        max_num_apartments_floor={
                            stateChess?.block_max_num_apartments_floor
                        }
                        data_sync_entrance={stateChess?.sync_entrance}
                        data_sync_id={stateChess?.sync_id}
                    />
                    <div className={styles.header}>
                        <h1>Жк {chessData?.title || ''}</h1>
                        {
                            /*
                            <Button onClick={() => set_start_tour(!start_tour)}>
                                Тур
                            </Button>
                            */
                        }
                        <div className={styles.navigation}>
                            <Button disabled>
                                Шахматка
                            </Button>
                            <Link to={`/real-estate/catalog/${id}`}>
                                <Button>
                                    Каталог
                                </Button>
                            </Link>
                        </div>
                    </div>
                    <div className={styles.tools}>
                        <div className={styles.leftTools}>
                            <Button
                                type="primary"
                                size="large"
                                onClick={() => modalBlockForm(true, 'create')}
                                ref={ref1}
                            >
                                добавить блок
                            </Button>

                            <Popover
                                content={`Дополнительные настройки`}
                            >
                                <Button
                                    type="primary"
                                    size="large"
                                    onClick={()=>{
                                        dispatch({
                                            type: 'REALESTATE_MODAL_SETTINGS',
                                            status: true
                                        });
                                    }}
                                >
                                    <SettingOutlined
                                        className={styles.toolIcon}
                                    />
                                </Button>
                            </Popover>
                        </div>
                        <div
                            className={styles.descriptionFlatColors}
                            ref={ref2}
                        >
                            <span>
                                <p>забронировано</p>
                                <div
                                    style={{
                                        background: '#f8ef29',
                                    }}
                                ></div>
                            </span>
                            <span>
                                <p>Свободные</p>
                                <div
                                    style={{
                                        background: '#5bcd3e',
                                    }}
                                ></div>
                            </span>
                            <span>
                                <p>Проданные</p>
                                <div
                                    style={{
                                        background: '#dde9ff',
                                    }}
                                ></div>
                            </span>

                            {userRole != 'BUILDER' ? (
                                <span>
                                    <p>Вторичный рынок</p>
                                    <div
                                        style={{
                                            background: '#d7ab11',
                                        }}
                                    ></div>
                                </span>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>

                    <div
                        className={styles.chess}
                        style={{
                            background: chessData?.blocks.length
                                ? 'rgb(233,233,233)'
                                : 'transparent',
                        }}
                    >
                        {stateChessData?.first_request_status ? (
                            <div className={styles.loadBlock}>
                                <LoadingOutlined />
                            </div>
                        ) : chessData?.blocks &&
                        chessData?.blocks.length ? (
                            <div ref={ref3} className={styles.wrapperItems}>
                                <Space
                                    onCreate={vp => {
                                        vp.camera.centerFitAreaIntoView({
                                            left: 0,
                                            top: -555,
                                            width: 4280,
                                            height: 4860,
                                        });
                                    }}
                                >
                                    <div style={{width: 'fit-content'}}>
                                        <div className={styles.blockItems}>
                                            {renderMemoList}
                                        </div>
                                        
                                        <ParkingGrid />
                                        
                                    </div>
                                </Space>
                            </div>
                        ) : (
                            <Result
                                title="Блоки не были добавлены"
                                extra={null}
                            />
                        )}
                    </div>
                </>
            ) : (
                <Result
                    title="В данном жк используется стандартная шахматка"
                    extra={null}
                />
            )}
        </div>
    );
};

export default ChessCustom;
