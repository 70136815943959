import React, { useEffect, useRef } from 'react';
import styles from '../../styles/settings.module.scss';
import { Form, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '@/Services';
import { Collapse } from 'antd';
import ListMessageKeys from './helpers/list-message-keys';
import { editorOptions } from './options';
import SunEditor from 'suneditor-react';

const WhatsappBusinessMessage = () => {

    const editorRef = useRef(null);
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { keys } = useSelector((state: any) => state?.contracts?.form_meta || []);
    const { user } = useSelector((state: any) => state?.account);

    const onFinish = (values) => {
        dispatch(api.account.saveKeysWhatsappBusiness({
            ...values,
            type: 'update_template_message'
        }));
    }

    useEffect(()=>{
        form.setFieldsValue({
            template_message: user?.builder?.whatsapp_business?.template_message || '',
        })
    }, []);

    useEffect(()=>{
        dispatch(api.contracts.getMeta('whatsapp-business'));
    }, []);

    const getSunEditorInstance = (sunEditor) => {
        editorRef.current = sunEditor;
    };

    return (
        <section className={styles.settingsWrapper}>

            <Collapse accordion items={[{
                key: '1',
                label: 'Ключи',
                children: <ListMessageKeys keys={keys} />,
            }]} />

            <div className={styles.blockSections}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    autoComplete="off"
                    size="large"
                    className={styles.form}
                >
                    <Form.Item
                        name="template_message"
                        label="Сообщение"
                        className={styles.input}
                        rules={[{ required: true }]}
                    >
                       <SunEditor 
                            setOptions={editorOptions}
                            defaultValue={user?.builder?.whatsapp_business?.template_message || ''}
                            getSunEditorInstance={getSunEditorInstance}
                            placeholder='Сообщение'
                        />
                    </Form.Item>

                    <Form.Item className={styles.input} style={{
                        marginTop: 5
                    }}>
                        <Button type="primary" htmlType="submit">
                            Сохранить
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </section>
    )
}

export default WhatsappBusinessMessage;