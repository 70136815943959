import React, {
	useMemo
} from 'react';

import { Link } from "react-router-dom";

const Treaties = () => {
	return useMemo(()=>(
		<div>
            <h2>Договора</h2>
            <p>Информации нет</p>
        </div>
	), [])
}

export default Treaties;