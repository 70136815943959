import React from 'react';
import { Modal, Tooltip, Tag } from 'antd';
import typeFormPayment from '../funcs/typeFormPayment';

const InfoFormPayments = ({
    close,
    item,
    styles
}) => {

    const calcCondition = (item) => {
        console.log(66, item)

        return (
            <div className={styles.calcItem}>
                <div className={styles.calcItemTop}>
                    <Tooltip title="Первоначальный взнос">
                    <Tag color="green"><h4>{item?.down_payment}%</h4></Tag>
                    </Tooltip>
                    <div>
                        {
                            item?.apartments_conditions && item?.apartments_conditions.length ?
                                item?.apartments_conditions.map((item)=>(
                                    <div key={item?._id} className={styles.apartmentItem}>
                                        <b><Tag color="cyan">{item?.math_symbol === 'subtraction' ? 'Вычитаем' : 'Прибавляем'} {item?.additional_number || 0}{item?.type === 'percentages' ? '%' : '₸'}</Tag> от стоимости квадратного метра для <Tag color="cyan">{item?.count_rooms}-комнатной квартиры</Tag>от <Tag color="cyan">{item?.min_square || 0}м²</Tag> до <Tag color="cyan">{item?.max_square || 0}м²</Tag></b>
                                    </div>
                                ))
                            :
                                <Tag>Операций для условия нет</Tag>
                        }
                    </div>
                </div>
                
            </div>
        )

    }

    return (
        <Modal 
            title='Расчет условий'
            open={item?._id} 
            footer={null} 
            onCancel={close}
            destroyOnClose={true}
        >
            <h3>Тип формы оплаты: <Tag color="green"><b>{typeFormPayment(item?.type)}</b></Tag></h3>

            <h2 className={styles.secondText}>Список условий</h2>
            <Tag color="gold">Если значение 0, оно не учитывается!</Tag>

            <div>
                {
                    item?.conditions && item?.conditions.length ?
                        item?.conditions.map((item, index)=>(
                            <div key={Math.random() + '_' + index}>{calcCondition(item)}</div>
                        ))
                    :
                        <h5>Информации не найдено</h5>
                }
            </div>

        </Modal>
    );
}

export default InfoFormPayments;