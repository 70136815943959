import { Button, Tag } from 'antd';
import styles from '../styles/templatePattern.module.scss';
import { useEffect, useState } from 'react';
import ModalGroupForm from './helpers/modals/group';
import ItemGroup from './helpers/group/item';
import FieldModalForm from './helpers/modals/field';
import { useDispatch } from 'react-redux';

const Form = ({ pattern }) => {
    const [group_form, set_group_form] = useState(false);
    const [groups, set_groups] = useState(pattern);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({
            type: 'TEMPLATE_PATTERN_FIELD_MODAL_FORM',
        });
    }, []);

    console.log(33, groups);

    return (
        <div className={styles.wrapper}>
            {(!groups || !groups.length) && (
                <Tag color="red" style={{ marginBottom: 10 }}>
                    Шаблон не настроен
                </Tag>
            )}

            <FieldModalForm updatefields={set_groups} />

            <ModalGroupForm
                open={group_form}
                close={() => set_group_form(false)}
                updatefields={set_groups}
            />
            {groups && groups.length ? (
                groups.map((group, index) => (
                    <ItemGroup key={group?.id} group={group} index={index} />
                ))
            ) : (
                <></>
            )}

            <div className={styles.buttonGroup} style={{ padding: 0 }}>
                <Button
                    className={styles.button}
                    size="large"
                    onClick={() => set_group_form(true)}
                    type="primary"
                >
                    добавить группу
                </Button>
            </div>
        </div>
    );
};

export default Form;
