import React, { useMemo, useState, useEffect } from 'react';
import styles from './styles/resetPassword.module.scss';
import { useMutation } from '@apollo/client';
import * as gqls from '@/Services/apollo/gqls';
import { Button, Input } from 'antd';

const ResetPassword = () => {
    const [password, set_password] = useState('');
    const [new_password, set_new_password] = useState('');
    const [new_repeat_password, set_new_repeat_password] = useState('');
    const [success_reset_password, set_success_reset_password] = useState('');

    const [form_errors, set_form_errors] = useState([]);

    const [changePassword, data]: any = useMutation(gqls.users.changePassword, {
        onCompleted: (data) => {
            set_new_password('');
            set_new_repeat_password('');
            set_password('');
            set_success_reset_password('Пароль успешно изменен');
            return;
        },
        onError: (errors) => {
            return;
        },
    });

    useEffect(() => {
        if (data?.error?.graphQLErrors) {
            set_form_errors(data?.error?.graphQLErrors);
        } else {
            set_form_errors([]);
        }
    }, [data.loading]);

    return useMemo(
        () => (
            <section className={styles.settingsWrapper}>
                <section className={styles.blockSections}>
                    <section className={styles.blockSection}>
                        <Input
                            size="large"
                            value={password}
                            type="password"
                            placeholder="Введите пароль"
                            onChange={(val) => set_password(val.target.value)}
                        />
                    </section>
                    <section className={styles.blockSection}>
                        <Input
                            size="large"
                            value={new_password}
                            type="password"
                            placeholder="Введите новый пароль"
                            onChange={(val) =>
                                set_new_password(val.target.value)
                            }
                        />
                    </section>
                    <section className={styles.blockSection}>
                        <Input
                            size="large"
                            value={new_repeat_password}
                            type="password"
                            placeholder="Повторите еще раз новый пароль"
                            onChange={(val) =>
                                set_new_repeat_password(val.target.value)
                            }
                        />
                    </section>

                    {form_errors.length ? (
                        <section
                            className={`${styles.blockSection} ${styles.blockSectionErrors}`}
                        >
                            {form_errors.map((error: any) => {
                                return <p>{error.message}</p>;
                            })}
                        </section>
                    ) : (
                        <></>
                    )}

                    {success_reset_password ? (
                        <section
                            className={`${styles.blockSection} ${styles.blockSectionErrors}`}
                            style={{
                                background: 'green',
                            }}
                        >
                            <p>{success_reset_password}</p>
                        </section>
                    ) : (
                        <></>
                    )}

                    <Button
                        onClick={() =>
                            changePassword({
                                variables: {
                                    fields: {
                                        password,
                                        new_password,
                                        new_repeat_password,
                                    },
                                },
                            })
                        }
                        type="primary"
                        style={{ width: '170px' }}
                    >
                        {!data.loading ? 'Изменить пароль' : 'Подождите...'}
                    </Button>
                </section>
            </section>
        ),
        [
            form_errors,
            password,
            new_password,
            new_repeat_password,
            data.loading,
            success_reset_password,
        ]
    );
};

export default ResetPassword;
