import { gql } from '@apollo/client';

const newContract = gql`
	subscription($params: contractPurchaseApartmentInput) {
		contractPurchaseApartment(params: $params) {
			_id
			title
			contract_number
			pdf_contract {
				url
			}
			status
			lock_up_to
			createdAt
			fields {
				second_name
				first_name
				middle_name
			}
			apartment {
				title
				apartment_complex_id {
					title
				}
				block_id {
					title
				}
			}
		}
	}
`;

export default {
    newContract
}