import React from 'react';
import styles from '../../../styles/custom-chess.module.scss';
import ParkingSettings from './parking';
import { Modal, Tabs } from 'antd';

const ModalSettings = ({
    open,
    close,
    api
}) => {

    const items = [
        {
            key: 'parking',
            label: `Паркинг`,
            children: (
                <ParkingSettings api={api} />
            ),
        },
    ];

    return (
        <Modal
            title="Дополнительные настройки"
            open={open}
            onCancel={close}
            footer={null}
            width={`50%`}
            destroyOnClose={true}
            centered={true}
            wrapClassName={styles.wrapperModal}
        >
            <Tabs
                defaultActiveKey="form"
                items={items}
            />
        </Modal>
    )
}

export default ModalSettings;