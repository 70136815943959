import React from 'react';
import styles from '../../styles/settings.module.scss';
import { Tabs } from 'antd';
import WhatsappBusinessKeys from './keys';
import WhatsappBusinessMessage from './message';

const WhatsappBusiness = () => {

    const items = [
        {
          key: '1',
          label: `Ключи`,
          children: <WhatsappBusinessKeys/>,
        },
        {
            key: '2',
            label: `Шаблон сообщения`,
            children: <WhatsappBusinessMessage/>,
        },
    ];

    return (
        <section className={styles.settingsWrapper}>
            <Tabs
                items={items}
                destroyInactiveTabPane={false}
            />
        </section>
    )
}

export default WhatsappBusiness;