import React from 'react';
import styles from './styles/analytics.module.scss';
import { Result } from 'antd';

const Analytics = () => {
    return (
        <section className={styles.settingsWrapper}>
            <h2 className={styles.settingsTitle}>Аналитика</h2>
            <Result
                status="500"
                title="В разработке"
                subTitle="Раздел будет доступен позже"
                extra={null}
            />
        </section>
    );
};

export default Analytics;
