import React from 'react';
import styles from './styles/contracts.module.scss';
import { Tabs } from 'antd';
import ListContracts from './comps/listContracts';
import ContractTemplates from './comps/contractTemplates';

const Contracts = () => {

    const items = [
        {
            key: 'list_contracts',
            label: `Список договоров (заявки, бронь)`,
            children: <ListContracts/>,
        },
        {
          key: 'template_contracts',
          label: `Шаблоны договоров`,
          children: <ContractTemplates/>,
        }
    ];

    return (
        <section className={styles.wrapper}>
            <h2 className={styles.title}>Договора</h2>

            <div>
                <Tabs defaultActiveKey="list_contracts" items={items} />
            </div>

        </section>
    )
}

export default Contracts;