import React, { useEffect, useRef } from 'react';
import { Drawer, Form, Input, Button, Select } from 'antd';
import styles from '../../../../styles/contracts.module.scss';
import { editorOptions } from './options';
import SunEditor from 'suneditor-react';
import FormKeys from './form-keys';
import { useSelector } from 'react-redux';
import 'suneditor/dist/css/suneditor.min.css';
import {
    LoadingOutlined,
    EditOutlined,
    PlusOutlined
} from '@ant-design/icons';

const labelStyle = {
    margin: 0,
    padding: 0
}

const ContractTemplateForm = ({
    open,
    close,
    item,
    dispatch,
    api,
    request
}) => {

    const editorRef = useRef(null);

    const [form] = Form.useForm();
    const { payment_methods_data, request_payment_methods, form_meta } = useSelector((state: any) => state?.contracts);

    const onFinish = (fields) => {

        if(!request){
            return dispatch(api.contracts.form_cu(fields, item?._id, close));
        }

    }

    useEffect(()=>{

        form.resetFields();

        if(item){
            form.setFieldsValue({
                title: item?.title || '',
                realestate: item?.realestate && item?.realestate.length ? item?.realestate.map((item)=>(item?._id)) : [],
                content: item?.content || '',
                payment_method: item?.payment_method || null,
            });
        }else {
            form.resetFields();
        }
    }, [item]);

    const handleImageUploadBefore = (files, info, core) => {

        (async () => {

            let response = await api.contracts.uploadImage(files[0], 'IMAGE_FOR_POSTS');
            console.log(444, response)

            return core.plugins.image.register.call(core, info, response);
        })();

        return undefined;
    
    };

    const getSunEditorInstance = (sunEditor) => {
        editorRef.current = sunEditor;
    };

    return (
        <Drawer 
            title={item ? 'Форма редактирования шаблона' : 'Форма создания шаблона'}
            onClose={close} 
            open={open}
            width={`65vw`}
            destroyOnClose={true}
            bodyStyle={{
                overflowY: 'scroll'
            }}
        >

            <FormKeys/>

            <Form
                form={form}
                name="basic"
                onFinish={onFinish}
                autoComplete="off"
                layout='vertical'
                size='large'
                className={styles.formTemplate}
            >
               <Form.Item
                    label="Наименование шаблона договора"
                    name="title"
                    rules={[
                        {
                        required: true,
                        message: 'Поле обязательно для заполнения',
                        },
                    ]}
                    labelCol={{ style: labelStyle}}
                    className={styles.formItem}
                >
                    <Input placeholder='Наименование шаблона договора'/>
                </Form.Item>

                <Form.Item
                    label="Текст шаблона"
                    name="content"
                    labelCol={{ style: labelStyle}}
                    className={styles.formItem}
                    rules={[
                        {
                            required: true,
                            message: 'Поле обязательно для заполнения',
                        },
                    ]}
                >
                    <SunEditor 
                        setOptions={editorOptions}
                        onImageUploadBefore={handleImageUploadBefore}
                        defaultValue={item?.content || ''}
                        getSunEditorInstance={getSunEditorInstance}
                    />
                </Form.Item>

                <Form.Item
                    label="Связанные жк"
                    name="realestate"
                    labelCol={{style: labelStyle}}
                    className={styles.formItem}
                    rules={[
                        {
                        required: true,
                        message: 'Поле обязательно для заполнения',
                        },
                    ]}
                >
                    <Select
                        mode="tags"
                        style={{
                            width: '100%',
                        }}
                        placeholder="Добавьте жк"
                        options={form_meta?.realestate && form_meta?.realestate.length ? form_meta?.realestate.map((item)=>{
                            return {
                                value: item?._id,
                                label: item?.title,
                            }
                        }) : []}
                    />
                </Form.Item>

                <Form.Item
                    label="Способ оплаты"
                    name="payment_method"
                    labelCol={{ style: labelStyle}}
                    className={styles.formItem}
                >
                    <Select
                        style={{
                            width: '100%',
                        }}
                        loading={request_payment_methods}
                        placeholder="Добавьте способ оплаты"
                        options={payment_methods_data && payment_methods_data.length ? payment_methods_data.map((item)=>{
                            return {
                                value: item?._id,
                                label: item?.title,
                            }
                        }) : []}
                    />
                </Form.Item>

                <Form.Item
                        className={styles.formItem} 
                        style={{marginTop: 10}}   
                    >
                    <Button icon={request ? <LoadingOutlined /> : item ? <EditOutlined /> : <PlusOutlined />} type="primary" htmlType="submit">
                        {request ? '' : item ? 'обновить информацию' : 'Добавить'}
                    </Button>
                </Form.Item>

            </Form>
        </Drawer>
    )
}

export default ContractTemplateForm;