import React from 'react';
import styles from '../../../Builder/styles/index.module.scss';

const SelectRealEstate = (props: any) => {

	let {
		status_modal,
		close_modal,
		data,
		selectRealEstate,
		config		
	} = props;

	return (
		<div className={`${styles.modalRealEstate} ${status_modal ? styles.modalRealEstateOpened : false}`}>

			<div onClick={close_modal} className={styles.modalRealEstateOverlay}></div>

			<div className={styles.modalRealEstateItems}>
				{
					data && data.length ?
						data.map((item: any)=>{

							let urlPhoto = config.public + '500/500/' + item.photo.url;

							return (
								<div onClick={()=>{
									selectRealEstate(item)
									close_modal()
								}} key={item._id} className={styles.modalRealEstateItem} style={{
									background:`url(${urlPhoto}) center / cover`
								}}>
									<div className={styles.modalRealEstateItemOverlow}></div>
									<h3>{ item.title }</h3>
								</div>
							)
						})
					:
						<h5>Жилых комплексов не найдено</h5>
				}
			</div>

		</div>
	)
}

export default SelectRealEstate;