import React from 'react';
import styles from '../../styles/custom-chess.module.scss';
import { Popconfirm } from 'antd';

const PointItem = ({
    point,
    blockRef,
    index,
    coords,
    updateMovePoint,
    deletePoint,
}) => {
    let isDown = false;

    const mousedownPoint = (event) => {
        event.preventDefault();

        isDown = true;
        window.addEventListener('mousemove', moveElement);
        window.addEventListener('mouseup', () => {
            stopMoving(event);
        });
    };

    const moveElement = (event) => {
        if (isDown) {
            let posXnative = event.layerX - 6;
            let posYnative = event.layerY - 6;

            let blockHeight =
                blockRef.current.clientHeight + blockRef.current.offsetLeft;
            let blockwidth =
                blockRef.current.clientWidth + blockRef.current.offsetLeft;

            let percentX = (posXnative / blockwidth) * 100;
            let percentY = (posYnative / blockHeight) * 100;

            if (percentX > 0 && percentY > 0) {
                let updateCoords = [...coords];
                updateCoords[index] = [percentX, percentY];
                updateMovePoint(updateCoords);
            }
        }
    };

    const stopMoving = () => {
        isDown = false;

        window.removeEventListener('mousemove', moveElement);
        window.removeEventListener('mouseup', stopMoving);
    };

    return (
        <Popconfirm
            title="Удалить точку?"
            description=""
            onConfirm={() => {
                deletePoint(index);
            }}
            okText="Удалить"
            cancelText="Нет"
            trigger="contextMenu"
        >
            <div
                onMouseDown={mousedownPoint}
                className={styles.coordPoint}
                style={{
                    left: `calc(${point[0]}% - 4px)`,
                    top: `calc(${point[1]}% - 4px)`,
                }}
            ></div>
        </Popconfirm>
    );
};

export default PointItem;
