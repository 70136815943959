import React, { useState, useEffect } from 'react';
import { Modal, Input, Button, Checkbox } from 'antd';
import { useSelector } from 'react-redux';
import styles from '../styles/sidebarFlatInfo.module.scss';

const FormSecondaryFlat = ({ statusForm, close, secondaryFlat, edit }: any) => {
    const stateUser = useSelector((state: any) => state.account.user);
    const stateFlat = useSelector((state: any) => state.flats.flat);
    const [total_price, setTotal_price] = useState('');
    const [square_metres, setSquare_metres] = useState('');
    const [phone, setPhone] = useState('');
    const [phoneAccount, setPhoneAccount] = useState(false);
    const [email, setEmail] = useState('');
    const [emailAccount, setEmailAccount] = useState(false);

    useEffect(() => {
        if (!statusForm) {
            setTotal_price('');
            setSquare_metres('');
            setPhone('');
            setPhoneAccount(false);
            setEmail('');
            setEmailAccount(false);
        }
    }, [statusForm]);

    useEffect(() => {
        if (edit) {
            setTotal_price(
                String(stateFlat?.secondary_info?.total_price) || ''
            );
            setSquare_metres(
                String(stateFlat?.secondary_info?.square_metres) || ''
            );
            setPhone(stateFlat?.secondary_info?.phone || '');
            setEmail(stateFlat?.secondary_info?.email || '');
        }
    }, [edit]);

    return (
        <Modal
            title={
                edit
                    ? 'Редактирование информации'
                    : 'Выставить квартиру на вторичный рынок'
            }
            visible={statusForm}
            onCancel={close}
            footer={null}
        >
            <section className={styles.fieldSection}>
                <Input
                    size="large"
                    value={total_price}
                    onChange={(value: any) =>
                        setTotal_price(value.target.value)
                    }
                    placeholder="Стоимость объекта"
                />
            </section>

            <section className={styles.fieldSection}>
                <Input
                    size="large"
                    value={square_metres}
                    onChange={(value: any) =>
                        setSquare_metres(value.target.value)
                    }
                    placeholder="Площадь объекта"
                />
            </section>

            <section className={styles.fieldSection}>
                <Input
                    size="large"
                    disabled={phoneAccount}
                    value={phone}
                    onChange={(value: any) => setPhone(value.target.value)}
                    placeholder="Телефон продавца"
                />
                <Checkbox
                    checked={phoneAccount}
                    onChange={(value: any) => {
                        setPhoneAccount(value.target.checked);
                        setPhone(value.target.checked ? stateUser?.phone : '');
                    }}
                >
                    Использовать телефон аккаунта
                </Checkbox>
            </section>

            <section className={styles.fieldSection}>
                <Input
                    disabled={emailAccount}
                    size="large"
                    value={email}
                    onChange={(value: any) => setEmail(value.target.value)}
                    placeholder="Email продавца"
                />
                <Checkbox
                    checked={emailAccount}
                    onChange={(value: any) => {
                        setEmailAccount(value.target.checked);
                        setEmail(value.target.checked ? stateUser?.email : '');
                    }}
                >
                    Использовать email аккаунта
                </Checkbox>
            </section>

            <div>
                <Button
                    type="primary"
                    onClick={() => {
                        secondaryFlat({
                            total_price,
                            square_metres,
                            phone,
                            email,
                            type: edit ? 'EDIT' : 'SALE',
                        });
                    }}
                >
                    {edit ? 'Редактировать' : 'Опубликовать'}
                </Button>
                {edit && (
                    <Button
                        onClick={() => {
                            secondaryFlat({
                                total_price,
                                square_metres,
                                phone,
                                email,
                                type: 'SOLD',
                            });
                        }}
                    >
                        Распубликовать
                    </Button>
                )}
            </div>
        </Modal>
    );
};

export default FormSecondaryFlat;
