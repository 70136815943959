import { gql } from '@apollo/client';

const getRealEstate = gql`
    query ($params: pageParams) {
        getRealEstate(params: $params) {
            pages
            count
            data {
                _id
                title
                count_booking
                signed_by_realtor
                photo {
                    url
                }
                management {
                    phone
                    name
                }
                commission
                min_price_square_metres
                builder {
                    logo {
                        url
                    }
                }
                price_information {
                    single_room {
                        min {
                            price
                            area
                        }
                    }
                    two_room {
                        min {
                            price
                            area
                        }
                    }
                    three_room {
                        min {
                            price
                            area
                        }
                    }
                    four_room {
                        min {
                            price
                            area
                        }
                    }
                }
            }
        }
    }
`;

const getRealEstateByRealtor = gql`
    query {
        getRealEstateByRealtor {
            _id
            title
            count_booking
            signed_by_realtor
            photo {
                url
            }
            management {
                phone
                name
            }
        }
    }
`;

const getManagersOfRealtor = gql`
    query {
        getManagersOfRealtor {
            _id
            firstname
            lastname
            email
            phone
            address
            role {
                type
            }
            createdAt
            settings {
                company_name
                real_estate {
                    _id
                    title
                }
            }
        }
    }
`;

const getChessOfRealEstate = gql`
    query ($fields: getNotificationsOfRealEstateInput, $id: String!) {
        getChessOfRealEstate(id: $id) {
            a_complex
            blocks {
                _id
                title
                floors_count
                floors {
                    floor
                    flats {
                        _id
                        title
                        floor
                        count_rooms
                        square_metres
                        total_price
                        sold
                        square_metre_price
                        commercial
                        booking
                        secondary
                        secondary_info {
                            total_price
                            square_metres
                            phone
                            email
                            realtor {
                                phone
                                email
                                _id
                            }
                        }
                        booking_notification
                        booking_comunication {
                            realtor {
                                phone
                                email
                                _id
                            }
                            builder {
                                phone
                                email
                                _id
                            }
                        }
                        template {
                            plan_photo {
                                url
                                _id
                            }
                            preview_photo {
                                url
                                _id
                            }
                            ceiling_height
                        }
                    }
                }
            }
        }

        getManagerOfRealEstate(id: $id) {
            _id
            firstname
            phone
        }

        getNotificationsOfRealEstate(fields: $fields) {
            _id
            title
            booking
            booking_notification
            template {
                plan_photo {
                    _id
                    url
                }
                preview_photo {
                    _id
                    url
                }
                ceiling_height
            }
            booking_comunication {
                realtor {
                    _id
                    phone
                    email
                }
                builder {
                    _id
                    phone
                    email
                }
            }
            square_metre_price
            sold
            square_metres
            floor
        }
    }
`;

const getChessOfRealEstateByRealtor = gql`
    query ($fields: getNotificationsOfRealEstateInput, $id: String!) {
        getChessOfRealEstateByRealtor(id: $id) {
            a_complex
            blocks {
                _id
                title
                floors_count
                floors {
                    floor
                    flats {
                        _id
                        title
                        floor
                        count_rooms
                        square_metres
                        total_price
                        sold
                        square_metre_price
                        commercial
                        booking
                        secondary
                        secondary_info {
                            total_price
                            square_metres
                            phone
                            email
                            realtor {
                                phone
                                email
                                _id
                            }
                        }
                        booking_comunication {
                            realtor {
                                phone
                                email
                                _id
                            }
                            builder {
                                phone
                                email
                                _id
                            }
                        }
                        template {
                            plan_photo {
                                url
                                _id
                            }
                            preview_photo {
                                url
                                _id
                            }
                            ceiling_height
                        }
                    }
                }
            }
        }
        getNotificationsOfRealEstate(fields: $fields) {
            _id
            title
            floor
            count_rooms
            square_metres
            total_price
            sold
            square_metre_price
            commercial
            booking
            booking_notification
            secondary
            secondary_info {
                total_price
                square_metres
                phone
                email
                realtor {
                    phone
                    email
                    _id
                }
            }
            booking_comunication {
                realtor {
                    _id
                    phone
                    email
                }
                builder {
                    _id
                    phone
                    email
                }
            }
            template {
                plan_photo {
                    url
                    _id
                }
                preview_photo {
                    url
                    _id
                }
                ceiling_height
            }
        }
    }
`;

const realEstateUpdateUser = gql`
    mutation ($fields: RealEstateUpdateUserInput) {
        realEstateUpdateUser(fields: $fields) {
            real_estate_id
            name
            phone
        }
    }
`;

const flatUpdate = gql`
    mutation ($fields: flatUpdateInput) {
        flatUpdate(fields: $fields) {
            sold
            square_metre_price
            flat_id
            real_estate_id
        }
    }
`;

const flatSecondary = gql`
    mutation ($fields: flatSecondaryInput) {
        flatSecondary(fields: $fields) {
            _id
            title
            floor
            count_rooms
            square_metres
            total_price
            sold
            square_metre_price
            commercial
            booking
            booking_notification
            secondary
            secondary_info {
                total_price
                square_metres
                phone
                email
                realtor {
                    phone
                    email
                    _id
                }
            }
            template {
                plan_photo {
                    url
                    _id
                }
                preview_photo {
                    url
                    _id
                }
                ceiling_height
            }
        }
    }
`;

const addManager = gql`
    mutation ($fields: ManagerInput) {
        addManager(fields: $fields)
    }
`;

const deleteManager = gql`
    mutation ($id: String) {
        deleteManager(id: $id)
    }
`;

const getRealEstateByRealtorAndManager = gql`
    query ($id: String) {
        getRealEstateByRealtor {
            _id
            title
            photo {
                url
            }
            management {
                phone
                name
            }
        }

        getManager(id: $id) {
            _id
            email
            firstname
            lastname
            phone
            address
            role {
                type
            }
            createdAt
            settings {
                company_name
                real_estate {
                    _id
                    title
                    photo {
                        url
                    }
                }
            }
        }
    }
`;

const getNotificationsOfRealEstate = gql`
    query ($fields: getNotificationsOfRealEstateInput) {
        getNotificationsOfRealEstate(fields: $fields) {
            _id
            title
            booking
            booking_notification
            template {
                plan_photo {
                    _id
                    url
                }
                preview_photo {
                    _id
                    url
                }
                ceiling_height
            }
            booking_comunication {
                realtor {
                    _id
                    phone
                    email
                }
                builder {
                    _id
                    phone
                    email
                }
            }
            square_metre_price
            sold
            square_metres
            floor
        }
    }
`;

const editManager = gql`
    mutation ($fields: ManagerInput) {
        editManager(fields: $fields)
    }
`;

const requestBooking = gql`
    mutation ($fields: requestBookingInput) {
        requestBooking(fields: $fields) {
            _id
            title
            floor
            count_rooms
            square_metres
            total_price
            sold
            square_metre_price
            commercial
            booking
            booking_notification
            secondary
            secondary_info {
                total_price
                square_metres
                phone
                email
                realtor {
                    _id
                    email
                    phone
                }
            }
            booking_comunication {
                realtor {
                    _id
                    email
                    phone
                }
                builder {
                    _id
                    email
                    phone
                }
            }
            template {
                plan_photo {
                    url
                    _id
                }
                preview_photo {
                    url
                    _id
                }
                ceiling_height
            }
        }
    }
`;

const BudgetNotificationHide = gql`
    mutation BudgetNotificationHide($budgetNotificationHideId: String) {
        budgetNotificationHide(id: $budgetNotificationHideId)
    }
`;

const getManagerOfRealEstate = gql`
    query ($id: String) {
        getManagerOfRealEstate(id: $id)
    }
`;

const getDataForCreateCompany = gql`
    query {
        getRealestateForCompany {
            _id
            title
            crm_price_lead
        }
        leadPrice
    }
`;

const createCompany = gql`
    mutation ($fields: createCompanyInput) {
        createCompany(fields: $fields)
    }
`;

const editCompany = gql`
    mutation ($fields: editCompanyInput) {
        editCompany(fields: $fields)
    }
`;

const deleteCompany = gql`
    mutation ($id: String) {
        deleteCompany(id: $id)
    }
`;

const getCompanies = gql`
    query ($type: String) {
        getCrmCompanies(type: $type) {
            _id
            title
            realestate {
                _id
                title
            }
            quantity_leads
            collected_leads
            budget
            status
            gift
        }
    }
`;

const getTransactions = gql`
    query ($type: String) {
        getTransactions(type: $type) {
            _id
            transaction
            SessionID
            amount
            status
            user {
                _id
                email
            }
            type_payment
            crm_company {
                _id
                title
                realestate {
                    _id
                    title
                }
                quantity_leads
                collected_leads
                budget
                status
                gift
            }
        }
    }
`;

const getLeadsOfCompany = gql`
    query ($fields: InputLeadsOfCompany, $id: String) {
        getLeadsOfCompany(fields: $fields) {
            _id
            crm_company {
                title
            }
            status {
                title
                color
            }
            client_name
            client_phone
            link
            realestate {
                title
            }
            createdAt
            timeline_events {
                time
                message
            }
        }

        getInfoOfCompany(id: $id) {
            title
            realestate {
                _id
                title
            }
        }

        crmStatuses {
            _id
            title
            color
        }
    }
`;

const getCrmStatuses = gql`
    query {
        crmStatuses {
            _id
            title
            color
        }
    }
`;

const createCrmStatus = gql`
    mutation ($title: String, $color: String) {
        createCrmStatus(title: $title, color: $color)
    }
`;

const editCrmStatus = gql`
    mutation ($id: String, $title: String, $color: String) {
        editCrmStatus(title: $title, color: $color, id: $id)
    }
`;

const deleteCrmStatus = gql`
    mutation ($id: String) {
        deleteCrmStatus(id: $id)
    }
`;

const crmAddCommentLead = gql`
    mutation ($lead_id: String, $comment: String) {
        crmAddCommentLead(lead_id: $lead_id, comment: $comment)
    }
`;

const changeStatusLead = gql`
    mutation ($lead_id: String, $status: String) {
        changecrmStatusLead(lead_id: $lead_id, status: $status)
    }
`;

const getLeadsOfCompanyFilter = gql`
    query ($fields: InputLeadsOfCompany) {
        getLeadsOfCompany(fields: $fields) {
            _id
            crm_company {
                title
            }
            status {
                title
                color
            }
            client_name
            client_phone
            link
            realestate {
                title
            }
            createdAt
            timeline_events {
                time
                message
            }
        }
    }
`;

export default {
    getLeadsOfCompanyFilter,
    changeStatusLead,
    crmAddCommentLead,
    createCrmStatus,
    editCrmStatus,
    deleteCrmStatus,
    getCrmStatuses,
    getLeadsOfCompany,
    getTransactions,
    editCompany,
    getCompanies,
    createCompany,
    deleteCompany,
    flatSecondary,
    getDataForCreateCompany,
    getRealEstate,
    getChessOfRealEstateByRealtor,
    realEstateUpdateUser,
    getChessOfRealEstate,
    flatUpdate,
    getRealEstateByRealtor,
    getManagersOfRealtor,
    addManager,
    deleteManager,
    getRealEstateByRealtorAndManager,
    editManager,
    requestBooking,
    BudgetNotificationHide,
    getManagerOfRealEstate,
    getNotificationsOfRealEstate,
};
