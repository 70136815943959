import React, { useState, useEffect } from 'react';
import styles from './styles/leads.module.scss';
import { Tabs, Button, Menu, Dropdown, message } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import CreateCompany from './helpers/create_company';
import { useLazyQuery, useMutation } from '@apollo/client';
import * as gqls from '@/Services/apollo/gqls';
import Companies from './helpers/companies';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Leads = () => {
    const Navigate = useNavigate();

    const dispatch = useDispatch();
    const stateCompanies = useSelector((state: any) => state.companies);

    const [type_companies, set_type_companies] = useState('active');

    const [createCompany, createCompanyData] = useMutation(
        gqls.builder.createCompany,
        {
            onError: (data: any) => {
                if (data?.graphQLErrors && data?.graphQLErrors.length) {
                    return message.error(data?.graphQLErrors[0]?.message);
                }
            },
            onCompleted: () => {
                return dispatch({
                    type: 'MODAL_FORM_COMPANY',
                    status: false,
                });
            },
        }
    );

    const [editCompany, editCompanyData] = useMutation(
        gqls.builder.editCompany,
        {
            onError: (data: any) => {
                if (data?.graphQLErrors && data?.graphQLErrors.length) {
                    return message.error(data?.graphQLErrors[0]?.message);
                }
            },
            onCompleted: () => {
                dispatch({
                    type: 'MODAL_EDIT_FORM_COMPANY',
                    item: null,
                });
                return dispatch({
                    type: 'MODAL_FORM_COMPANY',
                    status: false,
                });
            },
        }
    );

    const [deleteCompany, deleteCompanyData] = useMutation(
        gqls.builder.deleteCompany,
        {
            onError: (data: any) => {
                if (data?.graphQLErrors && data?.graphQLErrors.length) {
                    return message.error(data?.graphQLErrors[0]?.message);
                }
            },
        }
    );

    const [loadCompanies, { data, loading }] = useLazyQuery(
        gqls.builder.getCompanies,
        {
            fetchPolicy: 'network-only',
            variables: {
                type: type_companies,
            },
        }
    );

    useEffect(() => {
        loadCompanies({
            variables: {
                type: type_companies,
            },
        });
    }, [
        type_companies,
        createCompanyData?.data?.createCompany,
        deleteCompanyData?.data?.deleteCompany,
        editCompanyData?.data?.editCompany,
    ]);

    const menu = (
        <Menu
            items={[
                {
                    key: '1',
                    label: (
                        <span
                            onClick={() => {
                                return Navigate('/leads/statuses');
                            }}
                        >
                            Управление статусами
                        </span>
                    ),
                },
            ]}
        />
    );

    return (
        <section className={styles.wrapper}>
            <CreateCompany
                isModalOpen={stateCompanies?.modal_form_company}
                createCompany={createCompany}
                createCompanyData={createCompanyData}
                editCompanyData={editCompanyData}
                editCompany={editCompany}
                close={() => {
                    dispatch({
                        type: 'MODAL_FORM_COMPANY',
                        status: false,
                    });
                }}
            />

            <div className={styles.header}>
                <h2 className={styles.settingsTitle}>Компании</h2>
                <div className={styles.headerRight}>
                    <Button
                        onClick={() => {
                            dispatch({
                                type: 'MODAL_FORM_COMPANY',
                                status: true,
                            });
                        }}
                        type="primary"
                    >
                        Создать компанию
                    </Button>
                    <Dropdown trigger={['click']} overlay={menu}>
                        <Button>
                            <MoreOutlined />
                        </Button>
                    </Dropdown>
                </div>
            </div>
            <Tabs
                activeKey={type_companies}
                size="large"
                defaultActiveKey="active"
                onTabClick={(key: any) => {
                    set_type_companies(key);
                }}
            >
                <Tabs.TabPane tab="Активные" key="active">
                    <Companies
                        deleteCompany={deleteCompany}
                        type_companies={type_companies}
                        loading={loading}
                        data={data?.getCrmCompanies}
                        no_data_text="Нет активных компаний"
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Ожидают оплаты" key="await">
                    <Companies
                        deleteCompany={deleteCompany}
                        type_companies={type_companies}
                        loading={loading}
                        data={data?.getCrmCompanies}
                        no_data_text="Нет компаний ожидающих оплаты"
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Завершенные" key="completed">
                    <Companies
                        deleteCompany={deleteCompany}
                        type_companies={type_companies}
                        loading={loading}
                        data={data?.getCrmCompanies}
                        no_data_text="Нет завершенных компаний"
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab={`Подарочные`} key="gift">
                    <Companies
                        deleteCompany={deleteCompany}
                        type_companies={type_companies}
                        loading={loading}
                        data={data?.getCrmCompanies}
                        no_data_text="Нет подарочных компаний"
                    />
                </Tabs.TabPane>
            </Tabs>
        </section>
    );
};

export default Leads;
