import React, { useEffect, useState } from 'react';
import styles from '../styles/leads.module.scss';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import * as gqls from '@/Services/apollo/gqls';
import { LoadingOutlined, MoreOutlined } from '@ant-design/icons';
import {
    Result,
    Popover,
    Menu,
    Dropdown,
    Drawer,
    Timeline,
    Modal,
    Input,
    message,
    Select,
} from 'antd';
import FilterLeads from '../helpers/filter-leads';
import { moment } from '@/Services';
import { useDispatch, useSelector } from 'react-redux';

const { TextArea } = Input;
const { Option } = Select;

const Company = () => {
    const dispatch = useDispatch();
    const stateLead = useSelector((state: any) => state.companies.lead);

    const [comment, set_comment] = useState('');
    const [status_lead, set_status_lead] = useState(null);

    let { company_id } = useParams();

    const [addCommentLead, addCommentLeadData] = useMutation(
        gqls.builder.crmAddCommentLead,
        {
            fetchPolicy: 'network-only',
            onError: () => {
                set_comment('');
                dispatch({
                    type: 'UPDATE_INFO_LEAD',
                    lead: null,
                });
                return message.warning('Что-то пошло не так, попробуйте позже');
            },
            onCompleted: () => {
                set_comment('');
                dispatch({
                    type: 'UPDATE_INFO_LEAD',
                    lead: null,
                });
                loadLeads();
                return message.success('Комментарий успешно добавлен');
            },
        }
    );

    const [changeStatusLead, changeStatusLeadData] = useMutation(
        gqls.builder.changeStatusLead,
        {
            fetchPolicy: 'network-only',
            onError: () => {
                set_status_lead(null);
                dispatch({
                    type: 'UPDATE_INFO_LEAD',
                    lead: null,
                });
                return message.warning('Что-то пошло не так, попробуйте позже');
            },
            onCompleted: () => {
                set_status_lead(null);
                dispatch({
                    type: 'UPDATE_INFO_LEAD',
                    lead: null,
                });
                loadLeads();
                return message.success('Статус успешно изменен');
            },
        }
    );

    const [loadLeads, { data, loading }] = useLazyQuery(
        gqls.builder.getLeadsOfCompany,
        {
            fetchPolicy: 'network-only',
        }
    );

    useEffect(() => {
        loadLeads({
            variables: {
                fields: {
                    company_id,
                },
                id: company_id,
            },
        });
    }, []);

    const menu = (item: any) => (
        <Menu
            items={[
                {
                    key: '1',
                    label: (
                        <span
                            onClick={() => {
                                dispatch({
                                    type: 'UPDATE_INFO_LEAD',
                                    lead: {
                                        type_lead: 'modal_status_lead',
                                        ...item,
                                    },
                                });
                            }}
                        >
                            Изменить статус
                        </span>
                    ),
                },
                {
                    key: '2',
                    label: (
                        <span
                            onClick={() => {
                                dispatch({
                                    type: 'UPDATE_INFO_LEAD',
                                    lead: {
                                        type_lead: 'modal_create_event',
                                        ...item,
                                    },
                                });
                            }}
                        >
                            Добавить комментарий
                        </span>
                    ),
                },
                {
                    key: '3',
                    label: (
                        <span
                            onClick={() => {
                                dispatch({
                                    type: 'UPDATE_INFO_LEAD',
                                    lead: {
                                        type_lead: 'drawer',
                                        ...item,
                                    },
                                });
                            }}
                        >
                            События
                        </span>
                    ),
                },
            ]}
        />
    );

    return (
        <section className={styles.wrapper}>
            <Modal
                width="45%"
                title="Изменить статус лида"
                visible={
                    stateLead?.type_lead === 'modal_status_lead' ? true : false
                }
                onOk={() => {
                    if (!changeStatusLeadData?.loading) {
                        if (status_lead) {
                            return changeStatusLead({
                                variables: {
                                    lead_id: stateLead?._id,
                                    status: status_lead,
                                },
                            });
                        } else {
                            return message.warning('Заполните поле');
                        }
                    }
                }}
                okText={
                    addCommentLeadData?.loading
                        ? `Подождите...`
                        : 'Изменить статус'
                }
                onCancel={() => {
                    dispatch({
                        type: 'UPDATE_INFO_LEAD',
                        lead: null,
                    });
                }}
            >
                <Select
                    size="large"
                    allowClear
                    showSearch
                    style={{
                        width: '100%',
                    }}
                    value={status_lead}
                    placeholder="Выберите статус"
                    onChange={(value: any) => set_status_lead(value)}
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                        option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA: any, optionB: any) =>
                        optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                    }
                >
                    {data?.crmStatuses &&
                        data?.crmStatuses.length &&
                        data?.crmStatuses.map((item: any) => {
                            return (
                                <Option key={item?._id} value={item?._id}>
                                    {item?.title}
                                </Option>
                            );
                        })}
                </Select>
            </Modal>

            <Modal
                width="45%"
                title="Форма добавления комментария"
                visible={
                    stateLead?.type_lead === 'modal_create_event' ? true : false
                }
                onOk={() => {
                    if (!addCommentLeadData?.loading) {
                        if (comment) {
                            return addCommentLead({
                                variables: {
                                    lead_id: stateLead?._id,
                                    comment,
                                },
                            });
                        } else {
                            return message.warning('Заполните поле');
                        }
                    }
                }}
                okText={
                    addCommentLeadData?.loading
                        ? `Подождите... ${(<LoadingOutlined />)}`
                        : 'Добавить комментарий'
                }
                onCancel={() => {
                    dispatch({
                        type: 'UPDATE_INFO_LEAD',
                        lead: null,
                    });
                }}
            >
                <TextArea
                    onChange={(val) => set_comment(val.target.value)}
                    size="large"
                    rows={4}
                    placeholder="Новый комментарий к лиду"
                    maxLength={400}
                />
            </Modal>

            <Drawer
                title={`События`}
                placement="right"
                onClose={() => {
                    dispatch({
                        type: 'UPDATE_INFO_LEAD',
                        lead: null,
                    });
                }}
                open={stateLead?.type_lead === 'drawer' ? true : false}
                width="500px"
            >
                {stateLead?.timeline_events &&
                stateLead?.timeline_events.length ? (
                    <Timeline mode={'left'}>
                        {stateLead?.timeline_events.map((message: any) => {
                            return (
                                <Timeline.Item
                                    key={Number(message?.time) * Math.random()}
                                    label={moment(message?.time).format(
                                        `DD MMMM YYYY в HH:mm`
                                    )}
                                >
                                    {message?.message}
                                </Timeline.Item>
                            );
                        })}
                    </Timeline>
                ) : (
                    <Result
                        status="403"
                        title="Событий не найдно"
                        subTitle="Информации нет"
                        extra={null}
                    />
                )}
            </Drawer>

            <div className={styles.header}>
                <h2 className={styles.settingsTitle}>
                    Лиды компании {data?.getInfoOfCompany?.title}
                </h2>
            </div>
            <div className={styles.content}>
                <FilterLeads
                    loadLeads={loadLeads}
                    company_id={company_id}
                    loading={loading}
                    realestate={data?.getInfoOfCompany?.realestate}
                    statuses={data?.crmStatuses || []}
                />

                {loading ? (
                    <div className={styles.infoBlock}>
                        <LoadingOutlined />
                    </div>
                ) : data?.getLeadsOfCompany &&
                  data?.getLeadsOfCompany.length ? (
                    <div className={styles.list}>
                        {data?.getLeadsOfCompany.map((item: any) => (
                            <div key={item?._id} className={styles.item}>
                                <div className={styles.section}>
                                    <Popover content="Имя клиента" title="">
                                        <p>{item?.client_name}</p>
                                    </Popover>
                                </div>
                                <div className={styles.line}></div>
                                <div className={styles.section}>
                                    <Popover content="Телефон клиента" title="">
                                        <p>
                                            <a
                                                href={`tel:${item?.client_phone}`}
                                            >
                                                {item?.client_phone}
                                            </a>
                                        </p>
                                    </Popover>
                                </div>
                                <div className={styles.line}></div>
                                <div className={styles.section}>
                                    <Popover
                                        content="Ссылка на источник (откроется в новой вкладке)"
                                        title=""
                                    >
                                        <p>
                                            <a
                                                target="_blank"
                                                href={`${item?.link}`}
                                            >
                                                Источник
                                            </a>
                                        </p>
                                    </Popover>
                                </div>
                                <div className={styles.line}></div>

                                <div className={styles.section}>
                                    <Popover content="Жилой комплекс" title="">
                                        <p>
                                            {item?.realestate?.title ||
                                                'Не опредено'}
                                        </p>
                                    </Popover>
                                </div>
                                <div className={styles.line}></div>
                                <div className={styles.section}>
                                    <Popover content="Дата создания" title="">
                                        <p>
                                            {moment(item?.createdAt).format(
                                                'DD MMMM YYYY в HH:mm'
                                            )}
                                        </p>
                                    </Popover>
                                </div>
                                <div className={styles.line}></div>
                                <div className={styles.section}>
                                    <Popover content="Статус" title="">
                                        <p>{item?.status?.title || 'Новый'}</p>
                                    </Popover>
                                </div>
                                <div className={styles.line}></div>
                                <div
                                    className={styles.section}
                                    style={{ width: 55 }}
                                >
                                    <Dropdown
                                        trigger={['click']}
                                        overlay={() => menu(item)}
                                    >
                                        <MoreOutlined />
                                    </Dropdown>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <Result
                        status="404"
                        title="Лидов нет"
                        subTitle={'информации пока нет'}
                        extra={null}
                    />
                )}
                <div className={styles.list}></div>
            </div>
        </section>
    );
};

export default Company;
