import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import * as gqls from '@/Services/apollo/gqls';
import Loader from '../../Loader';
import * as types from './interfaces';
import styles from './styles/home.module.scss';
import { RealEstateCard, Filter } from '@/Components';
import { Pagination } from 'antd';

const Home = () => {
    const [commission, set_commission] = useState<string | number>('Все');
    const [search_text, set_search_text] = useState<string>('');
    const [page, set_page] = useState<number>(1);
    const [limit, set_limit] = useState<number>(24);

    const [loadData, { data, loading, error }] = useLazyQuery(
        gqls.builder.getRealEstate,
        {
            variables: {
                params: {
                    commission,
                    search_text,
                    page,
                    limit,
                },
            },
        }
    );

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div>
            <Filter
                search_text={(value: string) => {
                    set_page(1);
                    set_limit(24);
                    set_search_text(value || '');
                }}
                commission={(value: string | number) => {
                    set_page(1);
                    set_limit(24);
                    set_commission(value || '');
                }}
                count={data?.getRealEstate?.count || 0}
            />

            {!loading ? (
                !error &&
                data?.getRealEstate?.data &&
                data?.getRealEstate?.data.length ? (
                    <>
                        <div className={styles.realEstateItems}>
                            {data?.getRealEstate?.data.map(
                                (realEstate: types.RealEstateType) => (
                                    <RealEstateCard
                                        key={realEstate._id}
                                        realEstate={realEstate}
                                    />
                                )
                            )}
                        </div>
                    </>
                ) : (
                    <div>
                        <h4>Жилых комплексов не найдено</h4>
                    </div>
                )
            ) : (
                <Loader width="100%" height="100%" background="transparent" />
            )}

            {data?.getRealEstate?.count > 24 ? (
                <div className={styles.pagination}>
                    <Pagination
                        pageSizeOptions={[24, 48, 76]}
                        showLessItems={false}
                        pageSize={limit}
                        current={page}
                        total={data?.getRealEstate?.count}
                        onChange={(page: number, limit: number) => {
                            window.scrollTo({
                                top: 0,
                                behavior: 'smooth',
                            });
                            set_page(page);
                            set_limit(limit);
                        }}
                    />
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default Home;
