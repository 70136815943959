import chess from './chess';
import widgets from './Widgets';
import payments from './payments';
import account from './account';
import contracts from './contracts';
import realestate from './realestate';
import files from './files';
import library from './Library';

export default {
    chess,
    widgets,
    payments,
    account,
    contracts,
    realestate,
    files,
    library
};
