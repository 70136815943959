import React, { useEffect, useRef } from 'react';
import { notification } from 'antd';
import { useDispatch } from 'react-redux';
import { useSubscription } from '@apollo/client';
import * as gqls from '@/Services/apollo/gqls';

const SubscriptionProvider = ({children}) => {

    const dispatch = useDispatch();

    const { data } = useSubscription(gqls.contracts.newContract, {
        variables: {
            params: {
                token: localStorage.getItem('SESSION_TOKEN')
            }
        }
    });

    useEffect(()=>{
        if(data?.contractPurchaseApartment){
            notification.success({
                message: `Новая бронь`,
                description: `Поступила новая бронь: ${data?.contractPurchaseApartment?.contract_number}`,
                duration: 0,
            });
            dispatch({
                type: 'ADD_NEW_APPLICATION_CONTRACT',
                contract: data?.contractPurchaseApartment
            })
        }
    }, [data]);

    return (
        <>
            {children}
        </>
    )
}

export default SubscriptionProvider;