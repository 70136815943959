import react from 'react';
import styles from '../styles/chess.module.scss';
import { BookingNotification } from '@/Components';
import { Result } from 'antd';

const Notifications = ({ items }: any) => {
    return (
        <div>
            {items && items?.length ? (
                items.map((item: any) => (
                    <div key={item?._id}>
                        <BookingNotification
                            bookingType={item?.booking}
                            bookingNotificationRole={item?.booking_notification}
                            flatNumber={item?.title}
                            flatID={item?._id}
                            disableCloseBtn={true}
                            flat={item}
                            openButton
                        />
                    </div>
                ))
            ) : (
                <div>
                    <Result title="Уведомлений нет" extra={null} />
                </div>
            )}
        </div>
    );
};

export default Notifications;
