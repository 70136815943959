import { Loader } from '@/Components';
import { api } from '@/Services';
import { Typography } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from './form';

const { Title } = Typography;

const EditTemplateSettings = () => {
    const dispatch = useDispatch();
    const builder = useSelector((state: any) => state?.account?.user?.builder);
    const realestate = useSelector((state: any) => state?.realestate);

    useEffect(() => {
        dispatch(api.realestate.getBuilder(builder?._id));
    }, []);

    return (
        <>
            <Title level={5}>Настройка шаблона</Title>

            <div>
                {realestate?.builder_request_status ? (
                    <Loader />
                ) : (
                    <Form
                        pattern={
                            realestate?.builder_information
                                ?.template_apartment_pattern || []
                        }
                    />
                )}
            </div>
        </>
    );
};

export default EditTemplateSettings;
