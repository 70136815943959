import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import * as gqls from '@/Services/apollo/gqls';
import Loader from '../../Loader';
import { RealEstateType } from './interfaces';
import { RealEstateCard, Filter } from '@/Components';
import styles from './styles/home.module.scss';
import { Pagination, Typography, Button } from 'antd';
import RealestateForm from './helpers/realestate-form';

const { Title } = Typography;

const Home = () => {
    const [page, set_page] = useState<number>(1);
    const [limit, set_limit] = useState<number>(24);
    const [search_text, set_search_text] = useState<string>('');

    const [form_realestate, set_form_realestate] = useState(false);

    const [loadData, { data, loading }] = useLazyQuery(
        gqls.builder.getRealEstate,
        {
            variables: {
                params: {
                    page,
                    limit,
                    search_text,
                },
            },
            fetchPolicy: 'network-only'
        }
    );

    useEffect(() => {
        loadData();
    }, []);

    return (
        <>

            <RealestateForm isModalOpen={form_realestate} close={()=>set_form_realestate(false)}/>

            <div className={styles.header}>
                <Title level={4}>Жилые комплексы</Title>
                <Button onClick={()=>set_form_realestate(true)} type='primary'>Добавить жк</Button>
            </div>

            <Filter
                search_text={(value: string) => {
                    set_page(1);
                    set_limit(24);
                    set_search_text(value || '');
                }}
                commission={(value: string | number) => {
                    set_page(1);
                    set_limit(24);
                }}
                count={data?.getRealEstate?.count || 0}
            />

            {!loading ? (
                data?.getRealEstate && data?.getRealEstate?.data.length ? (
                    <div className={styles.realEstateItems}>
                        {data?.getRealEstate?.data.map(
                            (realEstate: RealEstateType) => (
                                <RealEstateCard
                                    key={realEstate._id}
                                    realEstate={realEstate}
                                    isBuilder={true}
                                />
                            )
                        )}
                    </div>
                ) : (
                    <div>
                        <h4>Жилых комплексов не найдено</h4>
                    </div>
                )
            ) : (
                <Loader width="100%" height="100%" background="transparent" />
            )}

            {data?.getRealEstate?.count > 24 ? (
                <div className={styles.pagination}>
                    <Pagination
                        pageSizeOptions={[24, 48, 76]}
                        showLessItems={false}
                        pageSize={limit}
                        current={page}
                        total={data?.getRealEstate?.count}
                        onChange={(page: number, limit: number) => {
                            window.scrollTo({
                                top: 0,
                                behavior: 'smooth',
                            });
                            set_page(page);
                            set_limit(limit);
                        }}
                    />
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default Home;
